import { TableAction, TableColumn } from '@components';
import { TableRowSkeleton } from '@components/Table/components/TableRowSkeleton';
import React from 'react';

const getActionColumn = (): TableColumn => {
  return {
    title: '',
    key: 'actions',
    paddingLeft: 16,
    paddingRight: 16,
    alignment: 'right'
  };
};

const getSkeletonRows = (
  columns: TableColumn[],
  actions: TableAction[],
  actionColumn: TableColumn
) => {
  const rowsAmount = 6;
  const rows = [];

  for (let i = 0; i < rowsAmount; i++) {
    rows.push(
      <TableRowSkeleton
        key={i}
        columns={columns}
        actions={actions}
        actionColumn={actionColumn}
      />
    );
  }
  return rows;
};

export { getActionColumn, getSkeletonRows };
