import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const NumberIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.38268 7.07615C5.75636 7.23093 6 7.59557 6 8.00003V16C6 16.5523 5.55228 17 5 17C4.44772 17 4 16.5523 4 16V10.4142L3.70711 10.7071C3.31658 11.0977 2.68342 11.0977 2.29289 10.7071C1.90237 10.3166 1.90237 9.68345 2.29289 9.29292L4.29289 7.29292C4.57889 7.00692 5.00901 6.92137 5.38268 7.07615ZM8 8.00003C8 7.44774 8.44772 7.00003 9 7.00003H12C12.5304 7.00003 13.0391 7.21074 13.4142 7.58582C13.7893 7.96089 14 8.4696 14 9.00003V11C14 11.5305 13.7893 12.0392 13.4142 12.4142C13.0391 12.7893 12.5304 13 12 13H10V15H13C13.5523 15 14 15.4477 14 16C14 16.5523 13.5523 17 13 17H10C9.46957 17 8.96086 16.7893 8.58579 16.4142C8.21071 16.0392 8 15.5305 8 15V13C8 12.4696 8.21071 11.9609 8.58579 11.5858C8.96086 11.2107 9.46957 11 10 11H12V9.00003H9C8.44772 9.00003 8 8.55231 8 8.00003ZM19.5 9.00003H17C16.4477 9.00003 16 8.55231 16 8.00003C16 7.44774 16.4477 7.00003 17 7.00003H19.5C20.163 7.00003 20.7989 7.26342 21.2678 7.73226C21.7366 8.2011 22 8.83699 22 9.50003V10.5C22 11.0441 21.8227 11.5698 21.5 12C21.8227 12.4302 22 12.956 22 13.5V14.5C22 15.1631 21.7366 15.799 21.2678 16.2678C20.7989 16.7366 20.163 17 19.5 17H17C16.4477 17 16 16.5523 16 16C16 15.4477 16.4477 15 17 15H19.5C19.6326 15 19.7598 14.9474 19.8536 14.8536C19.9473 14.7598 20 14.6326 20 14.5V13.5C20 13.3674 19.9473 13.2402 19.8536 13.1465C19.7598 13.0527 19.6326 13 19.5 13H18C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11H19.5C19.6326 11 19.7598 10.9474 19.8536 10.8536C19.9473 10.7598 20 10.6326 20 10.5V9.50003C20 9.36742 19.9473 9.24024 19.8536 9.14648C19.7598 9.05271 19.6326 9.00003 19.5 9.00003Z"
      fill={props.color}
    />
  </SvgIcon>
);

NumberIcon.displayName = 'NumberIcon';
