import React, { FC } from 'react';
import styles from './SkeletonPlaceholder.module.scss';
import { SkeletonPlaceholderType } from './dataTypes';
import { ComponentProps } from '@components/dataTypes';
import classNames from 'classnames';

export interface SkeletonPlaceholderProps extends ComponentProps {
  width: number | string;
  height: number | string;
  type?: SkeletonPlaceholderType;
}

export const SkeletonPlaceholder: FC<SkeletonPlaceholderProps> = (props) => {
  const {
    className,
    id,
    style,
    dataTestId,
    type = 'rectangle',
    height,
    width
  } = props;

  return (
    <div
      id={id}
      style={{ ...style, width, height }}
      className={classNames(
        styles[`skeleton_placeholders--${type}`],
        className
      )}
      data-testid={dataTestId}
    />
  );
};

SkeletonPlaceholder.displayName = 'SkeletonPlaceholder';
