import React, { FC } from 'react';
import { ComponentProps, RadioAlign, Typography } from '@components';
import styles from './Radio.module.scss';
import { RadioButton } from './dataTypes';
import { colors, offsets } from '@componentsStyles';
import cn from 'classnames';

export interface RadioProps extends ComponentProps {
  values: RadioButton[];
  onChange: (value: string) => void;
  title?: string;
  selected?: string;
  disabled?: boolean;
  radioGroupName?: string;
  align?: RadioAlign;
}

export const Radio: FC<RadioProps> = (props) => {
  const {
    id,
    className,
    style,
    selected,
    onChange,
    disabled,
    values,
    radioGroupName = 'radio',
    align = 'horizontal',
    title
  } = props;

  return (
    <div id={id} style={style} className={className}>
      {title && (
        <Typography className={offsets['mb-20']} variant="label-1">
          {title}
        </Typography>
      )}

      <div
        className={
          align === 'horizontal'
            ? styles['group-horizontal']
            : styles['group-vertical']
        }
      >
        {values?.map((radio, key) => {
          return (
            <label
              key={key}
              className={cn(
                disabled ? styles['container--disabled'] : styles.container,
                align === 'horizontal'
                  ? styles['container-horizontal']
                  : styles['container-vertical']
              )}
            >
              <input
                type="radio"
                name={radioGroupName}
                checked={radio.value === selected}
                onChange={() => onChange(radio.value)}
                disabled={disabled}
              />
              <div className={styles.checkmark}></div>
              <Typography
                variant="body-1"
                color={
                  disabled && radio.value !== selected
                    ? colors.gray20Color
                    : colors.gray100Color
                }
                className={offsets['ml-8']}
              >
                {radio.label}
              </Typography>
            </label>
          );
        })}
      </div>
    </div>
  );
};

Radio.displayName = 'Radio';
