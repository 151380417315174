import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const LicensesModelTreeIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.87868 1.87868C4.44129 1.31607 5.20435 1 6 1H14C14.2652 1 14.5196 1.10536 14.7071 1.29289L20.7071 7.29289C20.8946 7.48043 21 7.73478 21 8V20C21 20.7957 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7957 23 18 23H6C5.20435 23 4.44129 22.6839 3.87868 22.1213C3.31607 21.5587 3 20.7957 3 20V4C3 3.20435 3.31607 2.44129 3.87868 1.87868ZM5 15V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V15H18.5662L15.8575 19.5145C15.6811 19.8084 15.3665 19.9916 15.0238 19.9997C14.6811 20.0079 14.3581 19.8399 14.1679 19.5547L8.95196 11.7307L6.8 14.6C6.61115 14.8518 6.31476 15 6 15H5ZM19 13V9H14C13.4477 9 13 8.55228 13 8V3H6C5.73478 3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 4V13H5.5L8.2 9.4C8.39472 9.14037 8.70333 8.99138 9.02775 9.00039C9.35216 9.00939 9.65203 9.17527 9.83205 9.4453L14.9555 17.1305L17.1425 13.4855C17.3232 13.1843 17.6487 13 18 13H19ZM15 4.41421L17.5858 7H15V4.41421Z"
      fill={props.color}
    />
  </SvgIcon>
);

LicensesModelTreeIcon.displayName = 'LicensesModelTreeIcon';
