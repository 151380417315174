import * as React from 'react';
import { FC } from 'react';
import { PageError } from '@xq/ui-kit';
import { useNavigate } from 'react-router-dom';

export const Forbidden: FC = () => {
  const navigate = useNavigate();

  return <PageError code={403} title={'Forbidden'} navigate={navigate} />;
};

Forbidden.displayName = 'Forbidden';
