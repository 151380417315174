import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const SuccessIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.663 3.77915C13.8902 2.98926 11.9096 2.79357 10.0166 3.22128C8.12351 3.649 6.41942 4.67719 5.15845 6.15252C3.89749 7.62785 3.14721 9.47126 3.01951 11.4078C2.89181 13.3444 3.39354 15.2704 4.44987 16.8985C5.50619 18.5266 7.06051 19.7697 8.88102 20.4423C10.7015 21.1149 12.6907 21.1809 14.5518 20.6307C16.413 20.0804 18.0464 18.9432 19.2084 17.3888C20.3705 15.8344 20.9989 13.9459 21 12.0052V11.0857C21 10.5334 21.4477 10.0857 22 10.0857C22.5523 10.0857 23 10.5334 23 11.0857V12.0057C22.9986 14.3778 22.2306 16.6864 20.8103 18.5863C19.39 20.4862 17.3936 21.876 15.1189 22.5486C12.8442 23.2211 10.413 23.1404 8.18792 22.3183C5.96285 21.4963 4.06312 19.977 2.77206 17.9871C1.48099 15.9971 0.86777 13.6432 1.02384 11.2762C1.17992 8.90932 2.09693 6.65626 3.63811 4.85308C5.17929 3.0499 7.26206 1.79322 9.57581 1.27046C11.8896 0.747697 14.3103 0.986867 16.477 1.9523C16.9815 2.17708 17.2082 2.76825 16.9834 3.27272C16.7587 3.7772 16.1675 4.00393 15.663 3.77915Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7068 3.29816C23.0975 3.68848 23.0978 4.32165 22.7075 4.71237L12.7075 14.7224C12.52 14.9101 12.2656 15.0155 12.0003 15.0156C11.7349 15.0157 11.4805 14.9103 11.2929 14.7227L8.29289 11.7227C7.90237 11.3322 7.90237 10.699 8.29289 10.3085C8.68342 9.91799 9.31658 9.91799 9.70711 10.3085L11.9996 12.601L21.2925 3.29886C21.6829 2.90814 22.316 2.90783 22.7068 3.29816Z"
      fill={props.color}
    />
  </SvgIcon>
);

SuccessIcon.displayName = 'SuccessIcon';
