import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const ZapIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3987 1.0812C13.8059 1.25797 14.0478 1.68204 13.9928 2.12253L13.1333 8.9985H21.0005C21.3885 8.9985 21.7415 9.22296 21.9061 9.57434C22.0707 9.92573 22.0171 10.3406 21.7687 10.6387L11.7687 22.6387C11.4845 22.9797 11.0095 23.0926 10.6023 22.9158C10.1951 22.739 9.95316 22.315 10.0082 21.8745L10.8677 14.9985H3.0005C2.61248 14.9985 2.25949 14.774 2.09491 14.4227C1.93033 14.0713 1.98388 13.6564 2.23228 13.3583L12.2323 1.35832C12.5165 1.01728 12.9915 0.904429 13.3987 1.0812ZM5.13554 12.9985H12.0005C12.2873 12.9985 12.5604 13.1217 12.7502 13.3367C12.94 13.5517 13.0284 13.8379 12.9928 14.1225L12.4158 18.738L18.8655 10.9985H12.0005C11.7137 10.9985 11.4406 10.8753 11.2508 10.6603C11.061 10.4453 10.9726 10.1591 11.0082 9.87446L11.5852 5.25896L5.13554 12.9985Z"
      fill={props.color}
    />
  </SvgIcon>
);

ZapIcon.displayName = 'ZapIcon';
