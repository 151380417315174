import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const UserCheckIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 4C7.34315 4 6 5.34315 6 7C6 8.65685 7.34315 10 9 10C10.6569 10 12 8.65685 12 7C12 5.34315 10.6569 4 9 4ZM4 7C4 4.23858 6.23858 2 9 2C11.7614 2 14 4.23858 14 7C14 9.76142 11.7614 12 9 12C6.23858 12 4 9.76142 4 7ZM23.7071 8.29289C24.0976 8.68342 24.0976 9.31658 23.7071 9.70711L19.7071 13.7071C19.3166 14.0976 18.6834 14.0976 18.2929 13.7071L16.2929 11.7071C15.9024 11.3166 15.9024 10.6834 16.2929 10.2929C16.6834 9.90237 17.3166 9.90237 17.7071 10.2929L19 11.5858L22.2929 8.29289C22.6834 7.90237 23.3166 7.90237 23.7071 8.29289ZM1.56574 15.442C2.55765 14.5121 3.88994 14 5.26667 14H12.7333C14.1101 14 15.4424 14.5121 16.4343 15.442C17.4284 16.374 18 17.6523 18 19V21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21V19C16 18.2259 15.6726 17.4694 15.0664 16.9011C14.458 16.3307 13.6198 16 12.7333 16H5.26667C4.38022 16 3.54202 16.3307 2.93362 16.9011C2.3274 17.4694 2 18.2259 2 19V21C2 21.5523 1.55228 22 1 22C0.447715 22 0 21.5523 0 21V19C0 17.6523 0.571643 16.374 1.56574 15.442Z"
      fill={props.color}
    />
  </SvgIcon>
);

UserCheckIcon.displayName = 'UserCheckIcon';
