import { Chips, ComponentProps, Icon, SelectItem } from '@components';
import { TableFilter } from '@components/Filter/dataTypes';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './SelectedFilters.module.scss';

type FilterScrollDirections = 'right' | 'left';

export interface SelectedFiltersProps extends ComponentProps {
  filters: TableFilter[];
  setFilters: (filters: TableFilter[]) => void;
}

export const SelectedFilters: FC<SelectedFiltersProps> = (props) => {
  const { className, id, style, dataTestId, filters, setFilters } = props;

  const selectedFilters: SelectItem[] = useMemo(() => {
    return filters
      ?.filter((filter) => filter.value)
      ?.map((filter) => {
        if (filter.type === 'radio') {
          return {
            label: filter?.items?.find((el) => el.value === filter.value)
              ?.label,
            value: filter.id
          };
        }
        return {
          label: filter.label,
          value: filter.id
        };
      });
  }, [filters]);

  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [contentWidth, setContentWidth] = useState<number>(0);
  /* Value to control scrollLeft of content container ([0, content width - container width]) */
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const MAX_CONTAINER_WIDTH = 500; // max width of container
  const SCROLL_STEP_IN_PX = 200;

  useEffect(() => {
    if (contentRef.current) {
      setScrollLeft(contentRef.current.scrollWidth - MAX_CONTAINER_WIDTH);
    }
  }, []);

  useEffect(() => {
    const container = containerRef?.current;
    if (container) {
      container.scrollTo({
        left: scrollLeft,
        behavior: 'smooth'
      });
      setIsLoaded(true);
    }
  }, [scrollLeft]);

  useEffect(() => {
    const container = containerRef?.current;
    if (container) {
      setContainerWidth(container.offsetWidth);
      setContentWidth(contentRef.current.scrollWidth);
    }
  }, [selectedFilters, containerRef, contentRef]);

  const handleScroll = (
    direction: FilterScrollDirections,
    scrollShift = SCROLL_STEP_IN_PX
  ) => {
    const directionValue = direction === 'left' ? -1 : 1;
    const newPosition = scrollLeft + directionValue * scrollShift;

    const maxOffset = contentWidth - MAX_CONTAINER_WIDTH;

    if (newPosition >= -scrollShift && newPosition <= maxOffset + scrollShift) {
      let updatedPosition = newPosition;

      if (contentWidth <= containerWidth) {
        //when we should not actually show arrows
        updatedPosition = maxOffset;
      }

      if (newPosition < 0) {
        //when we are at the start position
        updatedPosition = 0;
      }

      if (newPosition > contentWidth) {
        //when we are at the end position
        updatedPosition = maxOffset;
      }

      setScrollLeft(updatedPosition);
    }
  };

  const onClose = (item: SelectItem) => {
    const newFilters = filters?.map((filter) => {
      if (filter.id === item.value) {
        return { ...filter, value: null };
      }
      return filter;
    });
    setFilters(newFilters);
  };

  return (
    <div
      id={id}
      style={style}
      className={cn(styles.wrap, className)}
      data-testid={dataTestId}
    >
      <div className={styles['container-inner']} ref={containerRef}>
        <div
          className={isLoaded ? styles.content : styles['content--hidden']}
          ref={contentRef}
        >
          {selectedFilters?.map((selectedFilter, key) => {
            return (
              <Chips
                dataTestId={'chips'}
                key={key}
                item={selectedFilter}
                onClose={(item: SelectItem) => onClose(item)}
              />
            );
          })}
        </div>
      </div>

      {scrollLeft > 0 && contentWidth > containerWidth && contentWidth > 0 && (
        <div
          className={styles['scroll-left']}
          onClick={() => handleScroll('left')}
        >
          <Icon name={'chevron-left'} size={'s'} />
        </div>
      )}

      {scrollLeft < contentWidth - containerWidth && contentWidth > 0 && (
        <div
          className={styles['scroll-right']}
          onClick={() => handleScroll('right')}
        >
          <Icon name={'chevron-right'} size={'s'} />
        </div>
      )}
    </div>
  );
};

SelectedFilters.displayName = 'SelectedFilters';
