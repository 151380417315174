import { logger } from '@componentsUtils/logger';

declare global {
  interface Window {
    msCrypto: Crypto;
  }
}

function randomAlphaNumString(): string {
  /**
   * Crypto based generation
   */
  try {
    const crypto = window.crypto || window.msCrypto;
    if (crypto && crypto.getRandomValues) {
      return crypto.getRandomValues(new Uint32Array(1))[0].toString(36);
    }
  } catch (err) {
    if (err instanceof Error) {
      logger.warn(
        `Short ID Generator has troubles with object Window. Message: ${err.message}`
      );
    }
  }

  /**
   * Fallback if has any problems with window crypto
   */
  return Math.random().toString(36).slice(2, 9);
}

/**
 * Short ID Generator
 * @default length 7
 */
export function shortId(length = 7): string {
  let str = '';

  while (str.length < length) {
    str += randomAlphaNumString();
  }
  return str.slice(0, length);
}
