import React, { FC } from 'react';
import {
  Button,
  Card,
  ComponentProps,
  DateIntervalType,
  DatePicker,
  Locales,
  SkeletonPlaceholder,
  Typography
} from '@components';
import cn from 'classnames';
import styles from './UserTrackingReport.module.scss';
import { display, flex, offsets } from '@componentsStyles';
import { UserTrackingReportTranslations } from './dataTypes';

export interface UserTrackingReportProps extends ComponentProps {
  translations?: UserTrackingReportTranslations;
  isLoading?: boolean;
  rangePickerValue?: [Date, Date];
  locale?: Locales;
  onRangePickerChange?: (dates: [Date, Date]) => void;

  onDownload?(): void;
}

const defaultTranslations: UserTrackingReportTranslations = {
  userTrackingReport: 'User tracking report'
};

export const UserTrackingReport: FC<UserTrackingReportProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    translations = defaultTranslations,
    locale,
    rangePickerValue,
    isLoading,
    onDownload,
    onRangePickerChange
  } = props;

  return (
    <Card className={className} style={style} id={id} data-testid={dataTestId}>
      <div className={styles.header}>
        <Typography className={offsets['mr-8']} variant="h5">
          {translations.userTrackingReport}
        </Typography>
      </div>

      <div className={cn(offsets['mb-16'], styles.row)}>
        {isLoading ? (
          <SkeletonPlaceholder width={224} height={34} />
        ) : (
          <div className={cn(display['d-flex'], flex['align-items-center'])}>
            <DatePicker
              type="range"
              rangePickerPlaceholder={'dd-mm-year'}
              dateIntervalType={DateIntervalType.Date}
              locale={locale}
              rangePickerValue={rangePickerValue}
              onRangePickerChange={onRangePickerChange}
              className={offsets['mr-20']}
            />
            <Button
              dataTestId="download"
              disabled={
                !(
                  rangePickerValue &&
                  rangePickerValue[0] &&
                  rangePickerValue[1]
                )
              }
              type="fifth"
              icon="download"
              onClick={onDownload}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

UserTrackingReport.displayName = 'UserTrackingReport';
