import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const BuildIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.553 1.10557C11.8345 0.964809 12.1659 0.964809 12.4474 1.10557L22.4474 6.10557C22.7862 6.27496 23.0002 6.62123 23.0002 7C23.0002 7.37877 22.7862 7.72504 22.4474 7.89443L12.4474 12.8944C12.1659 13.0352 11.8345 13.0352 11.553 12.8944L1.55301 7.89443C1.21422 7.72504 1.00022 7.37877 1.00022 7C1.00022 6.62123 1.21422 6.27496 1.55301 6.10557L11.553 1.10557ZM4.23629 7L12.0002 10.882L19.7642 7L12.0002 3.11803L4.23629 7ZM1.10579 11.5528C1.35278 11.0588 1.95345 10.8586 2.44743 11.1056L12.0002 15.882L21.553 11.1056C22.047 10.8586 22.6477 11.0588 22.8946 11.5528C23.1416 12.0468 22.9414 12.6474 22.4474 12.8944L12.4474 17.8944C12.1659 18.0352 11.8345 18.0352 11.553 17.8944L1.55301 12.8944C1.05903 12.6474 0.858803 12.0468 1.10579 11.5528ZM1.10579 16.5528C1.35278 16.0588 1.95345 15.8586 2.44743 16.1056L12.0002 20.882L21.553 16.1056C22.047 15.8586 22.6477 16.0588 22.8946 16.5528C23.1416 17.0468 22.9414 17.6474 22.4474 17.8944L12.4474 22.8944C12.1659 23.0352 11.8345 23.0352 11.553 22.8944L1.55301 17.8944C1.05903 17.6474 0.858803 17.0468 1.10579 16.5528Z"
      fill={props.color}
    />
  </SvgIcon>
);

BuildIcon.displayName = 'BuildIcon';
