import { HeaderAppNames } from '@xq/ui-kit';
import {
  iamGateway,
  generateHeaderAppItems,
  handleBackendError,
  wait,
  modelTreeApiConfiguration
} from '@services';
import { config } from '@config';
import { LayoutApi } from '@xq/model-tree-releases-gateway-frontend-client';
import { UserLayoutData } from './dateTypes';

const layoutGateway = new LayoutApi(modelTreeApiConfiguration);

export interface LayoutService {
  fetchData(): Promise<UserLayoutData>;
  authCheck(): Promise<void>;
  logout(): Promise<void>;
}

export class LayoutService implements LayoutService {
  async fetchData(): Promise<UserLayoutData> {
    try {
      const response = await layoutGateway.layoutControllerGetLayout();

      return {
        firstName: response?.firstName || '',
        lastName: response?.lastName || '',
        email: response?.email || '',
        avatar: response?.avatar,
        languageIso2: response?.languageIso2,
        organizations: response?.organizations,
        currentOrganizationUuid: response?.currentOrganizationUuid,
        apps: generateHeaderAppItems(
          response?.isOrganizationAdmin,
          response?.isXQAdmin
        )
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async authCheck(): Promise<void> {
    try {
      await iamGateway.authControllerCheck();
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.authCheck.name
      );
    }
  }

  async logout(): Promise<void> {
    try {
      await iamGateway.authControllerLogout();
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.logout.name);
    }
  }
}

const mockResponse: UserLayoutData = {
  firstName: 'Simona',
  lastName: 'Mulkova',
  organizations: [
    {
      name: 'Exquance',
      uuid: '91e16833-6f0e-46fa-bfea-e817dfaaf5b61'
    }
  ],
  currentOrganizationUuid: '91e16833-6f0e-46fa-bfea-e817dfaaf5b61',
  email: 'simona.mulkova@exquance.com',
  avatar: '',
  apps: [
    {
      name: HeaderAppNames.ModelTree,
      url: config.modelTreeFrontendUrl
    }
  ],
  languageIso2: 'ru'
};

export class LayoutServiceMock implements LayoutService {
  async fetchData(): Promise<UserLayoutData> {
    try {
      /* api call */
      await wait(1000);
      return mockResponse;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async authCheck(): Promise<void> {
    try {
      /* api call */
      await wait(10);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.authCheck.name
      );
    }
  }

  async logout(): Promise<void> {
    try {
      /* api call */
      await wait(10);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.logout.name);
    }
  }
}
