import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const LogoAdministrationIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 139 16" width={139} height={16}>
    <path d="M0 0L2.66667 4L5.33333 0H0Z" fill="#FBB040" />
    <path d="M14.6665 12L17.3332 8L19.9998 12H14.6665Z" fill="#F26522" />
    <path d="M12 0L14.6667 4L17.3333 0H12Z" fill="#EE2A7B" />
    <path d="M9.3335 4L12.0002 0L14.6668 4H9.3335Z" fill="#DA1C5C" />
    <path d="M9.3335 4L12.0002 8L14.6668 4H9.3335Z" fill="#BE1E2D" />
    <path d="M5.3335 8L8.00016 4L10.6668 8H5.3335Z" fill="#F15A29" />
    <path d="M5.3335 8L8.00016 12L10.6668 8H5.3335Z" fill="#EF4136" />
    <path d="M2.6665 12L5.33317 8L7.99984 12H2.6665Z" fill="#BE1E2D" />
    <path d="M14.6665 12L17.3332 16L19.9998 12H14.6665Z" fill="#F7941D" />
    <path d="M17.3335 16L20.0002 12L22.6668 16H17.3335Z" fill="#FBB040" />
    <path d="M2.6665 4L5.33317 8L7.99984 4H2.6665Z" fill="#F26522" />
    <path d="M2.6665 4L5.33317 0L7.99984 4H2.6665Z" fill="#F7941D" />
    <path d="M12 8L14.6667 12L17.3333 8H12Z" fill="#F15A29" />
    <path d="M12 8L14.6667 4L17.3333 8H12Z" fill="#EF4136" />
    <path
      d="M32.52 9.392H28.808L29.048 8.384H32.248L32.52 9.392ZM30.744 4.832L28.328 12.112H27L30.568 2H30.952L34.52 12.112H33.144L30.744 4.832Z"
      fill="#121212"
    />
    <path
      d="M35.56 12.128V2.144H37.96C38.5467 2.144 39.0373 2.19733 39.432 2.304C39.8267 2.41067 40.1627 2.56533 40.44 2.768C40.7173 2.96 40.968 3.20533 41.192 3.504C41.416 3.80267 41.5973 4.144 41.736 4.528C41.8853 4.90133 41.9973 5.30667 42.072 5.744C42.1467 6.18133 42.184 6.66133 42.184 7.184C42.1627 7.952 42.0613 8.64 41.88 9.248C41.7093 9.856 41.448 10.3733 41.096 10.8C40.7547 11.2267 40.312 11.5573 39.768 11.792C39.224 12.016 38.5573 12.128 37.768 12.128H35.56ZM36.856 11.072H37.72C38.4667 11.072 39.0693 10.9227 39.528 10.624C39.9867 10.3147 40.3227 9.872 40.536 9.296C40.76 8.72 40.8667 8.032 40.856 7.232C40.856 6.41067 40.7547 5.70133 40.552 5.104C40.36 4.50667 40.0507 4.048 39.624 3.728C39.1973 3.408 38.6213 3.248 37.896 3.248H36.856V11.072Z"
      fill="#121212"
    />
    <path
      d="M43.368 12.112V2.144H44.488L46.776 6.928L49.096 2.128H50.184V12.112H48.952V4.784L47 8.672H46.408L44.584 4.816V12.112H43.368Z"
      fill="#121212"
    />
    <path
      d="M51.992 12.112V10.992H53.96V3.264H52.104V2.144H57.24V3.264H55.272V11.008H57.336V12.112H51.992Z"
      fill="#121212"
    />
    <path
      d="M59.496 2.144H60.84L64.76 9.264L64.728 2.144H65.976H66.136V2.272C66.072 2.336 66.0293 2.40533 66.008 2.48C65.9973 2.55467 65.992 2.68267 65.992 2.864V12.112H64.84L60.744 4.56V12.112H59.496V2.144Z"
      fill="#121212"
    />
    <path
      d="M67.992 12.112V10.992H69.96V3.264H68.104V2.144H73.24V3.264H71.272V11.008H73.336V12.112H67.992Z"
      fill="#121212"
    />
    <path
      d="M78.68 12.272C78.0293 12.272 77.432 12.1707 76.888 11.968C76.3547 11.7547 75.8907 11.4453 75.496 11.04L76.184 9.872L76.248 9.728L76.36 9.792C76.3813 9.86667 76.408 9.952 76.44 10.048C76.4827 10.1333 76.5627 10.24 76.68 10.368C76.9573 10.592 77.272 10.7733 77.624 10.912C77.976 11.04 78.3707 11.104 78.808 11.104C79.096 11.104 79.3573 11.0667 79.592 10.992C79.8267 10.9067 80.0293 10.7947 80.2 10.656C80.3813 10.5173 80.52 10.352 80.616 10.16C80.712 9.968 80.76 9.76 80.76 9.536C80.76 9.36533 80.7387 9.21067 80.696 9.072C80.664 8.93333 80.6 8.80533 80.504 8.688C80.408 8.57067 80.28 8.45333 80.12 8.336C79.96 8.21867 79.752 8.09067 79.496 7.952C79.24 7.81333 78.9307 7.664 78.568 7.504C77.8853 7.22667 77.3413 6.93333 76.936 6.624C76.5413 6.31467 76.2587 5.984 76.088 5.632C75.9173 5.28 75.832 4.90133 75.832 4.496C75.832 4.00533 75.96 3.57333 76.216 3.2C76.472 2.82667 76.8293 2.53333 77.288 2.32C77.7573 2.10667 78.3013 2 78.92 2C79.304 2 79.6667 2.05333 80.008 2.16C80.36 2.256 80.6853 2.39467 80.984 2.576C81.2827 2.75733 81.5493 2.98667 81.784 3.264L81.016 4.224L80.92 4.352L80.808 4.272C80.7973 4.18667 80.776 4.10133 80.744 4.016C80.712 3.93067 80.6373 3.824 80.52 3.696C80.2853 3.472 80.0347 3.32267 79.768 3.248C79.5013 3.16267 79.1813 3.12 78.808 3.12C78.5627 3.12 78.3387 3.152 78.136 3.216C77.944 3.28 77.7787 3.376 77.64 3.504C77.5013 3.632 77.3893 3.776 77.304 3.936C77.2293 4.08533 77.192 4.24533 77.192 4.416C77.192 4.58667 77.2133 4.74133 77.256 4.88C77.2987 5.008 77.368 5.136 77.464 5.264C77.56 5.392 77.6933 5.52533 77.864 5.664C78.0453 5.792 78.2693 5.92533 78.536 6.064C78.8133 6.192 79.144 6.336 79.528 6.496C80.0293 6.70933 80.4453 6.91733 80.776 7.12C81.1067 7.32267 81.3627 7.54133 81.544 7.776C81.736 8 81.8747 8.24533 81.96 8.512C82.0453 8.768 82.088 9.06133 82.088 9.392C82.088 9.85067 81.9707 10.304 81.736 10.752C81.5013 11.2 81.1333 11.568 80.632 11.856C80.1307 12.1333 79.48 12.272 78.68 12.272Z"
      fill="#121212"
    />
    <path
      d="M85.992 12.112V3.312H83.208V2.128H90.232V3.312H87.336V12.112H85.992Z"
      fill="#121212"
    />
    <path
      d="M91.592 12.112V2.144H94.616C95.416 2.144 96.0613 2.26133 96.552 2.496C97.0533 2.73067 97.416 3.06667 97.64 3.504C97.8747 3.93067 97.992 4.43733 97.992 5.024C97.992 5.38667 97.912 5.75467 97.752 6.128C97.592 6.50133 97.3627 6.82667 97.064 7.104C96.7653 7.38133 96.4133 7.584 96.008 7.712L98.264 12.112H96.792L94.648 7.792H92.968V12.112H91.592ZM92.968 6.64H94.744C95.384 6.64 95.8533 6.496 96.152 6.208C96.4507 5.90933 96.6 5.52 96.6 5.04C96.6 4.73067 96.536 4.45333 96.408 4.208C96.28 3.952 96.0773 3.74933 95.8 3.6C95.5227 3.45067 95.1707 3.376 94.744 3.376H92.968V6.64Z"
      fill="#121212"
    />
    <path
      d="M104.52 9.392H100.808L101.048 8.384H104.248L104.52 9.392ZM102.744 4.832L100.328 12.112H99L102.568 2H102.952L106.52 12.112H105.144L102.744 4.832Z"
      fill="#121212"
    />
    <path
      d="M109.992 12.112V3.312H107.208V2.128H114.232V3.312H111.336V12.112H109.992Z"
      fill="#121212"
    />
    <path
      d="M115.992 12.112V10.992H117.96V3.264H116.104V2.144H121.24V3.264H119.272V11.008H121.336V12.112H115.992Z"
      fill="#121212"
    />
    <path
      d="M126.776 12.288C125.997 12.288 125.336 12.0907 124.792 11.696C124.259 11.3013 123.848 10.72 123.56 9.952C123.283 9.184 123.144 8.256 123.144 7.168C123.144 6.10133 123.283 5.18933 123.56 4.432C123.848 3.664 124.259 3.07733 124.792 2.672C125.336 2.256 125.997 2.048 126.776 2.048C127.555 2.048 128.211 2.256 128.744 2.672C129.277 3.07733 129.683 3.664 129.96 4.432C130.248 5.18933 130.392 6.10133 130.392 7.168C130.392 8.256 130.248 9.184 129.96 9.952C129.683 10.72 129.277 11.3013 128.744 11.696C128.211 12.0907 127.555 12.288 126.776 12.288ZM126.776 11.152C127.245 11.152 127.645 11.0027 127.976 10.704C128.317 10.3947 128.573 9.94667 128.744 9.36C128.925 8.76267 129.016 8.032 129.016 7.168C129.016 6.34667 128.925 5.64267 128.744 5.056C128.573 4.45867 128.317 4 127.976 3.68C127.645 3.36 127.245 3.2 126.776 3.2C126.307 3.2 125.901 3.36 125.56 3.68C125.219 4 124.957 4.45867 124.776 5.056C124.605 5.64267 124.52 6.34667 124.52 7.168C124.52 8.032 124.605 8.76267 124.776 9.36C124.957 9.94667 125.219 10.3947 125.56 10.704C125.901 11.0027 126.307 11.152 126.776 11.152Z"
      fill="#121212"
    />
    <path
      d="M131.496 2.144H132.84L136.76 9.264L136.728 2.144H137.976H138.136V2.272C138.072 2.336 138.029 2.40533 138.008 2.48C137.997 2.55467 137.992 2.68267 137.992 2.864V12.112H136.84L132.744 4.56V12.112H131.496V2.144Z"
      fill="#121212"
    />
  </SvgIcon>
);

LogoAdministrationIcon.displayName = 'LogoAdministrationIcon';
