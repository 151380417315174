import React, { FC } from 'react';
import {
  ComponentProps,
  Typography,
  Card,
  Icon,
  SkeletonPlaceholder,
  PaymentsTranslations,
  DonutChart,
  DonutSlice,
  Tooltip
} from '@components';
import cn from 'classnames';
import styles from './Payments.module.scss';
import { offsets } from '@componentsStyles';
import { Currency } from '@componentsUtils';

export interface PaymentsProps extends ComponentProps {
  data: DonutSlice[];
  currency: Currency;
  isLoading?: boolean;
  translations?: PaymentsTranslations;
  onSeeDetails?: () => void;
}

const defaultTranslations: PaymentsTranslations = {
  seeDetails: 'See details',
  total: 'Total',
  payments: 'Payments'
};

export const Payments: FC<PaymentsProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    translations = defaultTranslations,
    data,
    currency,
    isLoading,
    onSeeDetails
  } = props;

  return (
    <Card
      className={cn(
        className,
        isLoading ? styles['card--loading'] : styles.card
      )}
      style={style}
      id={id}
      data-testid={dataTestId}
    >
      <div className={styles.header}>
        <Typography className={offsets['mr-8']} variant="h5">
          {translations.payments}
        </Typography>
        <div className={styles.details} onClick={onSeeDetails}>
          <div className={styles.redirect}>
            <Tooltip content={translations.seeDetails}>
              <Icon size="s" name="chevrons-right" />
            </Tooltip>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className={styles.loading}>
          <SkeletonPlaceholder
            className={offsets['mr-20']}
            type="circle"
            width={234}
            height={234}
          />
          <div>
            <SkeletonPlaceholder
              className={offsets['mb-4']}
              width={80}
              height={12}
            />
            <SkeletonPlaceholder
              className={offsets['mb-24']}
              width={115}
              height={18}
            />

            <SkeletonPlaceholder
              className={offsets['mb-4']}
              width={80}
              height={12}
            />
            <SkeletonPlaceholder
              className={offsets['mb-24']}
              width={115}
              height={18}
            />

            <SkeletonPlaceholder
              className={offsets['mb-4']}
              width={80}
              height={12}
            />
            <SkeletonPlaceholder
              className={offsets['mb-24']}
              width={115}
              height={18}
            />

            <SkeletonPlaceholder
              className={offsets['mb-4']}
              width={80}
              height={12}
            />
            <SkeletonPlaceholder width={115} height={18} />
          </div>
        </div>
      ) : (
        <DonutChart
          data={data}
          heading={translations.total}
          currency={currency}
        />
      )}
    </Card>
  );
};

Payments.displayName = 'Payments';
