import React, { FC, useEffect, useState } from 'react';
import {
  ComponentProps,
  Modal,
  PriceRuleType,
  PricingData,
  PricingListTranslations,
  Typography
} from '@components';
import styles from './PricingPreview.module.scss';
import { colors, offsets } from '@componentsStyles';
import {
  Currency,
  formatCurrency,
  formatDate,
  formatNumber
} from '@componentsUtils';
import { calculatePricingPerUser } from '@components/PricingList/utils';
import { DASH } from '@constants';

export interface PricingPreviewProps extends ComponentProps {
  pricing: PricingData[];
  maxUserAmount: number;
  currency?: Currency;
  maxAmountPrice?: number;
  isOpen?: boolean;
  onClose?: () => void;
  translations?: PricingListTranslations;
  organizationName?: string;
  billingStartDate?: string;
  licenseName?: string;
  priceRuleType?: PriceRuleType;
}

export interface PricingPreviewData {
  numberOfUsers: number;
  priceMonthly?: number;
  pricePerUserMonthly?: number;
}

export const PricingPreview: FC<PricingPreviewProps> = (props) => {
  const {
    className,
    id,
    style,
    dataTestId,
    isOpen,
    onClose,
    pricing,
    maxUserAmount,
    maxAmountPrice,
    translations,
    currency,
    organizationName,
    billingStartDate,
    licenseName,
    priceRuleType
  } = props;

  const [pricingPerUser, setPricingPerUser] = useState<PricingPreviewData[]>();

  useEffect(() => {
    setPricingPerUser(
      calculatePricingPerUser(
        pricing,
        maxAmountPrice,
        maxUserAmount,
        priceRuleType
      )
    );
  }, [pricing, maxAmountPrice, maxUserAmount, priceRuleType]);

  return (
    <div id={id} style={style} className={className} data-testid={dataTestId}>
      <Modal
        withCloseButton
        isOpen={isOpen}
        title={translations.pricingPreview}
        onClose={onClose}
        maxWidth="652px"
      >
        <Typography variant="system" element="div">
          {translations.organization}: {organizationName || DASH}
        </Typography>
        <Typography variant="system" element="div">
          {translations.license}: {licenseName || DASH}
        </Typography>
        <Typography className={offsets['mb-40']} variant="system" element="div">
          {translations.billingStartDate}: {formatDate(billingStartDate)}
        </Typography>

        <div>
          <div className={styles['table-head']}>
            <Typography
              className={styles['table-head-cell']}
              element="div"
              variant="system-heading"
              color={colors.gray40Color}
            >
              {translations.users}
            </Typography>

            <Typography
              className={styles['table-head-cell-right']}
              element="div"
              variant="system-heading"
              color={colors.gray40Color}
            >
              {translations.priceMonthly}
            </Typography>
            <Typography
              className={styles['table-head-cell-right']}
              element="div"
              variant="system-heading"
              color={colors.gray40Color}
            >
              {translations.pricePerUserMonthly}
            </Typography>
          </div>

          {pricingPerUser?.map((pricing) => {
            return (
              <div key={pricing.numberOfUsers} className={styles['table-row']}>
                <Typography
                  element="div"
                  className={styles['table-cell']}
                  variant="number-1"
                >
                  {pricing?.numberOfUsers &&
                    formatNumber(pricing.numberOfUsers)}
                </Typography>
                <Typography
                  element="div"
                  className={styles['table-cell-price']}
                  variant="number-1"
                >
                  {currency && pricing?.priceMonthly
                    ? formatCurrency(currency, pricing.priceMonthly, 2)
                    : DASH}
                </Typography>
                <Typography
                  element="div"
                  className={styles['table-cell-price']}
                  variant="number-1"
                >
                  {currency && pricing?.pricePerUserMonthly
                    ? formatCurrency(currency, pricing.pricePerUserMonthly, 2)
                    : DASH}
                </Typography>
              </div>
            );
          })}
        </div>
      </Modal>
    </div>
  );
};

PricingPreview.displayName = 'PricingPreview';
