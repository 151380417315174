import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const MapIconFilled: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_13288_3583)">
      <path d="M1 6V22L8 18L16 22L23 18V2L16 6L8 2L1 6Z" fill="#3D3D3D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50386 1.13176C7.79416 0.965872 8.14816 0.956046 8.44721 1.10557L15.9685 4.86623L22.5039 1.13176C22.8134 0.954898 23.1936 0.956168 23.5019 1.13509C23.8102 1.31401 24 1.64353 24 2V18C24 18.3589 23.8077 18.6902 23.4961 18.8682L16.4961 22.8682C16.2058 23.0341 15.8518 23.044 15.5528 22.8944L8.03147 19.1338L1.49614 22.8682C1.18664 23.0451 0.806388 23.0438 0.498073 22.8649C0.189758 22.686 0 22.3565 0 22V6C0 5.64114 0.192286 5.3098 0.503861 5.13176L7.50386 1.13176ZM8.03147 3.13377L2 6.58032V20.2768L7.50386 17.1318C7.79416 16.9659 8.14816 16.956 8.44721 17.1056L15.9685 20.8662L22 17.4197V3.72318L16.4961 6.86824C16.2058 7.03413 15.8518 7.04395 15.5528 6.89443L8.03147 3.13377Z"
        fill="#3D3D3D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 -1C8.55228 -1 9 -0.552285 9 0V21C9 21.5523 8.55228 22 8 22C7.44772 22 7 21.5523 7 21V0C7 -0.552285 7.44772 -1 8 -1ZM16 2C16.5523 2 17 2.44772 17 3V24C17 24.5523 16.5523 25 16 25C15.4477 25 15 24.5523 15 24V3C15 2.44772 15.4477 2 16 2Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_13288_3583">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

MapIconFilled.displayName = 'MapIconFilled';
