import { HeaderAppItem, HeaderAppNames } from '@components/Header/dataTypes';

export const MODEL_TREE_DESKTOP_UUID = 'afd851cb-bb8e-4b16-9f4f-597445b0177f';
export const INSIGHTS_UUID = 'b4749d52-34ec-4905-8c0b-ca56fc9c0dca';

export interface FrontendAppUrls {
  modelTreeFrontendUrl: string;
  insightsFrontendUrl: string;
}

export const getLicenseProductByUUID = (
  uuid: string,
  urls: FrontendAppUrls
): HeaderAppItem => {
  switch (uuid) {
    case MODEL_TREE_DESKTOP_UUID: {
      return {
        name: HeaderAppNames.ModelTree,
        url: urls.modelTreeFrontendUrl
      };
    }
    case INSIGHTS_UUID: {
      return {
        name: HeaderAppNames.Insights,
        url: urls.insightsFrontendUrl
      };
    }
    default: {
      return;
    }
  }
};

export interface HeaderAppSwitcherUrls {
  modelTreeFrontendUrl: string;
  insightsFrontendUrl: string;
  administrationFrontendUrl: string;
  omniFrontendUrl: string;
}

export const generateHeaderAppSwitcherItems = (
  urls: HeaderAppSwitcherUrls,
  isOrganizationAdmin?: boolean,
  isXQAdmin?: boolean
): HeaderAppItem[] => {
  const modelTreeApp: HeaderAppItem = {
    name: HeaderAppNames.ModelTree,
    url: urls.modelTreeFrontendUrl
  };

  const exquanceInsightsApp: HeaderAppItem = {
    name: HeaderAppNames.Insights,
    url: urls.insightsFrontendUrl
  };

  const adminApp: HeaderAppItem = {
    name: HeaderAppNames.Administration,
    url: urls.administrationFrontendUrl
  };

  const omniApp: HeaderAppItem = {
    name: HeaderAppNames.Omni,
    url: urls.omniFrontendUrl
  };

  const apps: HeaderAppItem[] = [modelTreeApp, exquanceInsightsApp];

  if (isOrganizationAdmin) {
    apps.push(adminApp);
  }

  if (isXQAdmin) {
    apps.push(omniApp);
  }

  return apps;
};
