import { ComponentProps, Icon, Typography } from '@components';
import { colors } from '@componentsStyles';
import dayjs from 'dayjs';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Brush,
  CartesianGrid,
  Line,
  LineChart,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/types';
import {
  ChartLegend,
  ChartLoader,
  ChartPlaceholder,
  CustomTickX,
  CustomTickY,
  ReferenceLineLabel
} from './Components';
import styles from './TableChart.module.scss';
import {
  BrushPosition,
  chartColors,
  ChartData,
  ChartKpiInfo
} from './dataTypes';
import { getUniqueYears } from './utils';

export interface TableChartProps extends ComponentProps {
  /** id of the chart canvas */
  id?: string;
  /** Title of the chart */
  chartTitle: string;
  /** Title of the chart */
  chartSubtitle: string;
  /** chart row data */
  data: ChartData[];
  /** External className for main element */
  className?: string;
  /** func when map item is clicked */
  mapIconHandler?: () => void;
  /** is chart data loading */
  loading?: boolean;
  /** func when chart is clicked */
  onChartClick?: (date: number) => void;
  /** units of incoming data */
  unit?: string;
  /** active item from table */
  activeItem?: number;
  /** func when legend item is clicked */
  onLegendItemClick?: (key: string) => void;
  /** Y axis domain */
  YAxisDomain?: [number, number] | [string, string];
  /** Active legend item */
  activeLegendItem?: string;
  /** active map item */
  isMapOpen?: boolean;
  /** connect null values in the chart */
  connectNulls?: boolean;
  /** chart kpi info */
  chartKpiInfo: ChartKpiInfo[];
}

export const TableChart: FC<TableChartProps> = (props) => {
  const [activeDate, setActiveDate] = useState<number | null>(null);
  const [brushPosition, setBrushPosition] = useState<BrushPosition | null>(
    null
  );
  const {
    chartTitle,
    chartSubtitle,
    unit,
    data,
    className,
    mapIconHandler,
    loading,
    onChartClick,
    onLegendItemClick,
    activeItem,
    activeLegendItem,
    YAxisDomain = ['dataMin', 'dataMax'],
    isMapOpen,
    connectNulls = false,
    chartKpiInfo
  } = props;
  const [activeKpi, setActiveKpi] = useState<string | null>();

  useEffect(() => {
    if (activeLegendItem) {
      setActiveKpi(activeLegendItem);
    } else {
      setActiveKpi(null);
    }
  }, [activeLegendItem]);

  useEffect(() => {
    if (activeItem) {
      setActiveDate(activeItem);
    }
  }, [activeItem]);

  let allKeys = data.reduce((acc, obj) => {
    const keys = Object.keys(obj).filter((key) => key !== 'date');
    keys.forEach((key) => {
      if (!acc.includes(key)) {
        acc.push(key);
      }
    });
    return acc;
  }, []);

  const numericValues = data.map((item) => dayjs(item.date).valueOf());
  const graphTicks = getUniqueYears(numericValues);

  const chartData = useMemo(
    () =>
      data
        .map((item) => ({
          ...item,
          date: dayjs(item.date).valueOf()
        }))
        .sort((a, b) => a.date - b.date),
    [data]
  );

  const formattedData = useMemo<Record<number, ChartData>>(
    () =>
      data.reduce(
        (acc, cur) => ({
          ...acc,
          [dayjs(cur.date).valueOf()]: {
            ...cur
          }
        }),
        {}
      ),
    [data]
  );

  const handleChartClick = (chartState: CategoricalChartState) => {
    const payloadKeys = Object.keys(
      chartState?.activePayload?.[0]?.payload ?? {}
    );
    const isFakeKey = payloadKeys.some((key) => key.includes('_fake'));

    if (isFakeKey) return;

    if (Object.keys(chartState).length > 0) {
      setActiveDate((prev) =>
        prev !== Number(chartState.activeLabel)
          ? Number(chartState.activeLabel)
          : null
      );

      if (onChartClick) {
        onChartClick(Number(chartState.activeLabel));
      }
    }
  };

  const handleKpiClick = (key: string) => {
    setActiveKpi(isMapOpen ? key : (prev) => (prev === key ? null : key));
    onLegendItemClick?.(key);
  };

  const getStrokeOpacity = useCallback(
    (key: string) => {
      if (activeKpi === key) {
        return 1;
      }
      if (activeKpi !== key && activeKpi) {
        return 0.2;
      }
      return 1;
    },
    [activeKpi]
  );

  if (!loading && data?.length < 1) {
    return <ChartPlaceholder className={className} />;
  }

  if (loading) {
    return <ChartLoader className={className} />;
  }
  const fakeKeys = allKeys.filter((key) => key.includes('_fake'));
  allKeys = allKeys.filter((key) => !key.includes('_fake'));

  return (
    <div className={className}>
      <div className={styles.customChartHeader}>
        <div className={styles.customChartHeaderWrap}>
          <div className={styles.customChartHeaderTitle}>
            <Typography variant="h5">{chartTitle}</Typography>
            <Icon
              className={styles.dividerIcon}
              name="break"
              size="m"
              color="disabled"
            />
            <Typography variant="h5">{chartSubtitle}</Typography>
            {mapIconHandler && (
              <Icon
                id={'table-chart-show-map-icon'}
                name={isMapOpen ? 'map-filled' : 'map'}
                size="s"
                color="default"
                className={styles.customChartHeaderTitleMapIcon}
                onClick={mapIconHandler}
              />
            )}
          </div>
          <Typography variant="chevron" color={colors.gray40Color}>
            {unit}
          </Typography>
        </div>
        <ChartLegend
          keys={allKeys}
          data={formattedData?.[activeDate]}
          onClick={handleKpiClick}
          activeKey={activeKpi}
          unit={unit}
          isMapOpen={isMapOpen}
          chartKpiInfo={chartKpiInfo}
        />
      </div>
      <div className={styles.chartWrapper}>
        <ResponsiveContainer>
          <LineChart
            onClick={handleChartClick}
            data={chartData}
            height={250}
            margin={{ left: -10, top: 10, right: 30 }}
          >
            <CartesianGrid
              stroke="#dadada"
              strokeDasharray="1 1"
              vertical={false}
            />
            <XAxis
              axisLine={false}
              dataKey="date"
              domain={[graphTicks[0], graphTicks[graphTicks.length - 1]]}
              tick={<CustomTickX />}
              tickLine={{ stroke: '#b3b3b3' }}
              interval={4}
              type="number"
              height={45}
              scale="time"
            />
            <YAxis
              axisLine={false}
              allowDecimals={false}
              tick={<CustomTickY />}
              tickLine={false}
              domain={YAxisDomain}
              // domain={([dataMin, dataMax]) => {
              //   return [
              //     dataMin <= 5 ? 0 : Math.ceil(dataMin / 5) * 5,
              //     Math.ceil(dataMax / 5) * 5
              //   ];
              // }}
            />
            <Tooltip
              formatter={(value) => {
                return value?.toLocaleString('fr', {
                  maximumFractionDigits: 2
                });
              }}
              labelFormatter={(value) => dayjs(value).format('DD.MM.YYYY')}
            />
            {activeDate && (
              <ReferenceLine
                label={{
                  content: (
                    <ReferenceLineLabel
                      isFirst={activeDate === chartData[0].date}
                      date={activeDate}
                    />
                  )
                }}
                x={activeDate ?? null}
                stroke="#3D3D3D"
              />
            )}

            {fakeKeys?.map((item) => {
              return (
                <Line
                  type="monotone"
                  isAnimationActive={false}
                  key={item}
                  dataKey={item}
                  tooltipType="none"
                  strokeWidth={0}
                  connectNulls={connectNulls}
                  stroke="#B3B3B3"
                  strokeOpacity={getStrokeOpacity(item)}
                  dot={{ strokeWidth: 2, r: 4 }}
                />
              );
            })}

            {allKeys?.map((item, index) => {
              return (
                <Line
                  type="monotone"
                  isAnimationActive={false}
                  key={item}
                  dataKey={item}
                  strokeWidth={2}
                  connectNulls={connectNulls}
                  stroke={chartColors[index]}
                  strokeOpacity={getStrokeOpacity(item)}
                  dot={{ strokeWidth: 2, r: 4 }}
                />
              );
            })}
            {activeDate &&
              allKeys.map((key, index) => (
                <ReferenceDot
                  key={key}
                  x={activeDate}
                  y={formattedData?.[activeDate]?.[key]}
                  r={4}
                  stroke="white"
                  strokeWidth={2}
                  fill={chartColors[index]}
                />
              ))}
            <Brush
              className={styles.brushStyles}
              onDragEnd={(position) =>
                setBrushPosition(position as BrushPosition)
              }
              fill="#e8e8e8"
              stroke="#b3b3b3"
              startIndex={brushPosition?.startIndex}
              endIndex={brushPosition?.endIndex}
              dataKey="date"
              tickFormatter={(date) => dayjs(date).format('YYYY')}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

TableChart.displayName = 'TableChart';
