import React, { FC } from 'react';
import {
  ComponentProps,
  Typography,
  Card,
  SkeletonPlaceholder,
  Copy,
  CountryIso3,
  Tooltip,
  Icon
} from '@components';
import cn from 'classnames';
import styles from './OrganizationInfo.module.scss';
import { common, offsets } from '@componentsStyles';
import { formatDate } from '@componentsUtils';
import { OrganizationInfoTranslations } from './dataTypes';
import { DASH } from '@constants';

export interface OrganizationInfoProps extends ComponentProps {
  organizationName: string;
  country: CountryIso3;
  currency: string;
  agreementNumber: string;
  agreementDate: Date;
  translations?: OrganizationInfoTranslations;
  isLoading?: boolean;
  isSeeDetails?: boolean;
  onSeeDetails?(): void;
}

const defaultTranslations: OrganizationInfoTranslations = {
  organizationInfoText: 'Organization info',
  organizationText: 'Organization',
  countryText: 'Country',
  currencyText: 'Currency',
  agreementNumberText: 'Agreement no',
  agreementDateText: 'Agreement date',
  copyText: 'Copy',
  copiedText: 'Copied',
  seeDetails: 'See details'
};

export const OrganizationInfo: FC<OrganizationInfoProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    translations = defaultTranslations,
    organizationName,
    country,
    currency,
    agreementNumber,
    agreementDate,
    isLoading,
    isSeeDetails,
    onSeeDetails
  } = props;

  return (
    <Card
      className={cn(
        className,
        isLoading ? styles['card--loading'] : styles.card
      )}
      style={style}
      id={id}
      data-testid={dataTestId}
    >
      <div className={styles.header}>
        <Typography className={offsets['mr-8']} variant="h5">
          {translations.organizationInfoText}
        </Typography>

        {isSeeDetails && (
          <div className={styles.details}>
            <Tooltip content={translations.seeDetails}>
              <div
                onClick={onSeeDetails && onSeeDetails}
                data-testid="details-icon"
              >
                <Icon size="s" name="chevrons-right" />
              </div>
            </Tooltip>
          </div>
        )}
      </div>

      <div className={styles.row}>
        {isLoading ? (
          <SkeletonPlaceholder width={180} height={18} />
        ) : (
          <>
            <Typography className={offsets['mr-4']} variant="body-4">
              {translations.organizationText}:
            </Typography>
            <Typography
              className={common['no-text-transform']}
              variant="body-5"
            >
              {organizationName || DASH}
            </Typography>
          </>
        )}
      </div>

      <div className={styles.row}>
        {isLoading ? (
          <SkeletonPlaceholder width={120} height={18} />
        ) : (
          <>
            <Typography className={offsets['mr-4']} variant="body-4">
              {translations.countryText}:
            </Typography>
            <Typography variant="body-5">{country || DASH}</Typography>
          </>
        )}
      </div>

      <div className={styles.row}>
        {isLoading ? (
          <SkeletonPlaceholder width={220} height={18} />
        ) : (
          <>
            <Typography className={offsets['mr-4']} variant="body-4">
              {translations.currencyText}:
            </Typography>
            <Typography variant="body-5">{currency || DASH}</Typography>
          </>
        )}
      </div>

      <div className={styles.row}>
        {isLoading ? (
          <SkeletonPlaceholder width={180} height={18} />
        ) : (
          <>
            <Typography className={offsets['mr-4']} variant="body-4">
              {translations.agreementNumberText}:
            </Typography>
            {agreementNumber ? (
              <Copy
                content={agreementNumber}
                copyText={translations.copyText}
                copiedText={translations.copiedText}
              />
            ) : (
              <Typography variant="body-5">–</Typography>
            )}
          </>
        )}
      </div>

      <div className={styles.row}>
        {isLoading ? (
          <SkeletonPlaceholder width={240} height={18} />
        ) : (
          <>
            <Typography className={offsets['mr-4']} variant="body-4">
              {translations.agreementDateText}:
            </Typography>
            <Typography variant="body-5">
              {formatDate(agreementDate)}
            </Typography>
          </>
        )}
      </div>
    </Card>
  );
};

OrganizationInfo.displayName = 'OrganizationInfo';
