export interface EntityName {
  uuid: string;
  name: string;
}

export enum Position {
  Top = 'top',
  Bottom = 'bottom'
}

export interface BackendErrorResponse {
  status: number;
  message: string;
  error: string;
  details?: BackendErrorResponseDetails[];
}

export interface BackendErrorResponseDetails {
  property: string;
  constraints?: Record<string, string>;
  value?: any;
  children?: BackendErrorResponseDetails[];
}
