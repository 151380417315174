import React, { FC } from 'react';
import {
  Card,
  ComponentProps,
  Icon,
  Tooltip,
  Typography,
  UsersTranslations
} from '@components';
import cn from 'classnames';
import styles from './Users.module.scss';
import { offsets } from '@componentsStyles';
import { UserItem } from '@components/InformationСard/components/Users/components/UserItem';

export interface UsersProps extends ComponentProps {
  totalUsers?: number;
  activeUsers?: number;
  externalUsers?: number;
  administrators?: number;
  translations?: UsersTranslations;
  isLoading?: boolean;
  isSeeDetails?: boolean;
  isHorizontalView?: boolean;

  onClick?(): void;
}

const defaultTranslations: UsersTranslations = {
  totalUsers: 'Total users',
  activeUsers: 'Active users',
  externalUsers: 'External users',
  administrators: 'Administrators',
  users: 'Users',
  seeDetails: 'See details'
};

export const Users: FC<UsersProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    translations = defaultTranslations,
    totalUsers,
    activeUsers,
    externalUsers,
    administrators,
    isLoading,
    isSeeDetails,
    onClick,
    isHorizontalView
  } = props;

  const seeDetails = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Card
      className={cn(
        className,
        isLoading ? styles['card--loading'] : styles.card
      )}
      style={style}
      id={id}
      data-testid={dataTestId}
    >
      <div className={styles.header}>
        <Typography className={offsets['mr-8']} variant="h5">
          {translations.users}
        </Typography>

        {isSeeDetails && (
          <div className={styles.details}>
            <Tooltip content={translations.seeDetails}>
              <div onClick={seeDetails} data-testid="details-icon">
                <Icon size="s" name="chevrons-right" />
              </div>
            </Tooltip>
          </div>
        )}
      </div>

      <div className={isHorizontalView ? styles.flex : styles.grid}>
        <UserItem
          title={translations.totalUsers}
          count={totalUsers}
          isLoading={isLoading}
        />
        <UserItem
          title={translations.activeUsers}
          count={activeUsers}
          isLoading={isLoading}
        />
        <UserItem
          title={translations.administrators}
          count={administrators}
          isLoading={isLoading}
        />
        <UserItem
          title={translations.externalUsers}
          count={externalUsers}
          isLoading={isLoading}
        />
      </div>
    </Card>
  );
};

Users.displayName = 'Users';
