import { colors } from '@componentsStyles';

export const chartColors = [
  colors.palettesBlue20,
  colors.palettesOrange30,
  colors.palettesGreen20,
  colors.palettesPurple20
];

export interface CartYAxisDatasets {
  label: string;
  data: ChartDataItem[];
}

export interface LegendKpiDataItem {
  [key: string]: number;
}

export interface DynamicKeysObj {
  [key: string]: any;
}

export interface ChartDataItem {
  x: string;
  y: number;
}

export type ChartData = {
  date: number | string;
} & {
  [key: string]: number | string;
};

export type ChartType = 'line' | 'bar';

export declare type ScrollDirection = 'left' | 'right';

export interface ChartKpiInfo {
  fieldName: string;
  title: string;
  description?: string;
  allowZeroValue: boolean;
}

export interface BrushPosition {
  startIndex?: number;
  endIndex?: number;
}
