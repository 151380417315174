import React, { FC } from 'react';

import colors from '@componentsStyles/colors.scss';

import { ComponentProps, SvgIconColors } from '@components';
import { SvgIconSize } from './dataTypes';

export interface SvgIconProps extends ComponentProps {
  color?: SvgIconColors;
  size?: SvgIconSize;
  strokeWidth?: number;
  viewBox?: string;
  width?: number;
  height?: number;

  onClick?(): void;
}

export const SvgIcon: FC<SvgIconProps> = (props) => {
  function getIconFillColor(color: SvgIconColors): string {
    switch (color) {
      case 'white': {
        return colors.whiteColor;
      }
      case 'warning': {
        return colors.goldColor;
      }
      case 'error': {
        return colors.red70Color;
      }
      case 'success': {
        return colors.green70Color;
      }
      case 'info': {
        return colors.blue70Color;
      }
      case 'default': {
        return colors.gray100Color;
      }
      case 'light-grey': {
        return colors.white10Color;
      }
      case 'disabled': {
        return colors.gray60Color;
      }
      default: {
        return props.color;
      }
    }
  }

  function getIconSizes(iconSize: SvgIconSize): {
    height: number;
    width: number;
  } {
    if (props.width && props.height) {
      return { height: props.height, width: props.width };
    }

    switch (iconSize) {
      case 'xxl': {
        return { height: 60, width: 60 };
      }
      case 'l': {
        return { height: 24, width: 24 };
      }
      case 'm': {
        return { height: 20, width: 20 };
      }
      case 's': {
        return { height: 16, width: 16 };
      }
      case 'xs': {
        return { height: 12, width: 12 };
      }
      case 'none': {
        return { height: 0, width: 0 };
      }
    }
  }

  const {
    className,
    children,
    color,
    id,
    dataTestId,
    size = 'l',
    strokeWidth,
    viewBox,
    style,
    onClick
  } = props;

  const iconFillColor = getIconFillColor(color);
  const iconSize = getIconSizes(size);

  return (
    <svg
      id={id}
      className={className}
      onClick={onClick}
      data-testid={dataTestId}
      version="1.1"
      baseProfile="full"
      xmlns="http://www.w3.org/2000/svg"
      height={iconSize?.height}
      width={iconSize?.width}
      viewBox={viewBox}
      fill={iconFillColor}
      color={iconFillColor}
      stroke="none"
      strokeWidth={strokeWidth}
      style={style}
    >
      {children}
    </svg>
  );
};

SvgIcon.displayName = 'SvgIcon';
