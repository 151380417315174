import React, { FC } from 'react';
import { colors, offsets } from '@componentsStyles';
import { Typography, Icon, SavedFileName, ComponentProps } from '@components';
import styles from './../File.module.scss';
import { displayFileName } from '@components/Uploader/components/utils';
import cn from 'classnames';
import { logger } from '@componentsUtils';

export interface SavedFileProps extends ComponentProps {
  fileName: SavedFileName;
  deleteFile: (backendFile: SavedFileName, event: any) => void;
  disabled?: boolean;
  onSaveBackendFile?: (file: SavedFileName) => Promise<Blob>;
}

export const SavedFile: FC<SavedFileProps> = (props) => {
  const {
    id,
    style,
    className,
    dataTestId,
    disabled,
    fileName,
    deleteFile,
    onSaveBackendFile
  } = props;

  const fillColor = (): string => {
    if (disabled) {
      return colors.gray20Color;
    }
    return colors.gray60Color;
  };

  async function saveBackendFile(fileName: SavedFileName) {
    try {
      const blob: Blob = await onSaveBackendFile(fileName);
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      logger.error(`Error in uploader saveBackendFile: `, error?.message);
    }
  }

  return (
    <div
      id={id}
      style={style}
      className={cn(styles.file, className)}
      data-testid={dataTestId}
      onClick={() => saveBackendFile(fileName)}
    >
      <div className={styles['file-wrap']}>
        <div className={styles['file-block']}>
          <Icon
            style={{ fill: colors.gray100Color }}
            size="s"
            className={offsets['mr-8']}
            name="paperclip"
          />
          <Typography
            color={colors.gray100Color}
            className={styles['file-name']}
            variant="label-1"
          >
            {displayFileName(fileName.name, fileName.byteSize).nameStart}
          </Typography>
          <Typography
            color={colors.gray100Color}
            variant="label-1"
            className={styles['file-name-end']}
          >
            {displayFileName(fileName.name, fileName.byteSize).nameEnd}
          </Typography>
          <Typography
            color={colors.gray100Color}
            variant="label-1"
            className={offsets['ml-8']}
          >
            {displayFileName(fileName.name, fileName.byteSize).size}
          </Typography>
        </div>
      </div>

      <div onClick={(event) => (disabled ? null : deleteFile(fileName, event))}>
        <Icon
          className={
            disabled ? styles['file-icon--disabled'] : styles['file-icon']
          }
          size="m"
          data-testid="file-icon"
          name={'trash'}
          style={{
            fill: fillColor()
          }}
        />
      </div>
    </div>
  );
};
