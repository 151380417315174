export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export async function downloadFileByLink(url: string, fileName?: string) {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName || 'File';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

export declare type ValidateType = 'email' | 'tel' | 'uuid';

export const ValidateTypes = Object.freeze({
  email: 'email' as ValidateType,
  tel: 'tel' as ValidateType,
  uuid: 'uuid' as ValidateType
});

export const validate = (
  inputType: ValidateType,
  value: number | string
): boolean => {
  const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  const phoneRegex = new RegExp(/^[\d\s()+-]+$/);
  const uuidRegex = new RegExp(
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
  );

  if (inputType === ValidateTypes.email) {
    return Boolean(emailRegex.test(String(value)));
  }

  if (inputType === ValidateTypes.tel) {
    return Boolean(phoneRegex.test(String(value)));
  }

  if (inputType === ValidateTypes.uuid) {
    return Boolean(uuidRegex.test(String(value)));
  }
  return true;
};

export const noop = () => {};
