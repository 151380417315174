import { openNotification } from '@components';
import { BackendErrorResponseDetails } from '@interfaces';
import React from 'react';

export const DEFAULT_NOTIFICATION_DELAY = 10000;
export const DEFAULT_SUCCESS_NOTIFICATION_DELAY = 5000;

/* Interface for translations of status notifications */
export interface StatusNotificationTranslations {
  savedSuccessfully: string;
  badRequest: string;
  internalServerError: string;
}

export interface OpenStatusNotificationParams {
  translations: StatusNotificationTranslations;
  status: number;
  message?: React.ReactNode;
  delay?: number;
  error?: {
    details?: BackendErrorResponseDetails[];
    code?: string;
    message?: string;
  };
}

export const openStatusNotification = (
  params: OpenStatusNotificationParams
) => {
  const { translations, status, message, delay, error } = params;

  /* Todo: in future we will map by error codes */

  switch (status) {
    case 200: {
      openNotification(
        message ? message : translations.savedSuccessfully,
        'success',
        delay ? delay : DEFAULT_SUCCESS_NOTIFICATION_DELAY
      );
      break;
    }
    case 400: {
      let errorMessage = message ? message : translations.badRequest;

      if (error?.details) {
        errorMessage += `, ${getRestrictionsMessage(error?.details)}`;
      }

      openNotification(
        errorMessage,
        'error',
        delay ? delay : DEFAULT_NOTIFICATION_DELAY
      );
      break;
    }
    default: {
      openNotification(
        getErrorMessage(params),
        'error',
        delay ? delay : DEFAULT_NOTIFICATION_DELAY
      );
    }
  }
};

function getErrorMessage(params: OpenStatusNotificationParams) {
  const { translations, message, error } = params;
  if (message) {
    return message;
  }

  if (!error?.message) {
    return translations.internalServerError;
  } else {
    return error?.message;
  }
}

/** Get string message from restrictions object */
export function getRestrictionsMessage(
  details: BackendErrorResponseDetails[]
): string {
  return details
    ?.map((el: BackendErrorResponseDetails) => {
      let message = `${el?.property} restriction`;
      if (el?.constraints) {
        const constraints: string[] = [];
        for (const constraintsKey in el?.constraints) {
          constraints.push(el?.constraints[constraintsKey]);
        }
        message += `: ${constraints.join(', ')}`;
      }
      if (
        el?.children &&
        Array.isArray(el.children) &&
        el.children.length > 0
      ) {
        message += `; ${getRestrictionsMessage(el?.children)}`;
      }
      return message;
    })
    .join('; ');
}
