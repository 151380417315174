import React, { FC } from 'react';
import {
  ComponentProps,
  Typography,
  Card,
  Icon,
  SkeletonPlaceholder,
  Superscript,
  Tooltip,
  GeneralInformationTranslations,
  EstimatedMonthlyPayment
} from '@components';
import cn from 'classnames';
import styles from './GeneralInformation.module.scss';
import { colors, offsets } from '@componentsStyles';
import { formatNumber, getCurrencySign } from '@componentsUtils';
import { DASH } from '@constants';

export interface GeneralInformationProps extends ComponentProps {
  organizationsNumber: number;
  estimatedMonthlyPayments: EstimatedMonthlyPayment[];
  translations?: GeneralInformationTranslations;
  isLoading?: boolean;
  onSeeDetails?(): void;
}

const defaultTranslations: GeneralInformationTranslations = {
  generalInformation: 'General information',
  organizations: 'organizations',
  estimatedMonthlyPayment: 'Estimated monthly payment',
  seeDetails: 'See details'
};

export const GeneralInformation: FC<GeneralInformationProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    translations = defaultTranslations,
    organizationsNumber,
    estimatedMonthlyPayments,
    isLoading,
    onSeeDetails
  } = props;

  return (
    <Card
      className={cn(
        className,
        isLoading ? styles['card--loading'] : styles.card
      )}
      style={style}
      id={id}
      data-testid={dataTestId}
    >
      <div className={styles.header}>
        <Typography className={offsets['mr-8']} variant="h5">
          {translations.generalInformation}
        </Typography>
        <div className={styles.details}>
          <Tooltip content={translations.seeDetails}>
            <div
              onClick={onSeeDetails && onSeeDetails}
              data-testid="details-icon"
            >
              <Icon size="s" name="chevrons-right" />
            </div>
          </Tooltip>
        </div>
      </div>

      <div className={styles.grid}>
        <div className={offsets['mr-60']}>
          <Typography
            className={offsets['mb-4']}
            variant="system-heading"
            color={colors.gray40Color}
            element="div"
          >
            {translations.organizations}
          </Typography>

          {isLoading ? (
            <SkeletonPlaceholder width={68} height={40} />
          ) : (
            <Typography variant="h1" element="div">
              {organizationsNumber ? formatNumber(organizationsNumber) : DASH}
            </Typography>
          )}
        </div>

        <div>
          <Typography
            className={offsets['mb-4']}
            variant="system-heading"
            color={colors.gray40Color}
            element="div"
          >
            {translations.estimatedMonthlyPayment}
          </Typography>

          {isLoading ? (
            <SkeletonPlaceholder width={210} height={40} />
          ) : (
            <div className={styles.payments}>
              {estimatedMonthlyPayments?.length > 0 &&
                estimatedMonthlyPayments?.map((payment, index) => {
                  return (
                    <div key={index} className={styles.payment}>
                      <Superscript
                        content={formatNumber(payment.amount, 2)}
                        sign={getCurrencySign(payment.currencyIso3)}
                      />
                      {index < estimatedMonthlyPayments.length - 1 && (
                        <div className={styles.line} />
                      )}
                    </div>
                  );
                })}

              {(!estimatedMonthlyPayments ||
                estimatedMonthlyPayments?.length === 0) && (
                <Typography variant="h1" element="div">
                  –
                </Typography>
              )}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

GeneralInformation.displayName = 'GeneralInformation';
