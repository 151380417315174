import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const LogoExquanceIconDisabled: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 247 40" width={247} height={40}>
    <path d="M0 0L6.66667 10L13.3333 0H0Z" fill="#DADADA" />
    <path d="M36.665 30L43.3317 20L49.9984 30H36.665Z" fill="#EEEEEE" />
    <path d="M30 0L36.6667 10L43.3333 0H30Z" fill="#B3B3B3" />
    <path d="M23.335 10L30.0016 0L36.6683 10H23.335Z" fill="#B3B3B3" />
    <path d="M23.335 10L30.0016 20L36.6683 10H23.335Z" fill="#EEEEEE" />
    <path d="M13.335 20L20.0016 10L26.6683 20H13.335Z" fill="#DADADA" />
    <path d="M13.335 20L20.0016 30L26.6683 20H13.335Z" fill="#8F8F8F" />
    <path d="M6.66504 30L13.3317 20L19.9984 30H6.66504Z" fill="#8F8F8F" />
    <path d="M36.665 30L43.3317 40L49.9984 30H36.665Z" fill="#EEEEEE" />
    <path d="M43.335 40L50.0016 30L56.6683 40H43.335Z" fill="#EEEEEE" />
    <path d="M6.66504 10L13.3317 20L19.9984 10H6.66504Z" fill="#DADADA" />
    <path d="M6.66504 10L13.3317 0L19.9984 10H6.66504Z" fill="#DADADA" />
    <path d="M30 20L36.6667 30L43.3333 20H30Z" fill="#EEEEEE" />
    <path d="M30 20L36.6667 10L43.3333 20H30Z" fill="#EEEEEE" />
    <path
      d="M95.3333 18.3333L88 28.1667H84.5L93.3333 16.3333L84.8333 5H88.3333L95.1667 14.3333L102.167 5H105.667L97.1667 16.3333L106.167 28.1667H102.667L95.3333 18.3333Z"
      fill="black"
    />
    <path
      d="M118.999 4.16675C112.166 4.16675 106.666 9.66675 106.666 16.5001C106.666 23.3334 112.166 28.8334 118.999 28.8334C120.499 28.8334 121.833 28.5001 123.166 28.0001L120.999 25.6667C120.333 25.8334 119.666 25.8334 118.999 25.8334C113.833 25.8334 109.666 21.6667 109.666 16.3334C109.666 11.0001 113.833 7.00008 118.999 7.00008C124.166 7.00008 128.333 11.3334 128.333 16.5001C128.333 20.0001 126.333 23.0001 123.499 24.6667L125.499 26.8334C128.833 24.6667 131.166 20.8334 131.166 16.5001C131.333 9.83341 125.833 4.16675 118.999 4.16675Z"
      fill="black"
    />
    <path
      d="M66.666 5H81.8327V7.5H69.4994V15V17.5V25.5H81.8327V28H66.666V5Z"
      fill="black"
    />
    <path
      d="M134.999 5H137.833V19.1667C137.833 23.3333 140.166 26 144.333 26C148.666 26 150.833 23.3333 150.833 19.1667V5H153.666V19.1667C153.666 25 150.499 28.6667 144.166 28.6667C137.833 28.6667 134.666 25 134.666 19.3333V5H134.999Z"
      fill="black"
    />
    <path
      d="M184.166 9.5V28.1667H181.333V5H184.333L197.666 23.3333V5H200.5V28.1667H197.666L184.166 9.5Z"
      fill="black"
    />
    <path
      d="M205 16.6667C205 9.50008 210.167 4.66675 216.833 4.66675C221 4.66675 223.833 6.66675 225.667 9.33341L223.333 10.5001C222 8.50008 219.667 7.16675 216.833 7.16675C211.833 7.16675 208 11.0001 208 16.5001C208 22.0001 211.833 25.8334 216.833 25.8334C219.5 25.8334 222 24.5001 223.333 22.5001L225.833 23.8334C223.833 26.5001 221.167 28.5001 216.833 28.5001C210.167 28.5001 205 23.6667 205 16.6667Z"
      fill="black"
    />
    <path
      d="M168.999 5H165.499L156.166 28.1667H159.333L162.333 20.5L167.333 7.5L172.333 20.5H164.999L163.999 23H173.166L175.166 28.1667H178.333L168.999 5Z"
      fill="black"
    />
    <path
      d="M78.166 15.1667H71.9994L70.166 17.6667H78.166V15.1667Z"
      fill="black"
    />
    <path
      d="M230 5H245.167V7.5H232.833V15V17.5V25.5H245.167V28H230V5Z"
      fill="black"
    />
    <path
      d="M241.5 15.1667H235.333L233.5 17.6667H241.5V15.1667Z"
      fill="black"
    />
    <path
      d="M120.166 21.3333L127.499 29.1667L129.666 27.5L122.166 19.5L120.166 21.3333Z"
      fill="black"
    />
  </SvgIcon>
);

LogoExquanceIconDisabled.displayName = 'LogoExquanceIconDisabled';
