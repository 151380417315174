import React, { FC, useMemo } from 'react';
import styles from './DonutChart.module.scss';
import { ComponentProps, Typography } from '@components';
import cn from 'classnames';
import { chartColors, DonutSlice } from './dataTypes';
import { colors, offsets } from '@componentsStyles';
import {
  Currency,
  formatCurrency,
  formatNumber
} from '@componentsUtils/format';
import { CalculusHelper } from './utils';

export interface DonutChartProps extends ComponentProps {
  data: DonutSlice[];
  /** Text in the center of the chart */
  heading: string;
  /** Currency of values */
  currency?: Currency;
  /** Callback when click on chart slice */
  onClickSlice?: (slice: DonutSlice) => void;
  /** If not currency values */
  sign?: string;
}

export const DonutChart: FC<DonutChartProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    data,
    heading,
    onClickSlice,
    currency,
    sign
  } = props;

  const helper = new CalculusHelper();

  const viewBox = 100;
  const borderSize = 21;
  const radius = 50;

  const total = useMemo(() => {
    return data?.reduce((sum, a) => sum + a?.value, 0);
  }, [data]);

  const dataWithPercent = useMemo(() => {
    if (!data || data?.length === 0) {
      return [
        {
          uuid: '0',
          value: 0,
          label: '',
          percent: 100
        }
      ];
    }

    if (total === 0) {
      return data?.map((el) => {
        return {
          ...el,
          percent: Number((1 / data.length) * 100)
        };
      });
    }

    return data?.map((el) => {
      return {
        ...el,
        percent: Number((el?.value / total) * 100)
      };
    });
  }, [data, total]);

  const formattedValue = (value: number) => {
    if (currency) {
      return formatCurrency(currency, value, 2);
    }
    const number = formatNumber(value);
    if (sign) {
      return `${number} ${sign}`;
    }
    return number;
  };

  return (
    <div
      id={id}
      style={style}
      data-testid={dataTestId}
      className={cn(className, styles.chart)}
    >
      <div className={styles['chart-svg']}>
        {data?.length > 1 && (
          <svg viewBox={'0 0 ' + viewBox + ' ' + viewBox}>
            {helper
              .getSlicesWithCommandsAndOffsets(
                dataWithPercent,
                radius,
                viewBox,
                borderSize
              )
              .map((slice, key) => (
                <path
                  data-testid={'slice'}
                  key={key}
                  onClick={onClickSlice ? () => onClickSlice(slice) : null}
                  fill={chartColors[key]}
                  d={slice.commands}
                  transform={'rotate(' + slice.offset + ')'}
                >
                  <title>{slice?.label}</title>
                </path>
              ))}
          </svg>
        )}

        {(!data || data?.length <= 1) && (
          <svg viewBox={'0 0 ' + viewBox + ' ' + viewBox}>
            <circle
              cx="50"
              cy="50"
              r="39.5"
              stroke={chartColors[0]}
              strokeWidth={borderSize}
              fill="transparent"
            />
          </svg>
        )}

        <div className={styles.info}>
          {heading && (
            <Typography
              variant="system-heading"
              className={offsets['mb-4']}
              color={colors.gray40Color}
              element="div"
            >
              {heading}
            </Typography>
          )}

          <Typography dataTestId="total" variant="number-2">
            {formattedValue(total)}
          </Typography>
        </div>
      </div>

      <div className={styles['description-block']}>
        {data?.map((el, key) => {
          return (
            <div key={key} className={styles.description}>
              <div>
                <Typography
                  variant="system-heading"
                  color={colors.gray40Color}
                  element="div"
                  className={offsets['mb-4']}
                >
                  {el.label}
                </Typography>
                <Typography variant="number-1">
                  {formattedValue(el.value)}
                </Typography>
              </div>
              <div
                className={styles['description-color']}
                style={{ backgroundColor: chartColors[key] }}
              ></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

DonutChart.displayName = 'DonutChart';
