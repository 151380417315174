import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const CloudIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.08827 3.04741C9.50714 2.89947 10.94 3.09892 12.2689 3.62932C13.5977 4.15972 14.7844 5.00587 15.7311 6.09804C16.5039 6.98953 17.0987 8.02409 17.4852 9.14315H18.0071C19.3842 9.14481 20.7189 9.6315 21.7871 10.5215C22.8553 11.4116 23.5919 12.6508 23.8734 14.0311C24.1548 15.4114 23.9639 16.8489 23.3327 18.1022C22.6549 19.4481 21.4845 20.1699 20.2699 20.5509C19.0718 20.9267 17.7466 21 16.5964 21H8.91396C5.43182 21 2.29539 19.4107 0.794808 16.0015C0.208801 14.6702 -0.0598008 13.2153 0.0111423 11.7568C0.0820855 10.2983 0.490542 8.87792 1.20285 7.61278C1.91515 6.34764 2.9109 5.27394 4.10803 4.48018C5.30516 3.68643 6.66939 3.19534 8.08827 3.04741ZM2.61818 15.1601C3.72863 17.6829 6.04145 18.9522 8.91396 18.9522H16.5964C17.6949 18.9522 18.7781 18.8771 19.684 18.5929C20.5734 18.3139 21.2025 17.864 21.5552 17.1637C21.9759 16.3281 22.1032 15.3698 21.9156 14.4496C21.7279 13.5294 21.2369 12.7032 20.5247 12.1099C19.8127 11.5166 18.9231 11.1922 18.0051 11.191C18.005 11.191 18.0052 11.191 18.0051 11.191H16.7459C16.2899 11.191 15.8917 10.8751 15.7776 10.4231C15.5003 9.32335 14.9716 8.30687 14.2353 7.4574C13.4989 6.60794 12.576 5.94982 11.5424 5.53729C10.5089 5.12476 9.39444 4.96963 8.29087 5.08469C7.1873 5.19975 6.12624 5.5817 5.19513 6.19907C4.26403 6.81643 3.48956 7.65153 2.93555 8.63553C2.38153 9.61953 2.06384 10.7243 2.00866 11.8586C1.95349 12.993 2.1624 14.1246 2.61818 15.1601Z"
      fill={props.color}
    />
  </SvgIcon>
);

CloudIcon.displayName = 'CloudIcon';
