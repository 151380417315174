export interface DatabaseVersionTranslations {
  seeDetails?: string;
  mtDatabaseVersion?: string;
  type?: string;
  local?: string;
  shared?: string;
  version?: string;
  errorDetails?: string;
  testConnectionFailed?: string;
  online?: string;
  lastUpdate?: string;
  from?: string;
}

export const DatabaseTypes = Object.freeze({
  Local: 'local',
  Shared: 'shared'
});
