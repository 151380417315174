import React, { forwardRef, useEffect, useState } from 'react';
import cn from 'classnames';
import colors from '@componentsStyles/colors.scss';
import { ComponentProps, Typography, Icon, Loader } from '@components';
import styles from './Select.module.scss';
import { SelectItem } from './dataTypes';
import { useComponentVisible } from '@componentsUtils';
import inputStyles from '../Input/Input.module.scss';

export interface SelectProps extends ComponentProps {
  /**
   * All items list
   */
  items: SelectItem[];
  /**
   * Label
   */
  label: string;
  /**
   * Called when value of an Input changed
   */
  onChange?: (value: SelectItem) => void;
  /**
   * Specifies that an Input should be disabled
   */
  disabled?: boolean;
  /**
   * Specifies that options are loading
   */
  isLoading?: boolean;
  /**
   * Specifies that an Input should be required
   */
  required?: boolean;
  /**
   * Shows error message
   */
  errorMessage?: string;
  /**
   * Specifies the value of an Input
   */
  selected?: SelectItem;
  /**
   * Specifies if close will be appeared on input
   */
  withCloseButton?: boolean;
}

export type SelectRef = HTMLDivElement;

export const Select = forwardRef<SelectRef, SelectProps>((props, ref) => {
  const {
    id,
    style,
    className,
    dataTestId,
    disabled,
    isLoading,
    required,
    label,
    items,
    errorMessage,
    selected,
    withCloseButton = true,
    onChange
  } = props;

  const {
    ref: optionsRef,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  const [search, setSearch] = useState<string>('');
  const [filteredItems, setFilteredItems] = useState<SelectItem[]>([]);

  useEffect(() => {
    setSearch(selected?.label || '');
  }, [selected]);

  useEffect(() => {
    if (search) {
      const filteredItems = items?.filter(
        (word) => word?.label?.toLowerCase().includes(search?.toLowerCase())
      );
      setFilteredItems(filteredItems);
    } else {
      setFilteredItems(items);
    }
  }, [items, search]);

  useEffect(() => {
    if (!isComponentVisible) {
      setSearch(selected?.label || '');
    }
  }, [isComponentVisible]);

  const selectItem = (item: SelectItem) => {
    onChange && onChange(item);
    setSearch(item.label);
    setIsComponentVisible(false);
  };

  const showOptionsList = () => {
    if (disabled) {
      return;
    }
    setIsComponentVisible(true);
  };

  const toggleOptionsList = () => {
    if (disabled) {
      return;
    }
    setIsComponentVisible(!isComponentVisible);
  };

  const onCloseClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSearch('');
    if (onChange) {
      onChange(null);
    }
  };

  return (
    <div
      ref={ref}
      id={id}
      style={style}
      className={cn(
        className,
        disabled ? styles['wrapper--disabled'] : styles.wrapper
      )}
      data-testid={dataTestId}
    >
      <div ref={optionsRef}>
        <div className={inputStyles.form}>
          <input
            value={search}
            className={cn(
              inputStyles.input,
              errorMessage && inputStyles.input_error,
              withCloseButton
                ? styles['input-select']
                : inputStyles['input-short']
            )}
            disabled={disabled}
            required={required}
            onChange={(event) => setSearch(String(event.target.value))}
            onFocus={showOptionsList}
            placeholder=" "
          />

          <label className={inputStyles.label} htmlFor={id}>
            {label}
            {required && (
              <span className={inputStyles['required-symbol']}>*</span>
            )}
          </label>

          {withCloseButton && (
            <div
              onClick={onCloseClick}
              className={
                selected?.label && !disabled
                  ? styles.close
                  : styles['close--hidden']
              }
            >
              <Icon name="close" size="s" color="disabled" />
            </div>
          )}

          <Icon
            name="chevron-down"
            size="s"
            className={styles.icon}
            onClick={toggleOptionsList}
          />

          {errorMessage && (
            <Typography
              variant="system"
              color={colors.red70Color}
              className={inputStyles['error-message']}
            >
              {errorMessage}
            </Typography>
          )}
        </div>

        <div className={styles.list}>
          {isComponentVisible && !disabled && (
            <ul>
              {isLoading && (
                <div className={styles.loader}>
                  <Loader size={'m'} color={'orange'} />
                </div>
              )}

              {!isLoading &&
                filteredItems?.map((item: SelectItem) => {
                  return (
                    <li
                      className={
                        item.value === selected?.value
                          ? styles['list-item--selected']
                          : styles['list-item']
                      }
                      onClick={() => selectItem(item)}
                      key={item.value}
                    >
                      {item.label}
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
});

Select.displayName = 'Select';
