import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { ComponentProps, Icon, Tooltip, Typography } from '@components';
import styles from './Copy.module.scss';
import { logger } from '@componentsUtils';

export interface CopyProps extends ComponentProps {
  /** Object for copy to clipboard */
  content: string;
  copyText: string;
  copiedText: string;
  contentClassName?: string;
}

export const Copy: FC<CopyProps> = (props) => {
  const {
    content,
    copiedText = 'copied',
    copyText = 'copy',
    className,
    id,
    style,
    contentClassName,
    dataTestId
  } = props;

  const [tooltipText, setTooltipText] = useState<string>(copyText);
  const [copyIconClass, setCopyIconClass] = useState<string>(styles.copy);

  const copyToClipboard = async () => {
    await navigator.clipboard
      .writeText(content)
      .then(() => {
        setTooltipText(copiedText);
        setCopyIconClass(styles['copy--success']);
      })
      .catch((error) => {
        setCopyIconClass(styles['copy--error']);
        logger.error(`Error while coping text: ${error}`);
      })
      .finally(() => {
        setTimeout(() => {
          setTooltipText(copyText);
          setCopyIconClass(styles.copy);
        }, 2000);
      });
  };

  return (
    <div
      id={id}
      style={style}
      className={classNames(className, styles.container)}
      data-testid={dataTestId}
    >
      <Typography className={contentClassName} variant="body-5">
        {content}
      </Typography>

      <Tooltip content={tooltipText} type="bottom">
        <Icon
          name="copy"
          size="s"
          className={copyIconClass}
          onClick={copyToClipboard}
        />
      </Tooltip>
    </div>
  );
};

Copy.displayName = 'Copy';
