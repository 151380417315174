import React, { FC } from 'react';
import classNames from 'classnames';

import { ComponentProps } from '@components';
import { ColType } from '@components/Grid/dataTypes';
import { grid } from '@componentsStyles';

export interface ColProps extends ComponentProps {
  col: ColType;
  sm?: ColType;
  md?: ColType;
  lg?: ColType;
}
export const Col: FC<ColProps> = (props) => {
  const { className, children, id, style, col, sm, md, lg } = props;

  const colClass = () => {
    let classes = `${grid[`col-${col}`]} `;
    if (sm) {
      classes += `${grid[`col-sm-${sm}`]} `;
    }
    if (md) {
      classes += `${grid[`col-md-${md}`]} `;
    }
    if (lg) {
      classes += `${grid[`col-lg-${lg}`]} `;
    }
    return classes;
  };

  return (
    <div id={id} className={classNames(className, colClass())} style={style}>
      {children}
    </div>
  );
};

Col.displayName = 'Col';
