import React, { FC } from 'react';
import {
  ComponentProps,
  Typography,
  Card,
  SkeletonPlaceholder
} from '@components';
import cn from 'classnames';
import styles from './UsersGeneral.module.scss';
import { colors, offsets } from '@componentsStyles';
import { UsersGeneralTranslations } from '@components/InformationСard/components/UsersGeneral/dataTypes';
import { DASH } from '@constants';

export interface UsersGeneralProps extends ComponentProps {
  totalUsers: number;
  activeUsers: number;
  administrators: number;
  translations?: UsersGeneralTranslations;
  isLoading?: boolean;
}

const defaultTranslations: UsersGeneralTranslations = {
  totalUsers: 'Total users',
  activeUsers: 'Active users',
  administrators: 'Administrators',
  users: 'Users'
};

export const UsersGeneral: FC<UsersGeneralProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    translations = defaultTranslations,
    totalUsers,
    activeUsers,
    administrators,
    isLoading
  } = props;

  return (
    <Card
      className={cn(
        className,
        isLoading ? styles['card--loading'] : styles.card
      )}
      style={style}
      id={id}
      data-testid={dataTestId}
    >
      <div className={styles.header}>
        <Typography className={offsets['mr-8']} variant="h5">
          {translations.users}
        </Typography>
      </div>

      <div className={styles.flex}>
        <div className={offsets['mr-60']}>
          <Typography
            className={offsets['mb-4']}
            variant="system-heading"
            color={colors.gray40Color}
            element="div"
          >
            {translations.administrators}
          </Typography>

          {isLoading ? (
            <SkeletonPlaceholder width={60} height={40} />
          ) : (
            <Typography variant={'h1'} element="div">
              {administrators || DASH}
            </Typography>
          )}
        </div>

        <div className={offsets['mr-60']}>
          <Typography
            className={offsets['mb-4']}
            variant="system-heading"
            color={colors.gray40Color}
            element="div"
          >
            {translations.totalUsers}
          </Typography>

          {isLoading ? (
            <SkeletonPlaceholder width={60} height={40} />
          ) : (
            <Typography variant={'h1'} element="div">
              {totalUsers || DASH}
            </Typography>
          )}
        </div>

        <div>
          <Typography
            className={offsets['mb-4']}
            variant="system-heading"
            color={colors.gray40Color}
            element="div"
          >
            {translations.activeUsers}
          </Typography>

          {isLoading ? (
            <SkeletonPlaceholder width={60} height={40} />
          ) : (
            <Typography variant={'h1'} element="div">
              {activeUsers || DASH}
            </Typography>
          )}
        </div>
      </div>
    </Card>
  );
};

UsersGeneral.displayName = 'UsersGeneral';
