import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Typography } from '@components';
const chartPlaceholder = require('@assets/images/chartPlaceholder.svg');

import styles from '@components/Charts/TableChart/TableChart.module.scss';

function ChartPlaceholder({ className }: { className: string }): ReactElement {
  return (
    <div className={classNames(styles.placeholderWrapper, className)}>
      <Typography variant="h5">No data selected</Typography>
      <img src={chartPlaceholder} alt="no data chart" />
    </div>
  );
}

export default ChartPlaceholder;
