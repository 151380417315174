export const config = {
  apiUrl: `${process.env.API_URL}/api`,

  accountFrontendUrl: process.env.FRONTEND_ACCOUNT_URL,
  ssoFrontendLoginUrl: process.env.FRONTEND_SSO_LOGIN_URL,
  setResetPasswordUrl: process.env.FRONTEND_SSO_RESET_PASSWORD_URL,
  administrationFrontendUrl: process.env.FRONTEND_ADMINISTRATION_URL,
  omniFrontendUrl: process.env.FRONTEND_OMNI_URL,
  modelTreeFrontendUrl: process.env.FRONTEND_MODEL_TREE_URL,
  insightsFrontendUrl: process.env.FRONTEND_INSIGHTS_URL,
  logLevel: process.env.APP_LOG_LEVEL || 'log',

  cdnUrl: 'https://cdn.exquance.com',

  defaultLang: 'EN',
  localStorageLang: 'lang'
};
