import React, { FC } from 'react';
import classNames from 'classnames';
import { ComponentProps, Typography } from '@components';
import styles from './Chevron.module.scss';
import { colors } from '@componentsStyles';

export interface ChevronProps extends ComponentProps {
  disabled?: boolean;
  backgroundColor?: string;
}

export const Chevron: FC<ChevronProps> = (props) => {
  const {
    className,
    children,
    id,
    style,
    disabled = false,
    dataTestId,
    backgroundColor = colors.gold
  } = props;

  const background = disabled ? colors.gray40Color : backgroundColor;

  return (
    <div
      id={id}
      style={{ ...style, backgroundColor: background }}
      className={classNames(className, styles.chevron)}
      data-testid={dataTestId}
    >
      <Typography variant="chevron" className={styles.title}>
        {children}
      </Typography>
    </div>
  );
};

Chevron.displayName = 'Chevron';
