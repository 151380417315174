import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const PlayIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.52081 2.12229C4.84189 1.947 5.23305 1.96101 5.54076 2.15882L19.5408 11.1588C19.827 11.3428 20 11.6597 20 12C20 12.3403 19.827 12.6572 19.5408 12.8412L5.54076 21.8412C5.23305 22.039 4.84189 22.053 4.52081 21.8777C4.19974 21.7024 4 21.3658 4 21V3C4 2.63419 4.19974 2.29758 4.52081 2.12229ZM6 4.83167V19.1683L17.1507 12L6 4.83167Z"
      fill={props.color}
    />
  </SvgIcon>
);

PlayIcon.displayName = 'PlayIcon';
