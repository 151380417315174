import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const EditIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 3.17163C18.7599 3.17163 18.5222 3.21892 18.3003 3.31081C18.0785 3.4027 17.8769 3.53738 17.7071 3.70716L4.39491 17.0194L3.42524 20.5748L6.9807 19.6052L20.2929 6.29295C20.4627 6.12317 20.5974 5.9216 20.6893 5.69977C20.7812 5.47793 20.8284 5.24017 20.8284 5.00006C20.8284 4.75994 20.7812 4.52218 20.6893 4.30035C20.5974 4.07852 20.4627 3.87695 20.2929 3.70716C20.1231 3.53738 19.9216 3.4027 19.6997 3.31081C19.4779 3.21892 19.2401 3.17163 19 3.17163ZM17.5349 1.46305C17.9994 1.27066 18.4973 1.17163 19 1.17163C19.5028 1.17163 20.0006 1.27066 20.4651 1.46305C20.9296 1.65545 21.3516 1.93745 21.7071 2.29295C22.0626 2.64845 22.3446 3.07049 22.537 3.53498C22.7294 3.99947 22.8284 4.4973 22.8284 5.00006C22.8284 5.50281 22.7294 6.00064 22.537 6.46513C22.3446 6.92962 22.0626 7.35166 21.7071 7.70716L8.20713 21.2072C8.08407 21.3302 7.93104 21.419 7.76314 21.4648L2.26314 22.9648C1.91693 23.0592 1.54667 22.9609 1.29292 22.7072C1.03917 22.4534 0.940838 22.0832 1.03526 21.7369L2.53526 16.2369C2.58105 16.069 2.66986 15.916 2.79292 15.793L16.2929 2.29295C16.6484 1.93745 17.0705 1.65545 17.5349 1.46305Z"
      fill={props.color}
    />
  </SvgIcon>
);

EditIcon.displayName = 'EditIcon';
