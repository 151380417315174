import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const ErrorIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.22289 4.22289C4.61342 3.83237 5.24658 3.83237 5.63711 4.22289L19.7771 18.3629C20.1676 18.7534 20.1676 19.3866 19.7771 19.7771C19.3866 20.1676 18.7534 20.1676 18.3629 19.7771L4.22289 5.63711C3.83237 5.24658 3.83237 4.61342 4.22289 4.22289Z"
      fill={props.color}
    />
    ]
  </SvgIcon>
);

ErrorIcon.displayName = 'ErrorIcon';
