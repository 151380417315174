import React, { forwardRef, Fragment, useState } from 'react';
import cn from 'classnames';
import colors from '@componentsStyles/colors.scss';
import { ComponentProps, Typography, Icon } from '@components';
import styles from './Input.module.scss';
import { InputType } from '@components/Input/dataTypes';
export interface InputProps extends ComponentProps {
  label: string;
  /**
   * Called when value of an Input changed
   */
  type?: InputType;
  /**
   * Called when value of an Input changed
   */
  onChange?: (value: number | string) => void;
  /**
   * Called when input set focus
   */
  onFocus?: (value: number | string) => void;
  /**
   * Called when input remove focus
   */
  onBlur?: (value: number | string) => void;
  /**
   * Called when enter button clicked
   */
  onEnter?: () => void;
  /**
   * Specifies that an Input should be disabled
   */
  disabled?: boolean;
  /**
   * Specifies that an Input should be required
   */
  required?: boolean;
  /**
   * Shows error message
   */
  errorMessage?: string;
  /**
   * Specifies the value of an Input
   */
  value?: number | string;
  /**
   * Specifies that an Input should be with icon
   */
  withIcon?: boolean;
  inputMode?: React.HTMLAttributes<HTMLLIElement>['inputMode'];
  autoComplete?: string;
}

export type InputRef = HTMLInputElement;

export const Input = forwardRef<InputRef, InputProps>((props, ref) => {
  const {
    id,
    className,
    dataTestId,
    disabled,
    required,
    label,
    type = 'text',
    errorMessage,
    withIcon,
    value,
    onChange,
    onEnter,
    onFocus,
    onBlur,
    style,
    inputMode = 'text',
    autoComplete
  } = props;

  const [inputType, setInputType] = useState<InputType>(type);

  const iconType = () => {
    if (errorMessage) {
      return <Icon name="info" size="m" className={styles.icon} />;
    }
    if (type === 'password') {
      if (inputType === 'password') {
        return (
          <Icon
            name="eye-open"
            onClick={toggleVisibility}
            size="m"
            className={styles.icon}
          />
        );
      }
      return (
        <Icon
          name="eye-close"
          onClick={toggleVisibility}
          size="m"
          className={styles.icon}
        />
      );
    }
    if (onEnter) {
      return (
        <Icon
          name="corner-down-left"
          onClick={onEnter}
          size="m"
          className={styles['icon-enter']}
        />
      );
    }
  };

  const toggleVisibility = () => {
    if (!disabled) {
      setInputType(inputType === 'password' ? 'text' : 'password');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onEnter) {
      onEnter();
    }
  };

  return (
    <div
      className={cn(styles.form, className)}
      style={style}
      data-testid={dataTestId}
    >
      <input
        ref={ref}
        id={id}
        type={inputType}
        value={value}
        className={cn(
          styles.input,
          errorMessage && styles.input_error,
          withIcon && styles['input-short']
        )}
        disabled={disabled}
        required={required}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onFocus={(e) => {
          onFocus && onFocus(e.target.value);
        }}
        onBlur={(e) => {
          onBlur && onBlur(e.target.value);
        }}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder=" "
        style={style}
        inputMode={inputMode}
        autoComplete={autoComplete}
      />

      <label className={styles.label} htmlFor={id}>
        {label}
        {required && <span className={styles['required-symbol']}>*</span>}
      </label>

      {withIcon && <Fragment>{iconType()}</Fragment>}

      {errorMessage && (
        <Typography
          variant="system"
          color={colors.red70Color}
          className={styles['error-message']}
        >
          {errorMessage}
        </Typography>
      )}
    </div>
  );
});

Input.displayName = 'Input';
