import React from 'react';
import styles from './Checkbox.module.scss';
import { Typography, ComponentProps } from '@components';
import classNames from 'classnames';
export interface CheckboxProps extends ComponentProps {
  /** ID for the checkbox */
  id?: string;
  /** Checkbox label */
  label?: string;
  /** Is the checkbox checked */
  checked?: boolean;
  /**
   * Disables the checkbox
   */
  disabled?: boolean;
  /** Function to invoke when checkbox has changed */
  onChange?: (e: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const {
    id,
    className,
    disabled,
    label,
    checked,
    onChange,
    style,
    dataTestId
  } = props;

  function onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    if (onChange) {
      onChange(event.target.checked);
    }
  }

  return (
    <label
      id={id}
      className={classNames(styles.checkbox, className)}
      style={style}
      data-testid={dataTestId}
    >
      <input
        type="checkbox"
        className={styles.input}
        checked={checked}
        disabled={disabled}
        onChange={onChangeHandler}
        aria-label={label ? label : 'input'}
      />
      <span className={styles.box} />
      {label && (
        <Typography variant="body-1" className={styles.text}>
          {label}
        </Typography>
      )}
    </label>
  );
};
