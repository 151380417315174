import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';

import { Button, ComponentProps, Icon, Typography } from '@components';
import styles from './Modal.module.scss';
import { offsets } from '@componentsStyles';

export interface ModalProps extends ComponentProps {
  title: string;
  isOpen: boolean;
  withCloseButton?: boolean;
  cancelText?: string;
  submitText?: string;
  isSubmitDisabled?: boolean;
  maxHeight?: string;
  maxWidth?: string;

  onClose(): void;

  onCancel?(): void;

  onSubmit?(): void;
}

export const Modal: FC<ModalProps> = (props) => {
  const {
    className,
    children,
    id,
    style,
    title,
    cancelText,
    submitText,
    onCancel,
    onSubmit,
    onClose,
    isOpen,
    maxHeight,
    maxWidth,
    withCloseButton,
    isSubmitDisabled
  } = props;

  const modalRef = useRef<HTMLDialogElement>(null);
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const submit = async () => {
    if (onSubmit) {
      setIsSubmitLoading(true);
      await onSubmit();
      setIsSubmitLoading(false);
    }
    close();
  };

  const cancel = () => {
    if (onCancel) {
      onCancel();
    }

    close();
  };

  const close = () => {
    const { current } = modalRef;

    setModalClasses(cn(className, styles.modal, styles.hide));

    current.addEventListener(
      'animationend',
      () => {
        setModalClasses(cn(className, styles.modal));
        // @ts-ignore
        current.close();
      },
      { once: true }
    );

    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    const { current } = modalRef;
    if (current && isOpen) {
      // todo check why typescript doesn't have showModal and other dialog attributes
      // @ts-ignore
      current.showModal();
      isOpen && current.scroll({ top: 0 });
    }
  }, [isOpen]);

  useEffect(() => {
    // @ts-ignore
    if (modalRef?.current?.open) {
      setIsModalShown(true);
    }

    // @ts-ignore
  }, [modalRef?.current?.open]);

  useEffect(() => {
    return () => {
      setIsModalShown(false);
    };
  }, []);

  const [modalClasses, setModalClasses] = useState(cn(className, styles.modal));

  const dialogStyles = useMemo(() => {
    const styles = { ...style };

    if (maxHeight) {
      styles.maxHeight = maxHeight;
    }

    if (maxWidth) {
      styles.width = maxWidth;
    }

    return styles;
  }, [style, maxHeight, maxWidth]);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  return (
    <dialog
      id={id}
      style={{ ...dialogStyles }}
      className={modalClasses}
      ref={modalRef}
    >
      <Typography className={styles.text} variant="body-2" element="div">
        {title}
      </Typography>

      {withCloseButton && (
        <Icon name="close" size="s" className={styles.close} onClick={close} />
      )}

      {children && isModalShown && <div>{children}</div>}

      {cancelText && submitText && (
        <div className={styles.buttons}>
          <Button
            type={'primary'}
            className={offsets['mr-20']}
            onClick={submit}
            isLoading={isSubmitLoading}
            disabled={isSubmitDisabled}
          >
            {submitText}
          </Button>
          <Button
            type={'secondary'}
            onClick={cancel}
            disabled={isSubmitLoading}
          >
            {cancelText}
          </Button>
        </div>
      )}
    </dialog>
  );
};

Modal.displayName = 'Modal';
