import React, { FC } from 'react';
import classNames from 'classnames';

import { ComponentProps } from '@components';
import { grid } from '@componentsStyles';

export interface RowProps extends ComponentProps {
  cols?: 10 | 12;
}

export const Row: FC<RowProps> = (props) => {
  const { className, cols = 12, children, id, style } = props;

  const rowClass = cols === 10 ? grid['row-10'] : grid.row;

  return (
    <div id={id} className={classNames(className, rowClass)} style={style}>
      {children}
    </div>
  );
};

Row.displayName = 'Row';
