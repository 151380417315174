import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const VisualTourChevronLeftIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 10 8" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.80441 0.195262C5.06476 0.455612 5.06476 0.877722 4.80441 1.13807L1.94248 4L4.80441 6.86193C5.06476 7.12228 5.06476 7.54439 4.80441 7.80474C4.54406 8.06509 4.12195 8.06509 3.8616 7.80474L0.52827 4.4714C0.26792 4.21106 0.26792 3.78894 0.52827 3.5286L3.8616 0.195262C4.12195 -0.0650874 4.54406 -0.0650874 4.80441 0.195262ZM9.47108 0.195262C9.73143 0.455612 9.73143 0.877722 9.47108 1.13807L6.60915 4L9.47108 6.86193C9.73143 7.12228 9.73143 7.54439 9.47108 7.80474C9.21073 8.06509 8.78862 8.06509 8.52827 7.80474L5.19494 4.4714C4.93459 4.21106 4.93459 3.78894 5.19494 3.5286L8.52827 0.195262C8.78862 -0.0650874 9.21073 -0.0650874 9.47108 0.195262Z"
      fill={props.color}
    />
  </SvgIcon>
);

VisualTourChevronLeftIcon.displayName = 'VisualTourChevronLeft';
