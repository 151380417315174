import React, { FC } from 'react';
import {
  activeStyle,
  ComponentProps,
  Icon,
  NavItem,
  Typography
} from '@components';
import styles from './HeaderNavLink.module.scss';
import classNames from 'classnames';
import { NavLinkProps } from 'react-router-dom';

export interface HeaderNavLinkProps extends ComponentProps {
  NavLink: React.ForwardRefExoticComponent<
    NavLinkProps & React.RefAttributes<HTMLAnchorElement>
  >;
  navItem: NavItem;
  onNavItemClick: () => void;
}

export const HeaderNavLink: FC<HeaderNavLinkProps> = (props) => {
  const { id, style, className, NavLink, navItem, onNavItemClick } = props;

  return (
    <div
      id={id}
      className={classNames(
        navItem?.alwaysActiveMode?.enabled
          ? styles['wrapper--active']
          : styles.wrapper,
        className
      )}
      style={style}
    >
      <NavLink
        id={id}
        onClick={onNavItemClick}
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className={styles.link}
        to={navItem.path}
      >
        <Typography variant={'body-5'}>{navItem.name}</Typography>
      </NavLink>
      {navItem?.alwaysActiveMode?.enabled &&
        navItem?.alwaysActiveMode?.icon && (
          <Icon
            onClick={navItem?.alwaysActiveMode?.buttonAction}
            className={styles.icon}
            name={navItem?.alwaysActiveMode.icon}
            size={'xs'}
          />
        )}
    </div>
  );
};

HeaderNavLink.displayName = 'HeaderNavLink';
