import React, { FC, useState, useEffect, DragEvent } from 'react';
import {
  ComponentProps,
  Typography,
  formatBytes,
  saveFileToUser
} from '@components';
import styles from './Uploader.module.scss';
import { colors, offsets } from '@componentsStyles';
import { FileInfo, SavedFileName } from './dataTypes';
import { UploadedFile } from './components/UploadedFile/UploadedFile';
import { SavedFile } from '@components/Uploader/components/SavedFile';

export interface UploaderProps extends ComponentProps {
  /* Already uploaded files on backend to show */
  savedFileNames?: SavedFileName[];
  /* Function to delete file from backend */
  onDeleteBackendFile?: (backendFile: SavedFileName) => void;
  /* Function to save file from backend to user PC */
  onSaveBackendFile?: (backendFile: SavedFileName) => Promise<Blob>;
  /* New uploaded files */
  files?: File[];
  /** We upload only correct files */
  upload?: (files: File[]) => void;
  /** Identifier to remove error files from screen */
  reset?: boolean;
  /** Function to reset identifier */
  setReset?: (value: boolean) => void;
  /** Identifier to remove files from ui */
  clearFiles?: boolean;
  /** Function for clearFiles identifier */
  setClearFiles?: (value: boolean) => void;
  /** Max size in bytes */
  maxSize?: number;
  accept?: string;
  maximumUploadFileSizeText?: string;
  dragFileHereOrText?: string;
  disabled?: boolean;
  browseText?: string;
  loadingFailedText?: string;
}

/** 20 MB */
const DEFAULT_MAX_SIZE = 20971520;

export const Uploader: FC<UploaderProps> = (props) => {
  const {
    id,
    style,
    dataTestId,
    className,
    savedFileNames,
    onDeleteBackendFile,
    onSaveBackendFile,
    files,
    disabled,
    accept,
    dragFileHereOrText = 'Drag file here or',
    browseText = 'browse',
    loadingFailedText = 'loading failed',
    maximumUploadFileSizeText = 'maximum upload file size',
    maxSize = DEFAULT_MAX_SIZE,
    upload,
    reset,
    setReset,
    clearFiles,
    setClearFiles
  } = props;

  const [filesData, setFilesData] = useState<FileInfo[]>(
    files?.length > 0 ? mapFiles(files) : []
  );

  function isFileSizeValid(file: File) {
    return file.size <= maxSize;
  }

  const defaultAccept = 'file_extension|audio/*|video/*|image/*|media_type';

  function mapFiles(files: File[], isNew?: boolean): FileInfo[] {
    const now = new Date();
    return files?.map((file) => {
      return {
        file: file,
        status: isFileSizeValid(file) ? 'success' : 'error',
        message: isFileSizeValid(file) ? null : 'error',
        uploadingTime: isNew ? now : null
      };
    });
  }

  function handleFile(fileList: FileList) {
    setFilesData([...filesData, ...mapFiles([...fileList], true)]);
  }

  useEffect(() => {
    if (upload) {
      const correctFiles = filesData
        ?.filter((file) => file.status === 'success')
        ?.map((file) => file.file);

      upload(correctFiles);
    }
  }, [filesData]);

  useEffect(() => {
    if (reset === true) {
      const correctFiles = filesData?.filter(
        (file) => file.status === 'success'
      );
      setFilesData(correctFiles);
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (clearFiles === true) {
      setFilesData([]);
      setClearFiles(false);
    }
  }, [clearFiles]);

  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef(null);

  const handleDrag = function (e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
    e.target.value = null;
  };

  const deleteFile = (file: FileInfo, event: any) => {
    event.stopPropagation();
    const newFiles = filesData?.filter((el) => el.file !== file.file);
    setFilesData([...newFiles]);
  };

  const deleteBackendFile = (backendFile: SavedFileName, event: any) => {
    onDeleteBackendFile(backendFile);
    event.stopPropagation();
  };

  const formClasses = () => {
    let classes = styles.form;

    if (dragActive) {
      classes = styles['form--draggable'];
    }

    if (disabled) {
      return styles['form--disabled'];
    }
    return classes;
  };

  return (
    <div id={id} className={className} style={style} data-testid={dataTestId}>
      <form
        id="form-file-upload"
        className={formClasses()}
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          className={styles['input-file-upload']}
          multiple={true}
          onChange={handleChange}
          disabled={disabled}
          data-testid="input-file"
          accept={accept ? accept : defaultAccept}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={styles['label-file-upload']}
        >
          <Typography
            element="div"
            variant="system-heading"
            className={styles['drag-text']}
          >
            {dragFileHereOrText}{' '}
            <span className={styles['browse-text']}>{browseText}</span>
          </Typography>

          <Typography
            element="div"
            className={offsets['mt-10']}
            variant="system-heading"
            color={disabled ? colors.gray60Color : colors.gray40Color}
          >
            {maximumUploadFileSizeText}: {formatBytes(maxSize)}
          </Typography>
        </label>
        {!disabled && dragActive && (
          <div
            className={styles['drag-file']}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>

      {(savedFileNames?.length > 0 || filesData?.length > 0) && (
        <div className={styles.files}>
          {savedFileNames?.map((fileName: SavedFileName) => {
            return (
              <SavedFile
                key={fileName.name}
                fileName={fileName}
                deleteFile={deleteBackendFile}
                disabled={disabled}
                onSaveBackendFile={onSaveBackendFile}
              />
            );
          })}

          {filesData?.map((fileInfo, key) => {
            return (
              <UploadedFile
                key={key}
                fileInfo={fileInfo}
                deleteFile={deleteFile}
                disabled={disabled}
                loadingFailedText={loadingFailedText}
                onClick={saveFileToUser}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

Uploader.displayName = 'Uploader';
