import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const LogoModelTreeIconDisabled: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 98 16" width={98} height={16}>
    <path d="M10.5263 0L0 10V16L13.6842 3L10.5263 0Z" fill="#8F8F8F" />
    <path d="M13.6838 3L6.31543 10V16L16.8417 6L13.6838 3Z" fill="#B3B3B3" />
    <path d="M12.6318 10L16.8424 6L20.0002 9L12.6318 16V10Z" fill="#DADADA" />
    <path
      d="M27 13V3.03203H28.12L30.408 7.81604L32.728 3.01604H33.816V13H32.584V5.67203L30.632 9.56003H30.04L28.216 5.70403V13H27Z"
      fill="#121212"
    />
    <path
      d="M38.408 13.176C37.6293 13.176 36.968 12.9787 36.424 12.584C35.8907 12.1894 35.48 11.608 35.192 10.84C34.9147 10.072 34.776 9.14403 34.776 8.05604C34.776 6.98937 34.9147 6.07737 35.192 5.32003C35.48 4.55203 35.8907 3.96537 36.424 3.56003C36.968 3.14404 37.6293 2.93604 38.408 2.93604C39.1867 2.93604 39.8427 3.14404 40.376 3.56003C40.9093 3.96537 41.3147 4.55203 41.592 5.32003C41.88 6.07737 42.024 6.98937 42.024 8.05604C42.024 9.14403 41.88 10.072 41.592 10.84C41.3147 11.608 40.9093 12.1894 40.376 12.584C39.8427 12.9787 39.1867 13.176 38.408 13.176ZM38.408 12.04C38.8773 12.04 39.2773 11.8907 39.608 11.592C39.9493 11.2827 40.2053 10.8347 40.376 10.248C40.5573 9.6507 40.648 8.92003 40.648 8.05604C40.648 7.2347 40.5573 6.5307 40.376 5.94404C40.2053 5.3467 39.9493 4.88804 39.608 4.56804C39.2773 4.24803 38.8773 4.08803 38.408 4.08803C37.9387 4.08803 37.5333 4.24803 37.192 4.56804C36.8507 4.88804 36.5893 5.3467 36.408 5.94404C36.2373 6.5307 36.152 7.2347 36.152 8.05604C36.152 8.92003 36.2373 9.6507 36.408 10.248C36.5893 10.8347 36.8507 11.2827 37.192 11.592C37.5333 11.8907 37.9387 12.04 38.408 12.04Z"
      fill="#121212"
    />
    <path
      d="M43.192 13.016V3.03203H45.592C46.1787 3.03203 46.6693 3.08537 47.064 3.19203C47.4587 3.2987 47.7947 3.45337 48.072 3.65603C48.3493 3.84803 48.6 4.09337 48.824 4.39203C49.048 4.6907 49.2293 5.03203 49.368 5.41603C49.5173 5.78937 49.6293 6.1947 49.704 6.63203C49.7787 7.06937 49.816 7.54937 49.816 8.07203C49.7947 8.84003 49.6933 9.52803 49.512 10.136C49.3413 10.744 49.08 11.2614 48.728 11.688C48.3867 12.1147 47.944 12.4454 47.4 12.68C46.856 12.904 46.1893 13.016 45.4 13.016H43.192ZM44.488 11.96H45.352C46.0987 11.96 46.7013 11.8107 47.16 11.512C47.6187 11.2027 47.9547 10.76 48.168 10.184C48.392 9.60804 48.4987 8.92004 48.488 8.12004C48.488 7.2987 48.3867 6.58937 48.184 5.99203C47.992 5.3947 47.6827 4.93603 47.256 4.61603C46.8293 4.29603 46.2533 4.13603 45.528 4.13603H44.488V11.96Z"
      fill="#121212"
    />
    <path
      d="M51.288 13V3.01604H57.528V4.16803H52.584V7.20804H56.664V8.39204H52.584V11.848H57.48V13H51.288Z"
      fill="#121212"
    />
    <path
      d="M59.4 3.03203H60.776H60.936V3.16004C60.872 3.22403 60.8293 3.29337 60.808 3.36803C60.7973 3.4427 60.792 3.5707 60.792 3.75204V11.864H65.512V13H59.4V3.03203Z"
      fill="#121212"
    />
    <path
      d="M69.624 13V4.20004H66.84V3.01604H73.864V4.20004H70.968V13H69.624Z"
      fill="#121212"
    />
    <path
      d="M75.224 13V3.03203H78.248C79.048 3.03203 79.6933 3.14937 80.184 3.38404C80.6853 3.6187 81.048 3.9547 81.272 4.39203C81.5067 4.8187 81.624 5.32537 81.624 5.91203C81.624 6.2747 81.544 6.6427 81.384 7.01604C81.224 7.38937 80.9947 7.7147 80.696 7.99203C80.3973 8.26937 80.0453 8.47204 79.64 8.60004L81.896 13H80.424L78.28 8.68004H76.6V13H75.224ZM76.6 7.52804H78.376C79.016 7.52804 79.4853 7.38404 79.784 7.09604C80.0827 6.79737 80.232 6.40804 80.232 5.92803C80.232 5.6187 80.168 5.34137 80.04 5.09604C79.912 4.84004 79.7093 4.63737 79.432 4.48804C79.1547 4.3387 78.8027 4.26404 78.376 4.26404H76.6V7.52804Z"
      fill="#121212"
    />
    <path
      d="M83.288 13V3.01604H89.528V4.16803H84.584V7.20804H88.664V8.39204H84.584V11.848H89.48V13H83.288Z"
      fill="#121212"
    />
    <path
      d="M91.288 13V3.01604H97.528V4.16803H92.584V7.20804H96.664V8.39204H92.584V11.848H97.48V13H91.288Z"
      fill="#121212"
    />
  </SvgIcon>
);

LogoModelTreeIconDisabled.displayName = 'LogoModelTreeIconDisabled';
