import * as React from 'react';
import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { NotFound, InternalServerError, Forbidden, Releases } from '@pages';
import { Layout } from './layouts/Layout';

export const App: FC = () => {
  return (
    // todo check 404
    <Routes>
      <Route path="/*" element={<Layout />}>
        <Route path="" element={<Releases />} />
        <Route path="version/:releaseVersion" element={<Releases />} />
      </Route>

      <Route path="*" element={<NotFound />} />
      <Route path="internal-error" element={<InternalServerError />} />
      <Route path="forbidden" element={<Forbidden />} />
    </Routes>
  );
};
