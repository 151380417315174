import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const ChevronLeftIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7071 6.29289C16.0976 6.68342 16.0976 7.31658 15.7071 7.70711L11.4142 12L15.7071 16.2929C16.0976 16.6834 16.0976 17.3166 15.7071 17.7071C15.3166 18.0976 14.6834 18.0976 14.2929 17.7071L9.29289 12.7071C8.90237 12.3166 8.90237 11.6834 9.29289 11.2929L14.2929 6.29289C14.6834 5.90237 15.3166 5.90237 15.7071 6.29289Z"
      fill={props.color}
    />
  </SvgIcon>
);

ChevronLeftIcon.displayName = 'ChevronLeftIcon';
