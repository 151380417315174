export declare type InputType =
  | 'email'
  | 'password'
  | 'number'
  | 'search'
  | 'tel'
  | 'text';

export const InputTypes = Object.freeze({
  email: 'email' as InputType,
  password: 'password' as InputType,
  number: 'number' as InputType,
  search: 'search' as InputType,
  tel: 'tel' as InputType,
  text: 'text' as InputType
});
