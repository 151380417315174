import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const LogoExquanceIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 247 40" width={247} height={40}>
    <path
      d="M50.0002 30L43.3335 20L36.6668 10L43.3335 0H30.0002L23.3335 10L30.0002 20L36.6668 30L43.3335 40H56.6668L50.0002 30Z"
      fill="#F7941D"
    />
    <path
      d="M13.3333 0H0L6.66667 10L13.3333 20L6.66667 30H20L26.6667 20L20 10L13.3333 0Z"
      fill="#F7941D"
    />
    <path d="M0 0L6.66667 10L13.3333 0H0Z" fill="#FBB040" />
    <path d="M36.6668 30L43.3335 20L50.0002 30H36.6668Z" fill="#F26522" />
    <path d="M30.0002 0L36.6668 10L43.3335 0H30.0002Z" fill="#EE2A7B" />
    <path d="M23.3335 10L30.0002 0L36.6668 10H23.3335Z" fill="#DA1C5C" />
    <path d="M23.3335 10L30.0002 20L36.6668 10H23.3335Z" fill="#BE1E2D" />
    <path d="M13.3333 20L20 10L26.6667 20H13.3333Z" fill="#F15A29" />
    <path d="M13.3333 20L20 30L26.6667 20H13.3333Z" fill="#EF4136" />
    <path d="M6.66667 30L13.3333 20L20 30H6.66667Z" fill="#BE1E2D" />
    <path d="M36.6668 30L43.3335 40L50.0002 30H36.6668Z" fill="#F7941D" />
    <path d="M43.3335 40L50.0002 30L56.6668 40H43.3335Z" fill="#FBB040" />
    <path d="M6.66667 10L13.3333 20L20 10H6.66667Z" fill="#F26522" />
    <path d="M6.66667 10L13.3333 0L20 10H6.66667Z" fill="#F7941D" />
    <path d="M30.0002 20L36.6668 30L43.3335 20H30.0002Z" fill="#F15A29" />
    <path d="M30.0002 20L36.6668 10L43.3335 20H30.0002Z" fill="#EF4136" />
    <path
      d="M95.3333 18.3333L88 28.1667H84.5L93.3333 16.3333L84.8333 5H88.3333L95.1667 14.3333L102.167 5H105.667L97.1667 16.3333L106.167 28.1667H102.667L95.3333 18.3333Z"
      fill="black"
    />
    <path
      d="M119 4.16675C112.167 4.16675 106.667 9.66675 106.667 16.5001C106.667 23.3334 112.167 28.8334 119 28.8334C120.5 28.8334 121.833 28.5001 123.167 28.0001L121 25.6667C120.333 25.8334 119.667 25.8334 119 25.8334C113.833 25.8334 109.667 21.6667 109.667 16.3334C109.667 11.0001 113.833 7.00008 119 7.00008C124.167 7.00008 128.333 11.3334 128.333 16.5001C128.333 20.0001 126.333 23.0001 123.5 24.6667L125.5 26.8334C128.833 24.6667 131.167 20.8334 131.167 16.5001C131.333 9.83341 125.833 4.16675 119 4.16675Z"
      fill="black"
    />
    <path
      d="M66.6665 5H81.8332V7.5H69.4998V15V17.5V25.5H81.8332V28H66.6665V5Z"
      fill="black"
    />
    <path
      d="M135 5H137.833V19.1667C137.833 23.3333 140.166 26 144.333 26C148.666 26 150.833 23.3333 150.833 19.1667V5H153.666V19.1667C153.666 25 150.5 28.6667 144.166 28.6667C137.833 28.6667 134.667 25 134.667 19.3333V5H135Z"
      fill="black"
    />
    <path
      d="M184.166 9.5V28.1667H181.333V5H184.333L197.666 23.3333V5H200.5V28.1667H197.666L184.166 9.5Z"
      fill="black"
    />
    <path
      d="M205 16.6667C205 9.50008 210.167 4.66675 216.833 4.66675C221 4.66675 223.833 6.66675 225.667 9.33341L223.333 10.5001C222 8.50008 219.667 7.16675 216.833 7.16675C211.833 7.16675 208 11.0001 208 16.5001C208 22.0001 211.833 25.8334 216.833 25.8334C219.5 25.8334 222 24.5001 223.333 22.5001L225.833 23.8334C223.833 26.5001 221.167 28.5001 216.833 28.5001C210.167 28.5001 205 23.6667 205 16.6667Z"
      fill="black"
    />
    <path
      d="M169 5H165.5L156.167 28.1667H159.333L162.333 20.5L167.333 7.5L172.333 20.5H165L164 23H173.167L175.167 28.1667H178.333L169 5Z"
      fill="black"
    />
    <path
      d="M78.1665 15.1667H71.9998L70.1665 17.6667H78.1665V15.1667Z"
      fill="black"
    />
    <path
      d="M230 5H245.167V7.5H232.833V15V17.5V25.5H245.167V28H230V5Z"
      fill="black"
    />
    <path
      d="M241.5 15.1667H235.333L233.5 17.6667H241.5V15.1667Z"
      fill="black"
    />
    <path
      d="M120.167 21.3333L127.5 29.1667L129.667 27.5L122.167 19.5L120.167 21.3333Z"
      fill="black"
    />
  </SvgIcon>
);

LogoExquanceIcon.displayName = 'LogoExquanceIcon';
