import { Loader, Typography, cellStyles } from '@components';
import { TableProps } from '@components/Table/Table.interface';
import useActions from '@components/Table/hooks/useActions';
import {
  getActionColumn,
  getSkeletonRows
} from '@components/Table/utils/tableHelpers';
import { noop } from '@componentsUtils';
import { DASH } from '@constants';
import classNames from 'classnames';
import React, { FC, Fragment, useEffect, useMemo } from 'react';
import styles from './Table.module.scss';
import { TableHeader } from './components/TableHeader';
import { TableRowMenu } from './components/TableRowMenu';
import { TableColumn, TableRow } from './dataTypes';

export const Table: FC<TableProps> = (props) => {
  const {
    className,
    id,
    style,
    dataTestId,
    rows,
    columns,
    actions,
    minHeight = '400px',
    maxHeight,
    minWidth,
    isLoading,
    onLoading,
    noMoreData,
    noMoreItemsText = 'No more items',
    filterActionsForRow,
    sortBy,
    sortOrder,
    showHeader = true,
    onSort,
    tableDataType,
    onTableDataType,
    onRowClick,
    selectedColumnKey,
    noResults,
    noResultsText = 'No results',
    selectedRowKey,
    valign = 'top'
  } = props;

  const {
    tableScrollTop,
    isLoadingMore,
    onScroll,
    ref,
    tableWidth,
    updateWidth,
    isSelectedRowHovered,
    setIsSelectedRowHovered,
    handleRowClick
  } = useActions({
    onLoading,
    noMoreData,
    onRowClick
  });

  const actionColumn: TableColumn = useMemo(() => getActionColumn(), []);
  const skeletonRows = useMemo(
    () => getSkeletonRows(columns, actions, actionColumn),
    [columns, actions, actionColumn]
  );

  const colsNumber = actions ? columns.length + 1 : columns.length;

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const isScrollable = useMemo(() => {
    return minWidth > tableWidth;
  }, [minWidth, tableWidth]);

  return (
    <div
      onScroll={onScroll}
      id="ui-table"
      ref={ref}
      data-testid={dataTestId}
      style={{ ...style, minHeight, maxHeight }}
      className={classNames(className, styles.table)}
    >
      <table style={{ minWidth: minWidth }} width="100%" id={id}>
        {showHeader && (
          <TableHeader
            onSort={onSort}
            sortBy={sortBy}
            sortOrder={sortOrder}
            columns={columns}
            actionColumn={actions ? actionColumn : null}
            onTableDataType={onTableDataType}
            isScrollable={isScrollable}
            selectedColumnKey={selectedColumnKey}
            tableDataType={tableDataType}
          />
        )}
        <tbody>
          {!isLoading &&
            rows?.map((row: TableRow) => {
              const isCurrentRowSelected = row.id === selectedRowKey;
              return (
                <tr
                  id={`row-${row.id}`}
                  className={classNames(styles.row, {
                    [styles['row-selected']]: isCurrentRowSelected,
                    [styles['row-clickable']]: onRowClick
                  })}
                  key={row.id}
                  onClick={() => {
                    handleRowClick(isCurrentRowSelected, row);
                  }}
                  onMouseEnter={() => {
                    isCurrentRowSelected ? setIsSelectedRowHovered(true) : noop;
                  }}
                  onMouseLeave={() => {
                    isCurrentRowSelected
                      ? setIsSelectedRowHovered(false)
                      : noop;
                  }}
                >
                  {columns?.map((column, index) => {
                    const firstSelectedColumn =
                      index === 0 && isCurrentRowSelected;
                    const selectedCell =
                      isCurrentRowSelected && column.key === selectedColumnKey;
                    const selectedRowCells =
                      !selectedCell && isCurrentRowSelected;

                    return (
                      <td
                        onClick={
                          column?.onClick ? () => column?.onClick(row) : noop
                        }
                        className={classNames({
                          [styles.firstSelectedColumn]: firstSelectedColumn,
                          [styles['firstSelectedColumn-hovered']]:
                            isSelectedRowHovered && firstSelectedColumn,
                          [styles.selectedCell]: selectedCell,
                          [styles.selectedRowCells]: selectedRowCells
                        })}
                        valign={valign}
                        style={cellStyles({
                          column,
                          isScrollable,
                          isCurrentRowSelected
                        })}
                        key={column.key}
                      >
                        {row?.data[column?.key] || DASH}
                      </td>
                    );
                  })}

                  {actions && (
                    <TableRowMenu
                      className={styles['row-menu']}
                      actions={
                        filterActionsForRow
                          ? filterActionsForRow(actions, row)
                          : actions
                      }
                      row={row}
                      tableScrollTop={tableScrollTop}
                      actionColumn={actionColumn}
                      isScrollable={isScrollable}
                    />
                  )}
                  {!actions && onTableDataType && <td></td>}
                </tr>
              );
            })}

          {isLoading && <Fragment>{skeletonRows}</Fragment>}
          {isLoadingMore && (
            <tr key="loading">
              <td
                width="100%"
                align="center"
                colSpan={colsNumber}
                className={styles.loaderContainer}
              >
                <Loader color="orange" size="m" />
              </td>
            </tr>
          )}

          {!noResults && !isLoading && noMoreData && (
            <tr key="no-data">
              <td
                width="100%"
                align="center"
                colSpan={colsNumber}
                className={styles.noMoreItemsContainer}
              >
                <Typography variant="body-4">{noMoreItemsText}</Typography>
              </td>
            </tr>
          )}

          {!isLoading && noResults && (
            <tr key="no-results">
              <td
                width="100%"
                align="center"
                colSpan={colsNumber}
                className={styles.noMoreItemsContainer}
              >
                <Typography variant="body-4">{noResultsText}</Typography>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

Table.displayName = 'Table';
export { TableProps };
