import React from 'react';
import styles from '../TableChart.module.scss';

interface TickProps {
  x?: number;
  y?: number;
  payload?: {
    value: string;
  };
}

const CustomTickY = React.memo(({ x, y, payload }: TickProps) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-6} y={-12} dy={16} textAnchor="end" className={styles.tick}>
        {payload?.value}
      </text>
    </g>
  );
});

export default CustomTickY;
