import React, { FC, useMemo } from 'react';
import {
  ComponentProps,
  Typography,
  Card,
  Icon,
  SkeletonPlaceholder,
  DatabaseVersionTranslations,
  Tooltip
} from '@components';
import cn from 'classnames';
import styles from './DatabaseVersion.module.scss';
import { colors, offsets } from '@componentsStyles';
import { formatDate } from '@componentsUtils';
import { DASH } from '@constants';

export interface DatabaseVersionProps extends ComponentProps {
  isLocal?: boolean;
  version?: string;
  lastUpdate?: {
    date: Date;
    version: string;
  };
  translations?: DatabaseVersionTranslations;
  error?: string;
  isLoading?: boolean;
  isSeeDetails?: boolean;
  onSeeDetails?: () => void;
}

const defaultTranslation: DatabaseVersionTranslations = {
  seeDetails: 'See details',
  mtDatabaseVersion: 'MT database version',
  type: 'Type',
  local: 'Local',
  shared: 'Shared',
  version: 'version',
  errorDetails: 'Error details',
  testConnectionFailed: 'Test connection failed',
  online: 'Online',
  lastUpdate: 'Last update',
  from: 'from'
};

export const DatabaseVersion: FC<DatabaseVersionProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    translations = defaultTranslation,
    isLocal,
    version,
    error,
    lastUpdate,
    isLoading,
    isSeeDetails,
    onSeeDetails
  } = props;

  const type = useMemo(() => {
    if (isLocal) {
      return translations.local;
    }
    return translations.shared;
  }, [isLocal, translations.shared, translations.local]);

  return (
    <Card
      className={cn(
        className,
        isLoading ? styles['card--loading'] : styles.card
      )}
      style={style}
      id={id}
      data-testid={dataTestId}
    >
      <div className={styles.header}>
        <Typography className={offsets['mr-8']} variant="h5">
          {translations.mtDatabaseVersion}
        </Typography>

        {isSeeDetails && (
          <div className={styles.details}>
            <Tooltip content={translations.seeDetails}>
              <Icon
                size="s"
                onClick={onSeeDetails && onSeeDetails}
                name="chevrons-right"
                dataTestId="details-icon"
              />
            </Tooltip>
          </div>
        )}
      </div>

      <div className={styles['scroll-container']}>
        <div className={styles.row}>
          <div className={offsets['mr-60']}>
            <Typography
              className={offsets['mb-4']}
              variant="system-heading"
              color={colors.gray40Color}
              element="div"
            >
              {translations.type}
            </Typography>

            {isLoading ? (
              <SkeletonPlaceholder width={97} height={40} />
            ) : (
              <Typography variant={'h1'} element="div">
                {type || DASH}
              </Typography>
            )}
          </div>

          <div>
            <Typography
              className={offsets['mb-4']}
              variant="system-heading"
              color={colors.gray40Color}
              element="div"
            >
              {translations.version}
            </Typography>

            {isLoading ? (
              <SkeletonPlaceholder width={194} height={40} />
            ) : (
              <Typography variant={'h1'} element="div">
                {isLocal ? DASH : version || DASH}
              </Typography>
            )}
          </div>
        </div>

        {!isLocal && lastUpdate?.date && !isLoading && (
          <div className={offsets['mt-16']}>
            <Typography
              className={offsets['mb-4']}
              variant={'body-2'}
              element={'div'}
            >
              {translations.lastUpdate}
            </Typography>
            <Typography variant={'body-1'} element={'div'}>
              {`${formatDate(lastUpdate?.date)}`}
              {lastUpdate?.version &&
                ` ${translations.from} ${translations.version} ${lastUpdate?.version}`}
            </Typography>
          </div>
        )}

        {!isLocal && (
          <>
            {isLoading ? (
              <SkeletonPlaceholder
                className={offsets['mt-16']}
                width={237}
                height={20}
              />
            ) : (
              <>
                <div className={cn(offsets['mt-16'], styles.row)}>
                  <Icon
                    name={error ? 'error' : 'success'}
                    color={error ? 'error' : 'success'}
                    size="s"
                    className={offsets['mr-8']}
                  />
                  <Typography variant="body-1" element="div">
                    {error
                      ? translations.testConnectionFailed
                      : translations.online}
                  </Typography>
                </div>

                {error && (
                  <div>
                    <Typography
                      variant="body-2"
                      element="div"
                      className={offsets['mt-16']}
                    >
                      {translations.errorDetails}
                    </Typography>
                    <div
                      className={offsets['mt-16']}
                      dangerouslySetInnerHTML={{ __html: error }}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Card>
  );
};

DatabaseVersion.displayName = 'DatabaseVersion';
