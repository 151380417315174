import { colors } from '@componentsStyles';

export const MAIN_CHART_STYLE_CONFIG = {
  fill: false,
  borderWidth: 2,
  //point style
  backgroundColor: colors.whiteColor,
  pointRadius: 3,
  pointBorderWidth: 2,
  hoverRadius: 4,
  hoverBorderWidth: 2,
  hoverBorderColor: colors.whiteColor
};

export const Y_AXIS_STYLE_CONFIG = {
  fill: false,
  borderColor: 'transparent',
  borderWidth: 0,
  //point style
  backgroundColor: 'transparent',
  pointRadius: 0,
  pointBorderWidth: 0,
  hoverRadius: 0,
  hoverBorderWidth: 0,
  hoverBackgroundColor: 'transparent',
  hoverBorderColor: 'transparent'
};

export const DISTANCE_BETWEEN_POINTS = 36;
export const MAX_CHART_VISIBLE_WIDTH = 1320;

export const SKELETON_MIN_WIDTH = 30;
export const SKELETON_MIN_HEIGHT = 12;
