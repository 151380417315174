import React, { FC, Fragment, useEffect, useState } from 'react';
import {
  Checkbox,
  ComponentProps,
  Icon,
  Radio,
  Toggle,
  Typography
} from '@components';
import styles from './FilterDropdown.module.scss';
import {
  CheckboxTableFilter,
  RadioTableFilter,
  TableFilter,
  TableFilterGroup,
  ToggleTableFilter
} from '@components/Filter/dataTypes';
import cn from 'classnames';

export interface FilterDropdownProps extends ComponentProps {
  filters: TableFilter[];
  setFilters: (filters: TableFilter[]) => void;
  filterGroups: TableFilterGroup[];
}

export const FilterDropdown: FC<FilterDropdownProps> = (props) => {
  const {
    className,
    id,
    style,
    dataTestId,
    filters,
    setFilters,
    filterGroups
  } = props;

  const [openedFilterId, setOpenedFilterId] = useState<string>(null);

  useEffect(() => {
    if (filterGroups?.length === 1) {
      setOpenedFilterId(filterGroups[0].id);
    }
  }, [filterGroups]);

  const toggleOpenedFilter = (filterId: string) => {
    if (filterId === openedFilterId) {
      setOpenedFilterId(null);
    } else {
      setOpenedFilterId(filterId);
    }
  };

  const onChangeRadio = (filterId: string, value: string) => {
    const newFilters: TableFilter[] = filters?.map((filter) => {
      if (filter.id === filterId) {
        return { ...filter, value: value } as RadioTableFilter;
      }
      return filter;
    });
    setFilters(newFilters);
  };

  const onChangeToggle = (filterId: string, isSelected: boolean) => {
    const newFilters: TableFilter[] = filters?.map((filter) => {
      if (filter.id === filterId) {
        return { ...filter, value: isSelected } as ToggleTableFilter;
      }
      return filter;
    });
    setFilters(newFilters);
  };

  const onChangeCheckbox = (filterId: string, isSelected: boolean) => {
    const newFilters: TableFilter[] = filters?.map((filter) => {
      if (filter.id === filterId) {
        return { ...filter, value: isSelected } as CheckboxTableFilter;
      }
      return filter;
    });
    setFilters(newFilters);
  };

  return (
    <div
      id={id}
      style={style}
      className={cn(styles.dropdown, className)}
      data-testid={dataTestId}
    >
      {filterGroups?.map((filterGroup, key) => {
        return (
          <div key={key}>
            <div
              className={styles['dropdown-item']}
              data-testid={'toggle'}
              onClick={() => toggleOpenedFilter(filterGroup.id)}
            >
              <div className={styles['dropdown-item-title']}>
                {filterGroup.groupTitle}
              </div>

              {filterGroups?.length > 1 && (
                <Icon
                  size={'s'}
                  name={
                    openedFilterId === filterGroup.id
                      ? 'chevron-down'
                      : 'chevron-right'
                  }
                />
              )}
            </div>

            {/*todo find max width of the items*/}
            <div
              className={
                openedFilterId === filterGroup.id
                  ? styles['block-visible']
                  : styles['block-hidden']
              }
            >
              <div data-testid={'filters'} className={styles.filters}>
                {filters
                  ?.filter(
                    (el) =>
                      el.groupId === filterGroup.id &&
                      el.groupId === openedFilterId
                  )
                  ?.map((filter, key) => {
                    return (
                      <div key={key} data-testid={'filter'}>
                        {filter.type === 'radio' && (
                          <Radio
                            className={styles.filter}
                            values={filter?.items}
                            align="vertical"
                            selected={filter?.value}
                            onChange={(value) =>
                              onChangeRadio(filter?.id, value)
                            }
                          />
                        )}

                        {filter.type === 'toggle' && (
                          <Fragment>
                            <div className={styles.toggle}>
                              <Toggle
                                className={styles.filter}
                                onChange={(isSelected) =>
                                  onChangeToggle(filter.id, isSelected)
                                }
                                label={filter.label}
                                checked={Boolean(filter.value)}
                              />
                              <Typography variant={'body-1'}>
                                {filter.label}
                              </Typography>
                            </div>
                          </Fragment>
                        )}

                        {filter.type === 'checkbox' && (
                          <Fragment>
                            <div>
                              <Checkbox
                                className={styles.filter}
                                onChange={(isSelected) =>
                                  onChangeCheckbox(filter.id, isSelected)
                                }
                                label={filter.label}
                                checked={Boolean(filter.value)}
                              />
                            </div>
                          </Fragment>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

FilterDropdown.displayName = 'FilterDropdown';
