import { FileInfo } from '@components';

export function formatBytes(bytes: number, decimals = 1) {
  if (bytes === 0) return '0 Bytes';

  const step = 1024;
  const decimal = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const sizeIndex = Math.floor(Math.log(bytes) / Math.log(step));

  return (
    parseFloat((bytes / Math.pow(step, sizeIndex)).toFixed(decimal)) +
    sizes[sizeIndex]
  );
}

export const saveFileToUser = (file: FileInfo) => {
  const url = URL.createObjectURL(file.file);
  const link = document.createElement('a');
  link.href = url;
  link.download = file.file.name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
