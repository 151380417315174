import React, { FC, useMemo } from 'react';
import { ComponentProps, CountryIso3 } from '@components';
import styles from './Flag.module.scss';
import cn from 'classnames';
import { getCountryISO2 } from './iso3toiso2';

export interface FlagProps extends ComponentProps {
  country: CountryIso3;
  width?: number;
  height?: number;
  onClick?(): void;
}

export const Flag: FC<FlagProps> = (props) => {
  const {
    country = 'FIN',
    className,
    id,
    style,
    dataTestId,
    onClick,
    width = 35,
    height = 25
  } = props;

  const flagStyles = useMemo(() => {
    const iso2 = getCountryISO2(
      country?.toUpperCase() as CountryIso3
    )?.toLowerCase();
    if (iso2) {
      // @ts-ignore
      return styles[`fi-${iso2}`];
    }
  }, [country]);

  return (
    <div
      style={{ ...style, width: `${width}px`, height: `${height}px` }}
      id={id}
      onClick={onClick}
      className={cn(className, styles.fi, flagStyles)}
      data-testid={dataTestId}
    ></div>
  );
};

Flag.displayName = 'Flag';
