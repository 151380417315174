import React, { ElementType, FC } from 'react';
import classNames from 'classnames';

import { ComponentProps } from '@components';
import styles from './Typography.module.scss';

import { TypographyVariant } from './dataTypes';

export interface TypographyProps extends ComponentProps {
  variant: TypographyVariant;
  color?: string;
  element?: ElementType;
}

const typographyElements: Map<TypographyVariant, ElementType> = new Map([
  ['h0', 'span'],
  ['h1', 'h1'],
  ['h2', 'h2'],
  ['h3', 'h3'],
  ['h4', 'h4'],
  ['h5', 'h5'],
  ['label-1', 'div'],
  ['label-2', 'span'],
  ['body-1', 'span'],
  ['body-2', 'span'],
  ['body-3', 'span'],
  ['body-4', 'span'],
  ['body-5', 'span'],
  ['body-6', 'span'],
  ['link', 'span'],
  ['system', 'span'],
  ['system-heading', 'span'],
  ['system-heading-2', 'span'],
  ['button-primary', 'span'],
  ['button-secondary', 'span'],
  ['button-third', 'span'],
  ['chevron', 'span'],
  ['superscript', 'span'],
  ['superscript-2', 'span'],
  ['number-1', 'span'],
  ['number-2', 'span'],
  ['number-3', 'span'],
  ['number-4', 'span'],
  ['header', 'span']
]);

const typographyVariantClass: Map<TypographyVariant, string> = new Map([
  ['h0', styles.typography_h0],
  ['h1', styles.typography_h1],
  ['h2', styles.typography_h2],
  ['h3', styles.typography_h3],
  ['h4', styles.typography_h4],
  ['h5', styles.typography_h5],
  ['label-1', styles['typography_label-1']],
  ['label-2', styles['typography_label-2']],
  ['body-1', styles['typography_body-1']],
  ['body-2', styles['typography_body-2']],
  ['body-3', styles['typography_body-3']],
  ['body-4', styles['typography_body-4']],
  ['body-5', styles['typography_body-5']],
  ['body-6', styles['typography_body-6']],
  ['link', styles.typography_link],
  ['system', styles.typography_system],
  ['system-heading', styles['typography_system-heading']],
  ['system-heading-2', styles['typography_system-heading-2']],
  ['button-primary', styles['typography_button-primary']],
  ['button-secondary', styles['typography_button-secondary']],
  ['button-third', styles['typography_button-third']],
  ['chevron', styles.typography_chevron],
  ['superscript', styles.typography_superscript],
  ['superscript-2', styles['typography_superscript-2']],
  ['number-1', styles['typography_number-1']],
  ['number-2', styles['typography_number-2']],
  ['number-3', styles['typography_number-3']],
  ['number-4', styles['typography_number-4']],
  ['header', styles.typography_header]
]);

export const Typography: FC<TypographyProps> = (props) => {
  function getTextElementClassName(variant: TypographyVariant): string {
    return typographyVariantClass.get(variant);
  }

  function getTextElement(variant: TypographyVariant): ElementType {
    return typographyElements.get(variant);
  }

  const {
    children,
    className,
    color,
    id,
    dataTestId,
    variant,
    style,
    element
  } = props;

  const TextElement = element || getTextElement(variant);

  const textElementClassName = classNames(
    styles.typography,
    getTextElementClassName(variant),
    className
  );

  return (
    <TextElement
      id={id}
      className={textElementClassName}
      style={{ color, ...style }}
      data-testid={dataTestId}
    >
      {children}
    </TextElement>
  );
};

Typography.displayName = 'Typography';
