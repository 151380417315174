import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const SendIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7071 1.29292C22.9788 1.56456 23.0708 1.96779 22.9439 2.33038L15.9439 22.3304C15.8084 22.7174 15.4504 22.9825 15.0408 22.9992C14.6311 23.0159 14.2527 22.7808 14.0862 22.4062L10.2424 13.7576L1.59387 9.91384C1.21919 9.74731 0.984122 9.36894 1.00084 8.95926C1.01755 8.54959 1.28265 8.19162 1.66965 8.05617L21.6697 1.05617C22.0322 0.929264 22.4355 1.02128 22.7071 1.29292ZM12.1928 13.2215L14.8894 19.289L19.6085 5.80571L12.1928 13.2215ZM18.1943 4.3915L4.71108 9.11063L10.7785 11.8073L18.1943 4.3915Z"
      fill={props.color}
    />
  </SvgIcon>
);

SendIcon.displayName = 'SendIcon';
