import cn from 'classnames';
import React, { FC } from 'react';
import styles from './BottomData.module.scss';

export interface BottomDataProps {
  children?: React.ReactNode;
  className?: string;
}

export const BottomData: FC<BottomDataProps> = (props) => {
  const { children, className } = props;

  return <div className={cn(styles.wrapper, className)}>{children}</div>;
};
