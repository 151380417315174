import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { SkeletonPlaceholder, Loader } from '@components';

import styles from '@components/Charts/TableChart/TableChart.module.scss';
import { SKELETON_MIN_HEIGHT, SKELETON_MIN_WIDTH } from '../TableChart.const';

function ChartLoader({ className }: { className: string }): ReactElement {
  return (
    <div className={classNames(styles.chartLoaderWrapper, className)}>
      <div className={styles.chartLoaderWrapperHeader}>
        <div className={styles.chartLoaderWrapperHeaderLeft}>
          <SkeletonPlaceholder
            width={SKELETON_MIN_WIDTH * 10}
            height={SKELETON_MIN_HEIGHT * 2}
          />
          <SkeletonPlaceholder
            width={SKELETON_MIN_WIDTH * 2}
            height={SKELETON_MIN_HEIGHT - 2}
          />
        </div>
        <div className={styles.chartLoaderWrapperHeaderRight}>
          <SkeletonPlaceholder
            width={SKELETON_MIN_WIDTH}
            height={SKELETON_MIN_HEIGHT}
          />
          <SkeletonPlaceholder
            width={SKELETON_MIN_WIDTH * 3}
            height={SKELETON_MIN_HEIGHT * 3}
          />
          <SkeletonPlaceholder
            width={SKELETON_MIN_WIDTH * 3}
            height={SKELETON_MIN_HEIGHT}
          />
        </div>
      </div>
      <Loader
        size="xl"
        color="orange"
        className={styles.chartLoaderWrapperLoader}
      />
    </div>
  );
}

export default ChartLoader;
