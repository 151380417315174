import React, { FC, Fragment, useEffect, useState } from 'react';
import styles from './Releases.module.scss';
import {
  Col,
  Row,
  Typography,
  openStatusNotification,
  Image,
  offsets,
  Button,
  Divider,
  display,
  Link,
  reactMarkdownConfig,
  common,
  downloadFileByLink
} from '@xq/ui-kit';
import { ReleasesService } from './releases-service';
import { useTranslation } from 'react-i18next';
import { errorLog } from '@services/logger';
import { getStatusNotificationTranslations } from '@services/notifications';
import ReactMarkdown from 'react-markdown';
import { NavLink, useParams } from 'react-router-dom';
import cn from 'classnames';
import { ModelTreeRelease, ReleasesList } from '@pages/Releases/dataTypes';
import { formatDateWithLongMoth } from '@services';
import { config } from '@config';

export const descriptionParts = [
  'A modern-day cash flow modeling platform for real estate and other types of assets and investment structures.',
  'Track and forecast asset, company, or loan cash flows and take out reports through comprehensive reporting functionalities.',
  'Cash flow models and reports are exportable to Excel with all calculations and formulas included.'
];

export const minimumSystemRequirements = 'Windows 8.1, 8Gb RAM, 4 core CPU';
export const recommendedSystemRequirements =
  'Windows 10, 16Gb RAM, Ryzen 5 (3rd Gen) /Intel i5 (7th Gen+)';

const RELEASE_NOTES_PER_PAGE = 5;

export const Releases: FC = () => {
  const service: ReleasesService = new ReleasesService();
  const { t } = useTranslation();
  const params = useParams();

  const [latestVersion, setLatestVersion] = useState<string>(null);
  const [currentVersion, setCurrentVersion] = useState<string>(null);
  const [releaseInfo, setReleaseInfo] = useState<ModelTreeRelease>(null);
  const [releasesList, setReleasesList] = useState<ReleasesList>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isReleaseLoading, setIsReleaseLoading] = useState<boolean>(false);

  async function fetchReleasesList(page?: number) {
    try {
      const response = await service.getReleasesList(
        page,
        RELEASE_NOTES_PER_PAGE
      );
      setReleasesList(response);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        message: t('notifications.fetchDataError'),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function fetchNextPageReleasesList() {
    if (releasesList?.meta?.currentPage < releasesList?.meta?.totalPages) {
      await fetchReleasesList(releasesList?.meta?.currentPage + 1);
    }
  }

  async function fetchPrevPageReleasesList() {
    if (releasesList?.meta?.currentPage > 1) {
      await fetchReleasesList(releasesList?.meta?.currentPage - 1);
    }
  }

  async function getLatestReleaseVersion() {
    setLoading(true);

    try {
      const response = await service.getLatestReleaseVersion();
      setLatestVersion(response);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        message: t('notifications.fetchDataError'),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
      errorLog(error, `getLatestReleaseVersion in releases page`);
    } finally {
      setLoading(false);
    }
  }

  async function getReleaseInfo(releaseVersion: string) {
    try {
      setIsReleaseLoading(true);
      const response = await service.getReleaseInfo(releaseVersion);
      setReleaseInfo(response);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        message: t('notifications.fetchDataError'),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
      errorLog(error, `getReleaseInfo in releases page`);
    } finally {
      setIsReleaseLoading(false);
    }
  }

  async function downloadRelease() {
    try {
      if (!latestVersion) {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: 500
        });
        return;
      }
      const url = `${config.apiUrl}/model-tree-releases/model-tree-releases-main-page/release/${latestVersion}/file`;
      await downloadFileByLink(url, `ModelTree_Desktop_${latestVersion}`);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: t('releases.modelTreeDesktopAppHasStartedDownloading')
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
      errorLog(error, `downloadRelease in releases page`);
    }
  }

  useEffect(() => {
    getLatestReleaseVersion();
    fetchReleasesList();
  }, []);

  useEffect(() => {
    if (params?.releaseVersion) {
      setCurrentVersion(params?.releaseVersion);
    } else if (latestVersion) {
      setCurrentVersion(latestVersion);
    }
  }, [params?.releaseVersion, latestVersion]);

  useEffect(() => {
    if (currentVersion) {
      getReleaseInfo(currentVersion);
    }
  }, [currentVersion]);

  return (
    <Fragment>
      {!isLoading && (
        <div>
          <Row cols={10}>
            <Col col={10} md={3}>
              <Typography variant="h1" className={offsets['mb-20']}>
                ModelTree desktop app
              </Typography>

              {descriptionParts?.map((part, key) => {
                return (
                  <Typography
                    key={key}
                    element={'div'}
                    className={offsets['mb-20']}
                    variant={'body-1'}
                  >
                    {part}
                  </Typography>
                );
              })}

              <Typography
                element={'div'}
                className={offsets['mt-40']}
                variant={'body-2'}
              >
                {t('releases.minimumSystemRequirements')}:
              </Typography>

              <Typography
                element={'div'}
                className={offsets['mb-20']}
                variant={'body-1'}
              >
                {minimumSystemRequirements}
              </Typography>

              <Button
                type={'primary'}
                onClick={downloadRelease}
                className={offsets['mb-20']}
              >
                {t('releases.download')}
              </Button>

              <Typography
                element={'div'}
                className={offsets['mb-60']}
                variant={'body-1'}
              >
                {t('releases.currently')}{' '}
                <b>
                  {t('releases.version')} {latestVersion}
                </b>
              </Typography>
            </Col>
            <Col col={1} />
            <Col col={10} md={6} className={offsets['mb-60']}>
              <Image
                src={`${config.cdnUrl}/frontend/apps/model-tree-releases/releases-page/release-sm.jpg`}
                srcset={`${`${config.cdnUrl}/frontend/apps/model-tree-releases/releases-page/release-sm.svg`} 768w, ${`${config.cdnUrl}/frontend/apps/model-tree-releases/releases-page/release-md.svg`} 1272w, ${`${config.cdnUrl}/frontend/apps/model-tree-releases/releases-page/release-lg.svg`} 1722w`}
              />
            </Col>
          </Row>

          <Row cols={10}>
            <Col col={10} md={6}>
              <Typography
                element={'div'}
                className={offsets['mb-8']}
                variant={'h2'}
              >
                {t('releases.theNewVersion')}{' '}
                {params.releaseVersion ? params.releaseVersion : latestVersion}{' '}
                {t('releases.hasReleased')}!
              </Typography>

              <Typography
                element={'div'}
                className={cn(
                  offsets['mb-40'],
                  isReleaseLoading
                    ? styles['release-notes--hidden']
                    : styles['release-notes']
                )}
                variant={'body-3'}
              >
                {formatDateWithLongMoth(releaseInfo?.publishDate)}
              </Typography>

              <ReactMarkdown
                className={
                  isReleaseLoading
                    ? styles['release-notes--hidden']
                    : styles['release-notes']
                }
                components={reactMarkdownConfig}
              >
                {releaseInfo?.notes}
              </ReactMarkdown>
            </Col>
            <Col col={1} />

            <Col col={10} md={3}>
              <Typography
                variant={'h5'}
                element={'div'}
                className={offsets['mb-40']}
              >
                {t('releases.allReleaseNotes')}
              </Typography>

              <Divider />

              {releasesList?.items?.map((note, key) => {
                return (
                  <div key={key} className={offsets['mt-40']}>
                    <Link
                      NavLink={NavLink}
                      isLocalRoute={true}
                      to={`/version/${note.version}`}
                      type={'button'}
                      className={cn(
                        display['d-block'],
                        offsets['pb-8'],
                        common['no-text-transform']
                      )}
                    >
                      v.{note.version}{' '}
                      {formatDateWithLongMoth(note?.publishDate)}
                    </Link>

                    <Typography
                      variant={'body-1'}
                      element={'div'}
                      className={offsets['mb-40']}
                    >
                      {note.description}
                    </Typography>
                  </div>
                );
              })}

              <div className={styles.pagination}>
                <Button
                  disabled={releasesList?.meta?.currentPage <= 1}
                  icon={'chevron-left'}
                  type={'fifth'}
                  onClick={fetchPrevPageReleasesList}
                  className={offsets['mr-20']}
                />
                <Button
                  disabled={
                    releasesList?.meta?.currentPage >=
                    releasesList?.meta?.totalPages
                  }
                  icon={'chevron-right'}
                  type={'fifth'}
                  onClick={fetchNextPageReleasesList}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </Fragment>
  );
};

Releases.displayName = 'Releases';
