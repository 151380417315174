import React, { FC } from 'react';
import classNames from 'classnames';

import {
  ComponentProps,
  Icon,
  IconNames,
  Typography,
  TypographyVariant
} from '@components';
import styles from './Button.module.scss';

import { ButtonType } from './dataTypes';
import { Loader, LoaderColor } from '@components/Loader';
import { display, offsets } from '@componentsStyles';

export interface ButtonProps extends ComponentProps {
  disabled?: boolean;
  onClick?(event?: React.MouseEvent<HTMLElement>): void;
  type?: ButtonType;
  isLoading?: boolean;
  icon?: IconNames;
  buttonType?: 'button' | 'submit' | 'reset';
}

export const Button: FC<ButtonProps> = (props) => {
  const {
    className,
    children,
    disabled,
    id,
    dataTestId,
    onClick,
    type = 'primary',
    style,
    isLoading,
    icon,
    buttonType = 'button'
  } = props;

  function getButtonClassNames(): string {
    switch (type) {
      case 'primary':
        return classNames(styles.button, styles.button_primary);

      case 'secondary':
        return classNames(
          styles.button,
          styles.button_secondary,
          isLoading && styles['button_secondary--loading']
        );

      case 'inactive':
        return classNames(styles.button, styles.button_inactive);

      case 'third':
        return classNames(styles.button, styles.button_third);

      case 'fourth':
        return classNames(styles.button, styles.button_fourth);

      case 'fifth':
        return classNames(styles.button, styles.button_fifth);

      default:
        return classNames(styles.button, styles.button_primary);
    }
  }

  const typeWithIcon = () => {
    return type === 'third' || type === 'fourth' || type === 'fifth';
  };

  function getTypographyVariant(): TypographyVariant {
    switch (type) {
      case 'primary':
        return 'button-primary';

      case 'secondary':
        return 'button-secondary';

      case 'third':
        return 'button-third';

      case 'fourth':
        return 'button-third';

      default:
        return 'button-primary';
    }
  }

  const loaderColor = (): LoaderColor => {
    switch (type) {
      case 'primary':
        return 'white';

      case 'secondary':
        return 'orange';

      case 'third':
        return 'white';

      case 'fourth':
        return 'orange';

      case 'fifth':
        return 'white';

      case 'inactive':
        return 'gray';

      default:
        return 'white';
    }
  };

  const iconClasses = () => {
    const classes = display['d-flex'];
    if (type !== 'fifth') {
      return classNames(classes, offsets['mr-8']);
    }
    return classes;
  };

  return (
    <button
      id={id}
      type={buttonType}
      className={classNames(className, getButtonClassNames())}
      disabled={disabled}
      onClick={onClick}
      style={style}
      data-testid={dataTestId}
    >
      <div
        className={styles.text}
        style={isLoading ? { visibility: 'hidden' } : {}}
      >
        {icon && typeWithIcon() && (
          <Icon className={iconClasses()} size="s" name={icon} />
        )}

        {type !== 'fifth' && (
          <Typography variant={getTypographyVariant()}>{children}</Typography>
        )}
      </div>

      {isLoading && (
        <div className={styles.loader}>
          <Loader size="s" color={loaderColor()} />
        </div>
      )}
    </button>
  );
};

Button.displayName = 'Button';
