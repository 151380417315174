import React from 'react';
import styles from './Toggle.module.scss';
import { ComponentProps } from '@components';

export interface ToggleProps extends ComponentProps {
  /** Toggle label */
  label?: string;
  /**
   * Called when value of an Input changed
   */
  onChange?: (e: boolean) => void;
  /**
   * Specifies that an Input should be disabled
   */
  disabled?: boolean;
  /** Is the checkbox checked */
  checked?: boolean;
}

export const Toggle: React.FC<ToggleProps> = (props) => {
  const {
    id,
    className,
    disabled,
    checked,
    label,
    onChange,
    style,
    dataTestId
  } = props;

  function onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    if (onChange) {
      onChange(event.target.checked);
    }
  }

  return (
    <div id={id} style={style} className={className} data-testid={dataTestId}>
      <label className={styles.toggle}>
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChangeHandler}
          aria-label={label ? label : 'input'}
        />
        <span className={styles.slider}></span>
      </label>
    </div>
  );
};
