import React, { FC, Fragment } from 'react';
import {
  Button,
  Card,
  Chevron,
  ComponentProps,
  Divider,
  Icon,
  IconNames,
  Select,
  SelectItem,
  Typography
} from '@components';
import classNames from 'classnames';
import styles from './AdminLicenseCard.module.scss';
import commonStyles from '../../LicenseCard.module.scss';
import { offsets } from '@componentsStyles';
import { AdminLicenseTranslations } from './dataTypes';
import { LoadingLicenseCard } from '../LoadingLicenseCard';
import { LicenseApp } from '@components/LicenseCard/dataTypes';
import dayjs from 'dayjs';

export interface AdminLicenseCardProps extends ComponentProps {
  /** Necessary labels */
  translations: AdminLicenseTranslations;
  /** For which application the license is used */
  app: LicenseApp;
  /** License description */
  description: string;
  /** List of roles that can be selected */
  roles?: SelectItem[];
  /** Function that allows to select the role */
  setSelectedRole?: (value: SelectItem) => void;
  /** Selected role */
  selectedRole?: SelectItem;
  /** Is a license active */
  isActive: boolean;
  /** Set license active */
  setIsActive: (value: boolean) => void;
  /** license type (Investment Management / valuation / pro) */
  licenseType?: string;
  /** Is a card disabled */
  disabled?: boolean;
  /** Is it a trial version */
  isTrial?: boolean;
  /** Expiry date if trial */
  expiryDate?: Date;
  /** Is a card loading */
  isLoading?: boolean;

  /** Action for clicking the card */
  onClick?(): void;
}

export const AdminLicenseCard: FC<AdminLicenseCardProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    translations,
    app,
    description,
    disabled,
    isTrial,
    expiryDate,
    onClick,
    roles,
    setSelectedRole,
    selectedRole,
    licenseType,
    isLoading,
    isActive,
    setIsActive
  } = props;

  const logoName: IconNames = `logo-${app}${disabled ? '-disabled' : ''}`;
  const cardClasses = classNames(
    className,
    disabled ? styles['card--disabled'] : styles.card
  );

  const onCardClick = () => {
    if (isLoading || disabled) {
      return;
    }

    if (!isActive && setIsActive) {
      setIsActive(true);
    }

    if (onClick) {
      onClick();
    }
  };

  const resetCard = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (isLoading || disabled) {
      return;
    }

    if (isActive) {
      setIsActive(false);
    }
  };

  return (
    <Card
      id={id}
      style={style}
      className={cardClasses}
      data-testid={dataTestId}
      onClick={onCardClick}
    >
      {isLoading ? (
        <LoadingLicenseCard />
      ) : (
        <Fragment>
          <div onClick={resetCard}>
            <Icon
              color="disabled"
              name={isActive ? 'trash' : 'plus'}
              size={'s'}
              className={classNames(styles['add-icon'], commonStyles.logo)}
            />
          </div>
          {!isActive && (
            <Button
              type="fifth"
              icon="plus"
              className={styles['add-icon--hover']}
            />
          )}

          <div className={commonStyles.header}>
            <Icon name={logoName} className={commonStyles.logo} />
            {isTrial && (
              <Chevron
                className={classNames(offsets['ml-12'])}
                disabled={disabled}
              >
                {translations.trialVersion}
              </Chevron>
            )}
          </div>

          {licenseType && (
            <Typography
              element="div"
              className={offsets['mb-12']}
              variant="body-5"
            >
              {licenseType}
            </Typography>
          )}

          {isTrial && expiryDate && (
            <Typography
              element="div"
              variant="body-3"
              className={offsets['my-12']}
            >
              {translations?.expiryDate}:{' '}
              <Typography variant="body-5">
                <time dateTime={dayjs(expiryDate).format('YYYY-MM-DD')}>
                  {dayjs(expiryDate).format('DD.MM.YYYY')}
                </time>
              </Typography>
            </Typography>
          )}

          <Typography element="div" variant="body-3">
            {description}
          </Typography>

          {roles?.length > 0 && (
            <div className={offsets['mt-20']}>
              <Divider
                type="thin"
                className={
                  disabled
                    ? commonStyles['divider--disabled']
                    : commonStyles.divider
                }
              />
              <Select
                disabled={disabled}
                className={styles.dropdown}
                label={translations.role}
                items={roles}
                selected={selectedRole}
                onChange={setSelectedRole}
              />
            </div>
          )}
        </Fragment>
      )}
    </Card>
  );
};

AdminLicenseCard.displayName = 'AdminLicenseCard';
