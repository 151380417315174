import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const Link2Icon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 8C4.93913 8 3.92172 8.42143 3.17157 9.17157C2.42143 9.92172 2 10.9391 2 12C2 13.0609 2.42143 14.0783 3.17157 14.8284C3.54301 15.1999 3.98396 15.4945 4.46927 15.6955C4.95457 15.8965 5.47471 16 6 16H9C9.55229 16 10 16.4477 10 17C10 17.5523 9.55229 18 9 18H6C5.21207 18 4.43185 17.8448 3.7039 17.5433C2.97595 17.2417 2.31451 16.7998 1.75736 16.2426C0.632141 15.1174 0 13.5913 0 12C0 10.4087 0.632141 8.88258 1.75736 7.75736C2.88258 6.63214 4.4087 6 6 6H9C9.55229 6 10 6.44772 10 7C10 7.55228 9.55229 8 9 8H6ZM14 7C14 6.44772 14.4477 6 15 6H18C18.7879 6 19.5681 6.15519 20.2961 6.45672C21.0241 6.75825 21.6855 7.20021 22.2426 7.75736C22.7998 8.31451 23.2417 8.97595 23.5433 9.7039C23.8448 10.4319 24 11.2121 24 12C24 12.7879 23.8448 13.5681 23.5433 14.2961C23.2417 15.0241 22.7998 15.6855 22.2426 16.2426C21.6855 16.7998 21.0241 17.2417 20.2961 17.5433C19.5681 17.8448 18.7879 18 18 18H15C14.4477 18 14 17.5523 14 17C14 16.4477 14.4477 16 15 16H18C18.5253 16 19.0454 15.8965 19.5307 15.6955C20.016 15.4945 20.457 15.1999 20.8284 14.8284C21.1999 14.457 21.4945 14.016 21.6955 13.5307C21.8965 13.0454 22 12.5253 22 12C22 11.4747 21.8965 10.9546 21.6955 10.4693C21.4945 9.98396 21.1999 9.54301 20.8284 9.17157C20.457 8.80014 20.016 8.5055 19.5307 8.30448C19.0454 8.10346 18.5253 8 18 8H15C14.4477 8 14 7.55228 14 7ZM7 12C7 11.4477 7.44772 11 8 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H8C7.44772 13 7 12.5523 7 12Z"
      fill={props.color}
    />
  </SvgIcon>
);

Link2Icon.displayName = 'Link2Icon';
