import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { ComponentProps, Typography } from '@components';
import styles from './PricingInput.module.scss';
import { Currency, getCurrencySign } from '@componentsUtils';
import cn from 'classnames';

// todo dont show delete buttons before init

export interface PricingInputProps extends ComponentProps {
  value?: number;
  onChange?: (value: number) => void;
  currency?: Currency;
  disabled?: boolean;
  inputMaxWidth?: string;
}

interface CountDecimalDigits {
  decimalDigits: number;
  integer: string;
  decimal: string;
}

export const PricingInput: FC<PricingInputProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    disabled,
    onChange,
    value,
    currency,
    inputMaxWidth = 'auto'
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  /* State of selectionStart when input changes  */
  const [selectionStart, setSelectionStart] = useState<number>(0);
  /* State of selectionEnd when input changes  */
  const [selectionEnd, setSelectionEnd] = useState<number>(0);
  /* Prev visible price to calculate  caretShift */
  const [prevVisiblePrice, setPrevVisiblePrice] = useState<string>(null);
  /* Value for selection shift after price formatting  */
  const [caretShift, setCaretShift] = useState<number>(0);

  const visiblePrice = useMemo(() => {
    if (!value) {
      return '';
    }
    return formatPrice(value);
  }, [value]);

  useEffect(() => {
    /* Set correct caret position */
    inputRef?.current?.setSelectionRange(
      selectionStart + caretShift,
      selectionEnd + caretShift
    );
  }, [visiblePrice, caretShift]);

  const onChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;

    const oldSelectionStart = event.target.selectionStart; // Сохраняем позицию начала выделения
    const oldSelectionEnd = event.target.selectionEnd;

    setSelectionStart(oldSelectionStart);
    setSelectionEnd(oldSelectionEnd);

    if (
      removeSpacesAndDotFromString(prevVisiblePrice) ==
      removeSpacesAndDotFromString(value)
    ) {
      event.preventDefault();

      setTimeout(() => {
        inputRef?.current?.setSelectionRange(
          oldSelectionStart,
          oldSelectionEnd
        );
      }, 0);

      return;
    }

    const price = getPriceFromFormattedInput(value);

    if (onChange) {
      onChange(price);
    }
  };

  const removeSpacesFromString = (value: string): string =>
    value?.replace(/[^\d.-]/g, '');

  const removeSpacesAndDotFromString = (value: string): string =>
    value?.replace(/[\s.]/g, '');

  const getPriceFromFormattedInput = (value: string): number => {
    if (!value) {
      return 0;
    }

    /* Remove spaces from formatted value */
    value = removeSpacesFromString(value);

    let numericValue: number = parseFloat(value);
    const { decimalDigits, integer, decimal } = countDecimalDigits(value);

    /* If numbers after not equal two, we should shift the dot position */
    if (decimalDigits > 2) {
      numericValue = numericValue * Math.pow(10, decimalDigits - 2);
    }
    if (decimalDigits === 1) {
      numericValue = (Number(integer) * 10 + Number(decimal)) / 100;
    }

    /* Format to 2 decimal */
    value = numericValue.toFixed(2).toString();

    /* Remove spaces */
    const priceNumber = Number(removeSpacesFromString(value));

    return priceNumber;
  };

  function formatPrice(price: number) {
    if (!price) {
      return '';
    }
    let value = String(price);

    /* Set spaces */
    value = Number(value).toFixed(2).toString();
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    /* Difference in length to set caret position */
    let dif = prevVisiblePrice?.length
      ? value.length - prevVisiblePrice.length
      : 0;

    if (dif > 0) {
      dif = dif - 1;
    } else {
      dif = dif + 1;
    }

    setCaretShift(dif);

    setPrevVisiblePrice(value);
    return value;
  }

  function countDecimalDigits(number: string): CountDecimalDigits {
    if (!number)
      return {
        decimalDigits: 0,
        integer: '',
        decimal: ''
      };
    const [integer, decimal] = number.split('.');
    return {
      decimalDigits: decimal?.length || 0,
      integer: integer,
      decimal: decimal
    };
  }

  return (
    <div
      id={id}
      className={cn(styles.wrapper, className)}
      style={style}
      data-testid={dataTestId}
    >
      <input
        style={{ maxWidth: inputMaxWidth }}
        className={disabled ? styles['input--disabled'] : styles.input}
        type="text"
        value={visiblePrice}
        placeholder={'00.00'}
        readOnly={disabled}
        onChange={(e) => {
          onChangePrice(e);
        }}
        ref={inputRef}
      />
      <Typography
        className={
          disabled || !visiblePrice ? styles['sign--disabled'] : styles.sign
        }
        variant="number-1"
      >
        {getCurrencySign(currency)}
      </Typography>
    </div>
  );
};

PricingInput.displayName = 'PricingInput';
