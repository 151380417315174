import React, { AnchorHTMLAttributes, forwardRef, Fragment } from 'react';
import styles from './Loader.module.scss';
import { LoaderSize, LoaderColor } from './dataTypes';
import { ComponentProps } from '@components/dataTypes';
import classNames from 'classnames';

export interface LoaderProps
  extends ComponentProps,
    AnchorHTMLAttributes<HTMLElement> {
  size?: LoaderSize;
  color?: LoaderColor;
}

export type LoaderRef = HTMLAnchorElement;

export const Loader = forwardRef<LoaderRef, LoaderProps>((props, ref) => {
  const {
    size = 's',
    color = 'white',
    className,
    id,
    style,
    dataTestId
  } = props;

  const loaderSizes = {
    s: 16,
    m: 20,
    l: 24,
    xl: 44,
    xxl: 88
  };

  const loaderSize = () => {
    return loaderSizes[size];
  };

  const gradient = () => {
    switch (color) {
      case 'white': {
        return (
          <Fragment>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.4418 2.51933C9.26 1.99274 7.93959 1.86228 6.67755 2.14742C5.41551 2.43256 4.27945 3.11802 3.43881 4.10158C2.59816 5.08513 2.09798 6.31407 2.01284 7.60512C1.92771 8.89617 2.2622 10.1802 2.96641 11.2656C3.67063 12.351 4.70685 13.1797 5.92052 13.6281C7.13419 14.0765 8.46029 14.1205 9.70105 13.7537C10.9418 13.3868 12.0307 12.6287 12.8054 11.5924C13.5801 10.5561 13.9991 9.29718 13.9998 8.00333V7.39038C13.9998 7.02219 14.2983 6.72371 14.6665 6.72371C15.0347 6.72371 15.3332 7.02219 15.3332 7.39038V8.00371C15.3323 9.58509 14.8202 11.1242 13.8733 12.3908C12.9265 13.6573 11.5956 14.5839 10.0791 15.0323C8.56262 15.4807 6.94183 15.4268 5.45845 14.8788C3.97507 14.3308 2.70858 13.3179 1.84787 11.9913C0.987166 10.6647 0.578351 9.09534 0.6824 7.51739C0.78645 5.93944 1.39779 4.4374 2.42524 3.23528C3.45269 2.03316 4.84121 1.19538 6.38371 0.846869C7.9262 0.498363 9.54003 0.657809 10.9845 1.30143C11.3208 1.45128 11.472 1.8454 11.3221 2.18171C11.1723 2.51803 10.7782 2.66919 10.4418 2.51933Z"
              fill="url(#paint0_linear_2589_1233)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2589_1233"
                x1="0.999756"
                y1="8.49991"
                x2="16.9998"
                y2="8.49991"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </linearGradient>
            </defs>
          </Fragment>
        );
      }
      case 'orange': {
        return (
          <Fragment>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.442 2.51927C9.26015 1.99267 7.93974 1.86222 6.6777 2.14736C5.41566 2.4325 4.2796 3.11796 3.43896 4.10151C2.59831 5.08507 2.09813 6.31401 2.013 7.60506C1.92787 8.89611 2.26235 10.1801 2.96657 11.2655C3.67078 12.3509 4.707 13.1796 5.92067 13.628C7.13434 14.0764 8.46045 14.1205 9.7012 13.7536C10.942 13.3868 12.0309 12.6287 12.8056 11.5924C13.5803 10.5561 13.9992 9.29712 14 8.00327V7.39032C14 7.02213 14.2985 6.72365 14.6667 6.72365C15.0348 6.72365 15.3333 7.02213 15.3333 7.39032V8.00365C15.3324 9.58503 14.8204 11.1241 13.8735 12.3907C12.9266 13.6573 11.5957 14.5838 10.0792 15.0322C8.56277 15.4806 6.94198 15.4268 5.4586 14.8787C3.97522 14.3307 2.70874 13.3178 1.84803 11.9912C0.987318 10.6646 0.578503 9.09528 0.682553 7.51733C0.786602 5.93938 1.39794 4.43734 2.42539 3.23522C3.45285 2.0331 4.84136 1.19531 6.38386 0.846808C7.92636 0.498302 9.54018 0.657748 10.9847 1.30137C11.321 1.45122 11.4721 1.84534 11.3223 2.18165C11.1724 2.51797 10.7783 2.66913 10.442 2.51927Z"
              fill="url(#paint0_linear_1027_10660)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1027_10660"
                x1="0.999908"
                y1="8.49985"
                x2="17"
                y2="8.49985"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F7941D" />
                <stop offset="1" stopColor="#F7941D" stopOpacity="0" />
              </linearGradient>
            </defs>
          </Fragment>
        );
      }

      case 'gray': {
        return (
          <Fragment>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.4418 2.51939C9.26 1.9928 7.93959 1.86234 6.67755 2.14748C5.41551 2.43262 4.27945 3.11809 3.43881 4.10164C2.59816 5.08519 2.09798 6.31413 2.01284 7.60518C1.92771 8.89623 2.2622 10.1802 2.96641 11.2656C3.67063 12.3511 4.70685 13.1798 5.92052 13.6281C7.13419 14.0765 8.46029 14.1206 9.70105 13.7537C10.9418 13.3869 12.0307 12.6288 12.8054 11.5925C13.5801 10.5562 13.9991 9.29725 13.9998 8.00339V7.39044C13.9998 7.02225 14.2983 6.72377 14.6665 6.72377C15.0347 6.72377 15.3332 7.02225 15.3332 7.39044V8.00377C15.3323 9.58515 14.8202 11.1242 13.8733 12.3908C12.9265 13.6574 11.5956 14.584 10.0791 15.0323C8.56262 15.4807 6.94183 15.4269 5.45845 14.8788C3.97507 14.3308 2.70858 13.318 1.84787 11.9913C0.987166 10.6647 0.578351 9.0954 0.6824 7.51745C0.78645 5.9395 1.39779 4.43746 2.42524 3.23534C3.45269 2.03322 4.84121 1.19544 6.38371 0.84693C7.9262 0.498424 9.54003 0.65787 10.9845 1.30149C11.3208 1.45134 11.472 1.84546 11.3221 2.18178C11.1723 2.51809 10.7782 2.66925 10.4418 2.51939Z"
              fill="url(#paint0_linear_3075_320)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3075_320"
                x1="0.999756"
                y1="8.49997"
                x2="16.9998"
                y2="8.49997"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#8F8F8F" />
                <stop offset="1" stopColor="#8F8F8F" stopOpacity="0" />
              </linearGradient>
            </defs>
          </Fragment>
        );
      }
    }
  };

  return (
    <svg
      className={classNames(styles.spinner, className)}
      width={loaderSize()}
      height={loaderSize()}
      viewBox="0 0 16 16"
      fill="none"
      id={id}
      style={style}
      data-testid={dataTestId}
      xmlns="http://www.w3.org/2000/svg"
    >
      {gradient()}
    </svg>
  );
});

Loader.displayName = 'Loader';
