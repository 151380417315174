import React, { FC, useEffect } from 'react';
import { Button, ComponentProps, Divider, Typography } from '@components';
import { colors } from '@componentsStyles';
import {
  AdditionalPaymentsItem,
  AdditionalPaymentsListTranslations
} from './dataTypes';
import { Currency } from '@componentsUtils/format';
import { AdditionalPaymentsListRow } from './components/AdditionalPaymentsListRow';
import styles from './AdditionalPaymentsList.module.scss';
import cn from 'classnames';

export interface AdditionalPaymentsListProps extends ComponentProps {
  /** Additional payment data */
  additionalPayments: AdditionalPaymentsItem[];
  /** Function change pricing data in the parent component */
  onChange: (value: AdditionalPaymentsItem[]) => void;
  /** Currency for input values */
  currency: Currency;
  /** Translations object */
  translations?: AdditionalPaymentsListTranslations;
}

export const AdditionalPaymentsList: FC<AdditionalPaymentsListProps> = (
  props
) => {
  const {
    id,
    className,
    style,
    dataTestId,
    additionalPayments,
    onChange,
    currency,
    translations = {
      delete: 'Delete',
      service: 'service',
      comment: 'comment',
      price: 'price',
      period: 'period',
      amountOfUsers: 'Amount of users'
    }
  } = props;

  function changeItemPrice(key: number, price: number) {
    const updatedPayments = additionalPayments?.map((payment, idx) => {
      if (idx === key) {
        return { ...payment, price: price };
      }
      return payment;
    });
    onChange(updatedPayments);
  }

  function changeItemAmountOfUsers(
    key: number,
    amountOfUsers: number | string
  ) {
    const updatedPayments = additionalPayments?.map((payment, idx) => {
      if (idx === key) {
        return {
          ...payment,
          amountOfUsers: amountOfUsers ? Number(amountOfUsers) : null
        };
      }
      return payment;
    });
    onChange(updatedPayments);
  }

  const changeItemComment = (key: number, comment: string) => {
    const updatedPayments = additionalPayments?.map((payment, idx) => {
      if (idx === key) {
        return { ...payment, comment: comment };
      }
      return payment;
    });
    onChange(updatedPayments);
  };

  const changeItemService = (key: number, service: string) => {
    const updatedPayments = additionalPayments?.map((payment, idx) => {
      if (idx === key) {
        return { ...payment, service: service };
      }
      return payment;
    });
    onChange(updatedPayments);
  };

  const changeItemDate = (key: number, date?: Date) => {
    const updatedPayments = additionalPayments?.map((payment, idx) => {
      if (idx !== key) {
        return payment;
      }

      if (!date) {
        return { ...payment, from: null, to: null };
      }

      const startDate = new Date(date);
      const endDate = new Date(date);

      startDate.setDate(1);
      endDate.setMonth(endDate.getMonth() + 1, 0);

      return { ...payment, from: startDate, to: endDate };
    });
    onChange(updatedPayments);
  };

  function deleteRow(key: number) {
    if (additionalPayments?.length > 1) {
      const newItems = additionalPayments?.filter((el, idx) => key !== idx);
      onChange([...newItems]);
    }
  }

  const addRow = () => {
    onChange([...additionalPayments, { isEditable: true }]);
  };

  useEffect(() => {
    if (additionalPayments?.length === 0) {
      onChange([{ isEditable: true }]);
    }
  }, [additionalPayments]);

  return (
    <div
      className={cn(className, styles.wrapper)}
      style={style}
      id={id}
      data-testid={dataTestId}
    >
      <div>
        <div className={styles.header}>
          <Typography
            className={styles['header-period']}
            variant="system-heading"
            color={colors.gray60Color}
          >
            {translations.period}
          </Typography>

          <Typography variant="system-heading" color={colors.gray60Color}>
            {translations.service}
          </Typography>

          <Typography variant="system-heading" color={colors.gray60Color}>
            {translations.amountOfUsers}
          </Typography>

          <Typography variant="system-heading" color={colors.gray60Color}>
            {translations.comment}
          </Typography>

          <Typography
            className={styles['header-price']}
            variant="system-heading"
            color={colors.gray60Color}
          >
            {translations.price}
          </Typography>
        </div>
        <Divider style={{ borderColor: colors.gray20Color }} />
      </div>

      <div className={styles.pricing}>
        {additionalPayments?.map((additionalPaymentsItem, key) => {
          return (
            <AdditionalPaymentsListRow
              key={key}
              className={styles.row}
              price={additionalPaymentsItem.price}
              comment={additionalPaymentsItem.comment}
              service={additionalPaymentsItem.service}
              date={additionalPaymentsItem.from}
              amountOfUsers={additionalPaymentsItem.amountOfUsers}
              isEditable={additionalPaymentsItem.isEditable}
              changeItemPrice={changeItemPrice}
              changeItemComment={changeItemComment}
              changeItemService={changeItemService}
              changeItemDate={changeItemDate}
              changeItemAmountOfUsers={changeItemAmountOfUsers}
              deleteRow={() => deleteRow(key)}
              currency={currency}
              length={additionalPayments?.length}
              index={key}
              translations={translations}
            />
          );
        })}

        <div className={styles.add}>
          <Button type="fifth" icon="plus" onClick={addRow} />
        </div>
      </div>
    </div>
  );
};

AdditionalPaymentsList.displayName = 'AdditionalPaymentsList';
