import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const CloudPublicIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <g clipRule="url(#clip0_8451_57994)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.08356 0.0463079C9.50175 -0.098193 10.934 0.0966314 12.2621 0.614703C13.5902 1.13277 14.7763 1.95924 15.7224 3.02593C16.4947 3.89658 17.0891 4.90691 17.4753 5.99971H17.9963C19.3729 6.00133 20.707 6.47678 21.7746 7.34611C22.8422 8.21543 23.5784 9.42569 23.8596 10.7737C24.1408 12.1216 23.9501 13.5254 23.3194 14.7493C23.0317 15.3075 22.6446 15.9361 22.1716 16.4988C21.7056 17.0534 21.1092 17.6005 20.3894 17.9159C19.8835 18.1376 19.2938 17.9072 19.0721 17.4014C18.8504 16.8955 19.0808 16.3058 19.5866 16.0841C19.9174 15.9391 20.2794 15.6418 20.6405 15.2121C20.9947 14.7906 21.3035 14.2951 21.5415 13.8332C21.9621 13.0171 22.0893 12.081 21.9018 11.1821C21.7142 10.2833 21.2234 9.47642 20.5118 8.89699C19.8003 8.31765 18.9114 8.00088 17.9943 7.99971V7.99971H16.7359C16.2799 7.99971 15.8816 7.69117 15.7676 7.2496C15.4904 6.17548 14.9621 5.1827 14.2262 4.35309C13.4903 3.52349 12.568 2.88081 11.5353 2.47797C10.5026 2.07513 9.38897 1.92365 8.28629 2.03601C7.18361 2.14836 6.12336 2.52133 5.19293 3.12422C4.2625 3.72711 3.48853 4.54267 2.93485 5.50371C2.38116 6.46476 2.06364 7.54376 2.00849 8.65178C1.94608 9.90571 1.99687 11.1693 2.41127 12.0893C2.90178 13.1784 3.80922 14.5687 4.62246 15.219C5.05381 15.5639 5.12389 16.1932 4.77898 16.6245C4.43408 17.0559 3.80481 17.1259 3.37346 16.781C2.20717 15.8485 1.13834 14.1332 0.587706 12.9107C-0.0390345 11.5192 -0.0526667 9.83078 0.0109641 8.55235C0.0818578 7.12801 0.49003 5.74088 1.20187 4.50531C1.91372 3.26973 2.90887 2.22106 4.10535 1.44578C5.30183 0.670491 6.66536 0.190809 8.08356 0.0463079Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5856 14.5858C7.96067 14.2107 8.46938 14 8.99981 14H14.9998C15.5302 14 16.0389 14.2107 16.414 14.5858C16.7891 14.9609 16.9998 15.4696 16.9998 16V19C16.9998 19.5304 16.7891 20.0391 16.414 20.4142C16.0389 20.7893 15.5302 21 14.9998 21H8.99981C8.46938 21 7.96067 20.7893 7.5856 20.4142C7.21052 20.0391 6.99981 19.5304 6.99981 19V16C6.99981 15.4696 7.21052 14.9609 7.5856 14.5858ZM14.9998 16H8.99981V19H14.9998V16Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5048 12.4191C12.2963 12.2107 11.9975 12.1202 11.7085 12.1781C11.2964 12.2605 10.9998 12.6223 10.9998 13.0425V14.5C10.9998 15.0523 10.5521 15.5 9.99981 15.5C9.44753 15.5 8.99981 15.0523 8.99981 14.5V13.0425C8.99981 11.6689 9.96934 10.4863 11.3163 10.2169C12.261 10.0279 13.2377 10.3237 13.919 11.0049L14.2069 11.2929C14.5974 11.6834 14.5974 12.3166 14.2069 12.7071C13.8164 13.0976 13.1832 13.0976 12.7927 12.7071L12.5048 12.4191Z"
        fill={props.color}
      />
    </g>
    <defs>
      <clipPath id="clip0_8451_57994">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

CloudPublicIcon.displayName = 'CloudPublicIcon';
