import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const CreditcardPlusIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 5C2.44772 5 2 5.44772 2 6V7H22V6C22 5.44771 21.5523 5 21 5H3ZM24 6C24 4.34315 22.6569 3 21 3H3C1.34315 3 0 4.34315 0 6V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V6ZM22 9H2V18C2 18.5523 2.44772 19 3 19H21C21.5523 19 22 18.5523 22 18V9ZM12 10C12.5523 10 13 10.4477 13 11V13H15C15.5523 13 16 13.4477 16 14C16 14.5523 15.5523 15 15 15H13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V15H9C8.44772 15 8 14.5523 8 14C8 13.4477 8.44772 13 9 13H11V11C11 10.4477 11.4477 10 12 10Z"
      fill={props.color}
    />
  </SvgIcon>
);

CreditcardPlusIcon.displayName = 'CreditcardPlusIcon';
