import React, { ReactElement } from 'react';
import { SkeletonPlaceholder } from '@components';
import cn from 'classnames';
import { offsets } from '@componentsStyles';
import styles from '@components/InformationСard/components/DataChoice/DataChoice.module.scss';

const loadingItems = [1, 2];

function CardLoader(): ReactElement {
  return (
    <>
      {loadingItems.map((loadingItem) => (
        <div key={loadingItem}>
          <SkeletonPlaceholder
            width={100}
            height={20}
            className={cn(
              offsets['mb-20'],
              loadingItem === 1 ? offsets['mt-40'] : offsets['mt-60']
            )}
          />
          <div className={cn(styles['options-wrapper'], offsets['mb-20'])}>
            {Array(4)
              .fill(1)
              .map((item, key) => (
                <SkeletonPlaceholder
                  key={item + key}
                  width="100%"
                  height={12}
                  className={offsets['mr-10']}
                />
              ))}
          </div>
        </div>
      ))}
    </>
  );
}

export default CardLoader;
