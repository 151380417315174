import { formatBytes } from '@components';

export const displayFileName = (
  name: string,
  byteSize: number
): { nameStart: string; nameEnd: string; size: string } => {
  const size = `(${formatBytes(byteSize)})`;

  const nameParts = name.split('.');
  const extension = nameParts.pop();
  const fileName = nameParts.join('.');
  const nameStart = fileName.slice(0, fileName.length - 2);
  const nameEnd =
    fileName.slice(fileName.length - 2, fileName.length) + '.' + extension;

  return { nameStart, nameEnd, size };
};
