import React, { FC, useState } from 'react';
import { ComponentProps, Divider, Icon, Typography } from '@components';
import styles from './PricingListHeader.module.scss';
import { colors, offsets } from '@componentsStyles';
import cn from 'classnames';
import { EntityName } from '@interfaces';

export interface PricingListHeaderProps extends ComponentProps {
  title: string;
  pricingOptions?: EntityName[];
  currentOptionUuid?: string;
  setCurrentOptionUuid?: (value: string) => void;
  disabled?: boolean;
}

export const PricingListHeader: FC<PricingListHeaderProps> = (props) => {
  const {
    id,
    className,
    style,
    title,
    dataTestId,
    disabled,
    pricingOptions,
    currentOptionUuid,
    setCurrentOptionUuid
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    if (disabled) {
      return;
    }
    setIsOpen(!isOpen);
  };

  const changeOption = (optionUuid: string) => {
    setCurrentOptionUuid(optionUuid);
    setIsOpen(false);
  };

  return (
    <div
      style={style}
      id={id}
      data-testid={dataTestId}
      className={cn(className, styles.wrapper)}
    >
      <div className={styles.header}>
        <Typography variant="system-heading" color={colors.gray60Color}>
          {title}
        </Typography>

        <div
          className={
            disabled
              ? styles['dropdown-header--disabled']
              : styles['dropdown-header']
          }
          onClick={toggleOpen}
        >
          <Typography variant="system-heading" color={colors.gray60Color}>
            {
              pricingOptions?.find(
                (option) => option?.uuid === currentOptionUuid
              )?.name
            }
          </Typography>

          {!disabled && (
            <Icon
              name="chevron-down"
              className={offsets['ml-2']}
              size="s"
            ></Icon>
          )}
        </div>
      </div>

      {isOpen && (
        <div className={styles['dropdown-menu']}>
          {pricingOptions
            ?.filter((option) => option?.uuid !== currentOptionUuid)
            ?.map((option) => {
              return (
                <div
                  key={option?.uuid}
                  onClick={() => changeOption(option.uuid)}
                >
                  <Typography variant="system">{option?.name}</Typography>
                </div>
              );
            })}
        </div>
      )}

      <Divider type="thin" className={styles.divider} />
    </div>
  );
};

PricingListHeader.displayName = 'PricingListHeader';
