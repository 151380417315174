import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const ReleaseIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 2.11604L10.6402 5.75357C10.4605 5.90351 10.2339 5.98563 10 5.98563H9V9.99042H10C10.2339 9.99042 10.4605 10.0725 10.6402 10.2225L15 13.86V2.11604ZM17 6.25389V9.72215C17.1501 9.6354 17.2895 9.52876 17.4142 9.40393C17.7893 9.02841 18 8.51909 18 7.98802C18 7.45695 17.7893 6.94763 17.4142 6.57211C17.2895 6.44729 17.1501 6.34065 17 6.25389ZM17 11.8657C17.686 11.6883 18.3189 11.33 18.8284 10.8198C19.5786 10.0688 20 9.05016 20 7.98802C20 6.92589 19.5786 5.90725 18.8284 5.15621C18.3189 4.64605 17.686 4.28775 17 4.11039V1.90325M17 11.8657V14.0728C17 14.0726 17 14.073 17 14.0728C17.0001 14.4342 16.8973 14.7887 16.7038 15.0938C16.5101 15.399 16.2336 15.6426 15.9065 15.7961C15.5795 15.9496 15.2156 16.0066 14.8574 15.9605C14.4992 15.9143 14.1615 15.7669 13.884 15.5354L9.63795 11.9928H9V15.9976C9 16.5287 8.78929 17.038 8.41421 17.4135C8.03914 17.789 7.53043 18 7 18H6C5.46957 18 4.96086 17.789 4.58579 17.4135C4.21071 17.038 4 16.5287 4 15.9976V11.9928H2C1.46957 11.9928 0.960859 11.7818 0.585786 11.4063C0.210714 11.0308 0 10.5215 0 9.99042V5.98563C0 5.45456 0.210714 4.94524 0.585786 4.56972C0.960859 4.1942 1.46957 3.98323 2 3.98323H9.63795L13.8838 0.44077C14.1613 0.209344 14.4992 0.0617501 14.8574 0.0155745C15.2156 -0.0306009 15.5795 0.0264007 15.9065 0.1799C16.2336 0.3334 16.5101 0.577037 16.7038 0.882262C16.8973 1.18734 17.0001 1.54183 17 1.90325M7 5.98563H2V9.99042H7V5.98563ZM7 11.9928H6V15.9976H7V11.9928Z"
      fill={props.color}
    />
  </SvgIcon>
);

ReleaseIcon.displayName = 'ReleaseIcon';
