import React, { ReactElement } from 'react';
import dayjs from 'dayjs';
import { Text } from 'recharts';

interface Props {
  date: number;
  viewBox?: { x: number; y: number };
  isFirst?: boolean;
}

function ReferenceLineLabel({ date, viewBox, isFirst }: Props): ReactElement {
  return (
    <g
      transform={`translate(${isFirst ? viewBox.x : viewBox.x - 68},${
        viewBox.y + 85
      })`}
    >
      <rect x={0} y={0} width="68" height="17" fill="#3D3D3D" />
      <Text
        fontSize="0.625rem"
        x={0}
        y={0}
        dy={12}
        dx={35}
        textAnchor="middle"
        fill="white"
      >
        {dayjs(date).format('DD.MM.YYYY')}
      </Text>
    </g>
  );
}

export default ReferenceLineLabel;
