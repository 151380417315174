import React, { FC } from 'react';
import classNames from 'classnames';
import { ComponentProps } from '@components';
import { grid } from '@componentsStyles';

export const LayoutContainer: FC<ComponentProps> = (props) => {
  const { className, children, id, style } = props;

  return (
    <div
      id={id}
      className={classNames(className, grid.container)}
      style={style}
    >
      {children}
    </div>
  );
};

LayoutContainer.displayName = 'LayoutContainer';
