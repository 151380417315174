import React from 'react';
import { InfoBox, Marker } from '@react-google-maps/api';
import { ActiveShape } from '@components/Map/dataTypes';
import styles from './Marker.module.scss';

interface Props {
  activeShape: ActiveShape & { position: google.maps.LatLng; title: string };
}

export const MarkerComponent = ({ activeShape }: Props) => {
  return (
    <Marker
      icon={{
        url: require('@assets/images/map-marker.svg'),
        scale: 7
      }}
      position={
        activeShape?.position ??
        ({ lat: 0, lng: 0 } as unknown as google.maps.LatLng)
      }
    >
      <InfoBox
        position={
          activeShape?.position ??
          ({ lat: 0, lng: 0 } as unknown as google.maps.LatLng)
        }
        options={{
          closeBoxURL: null,
          pixelOffset:
            typeof google !== 'undefined'
              ? new google.maps.Size(10, -20)
              : undefined,
          boxStyle: {
            background: '#ffffff',
            borderRadius: '2px',
            padding: '6px'
          }
        }}
      >
        <div className={styles.wrapper}>
          <p>
            <span>{activeShape.title}</span>
          </p>
          <p>
            {activeShape.kpi && (
              <span>{`${activeShape.kpi} ${
                activeShape.kpiValue ? '-' : ''
              } `}</span>
            )}
            {activeShape.kpiValue ? (
              <span>
                {Number.parseFloat(activeShape.kpiValue).toLocaleString('fr', {
                  maximumFractionDigits: 2
                })}
              </span>
            ) : (
              <span>—</span>
            )}
          </p>
        </div>
      </InfoBox>
    </Marker>
  );
};
