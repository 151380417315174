import { colors } from '@componentsStyles';

export interface DonutSlice {
  /** Title of slice */
  label: string;
  /** Slice value for chart */
  value: number;
  /** Slice id */
  uuid?: string;
}

export interface DonutSliceWithPercent extends DonutSlice {
  /** Percent of slice */
  percent: number;
}

export const chartColors = [
  colors.honey,
  colors.gold,
  colors.red,
  colors.ruby,
  colors.fuchsia,
  colors.palettesOrange100,
  colors.palettesHoney80,
  colors.palettesGold80,
  colors.palettesRed80,
  colors.palettesRuby80,
  colors.palettesOrange80,
  colors.palettesFuchsia80,
  colors.palettesHoney60,
  colors.palettesGold60,
  colors.palettesRed60,
  colors.palettesRuby60,
  colors.palettesOrange60,
  colors.palettesFuchsia60,
  colors.palettesHoney40,
  colors.palettesGold40,
  colors.palettesRed40,
  colors.palettesRuby40,
  colors.palettesOrange40,
  colors.palettesFuchsia40,
  colors.palettesHoney120,
  colors.palettesGold120,
  colors.palettesRed120,
  colors.palettesFuchsia120,
  colors.palettesHoney20,
  colors.palettesGold20,
  colors.palettesRed20,
  colors.palettesRuby20,
  colors.palettesOrange20,
  colors.palettesFuchsia20,
  colors.palettesHoney140,
  colors.palettesRed140,
  colors.palettesFuchsia140
];
