import React, { FC } from 'react';
import {
  ComponentProps,
  Typography,
  Card,
  Divider,
  Icon,
  SkeletonPlaceholder,
  Tooltip
} from '@components';
import cn from 'classnames';
import styles from './LastActivities.module.scss';
import { colors, common, offsets } from '@componentsStyles';
import { LastActivitiesItems, LastActivitiesTranslations } from './dataTypes';
import { formatDate, formatTime } from '@componentsUtils';

export interface LastActivitiesProps extends ComponentProps {
  lastActivities: LastActivitiesItems[];
  translations?: LastActivitiesTranslations;
  isLoading?: boolean;
  onSeeDetails?(): void;
}

const defaultTranslations: LastActivitiesTranslations = {
  seeDetails: 'See details',
  lastActivities: 'Last activities',
  application: 'Application',
  dateTime: 'Date & Time',
  user: 'User',
  notDefined: 'Not defined'
};

export const LastActivities: FC<LastActivitiesProps> = (props) => {
  const {
    id,
    dataTestId,
    className,
    style,
    translations = defaultTranslations,
    lastActivities,
    isLoading,
    onSeeDetails
  } = props;

  const loadingItems = [1, 2, 3, 4];

  const emptyLastActivities: LastActivitiesItems[] = [{}];

  return (
    <Card
      className={cn(
        className,
        isLoading ? styles['card--loading'] : styles.card
      )}
      style={style}
      id={id}
      dataTestId={dataTestId}
    >
      <div className={styles.header}>
        <Typography variant="h5">{translations?.lastActivities}</Typography>

        <div
          className={styles.details}
          data-testid="details-icon"
          onClick={onSeeDetails && onSeeDetails}
        >
          <Tooltip content={translations.seeDetails}>
            <Icon size="s" name="chevrons-right" />
          </Tooltip>
        </div>
      </div>

      <div className={styles['scroll-container']}>
        <div className={styles['min-width']}>
          <div className={styles.grid}>
            <Typography variant="system-heading" color={colors.gray40Color}>
              {translations?.application}
            </Typography>
            <Typography variant="system-heading" color={colors.gray40Color}>
              {translations?.dateTime}
            </Typography>
            <Typography variant="system-heading" color={colors.gray40Color}>
              {translations?.user}
            </Typography>
          </div>

          <Divider type="thin" className={offsets['pt-8']} />

          {!isLoading &&
            (lastActivities && lastActivities?.length > 0
              ? lastActivities
              : emptyLastActivities
            )?.map((log, key) => {
              return (
                <div key={key} className={cn(offsets['mt-16'], styles.grid)}>
                  <Typography variant="body-4" element="div">
                    {log?.application || translations.notDefined}
                  </Typography>

                  <Typography variant="body-4" element="div">
                    {log?.date ? (
                      <span>
                        {formatDate(log?.date)} <br />
                        {formatTime(log?.date)}
                      </span>
                    ) : (
                      translations.notDefined
                    )}
                  </Typography>

                  <Typography
                    className={cn(common['no-text-transform'])}
                    variant="body-4"
                    element="div"
                  >
                    {log?.email || translations.notDefined}
                  </Typography>
                </div>
              );
            })}

          {isLoading &&
            loadingItems.map((el) => (
              <div
                key={el}
                className={cn(offsets['mt-16'], offsets['pb-18'], styles.grid)}
              >
                <SkeletonPlaceholder width={100} height={18} />
                <SkeletonPlaceholder width={90} height={18} />
                <SkeletonPlaceholder width={167} height={18} />
              </div>
            ))}
        </div>
      </div>
    </Card>
  );
};

LastActivities.displayName = 'LastActivities';
