import { Icon } from '@components/Icon';
import cn from 'classnames';
import React, { FC } from 'react';
import styles from './CloseControl.module.scss';

export interface CloseControlProps {
  className?: string;
  onClick?: () => void;
}

export const CloseControl: FC<CloseControlProps> = (props) => {
  const { className, onClick } = props;
  return (
    <div className={cn(styles.wrapper, className)} onClick={onClick}>
      <Icon name="close" size="m" />
    </div>
  );
};

CloseControl.displayName = 'CloseControl';
