import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const AlertIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.528 2.28306C10.9773 2.0301 11.4842 1.89722 11.9998 1.89722C12.5154 1.89722 13.0223 2.0301 13.4716 2.28306C13.9209 2.53601 14.2974 2.90049 14.5648 3.34133L14.5677 3.34608L23.0376 17.4861L23.0458 17.4999C23.3077 17.9535 23.4463 18.4678 23.4478 18.9917C23.4493 19.5155 23.3135 20.0306 23.0541 20.4857C22.7947 20.9408 22.4207 21.32 21.9692 21.5857C21.5177 21.8513 21.0046 21.9941 20.4808 21.9999L20.4698 22L3.51879 22C2.99498 21.9942 2.48182 21.8513 2.03035 21.5857C1.57887 21.32 1.20483 20.9408 0.945426 20.4857C0.686022 20.0306 0.550303 19.5155 0.55177 18.9917C0.553236 18.4678 0.691839 17.9535 0.953786 17.4999L0.961909 17.4861L9.43476 3.34132C9.70217 2.90048 10.0787 2.53601 10.528 2.28306ZM11.9998 3.89722C11.8279 3.89722 11.6589 3.94151 11.5092 4.02583C11.3601 4.10977 11.235 4.23054 11.146 4.3766L2.68246 18.5058C2.59729 18.6556 2.55224 18.8249 2.55176 18.9973C2.55127 19.1719 2.59651 19.3436 2.68298 19.4953C2.76945 19.647 2.89413 19.7734 3.04462 19.8619C3.1938 19.9497 3.36317 19.9973 3.53617 20H20.4634C20.6364 19.9973 20.8058 19.9497 20.9549 19.8619C21.1054 19.7734 21.2301 19.647 21.3166 19.4953C21.403 19.3436 21.4483 19.1719 21.4478 18.9973C21.4473 18.8249 21.4023 18.6556 21.3171 18.5058L12.8548 4.37859C12.8544 4.37792 12.854 4.37726 12.8536 4.3766C12.7645 4.23054 12.6395 4.10977 12.4904 4.02583C12.3406 3.94151 12.1716 3.89722 11.9998 3.89722ZM11.9998 7.99996C12.5521 7.99996 12.9998 8.44768 12.9998 8.99996V13C12.9998 13.5522 12.5521 14 11.9998 14C11.4475 14 10.9998 13.5522 10.9998 13V8.99996C10.9998 8.44768 11.4475 7.99996 11.9998 7.99996ZM10.9998 17C10.9998 16.4477 11.4475 16 11.9998 16H12.0098C12.5621 16 13.0098 16.4477 13.0098 17C13.0098 17.5522 12.5621 18 12.0098 18H11.9998C11.4475 18 10.9998 17.5522 10.9998 17Z"
      fill={props.color}
    />
  </SvgIcon>
);

AlertIcon.displayName = 'AlertIcon';
