import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@components';

export const UpdateIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.22695 0.396167C10.8497 -0.0732026 12.5648 -0.123858 14.2124 0.248927C15.86 0.621713 17.3863 1.40579 18.6489 2.52799C19.9115 3.6502 20.8692 5.07396 21.4327 6.66642C21.617 7.18707 21.3442 7.75849 20.8236 7.94273C20.3029 8.12697 19.7315 7.85426 19.5473 7.33361C19.0965 6.05964 18.3303 4.92064 17.3202 4.02287C16.3101 3.12511 15.0891 2.49785 13.771 2.19962C12.453 1.90139 11.0808 1.94191 9.78267 2.31741C8.4845 2.69291 7.30261 3.39113 6.34728 4.34694C6.3399 4.35434 6.33239 4.36161 6.32478 4.36877L3.52455 7.00001H7C7.55228 7.00001 8 7.44773 8 8.00001C8 8.5523 7.55228 9.00002 7 9.00002H1C0.447715 9.00002 0 8.5523 0 8.00001V2.00001C0 1.44773 0.447715 1.00001 1 1.00001C1.55228 1.00001 2 1.44773 2 2.00001V5.68815L4.9441 2.92172C6.13626 1.73279 7.60939 0.864047 9.22695 0.396167ZM16 12C16 11.4477 16.4477 11 17 11H23C23.5523 11 24 11.4477 24 12V18C24 18.5523 23.5523 19 23 19C22.4477 19 22 18.5523 22 18V14.3119L19.0559 17.0783C17.8637 18.2673 16.3906 19.136 14.7731 19.6039C13.1503 20.0732 11.4352 20.1239 9.7876 19.7511C8.14002 19.3783 6.61373 18.5942 5.35114 17.472C4.08854 16.3498 3.1308 14.9261 2.56728 13.3336C2.38304 12.813 2.65576 12.2415 3.1764 12.0573C3.69705 11.8731 4.26848 12.1458 4.45272 12.6664C4.90353 13.9404 5.66972 15.0794 6.6798 15.9772C7.68987 16.8749 8.91091 17.5022 10.229 17.8004C11.547 18.0986 12.9192 18.0581 14.2173 17.6826C15.5155 17.3071 16.6974 16.6089 17.6527 15.6531C17.6572 15.6486 17.6616 15.6442 17.6662 15.6399C17.6692 15.637 17.6722 15.6341 17.6752 15.6313L20.4755 13H17C16.4477 13 16 12.5523 16 12Z"
      fill={props.color}
    />
  </SvgIcon>
);

UpdateIcon.displayName = 'UpdateIcon';
