import React, { AnchorHTMLAttributes, forwardRef } from 'react';
import styles from './Divider.module.scss';
import { DividerType } from './dataTypes';
import { ComponentProps } from '@components/dataTypes';
import classNames from 'classnames';

export interface DividerProps
  extends ComponentProps,
    AnchorHTMLAttributes<HTMLElement> {
  type?: DividerType;
}

export type DividerRef = HTMLHRElement;

export const Divider = forwardRef<DividerRef, DividerProps>((props, ref) => {
  const { type = 'bold', className, id, style, dataTestId } = props;

  return (
    <hr
      id={id}
      ref={ref}
      style={style}
      className={classNames(className, styles[`divider--${type}`])}
      data-testid={dataTestId}
    />
  );
});

Divider.displayName = 'Divider';
