import {
  Button,
  Card,
  Chevron,
  ComponentProps,
  SkeletonPlaceholder,
  Typography
} from '@components';
import CardLoader from '@components/InformationСard/components/DataChoice/Components/CardLoader';
import { colors, offsets } from '@componentsStyles';
import { formatDate } from '@componentsUtils';
import cn from 'classnames';
import React, { FC, ReactElement } from 'react';
import styles from './DataChoice.module.scss';
import { DataChoiceCards, ProvidersImgs } from './dataTypes';

const NewsecIcon = require('@assets/dataProviders/newsec.svg');
const KTIIcon = require('@assets/dataProviders/KTI.svg');
const DefaultIcon = require('@assets/dataProviders/default.svg');

const dataProvidersImg: ProvidersImgs = {
  newsec: NewsecIcon,
  'KTI Industrial': KTIIcon,
  default: DefaultIcon
};

export interface DataChoiceProps extends ComponentProps {
  cardTitle: string;
  id: string;
  dataVariants: DataChoiceCards[];
  actualDate: Date;
  isLoading?: boolean;
  onCardClick?: (key: string) => void;
  onDataProviderClick?: (provider: string) => void;
  onKpiSetClick?: (set: string, provider: string) => void;
  onRequestClick?: (name: string) => void;
  isTrial?: boolean;
}

export const DataChoice: FC<DataChoiceProps> = (props) => {
  const {
    cardTitle,
    id,
    className,
    style,
    dataTestId,
    dataVariants,
    actualDate,
    isLoading,
    onCardClick,
    onDataProviderClick,
    onKpiSetClick,
    onRequestClick,
    isTrial
  } = props;

  const onDataOptionClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    provider: string
  ) => {
    e.preventDefault();
    e.stopPropagation();
    onDataProviderClick(provider);
  };
  const onKpiClick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    set: string,
    provider: string
  ) => {
    e.preventDefault();
    e.stopPropagation();
    onKpiSetClick(set, provider);
  };

  const onReqClick = (e: React.MouseEvent<HTMLElement>, name: string) => {
    e.preventDefault();
    e.stopPropagation();
    onRequestClick(name);
  };

  return (
    <>
      <Card
        className={cn(
          className,
          isLoading ? styles['card--loading'] : styles.card
        )}
        style={style}
        id={id}
        data-testid={dataTestId}
      >
        <div className={styles.header} onClick={() => onCardClick(id)}>
          {!isLoading ? (
            <Typography variant="h5" className={styles['header-title']}>
              {cardTitle.replace(/_/g, ' ')}
            </Typography>
          ) : (
            <SkeletonPlaceholder
              width={100}
              height={20}
              className={offsets['mb-4']}
            />
          )}
          {!isLoading ? (
            <Typography
              variant="chevron"
              color={colors.gray60Color}
              className={offsets['ml-30']}
            >
              {actualDate ? formatDate(actualDate) : 'dd.mm.year'}
            </Typography>
          ) : (
            <SkeletonPlaceholder width={50} height={10} />
          )}
        </div>

        {!isLoading &&
          dataVariants?.map(({ id, options, available, name }) => {
            return (
              <div
                key={id}
                className={cn(styles['variant-wrapper'], {
                  [styles['variant-wrapper--unavailable']]: !available
                })}
                onClick={available ? (e) => onDataOptionClick(e, name) : null}
              >
                <div className={styles['variant-wrapper-header']}>
                  <div
                    className={styles['variant-wrapper-header-logo-container']}
                  >
                    <img
                      src={dataProvidersImg[name] ?? dataProvidersImg.default}
                      alt="providerLogo"
                      className={cn(styles['variant-wrapper-header-image'], {
                        [styles['variant-wrapper-header-image--unavailable']]:
                          !available
                      })}
                    />
                    {isTrial && <Chevron>Trial version</Chevron>}
                  </div>
                  {(!available || isTrial) && (
                    <div
                      className={cn(styles['variant-wrapper-unlock'], {
                        [styles['variant-wrapper-full-access']]: isTrial
                      })}
                    >
                      <Button
                        type="fourth"
                        icon={isTrial ? 'creditcard' : 'unlock'}
                        onClick={(e) => {
                          onReqClick(e, name);
                        }}
                      >
                        {`${isTrial ? 'Buy Full version' : 'Request access'}`}
                      </Button>
                    </div>
                  )}
                </div>
                <div className={styles['options-wrapper']}>
                  {options.map(
                    ({
                      key,
                      title
                    }: {
                      key: string;
                      title: string;
                    }): ReactElement => (
                      <Typography
                        key={key}
                        variant="system-heading-2"
                        className={cn(styles['options-wrapper-item'], {
                          [styles['options-wrapper-item--unavailable']]:
                            !available
                        })}
                      >
                        <span
                          onClick={
                            available ? (e) => onKpiClick(e, key, name) : null
                          }
                          key={key}
                        >
                          {title}
                        </span>
                      </Typography>
                    )
                  )}
                </div>
              </div>
            );
          })}
        {isLoading && <CardLoader />}
      </Card>
    </>
  );
};

DataChoice.displayName = 'DataChoice';
