import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const StatusIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <use
      href="#circleSymbol"
      width="6"
      height="6"
      x="50%"
      y="50%"
      transform="translate(-3 -3)"
    />
    <symbol id="circleSymbol" viewBox="0 0 6 6">
      <circle cx="3" cy="3" r="3" fill={props.color} />
    </symbol>
  </SvgIcon>
);

StatusIcon.displayName = 'StatusIcon';
