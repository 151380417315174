import {
  ComponentProps,
  Icon,
  TableAction,
  TableRow,
  Typography,
  cellStyles
} from '@components';
import { TableColumn } from '@components/Table/dataTypes';
import { offsets } from '@componentsStyles';
import cn from 'classnames';
import React, { FC, Fragment, useLayoutEffect, useMemo, useState } from 'react';
import styles from './TableRowMenu.module.scss';
//todo replace to @ !
import { Position } from '../../../../interfaces';

export interface TableRowMenuProps extends ComponentProps {
  actions: TableAction[];
  row: TableRow;
  actionColumn: TableColumn;
  tableScrollTop: number;
  isScrollable?: boolean;
}

export const TableRowMenu: FC<TableRowMenuProps> = (props) => {
  const {
    actions,
    className,
    tableScrollTop,
    actionColumn,
    isScrollable,
    row,
    id,
    style
  } = props;

  const menuHeight = useMemo(() => {
    // string height is 20 px and gap is 40 px
    // we must calculate it to show before mount
    return actions?.length * 20 + (actions?.length + 1) * 40;
  }, [actions?.length]);

  const [rowOffset, setRowOffset] = useState<number>(0);

  const menuPosition: Position = useMemo(() => {
    if (!rowOffset || !menuHeight || rowOffset < menuHeight) {
      return Position.Top;
    }
    return Position.Bottom;
  }, [rowOffset, menuHeight]);

  useLayoutEffect(() => {
    const rowElement = document?.getElementById(`row-${row?.id}`);
    if (rowElement) {
      setRowOffset(rowElement.offsetTop - tableScrollTop);
    }
  }, [tableScrollTop]);

  const onActionClick = (actionIdx: number, row: TableRow) => {
    if (actions[actionIdx]?.onClick && !actions[actionIdx].disabled) {
      actions[actionIdx].onClick(row);
    }
  };

  return (
    <td
      valign="top"
      className={cn(className, styles['icon-block'])}
      id={id}
      style={{
        ...cellStyles({
          column: actionColumn,
          isScrollable
        }),
        ...style
      }}
    >
      {!row.hideActions && (
        <Fragment>
          {actions?.length === 1 && (
            <Icon
              onClick={() => onActionClick(0, row)}
              className={styles.icon}
              size="s"
              name={actions[0].icon}
            ></Icon>
          )}
          {actions?.length > 1 && (
            <Fragment>
              <Icon className={styles.icon} name="more" size="m" />
              <div
                className={styles['menu-wrap']}
                style={{
                  top:
                    menuPosition === Position.Top
                      ? '44px'
                      : `-${menuHeight - 5}px`
                }}
                id="ui-menu"
              >
                <ul
                  className={
                    menuPosition === Position.Top
                      ? styles['menu--top']
                      : styles['menu--bottom']
                  }
                >
                  {actions?.map((action, actionKey) => {
                    return (
                      <li
                        onClick={() => onActionClick(actionKey, row)}
                        key={actionKey}
                        className={
                          action.disabled
                            ? styles['menu-item--disabled']
                            : styles['menu-item']
                        }
                      >
                        <Icon
                          className={offsets['mr-10']}
                          size="m"
                          name={action.icon}
                        ></Icon>
                        <Typography
                          style={{ width: 'max-content' }}
                          variant="body-4"
                        >
                          {action.title}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </td>
  );
};

TableRowMenu.displayName = 'TableRowMenu';
