import { DASH } from '@constants';
import { logger } from '@componentsUtils/logger';

export type Currency = 'RUB' | 'USD' | 'EUR';

export const formatCurrency = (
  currency: Currency,
  number: number,
  fractionDigits = 2,
  locale = 'ru'
): string => {
  if (!(number && typeof number === 'number' && !isNaN(number))) {
    number = 0;
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency || 'EUR',
    maximumFractionDigits: fractionDigits || 0,
    minimumFractionDigits: fractionDigits || 0
  })
    .format(number)
    .replace(',', '.');
};

export const formatNumber = (
  number: number,
  fractionDigits = 0,
  locale = 'ru'
): string => {
  if (!(number && typeof number === 'number' && !isNaN(number))) {
    return '0';
  }
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: fractionDigits || 0,
    maximumFractionDigits: fractionDigits || 0
  })
    .format(number)
    .replace(',', '.');
};

export const formatDate = (date: Date | string): string => {
  if (!date) return DASH;

  if (typeof date === 'string') {
    date = new Date(date);
  }

  const userTimeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateFormatter = new Intl.DateTimeFormat('ru', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: userTimeZone
  });
  return dateFormatter.format(date);
};

export const formatTime = (date: Date | string): string => {
  if (!date) return DASH;

  if (typeof date === 'string') {
    date = new Date(date);
  }

  const userTimeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateFormatter = new Intl.DateTimeFormat('ru', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: userTimeZone,
    timeZoneName: 'longOffset'
  } as Intl.DateTimeFormatOptions);

  const formattedDate = dateFormatter.format(date);
  const [time, timezone] = formattedDate.split('GMT');

  return `${time}(UTC${timezone})`;
};

export const getCurrencySign = (currency: Currency): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'EUR',
    currencyDisplay: 'narrowSymbol'
  }).format(0)[0];
};

export const getMonthName = (date: Date, locale = 'en') => {
  return new Intl.DateTimeFormat(locale, { month: 'long' }).format(date);
};

export const convertBillingFrequencyToText = (
  billingFrequency: string
): string => {
  switch (billingFrequency) {
    case 'MONTHLY': {
      return 'monthly';
    }
    case 'QUARTERLY': {
      return 'quarterly';
    }
    case 'ANNUALLY': {
      return 'annually';
    }
    default: {
      logger.warn(
        `billingFrequency ${billingFrequency} is not defined in license page`
      );
      return DASH;
    }
  }
};

export const convertPaymentMethodToText = (paymentMethod: string): string => {
  //todo add translations or not?
  switch (paymentMethod) {
    case 'BEFORE': {
      return 'Prepayment';
    }
    case 'MIDDLE': {
      return 'Middlepayment';
    }
    case 'AFTER': {
      return 'Postpayment';
    }
    default: {
      logger.warn(
        `paymentMethod ${paymentMethod} is not defined in license page`
      );
      return DASH;
    }
  }
};
