import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  ComponentProps,
  PriceRuleType,
  Typography
} from '@components';
import { PRICING_OPTIONS } from './constants';
import styles from './PricingList.module.scss';
import { colors, offsets } from '@componentsStyles';
import { PricingListHeader } from './components/PricingListHeader';
import { PricingData, PricingListTranslations } from './dataTypes';
import { PricingListRow } from './components/PricingListRow';
import { EntityName } from '@interfaces';
import { Currency, formatNumber } from '@componentsUtils/format';
import { PricingInput } from './components/PricingInput';
import {
  PricingPreview,
  PricingPreviewData
} from './components/PricingPreview';
import {
  calculatePricingPerUser,
  priceDifference
} from '@components/PricingList/utils';

export interface PricingListProps extends ComponentProps {
  /** Pricing data */
  pricing: PricingData[];
  /** Function change pricing data in the parent component */
  onChangePricing: (value: PricingData[]) => void;
  /** Currency for input values */
  currency: Currency;
  /** Price per user used in the second table */
  pricePerUser?: number;
  /** Function change price per user in the parent component */
  onChangePricePerUser?: (value: number) => void;
  /** Type of pricing */
  priceRuleType?: PriceRuleType;
  /** Function to change type of pricing */
  onChangePriceRuleType?: (value: PriceRuleType) => void;
  /** Previous pricing data to compare and calculate difference percent */
  prevPricing?: PricingData[];
  /** Previous value of price per user to compare and calculate difference percent */
  prevPricePerUser?: number;
  /** Previous pricing type */
  prevPriceRuleType?: PriceRuleType;
  /** If the pricing in disabled state */
  disabled?: boolean;
  /** Text to show in the alert before delete pricing */
  deleteAlertText?: string;
  /** Organization name to show in pricing preview */
  organizationName?: string;
  /** Billing Start Date to show in pricing preview */
  billingStartDate?: string;
  /** License name to show in pricing preview */
  licenseName?: string;
  /** Translations object */
  translations?: PricingListTranslations;

  /** Function for delete/clear current pricing */
  onDelete?(): void;
}

const defaultTranslations = {
  delete: 'Delete',
  cancel: 'Cancel',
  submit: 'Submit',
  users: 'Users',
  preview: 'Preview',
  priceMonthly: 'Price / monthly',
  pricePerUserMonthly: 'Price per user / monthly',
  pricingPreview: 'Pricing preview',
  organization: 'Organization',
  license: 'License',
  billingStartDate: 'Billing start date',
  priceRuleType: 'TOTAL_PRICE'
};

export const PricingList: FC<PricingListProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    disabled,
    onDelete,
    pricing,
    prevPricing,
    prevPriceRuleType = PRICING_OPTIONS.TOTAL_PRICE,
    priceRuleType = PRICING_OPTIONS.TOTAL_PRICE,
    onChangePricing,
    pricePerUser,
    onChangePricePerUser,
    prevPricePerUser,
    currency,
    onChangePriceRuleType,
    organizationName,
    billingStartDate,
    deleteAlertText = 'Are you sure you want to delete pricing?',
    licenseName,
    translations = defaultTranslations
  } = props;

  const maxUserAmount = 200;
  const usersAmountOptions: number[] = Array.from(
    { length: maxUserAmount },
    (_, i) => i + 1
  );

  const pricingOptions: EntityName[] = [
    {
      uuid: PRICING_OPTIONS.TOTAL_PRICE,
      name: translations.priceMonthly
    },
    {
      uuid: PRICING_OPTIONS.PRICE_PER_USER,
      name: translations.pricePerUserMonthly
    }
  ];

  const [userAmountAvailableOptions, setUserAmountAvailableOptions] = useState<
    number[]
  >([]);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);

  const calculateAvailablePricingOptions = (): number[] => {
    const unavailableOptions = pricing?.map((el) => el.numberOfUsers);
    const res: number[] = [];

    usersAmountOptions?.forEach((el) => {
      if (!unavailableOptions?.includes(el)) {
        res.push(el);
      }
    });
    return res;
  };

  useEffect(() => {
    setUserAmountAvailableOptions(calculateAvailablePricingOptions());
  }, [pricing]);

  function changeItemAmount(oldUserAmount: number, numberOfUsers: number) {
    const pricingItemIndex = pricing?.findIndex(
      (el) => el?.numberOfUsers === oldUserAmount
    );

    if (pricingItemIndex !== -1) {
      const newPricingData = [...pricing];
      newPricingData[pricingItemIndex].numberOfUsers = numberOfUsers;
      onChangePricing(newPricingData);
    } else {
      onChangePricing([...pricing, { numberOfUsers: numberOfUsers }]);
    }
  }

  function changeItemPrice(numberOfUsers: number, price: number) {
    const pricingItemIndex = pricing?.findIndex(
      (el) => el?.numberOfUsers === numberOfUsers
    );

    if (pricingItemIndex !== -1) {
      const newPricingData = [...pricing];
      newPricingData[pricingItemIndex].price = price;
      onChangePricing(newPricingData);
    }
  }

  function deleteRow(numberOfUsers: number) {
    if (pricing?.length > 1) {
      const newItems = pricing?.filter(
        (el) => el?.numberOfUsers !== numberOfUsers
      );

      onChangePricing([...newItems]);
    }
  }

  const addRow = () => {
    const lastPricingOption: number =
      pricing[pricing?.length - 1].numberOfUsers;

    if (isAddButtonActive) {
      onChangePricing([...pricing, { numberOfUsers: lastPricingOption + 1 }]);
    }
  };

  const isAddButtonActive = useMemo(() => {
    if (pricing) {
      const lastPricingOption: number =
        pricing[pricing?.length - 1]?.numberOfUsers;
      const lastUserAmountOption: number =
        userAmountAvailableOptions[userAmountAvailableOptions.length - 1];
      return lastUserAmountOption > lastPricingOption;
    }
    return true;
  }, [pricing, userAmountAvailableOptions]);

  const deleteButtonWidth = () => {
    const clientWidth = document.getElementById('delete-button')?.clientWidth;
    if (!clientWidth) return 0;
    return clientWidth;
  };

  const deleteButtonOffset = useMemo(() => {
    return -deleteButtonWidth();
  }, [deleteButtonWidth]);

  const width = useMemo(() => {
    return `calc(100% - ${deleteButtonWidth()}px)`;
  }, [deleteButtonWidth]);

  const maxAmount = useMemo(() => {
    if (pricing) {
      const pricingDataCopy = [...pricing];
      const data = pricingDataCopy
        ?.sort((a, b) => a?.numberOfUsers - b?.numberOfUsers)
        ?.reverse();
      return data[0]?.numberOfUsers + 1;
    }
  }, [pricing]);

  const filterUserAmountAvailableOptions = (idx: number) => {
    const prev = pricing[idx - 1];
    const next = pricing[idx + 1];

    if (!prev && !next) {
      return userAmountAvailableOptions;
    }

    if (!prev) {
      return userAmountAvailableOptions?.filter(
        (el) => el < next.numberOfUsers
      );
    }

    if (!next) {
      return userAmountAvailableOptions?.filter(
        (el) => el > prev.numberOfUsers
      );
    }

    return userAmountAvailableOptions?.filter(
      (el) => el > prev?.numberOfUsers && el < next.numberOfUsers
    );
  };

  useEffect(() => {
    if (pricing.length === 0) {
      onChangePricing([{ numberOfUsers: 1 }]);
    }
  }, [pricing]);

  const pricePerUserPercent = useMemo(() => {
    if (pricePerUser && prevPricePerUser) {
      return priceDifference(prevPricePerUser, pricePerUser);
    }
    return null;
  }, [prevPricePerUser, pricePerUser]);

  const [pricingPerUser, setPricingPerUser] = useState<PricingPreviewData[]>();

  useEffect(() => {
    setPricingPerUser(
      calculatePricingPerUser(
        pricing,
        pricePerUser,
        maxUserAmount,
        priceRuleType
      )
    );
  }, [pricing, pricePerUser, maxUserAmount]);

  const prevPricingPerUser: PricingPreviewData[] = useMemo(
    () =>
      calculatePricingPerUser(
        prevPricing,
        prevPricePerUser,
        maxUserAmount,
        prevPriceRuleType
      ),
    [prevPricing, prevPricePerUser, maxUserAmount, prevPriceRuleType]
  );

  //todo fix dependencies
  const compareMap = useMemo(() => {
    return pricingPerUser?.map((el) => {
      if (!prevPricingPerUser || !pricingPerUser) {
        return null;
      }

      const prevPricingEl = prevPricingPerUser[el.numberOfUsers - 1];
      const newPricingEl = pricingPerUser[el.numberOfUsers - 1];

      if (priceRuleType === PRICING_OPTIONS.PRICE_PER_USER) {
        return priceDifference(
          prevPricingEl?.pricePerUserMonthly,
          newPricingEl?.pricePerUserMonthly
        );
      }
      return priceDifference(
        prevPricingEl?.priceMonthly,
        newPricingEl?.priceMonthly
      );
    });
  }, [pricingPerUser, prevPricingPerUser, priceRuleType]);

  return (
    <div
      className={className}
      style={{ ...style, width: width }}
      id={id}
      data-testid={dataTestId}
    >
      <PricingListHeader
        title={translations.users}
        disabled={disabled}
        pricingOptions={pricingOptions}
        currentOptionUuid={priceRuleType}
        setCurrentOptionUuid={onChangePriceRuleType}
      />

      <div className={styles.pricing}>
        {pricing?.map((pricingEl, key) => {
          return (
            <PricingListRow
              className={disabled ? styles['row--disabled'] : styles.row}
              key={key}
              numberOfUsers={pricingEl?.numberOfUsers}
              price={pricingEl?.price}
              percent={
                compareMap?.length > pricingEl?.numberOfUsers
                  ? compareMap[pricingEl?.numberOfUsers - 1]
                  : null
              }
              usersAmountOptions={filterUserAmountAvailableOptions(key)}
              changePricingItemAmount={changeItemAmount}
              disabled={disabled}
              deleteButtonOffset={deleteButtonOffset}
              changeItemPrice={changeItemPrice}
              deleteRow={deleteRow}
              currency={currency}
              pricingAmount={pricing.length}
            />
          );
        })}

        <Button
          disabled={disabled || !isAddButtonActive}
          type="fifth"
          icon="plus"
          className={styles.add}
          onClick={addRow}
        />
      </div>

      <PricingListHeader
        title={translations.users}
        disabled={true}
        className={offsets['mt-80']}
        pricingOptions={[pricingOptions[1]]}
        currentOptionUuid={'PRICE_PER_USER'}
      />

      <div
        className={
          disabled ? styles['more-users--disabled'] : styles['more-users']
        }
      >
        <Typography
          color={disabled ? colors.gray40Color : colors.gray100Color}
          variant="number-1"
        >
          {maxAmount}+
        </Typography>

        <div>
          <PricingInput
            disabled={disabled}
            value={pricePerUser}
            onChange={onChangePricePerUser}
            currency={currency}
          />

          {typeof pricePerUserPercent === 'number' &&
            !isNaN(pricePerUserPercent) && (
              <Typography
                className={styles.percent}
                element="div"
                variant={'label-2'}
              >
                {pricePerUserPercent >= 0 && '+ '}
                {pricePerUserPercent < 0 && '- '}
                {`${formatNumber(Math.abs(pricePerUserPercent), 2)} %`}
              </Typography>
            )}
        </div>
      </div>

      <div className={styles.preview}>
        <Button
          onClick={() => setIsPreviewOpen(true)}
          type="fourth"
          icon="eye-open"
          disabled={!pricePerUser || pricing?.length === 0}
        >
          {translations.preview}
        </Button>

        <Button
          onClick={() => setIsAlertOpen(true)}
          type="fourth"
          icon="trash"
          disabled={disabled}
        >
          {translations.delete}
        </Button>
      </div>

      {isPreviewOpen && (
        <PricingPreview
          dataTestId={'preview'}
          pricing={pricing}
          isOpen={isPreviewOpen}
          onClose={() => setIsPreviewOpen(false)}
          maxUserAmount={maxUserAmount}
          maxAmountPrice={pricePerUser}
          translations={translations}
          currency={currency}
          organizationName={organizationName}
          billingStartDate={billingStartDate}
          licenseName={licenseName}
          priceRuleType={priceRuleType}
        />
      )}

      <Alert
        isOpen={isAlertOpen}
        onSubmit={onDelete}
        onClose={() => setIsAlertOpen(false)}
        cancelText={translations.cancel}
        submitText={translations.submit}
      >
        <div dangerouslySetInnerHTML={{ __html: deleteAlertText }} />
      </Alert>
    </div>
  );
};

PricingList.displayName = 'PricingList';
