import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const ToolIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5577 3.02796C16.0747 2.97339 15.5836 2.9892 15.1 3.07733C14.1 3.25952 13.1794 3.74211 12.4607 4.46081C11.742 5.1795 11.2594 6.10016 11.0772 7.10009C10.895 8.10001 11.0219 9.13172 11.4409 10.0577C11.6125 10.4369 11.5313 10.8828 11.237 11.1771L4.32696 18.0871C4.11667 18.2974 3.99854 18.5826 3.99854 18.88C3.99854 19.1774 4.11667 19.4626 4.32696 19.6729C4.53725 19.8832 4.82246 20.0013 5.11986 20.0013C5.41725 20.0013 5.70246 19.8832 5.91275 19.6729L12.8227 12.7629C13.1171 12.4686 13.5629 12.3873 13.9421 12.5589C14.8681 12.978 15.8998 13.1048 16.8997 12.9226C17.8997 12.7404 18.8203 12.2578 19.539 11.5392C20.2577 10.8205 20.7403 9.8998 20.9225 8.89987C21.0106 8.41622 21.0264 7.92514 20.9719 7.44218L18.3999 10.0142C18.026 10.3806 17.5234 10.5858 16.9999 10.5858C16.4763 10.5858 15.9737 10.3806 15.5999 10.0141L15.5927 10.0071L13.9857 8.40002C13.6192 8.02616 13.414 7.52349 13.414 6.99998C13.414 6.47647 13.6193 5.97384 13.9857 5.59998L13.9927 5.59284L16.5577 3.02796ZM14.7415 1.10972C16.1414 0.854655 17.5857 1.03228 18.8821 1.61892C19.1812 1.75426 19.3948 2.02777 19.4537 2.35073C19.5125 2.67369 19.4091 3.00496 19.177 3.23709L15.4141 6.99998L16.9999 8.58577L20.7627 4.82287C20.9949 4.59075 21.3261 4.48733 21.6491 4.54618C21.9721 4.60502 22.2456 4.81863 22.3809 5.11771C22.9676 6.41409 23.1452 7.85848 22.8901 9.25838C22.6351 10.6583 21.9594 11.9472 20.9532 12.9534C19.9471 13.9595 18.6581 14.6352 17.2583 14.8902C16.0908 15.103 14.8924 15.0147 13.7747 14.6393L7.32696 21.0871C6.7416 21.6724 5.94768 22.0013 5.11986 22.0013C4.29203 22.0013 3.49811 21.6724 2.91275 21.0871C2.32739 20.5017 1.99854 19.7078 1.99854 18.88C1.99854 18.0522 2.32739 17.2582 2.91275 16.6729L9.36051 10.2251C8.9851 9.10743 8.89688 7.90903 9.10959 6.74158C9.36466 5.34169 10.0403 4.05277 11.0465 3.0466C12.0526 2.04042 13.3416 1.36478 14.7415 1.10972Z"
      fill={props.color}
    />
  </SvgIcon>
);

ToolIcon.displayName = 'ToolIcon';
