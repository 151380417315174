import React, { Suspense } from 'react';
import './styles/index.scss';
import { App } from './App';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import './i18n';

render(
  <BrowserRouter>
    <ErrorBoundary>
      <Suspense fallback={<div />}>
        <App />
      </Suspense>
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById('root')
);
