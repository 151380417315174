import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import breakpoints from '@componentsStyles/breakpoints.scss';
import { ComponentProps } from '@components';
import styles from './Image.module.scss';
import { ImageType } from '@components/Image/dataTypes';
import { config } from '../../config';

const imagesUrl = `${config.cdnUrl}/frontend/libs/ui-kit/images/image-component`;
const imageArray = [
  {
    imageL: `${imagesUrl}/1-l.jpg`,
    imageM: `${imagesUrl}/1-m.jpg`,
    imageS: `${imagesUrl}/1-s.jpg`
  },
  {
    imageL: `${imagesUrl}/2-l.jpg`,
    imageM: `${imagesUrl}/2-m.jpg`,
    imageS: `${imagesUrl}/2-s.jpg`
  },
  {
    imageL: `${imagesUrl}/3-l.jpg`,
    imageM: `${imagesUrl}/3-m.jpg`,
    imageS: `${imagesUrl}/3-s.jpg`
  },
  {
    imageL: `${imagesUrl}/4-l.jpg`,
    imageM: `${imagesUrl}/4-m.jpg`,
    imageS: `${imagesUrl}/4-s.jpg`
  },
  {
    imageL: `${imagesUrl}/5-l.jpg`,
    imageM: `${imagesUrl}/5-m.jpg`,
    imageS: `${imagesUrl}/5-s.jpg`
  }
];

export interface ImageProps extends ComponentProps {
  src?: string;
  alt?: string;
  srcset?: string;
  sizes?: string;
  width?: number;
  height?: number;
  type?: ImageType;
}

const defaultSizes = `((max-width: ${breakpoints.sm}) 768px, (max-width: ${breakpoints.md}) 1272px, 1722px`;

export const Image: FC<ImageProps> = (props) => {
  const {
    className,
    id,
    style,
    dataTestId,
    src,
    alt,
    srcset,
    sizes = defaultSizes,
    width,
    height,
    type
  } = props;

  const [randomIndex, setRandomIndex] = useState<number>(0);

  useEffect(() => {
    if (!src) {
      setRandomIndex(getRandomInt(imageArray.length));
    }
  }, []);

  function getFormatClass(): string {
    switch (type) {
      case 'fullscreen':
        return styles.image_fullscreen;

      case 'half-screen':
        return styles.image_halfscreen;

      case 'contain':
        return styles.image_contain;

      default:
        return styles.image_default;
    }
  }

  const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * max);
  };

  const imageSrc = () => {
    return src || imageArray[randomIndex].imageS;
  };

  const imageSrcSet = () => {
    if (srcset) {
      return srcset;
    }
    if (!src) {
      const randomSrcset = imageArray[randomIndex];
      return `${randomSrcset.imageS} 768w, ${randomSrcset.imageM} 1272w, ${randomSrcset.imageL} 1722w`;
    }
  };

  return (
    <img
      id={id}
      style={style}
      data-testid={dataTestId}
      className={classNames(className, styles.image, getFormatClass())}
      src={imageSrc()}
      alt={alt}
      srcSet={imageSrcSet()}
      sizes={sizes}
      width={width}
      height={height}
    />
  );
};

Image.displayName = 'Image';
