import { Icon } from '@components/Icon';
import cn from 'classnames';
import React, { FC } from 'react';
import styles from './ZoomControls.module.scss';
import { ZoomControl } from './dataTypes';
export interface ZoomControlsProps {
  className?: string;
  map: google.maps.Map;
}

export const ZoomControls: FC<ZoomControlsProps> = (props) => {
  const { className, map } = props;

  const handleClick = (side: ZoomControl) => {
    if (map) {
      const zoom =
        side === ZoomControl.In ? map.getZoom() + 1 : map.getZoom() - 1;
      return map.setZoom(zoom);
    }
  };

  return (
    <div className={cn(styles.wrapper, className)}>
      <div onClick={() => handleClick(ZoomControl.In)}>
        <Icon name="plus" size="m" />
      </div>
      <div onClick={() => handleClick(ZoomControl.Out)}>
        <Icon name="minus" size="m" />
      </div>
    </div>
  );
};
