import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ComponentProps, Icon } from '@components';
import styles from './ScrollTopButton.module.scss';

export interface ScrollTopButtonProps extends ComponentProps {}

export const ScrollTopButton: FC<ScrollTopButtonProps> = (props) => {
  const { className, style, id, dataTestId } = props;

  const [shown, setShown] = useState<boolean>(false);

  const scrollListener = () => {
    if (window.scrollY > 100) {
      setShown(true);
    } else {
      setShown(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return (
    <a
      href="#"
      id={id}
      type="button"
      className={classNames(
        className,
        styles.button,
        !shown && styles['button--hidden']
      )}
      style={style}
      data-testid={dataTestId}
    >
      <Icon size="m" name="chevron-up" className={styles.icon} />
    </a>
  );
};

ScrollTopButton.displayName = 'ScrollTopButton';
