import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const ShieldIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6489 1.06367C11.8753 0.978776 12.1247 0.978776 12.3511 1.06367L20.3511 4.06367C20.7414 4.21003 21 4.58316 21 5V12C21 15.4464 18.7183 18.2003 16.6585 20.0026C15.6076 20.9221 14.5615 21.6408 13.78 22.1292C13.3882 22.3741 13.0603 22.5627 12.8281 22.6913C12.7119 22.7556 12.6194 22.805 12.5547 22.839C12.5223 22.856 12.4968 22.8692 12.4788 22.8784L12.4573 22.8894L12.4508 22.8926L12.4486 22.8937C12.4483 22.8939 12.4472 22.8944 12 22C11.5528 22.8944 11.5525 22.8943 11.5522 22.8941L11.5492 22.8926L11.5427 22.8894L11.5212 22.8784C11.5032 22.8692 11.4777 22.856 11.4453 22.839C11.3806 22.805 11.2881 22.7556 11.1719 22.6913C10.9397 22.5627 10.6118 22.3741 10.22 22.1292C9.43854 21.6408 8.39238 20.9221 7.3415 20.0026C5.28175 18.2003 3 15.4464 3 12V5C3 4.58316 3.25857 4.21003 3.64888 4.06367L11.6489 1.06367ZM12 22L11.5522 22.8941C11.8337 23.0349 12.1657 23.0352 12.4472 22.8944L12 22ZM12 20.8628C12.1916 20.7541 12.4367 20.6103 12.72 20.4333C13.4385 19.9842 14.3924 19.3279 15.3415 18.4974C17.2817 16.7997 19 14.5536 19 12V5.693L12 3.068L5 5.693V12C5 14.5536 6.71825 16.7997 8.6585 18.4974C9.60762 19.3279 10.5615 19.9842 11.28 20.4333C11.5633 20.6103 11.8084 20.7541 12 20.8628Z"
      fill={props.color}
    />
  </SvgIcon>
);

ShieldIcon.displayName = 'ShieldIcon';
