import React, { FC } from 'react';
import { ComponentProps, Typography } from '@components';
import classNames from 'classnames';
import styles from './Card.module.scss';
import { offsets } from '@componentsStyles';

export interface CardProps extends ComponentProps {
  disabled?: boolean;
  heading?: string;
  content?: string;
  onClick?(): void;
}
export const Card: FC<CardProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    disabled,
    onClick,
    children,
    heading,
    content
  } = props;

  const cardClasses = classNames(
    className,
    disabled ? styles['card--disabled'] : styles.card
  );

  return (
    <div
      className={cardClasses}
      style={style}
      id={id}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {heading && (
        <Typography className={content && offsets['pb-16']} variant="h5">
          {heading}
        </Typography>
      )}
      {content && <Typography variant="body-4">{content}</Typography>}
      {children}
    </div>
  );
};

Card.displayName = 'Card';
