import React, { useState, FC, useEffect, useMemo, Fragment } from 'react';
import {
  LayoutContainer,
  NotificationContainer,
  Header,
  HeaderApp,
  NavItem,
  offsets,
  HeaderAppNames,
  openStatusNotification
} from '@xq/ui-kit';
import { NavLink, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LayoutService } from './layout-service';
import { config } from '@config';
import styles from './Layout.module.scss';
import { getStatusNotificationTranslations } from '@services/notifications';
import { UserLayoutData } from './dateTypes';
import { redirectToSSOLoginPage } from '@services';

interface LayoutProps {}

const currentApp: HeaderApp = HeaderAppNames.ModelTree;

export const Layout: FC<LayoutProps> = () => {
  const { t, i18n } = useTranslation();
  const service: LayoutService = new LayoutService();
  const [userData, setUserData] = useState<UserLayoutData>(null);
  const [isAuthChecked, setIsAuthChecked] = useState<boolean>(false);

  const fullName = useMemo(() => {
    if (!userData) {
      return '';
    }
    const { firstName, lastName } = userData;

    if (firstName && lastName) {
      return firstName + ' ' + lastName;
    }
    if (firstName) {
      return firstName;
    }
    if (lastName) {
      return lastName;
    }
    return '';
  }, [userData]);

  async function fetchData() {
    try {
      const response = await service.fetchData();
      setUserData(response);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        message: t('notifications.fetchDataError'),
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function authCheck() {
    try {
      await service.authCheck();
      setIsAuthChecked(true);
    } catch (error) {
      setIsAuthChecked(false);
      const status = error.status;
      if (status === 401) {
        redirectToSSOLoginPage();
      } else {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: error?.status,
          error: {
            details: error?.details,
            code: error?.error,
            message: error?.message
          }
        });
      }
    }
  }

  async function logout() {
    try {
      await service.logout();
      redirectToSSOLoginPage();
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  useEffect(() => {
    authCheck().then(() => {
      fetchData();
    });
  }, []);

  useEffect(() => {
    if (userData?.languageIso2) {
      i18n.changeLanguage(userData?.languageIso2);
      if (localStorage.getItem('lang') !== userData?.languageIso2) {
        localStorage.setItem('lang', userData?.languageIso2);
      }
    }
  }, [userData?.languageIso2]);

  const profileLinks: NavItem[] = [
    {
      name: t('settings.userSettings'),
      path: config.accountFrontendUrl
    }
  ];

  return (
    <Fragment>
      {isAuthChecked && (
        <div className={styles.layout}>
          <Header
            className={styles.header}
            apps={userData?.apps}
            avatar={userData?.avatar}
            organizations={userData?.organizations}
            currentOrganizationId={userData?.currentOrganizationUuid}
            name={fullName}
            currentApp={currentApp}
            NavLink={NavLink}
            profileLinks={profileLinks}
            onSignOut={logout}
            translations={{
              signOut: t('routes.signOut')
            }}
          />
          <main>
            <LayoutContainer className={offsets['mt-60']}>
              <Outlet />
            </LayoutContainer>
          </main>
          <NotificationContainer />
        </div>
      )}
    </Fragment>
  );
};

Layout.displayName = 'Layout';
