import React from 'react';
import { useNavigate } from 'react-router-dom';

interface withNavigationProps {}

export const withNavigation =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  ({ ...props }: withNavigationProps) => (
    <Component {...(props as P)} navigate={useNavigate()} />
  );
