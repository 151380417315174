import React, { FC, Fragment } from 'react';
import {
  Button,
  Card,
  ComponentProps,
  DateIntervalType,
  DatePicker,
  Divider,
  Icon,
  IconNames,
  Locales,
  Select,
  SelectItem,
  Toggle,
  Typography
} from '@components';
import styles from './OmniLicenseCard.module.scss';
import commonStyles from '../../LicenseCard.module.scss';
import { colors, offsets } from '@componentsStyles';
import { LicenseFeature } from '@components/LicenseCard/dataTypes';
import { OmniLicenseTranslations } from './dataTypes';
import classNames from 'classnames';
import { LicenseApp } from '@components/LicenseCard';
import { LoadingLicenseCard } from '../LoadingLicenseCard';

export interface OmniLicenseCardProps extends ComponentProps {
  /** Necessary translations */
  translations: OmniLicenseTranslations;
  /** For which application the license is used */
  app: LicenseApp;
  /** License description */
  description: string;
  /** License features list */
  features: LicenseFeature[];
  /** On feature change function */
  onChangeFeature: (featureId: string, value: boolean) => void;
  /** On reset card */
  resetFeatures: () => void;
  /** Is it a trial version */
  isTrial: boolean;
  /** Set a trial version */
  setTrial: (e: boolean) => void;
  /** Language for the date-picker */
  locale: Locales;
  /** Expiry date if trial */
  expiryDate: Date;
  /** On change function for the date-picker */
  setExpiryDate: (date: Date) => void;
  /** Is a license active */
  isActive: boolean;
  /** Set license active */
  setIsActive: (value: boolean) => void;
  /** License type label for select */
  licenseTypeLabel?: string;
  /** License type (Investment Management / valuation / pro) */
  licenseType?: SelectItem;
  /** License types list */
  licenseTypes?: SelectItem[];
  /** Select license type */
  setLicenseType?: (value: SelectItem) => void;
  /** Is a card loading */
  isLoading?: boolean;
  useRemove?: boolean;

  /** Action for clicking the card */
  onClick?(): void;
}

export const OmniLicenseCard: FC<OmniLicenseCardProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    app,
    expiryDate,
    setExpiryDate,
    description,
    features,
    resetFeatures,
    onChangeFeature,
    isLoading,
    isTrial,
    translations,
    setTrial,
    onClick,
    licenseType,
    licenseTypes,
    setLicenseType,
    licenseTypeLabel,
    isActive,
    setIsActive,
    useRemove = true
  } = props;

  const onCardClick = () => {
    if (!isActive && setIsActive) {
      setIsActive(true);
    }

    if (onClick) {
      onClick();
    }
  };

  const typographyColor = isActive ? colors.gray100Color : colors.gray40Color;

  const logoName: IconNames = `logo-${app}`;

  const resetCard = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (isActive) {
      setExpiryDate(null);
      resetFeatures();
      setTrial(false);
      setIsActive(false);
    }
  };

  return (
    <Card
      className={classNames(styles.card, className)}
      style={style}
      id={id}
      onClick={onCardClick}
      data-testid={dataTestId}
    >
      {isLoading ? (
        <LoadingLicenseCard />
      ) : (
        <Fragment>
          {useRemove && (
            <div onClick={resetCard}>
              <Icon
                color="disabled"
                name={isActive ? 'trash' : 'plus'}
                size={'s'}
                className={classNames(styles['add-icon'], commonStyles.logo)}
              />
            </div>
          )}
          {useRemove && !isActive && (
            <Button
              type="fifth"
              icon="plus"
              className={styles['add-icon--hover']}
            />
          )}

          <Icon name={logoName} className={commonStyles.header} />

          <Typography
            element="div"
            variant="body-3"
            className={offsets['mb-20']}
          >
            {description}
          </Typography>
          <Divider
            type="thin"
            className={
              isActive
                ? commonStyles.divider
                : commonStyles['divider--disabled']
            }
          />

          <div className={styles.is_trial}>
            <Typography
              color={typographyColor}
              variant="body-4"
              className={offsets['mr-12']}
            >
              {translations?.isTrial}
            </Typography>
            <Toggle
              label={translations.isTrial}
              checked={isTrial}
              onChange={setTrial}
              disabled={!isActive}
            />
          </div>

          {isTrial && (
            <div className={offsets['mt-20']}>
              <DatePicker
                disablePrevDates
                datePickerValue={expiryDate}
                datePickerPlaceholder={translations.expiryDate}
                onDatePickerChange={setExpiryDate}
                type="day"
                dateIntervalType={DateIntervalType.Date}
              />
            </div>
          )}

          {licenseTypes?.length > 0 && (
            <Select
              className={offsets['mt-40']}
              disabled={!isActive}
              items={licenseTypes}
              selected={licenseType}
              label={licenseTypeLabel}
              onChange={setLicenseType}
            />
          )}

          {features?.length > 0 && (
            <>
              <Typography
                element="div"
                variant="system-heading"
                color={colors.gray40Color}
                className={offsets['mt-40']}
              >
                License features
              </Typography>

              <div className={styles.feature}>
                <div>
                  {features.map((feature) => {
                    return (
                      <div key={`label-${feature.id}`} className={styles.label}>
                        <Typography
                          color={typographyColor}
                          variant="body-4"
                          className={offsets['mr-12']}
                        >
                          {feature.label}
                        </Typography>
                      </div>
                    );
                  })}
                </div>
                <div>
                  {features?.map((feature) => {
                    return (
                      <div key={feature.id} className={offsets['mt-20']}>
                        <Toggle
                          label={feature.label}
                          disabled={!isActive}
                          checked={feature.value}
                          onChange={() =>
                            onChangeFeature(feature.id, !feature.value)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </Fragment>
      )}
    </Card>
  );
};

OmniLicenseCard.displayName = 'OmniLicenseCard';
