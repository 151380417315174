import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const BreakIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C11.4477 20 11 19.5523 11 19L11 5C11 4.44771 11.4477 4 12 4C12.5523 4 13 4.44771 13 5V19C13 19.5523 12.5523 20 12 20Z"
      fill={props.color}
    />
  </SvgIcon>
);

BreakIcon.displayName = 'BreakIcon';
