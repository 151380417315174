import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const LogoAccountIconDisabled: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 151 16" width={151} height={16}>
    <g clipPath="url(#clip0_9303_59773)">
      <path d="M0 0L2.66667 4L5.33333 0H0Z" fill="#DADADA" />
      <path d="M14.666 12L17.3327 8L19.9993 12H14.666Z" fill="#EEEEEE" />
      <path d="M12 0L14.6667 4L17.3333 0H12Z" fill="#B3B3B3" />
      <path d="M9.33398 4L12.0007 0L14.6673 4H9.33398Z" fill="#B3B3B3" />
      <path d="M9.33398 4L12.0007 8L14.6673 4H9.33398Z" fill="#EEEEEE" />
      <path d="M5.33398 8L8.00065 4L10.6673 8H5.33398Z" fill="#DADADA" />
      <path d="M5.33398 8L8.00065 12L10.6673 8H5.33398Z" fill="#8F8F8F" />
      <path d="M2.66602 12L5.33268 8L7.99935 12H2.66602Z" fill="#8F8F8F" />
      <path d="M14.666 12L17.3327 16L19.9993 12H14.666Z" fill="#EEEEEE" />
      <path d="M17.334 16L20.0007 12L22.6673 16H17.334Z" fill="#EEEEEE" />
      <path d="M2.66602 4L5.33268 8L7.99935 4H2.66602Z" fill="#DADADA" />
      <path d="M2.66602 4L5.33268 0L7.99935 4H2.66602Z" fill="#DADADA" />
      <path d="M12 8L14.6667 12L17.3333 8H12Z" fill="#EEEEEE" />
      <path d="M12 8L14.6667 4L17.3333 8H12Z" fill="#EEEEEE" />
      <path
        d="M100.624 9.392H96.9115L97.1515 8.384H100.352L100.624 9.392ZM98.8475 4.832L96.4315 12.112H95.1035L98.6715 2H99.0555L102.624 12.112H101.248L98.8475 4.832Z"
        fill="#121212"
      />
      <path
        d="M107.392 12.272C106.848 12.272 106.336 12.176 105.856 11.984C105.386 11.7813 104.97 11.472 104.608 11.056C104.256 10.64 103.978 10.112 103.776 9.472C103.573 8.832 103.472 8.06933 103.472 7.184C103.472 6.40533 103.546 5.728 103.696 5.152C103.845 4.56533 104.048 4.07467 104.304 3.68C104.57 3.27467 104.869 2.95467 105.2 2.72C105.541 2.48533 105.893 2.31467 106.256 2.208C106.629 2.10133 107.002 2.048 107.376 2.048C108.112 2.048 108.736 2.224 109.248 2.576C109.77 2.928 110.165 3.40267 110.432 4L109.408 4.592L109.264 4.656L109.216 4.544C109.237 4.45867 109.232 4.37867 109.2 4.304C109.168 4.22933 109.109 4.11733 109.024 3.968C108.768 3.63733 108.501 3.41333 108.224 3.296C107.957 3.17867 107.674 3.12 107.376 3.12C107.002 3.12 106.656 3.21067 106.336 3.392C106.016 3.56267 105.738 3.81867 105.504 4.16C105.269 4.50133 105.088 4.92267 104.96 5.424C104.842 5.91467 104.784 6.47467 104.784 7.104C104.784 7.712 104.848 8.26667 104.976 8.768C105.114 9.25867 105.301 9.68 105.536 10.032C105.77 10.384 106.042 10.656 106.352 10.848C106.672 11.04 107.013 11.136 107.376 11.136C107.642 11.136 107.893 11.0933 108.128 11.008C108.373 10.9227 108.597 10.7893 108.8 10.608C109.002 10.4267 109.178 10.2133 109.328 9.968L110.288 10.64C109.957 11.1947 109.557 11.6053 109.088 11.872C108.618 12.1387 108.053 12.272 107.392 12.272Z"
        fill="#121212"
      />
      <path
        d="M115.392 12.272C114.848 12.272 114.336 12.176 113.856 11.984C113.386 11.7813 112.97 11.472 112.608 11.056C112.256 10.64 111.978 10.112 111.776 9.472C111.573 8.832 111.472 8.06933 111.472 7.184C111.472 6.40533 111.546 5.728 111.696 5.152C111.845 4.56533 112.048 4.07467 112.304 3.68C112.57 3.27467 112.869 2.95467 113.2 2.72C113.541 2.48533 113.893 2.31467 114.256 2.208C114.629 2.10133 115.002 2.048 115.376 2.048C116.112 2.048 116.736 2.224 117.248 2.576C117.77 2.928 118.165 3.40267 118.432 4L117.408 4.592L117.264 4.656L117.216 4.544C117.237 4.45867 117.232 4.37867 117.2 4.304C117.168 4.22933 117.109 4.11733 117.024 3.968C116.768 3.63733 116.501 3.41333 116.224 3.296C115.957 3.17867 115.674 3.12 115.376 3.12C115.002 3.12 114.656 3.21067 114.336 3.392C114.016 3.56267 113.738 3.81867 113.504 4.16C113.269 4.50133 113.088 4.92267 112.96 5.424C112.842 5.91467 112.784 6.47467 112.784 7.104C112.784 7.712 112.848 8.26667 112.976 8.768C113.114 9.25867 113.301 9.68 113.536 10.032C113.77 10.384 114.042 10.656 114.352 10.848C114.672 11.04 115.013 11.136 115.376 11.136C115.642 11.136 115.893 11.0933 116.128 11.008C116.373 10.9227 116.597 10.7893 116.8 10.608C117.002 10.4267 117.178 10.2133 117.328 9.968L118.288 10.64C117.957 11.1947 117.557 11.6053 117.088 11.872C116.618 12.1387 116.053 12.272 115.392 12.272Z"
        fill="#121212"
      />
      <path
        d="M122.88 12.288C122.101 12.288 121.44 12.0907 120.896 11.696C120.362 11.3013 119.952 10.72 119.664 9.952C119.386 9.184 119.248 8.256 119.248 7.168C119.248 6.10133 119.386 5.18933 119.664 4.432C119.952 3.664 120.362 3.07733 120.896 2.672C121.44 2.256 122.101 2.048 122.88 2.048C123.658 2.048 124.314 2.256 124.848 2.672C125.381 3.07733 125.786 3.664 126.064 4.432C126.352 5.18933 126.496 6.10133 126.496 7.168C126.496 8.256 126.352 9.184 126.064 9.952C125.786 10.72 125.381 11.3013 124.848 11.696C124.314 12.0907 123.658 12.288 122.88 12.288ZM122.88 11.152C123.349 11.152 123.749 11.0027 124.08 10.704C124.421 10.3947 124.677 9.94667 124.848 9.36C125.029 8.76267 125.12 8.032 125.12 7.168C125.12 6.34667 125.029 5.64267 124.848 5.056C124.677 4.45867 124.421 4 124.08 3.68C123.749 3.36 123.349 3.2 122.88 3.2C122.41 3.2 122.005 3.36 121.664 3.68C121.322 4 121.061 4.45867 120.88 5.056C120.709 5.64267 120.624 6.34667 120.624 7.168C120.624 8.032 120.709 8.76267 120.88 9.36C121.061 9.94667 121.322 10.3947 121.664 10.704C122.005 11.0027 122.41 11.152 122.88 11.152Z"
        fill="#121212"
      />
      <path
        d="M130.88 12.304C129.802 12.304 128.981 11.984 128.416 11.344C127.861 10.6933 127.584 9.79733 127.584 8.656V2.144H129.072V2.272C129.008 2.336 128.965 2.40533 128.944 2.48C128.933 2.55467 128.928 2.68267 128.928 2.864V8.672C128.928 9.44 129.072 10.0533 129.36 10.512C129.648 10.96 130.16 11.184 130.896 11.184C131.632 11.184 132.144 10.96 132.432 10.512C132.73 10.0533 132.88 9.424 132.88 8.624V2.144H134.192V8.608C134.192 9.45067 134.053 10.144 133.776 10.688C133.498 11.232 133.109 11.6373 132.608 11.904C132.117 12.1707 131.541 12.304 130.88 12.304Z"
        fill="#121212"
      />
      <path
        d="M135.6 2.144H136.944L140.864 9.264L140.832 2.144H142.24V2.272C142.176 2.336 142.133 2.40533 142.112 2.48C142.101 2.55467 142.096 2.68267 142.096 2.864V12.112H140.944L136.848 4.56V12.112H135.6V2.144Z"
        fill="#121212"
      />
      <path
        d="M146.096 12.112V3.312H143.312V2.128H150.336V3.312H147.44V12.112H146.096Z"
        fill="#121212"
      />
      <path
        d="M27 12.112V2.128H33.24V3.28H28.296V6.32H32.376V7.504H28.296V10.96H33.192V12.112H27Z"
        fill="#121212"
      />
      <path
        d="M34.696 12.112L37.384 7.024L34.744 2.128H36.152L38.12 5.76L40.04 2.128H41.368L38.888 7.008L41.672 12.112H40.2L38.136 8.336L36.12 12.112H34.696Z"
        fill="#121212"
      />
      <path
        d="M48.392 14.432C47.88 14.4533 47.4427 14.4267 47.08 14.352C46.728 14.288 46.44 14.1653 46.216 13.984C46.0027 13.8027 45.8427 13.568 45.736 13.28C45.64 12.992 45.592 12.6347 45.592 12.208C45.0373 12.1227 44.5253 11.8827 44.056 11.488C43.5973 11.0827 43.224 10.5227 42.936 9.808C42.6587 9.08267 42.52 8.20267 42.52 7.168C42.52 6.19733 42.6267 5.38133 42.84 4.72C43.0533 4.05867 43.336 3.53067 43.688 3.136C44.0507 2.74133 44.44 2.45867 44.856 2.288C45.2827 2.10667 45.704 2.016 46.12 2.016C46.6 2.016 47.0587 2.11733 47.496 2.32C47.9333 2.512 48.3173 2.816 48.648 3.232C48.9893 3.648 49.2507 4.18133 49.432 4.832C49.624 5.472 49.7147 6.24 49.704 7.136C49.704 7.88267 49.64 8.54933 49.512 9.136C49.384 9.72267 49.1973 10.2293 48.952 10.656C48.7067 11.072 48.4027 11.408 48.04 11.664C47.6773 11.92 47.2667 12.1013 46.808 12.208C46.8293 12.4213 46.872 12.608 46.936 12.768C47 12.9387 47.1067 13.0667 47.256 13.152C47.4053 13.2373 47.6133 13.28 47.88 13.28L49.192 13.216L49.16 14.432H48.392ZM46.184 11.088C46.4507 11.088 46.712 11.024 46.968 10.896C47.2347 10.7573 47.4747 10.5387 47.688 10.24C47.9013 9.94133 48.072 9.55733 48.2 9.088C48.3387 8.608 48.408 8.02667 48.408 7.344C48.408 6.512 48.3387 5.82933 48.2 5.296C48.0613 4.752 47.8747 4.32533 47.64 4.016C47.416 3.696 47.1707 3.472 46.904 3.344C46.6373 3.216 46.376 3.152 46.12 3.152C45.7467 3.152 45.416 3.25333 45.128 3.456C44.84 3.65867 44.6 3.936 44.408 4.288C44.216 4.62933 44.072 5.02933 43.976 5.488C43.88 5.94667 43.832 6.43733 43.832 6.96C43.832 7.792 43.928 8.51733 44.12 9.136C44.312 9.75467 44.584 10.2347 44.936 10.576C45.288 10.9173 45.704 11.088 46.184 11.088Z"
        fill="#121212"
      />
      <path
        d="M54.12 12.304C53.0427 12.304 52.2213 11.984 51.656 11.344C51.1013 10.6933 50.824 9.79733 50.824 8.656V2.144H52.312V2.272C52.248 2.336 52.2053 2.40533 52.184 2.48C52.1733 2.55467 52.168 2.68267 52.168 2.864V8.672C52.168 9.44 52.312 10.0533 52.6 10.512C52.888 10.96 53.4 11.184 54.136 11.184C54.872 11.184 55.384 10.96 55.672 10.512C55.9707 10.0533 56.12 9.424 56.12 8.624V2.144H57.432V8.608C57.432 9.45067 57.2933 10.144 57.016 10.688C56.7387 11.232 56.3493 11.6373 55.848 11.904C55.3573 12.1707 54.7813 12.304 54.12 12.304Z"
        fill="#121212"
      />
      <path
        d="M63.864 9.392H60.152L60.392 8.384H63.592L63.864 9.392ZM62.088 4.832L59.672 12.112H58.344L61.912 2H62.296L65.864 12.112H64.488L62.088 4.832Z"
        fill="#121212"
      />
      <path
        d="M66.84 2.144H68.184L72.104 9.264L72.072 2.144H73.48V2.272C73.416 2.336 73.3733 2.40533 73.352 2.48C73.3413 2.55467 73.336 2.68267 73.336 2.864V12.112H72.184L68.088 4.56V12.112H66.84V2.144Z"
        fill="#121212"
      />
      <path
        d="M78.632 12.272C78.088 12.272 77.576 12.176 77.096 11.984C76.6267 11.7813 76.2107 11.472 75.848 11.056C75.496 10.64 75.2187 10.112 75.016 9.472C74.8133 8.832 74.712 8.06933 74.712 7.184C74.712 6.40533 74.7867 5.728 74.936 5.152C75.0853 4.56533 75.288 4.07467 75.544 3.68C75.8107 3.27467 76.1093 2.95467 76.44 2.72C76.7813 2.48533 77.1333 2.31467 77.496 2.208C77.8693 2.10133 78.2427 2.048 78.616 2.048C79.352 2.048 79.976 2.224 80.488 2.576C81.0107 2.928 81.4053 3.40267 81.672 4L80.648 4.592L80.504 4.656L80.456 4.544C80.4773 4.45867 80.472 4.37867 80.44 4.304C80.408 4.22933 80.3493 4.11733 80.264 3.968C80.008 3.63733 79.7413 3.41333 79.464 3.296C79.1973 3.17867 78.9147 3.12 78.616 3.12C78.2427 3.12 77.896 3.21067 77.576 3.392C77.256 3.56267 76.9787 3.81867 76.744 4.16C76.5093 4.50133 76.328 4.92267 76.2 5.424C76.0827 5.91467 76.024 6.47467 76.024 7.104C76.024 7.712 76.088 8.26667 76.216 8.768C76.3547 9.25867 76.5413 9.68 76.776 10.032C77.0107 10.384 77.2827 10.656 77.592 10.848C77.912 11.04 78.2533 11.136 78.616 11.136C78.8827 11.136 79.1333 11.0933 79.368 11.008C79.6133 10.9227 79.8373 10.7893 80.04 10.608C80.2427 10.4267 80.4187 10.2133 80.568 9.968L81.528 10.64C81.1973 11.1947 80.7973 11.6053 80.328 11.872C79.8587 12.1387 79.2933 12.272 78.632 12.272Z"
        fill="#121212"
      />
      <path
        d="M83 12.112V2.128H89.24V3.28H84.296V6.32H88.376V7.504H84.296V10.96H89.192V12.112H83Z"
        fill="#121212"
      />
    </g>
    <defs>
      <clipPath id="clip0_9303_59773">
        <rect width="151" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

LogoAccountIconDisabled.displayName = 'LogoAccountIconDisabled';
