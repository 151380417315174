import * as React from 'react';
import styles from './PageError.module.scss';
import { FC } from 'react';
import { NavigateFunction } from 'react-router';
import { ComponentProps } from '@components/dataTypes';
import cn from 'classnames';
import { Button } from '@components/Button';
import { Typography } from '@components/Typography';

export interface PageErrorProps extends ComponentProps {
  title: string;
  code: number;
  navigate: NavigateFunction;
}

export const PageError: FC<PageErrorProps> = (props) => {
  const { id, style, className, dataTestId, title, code, navigate } = props;

  return (
    <div
      id={id}
      style={style}
      className={cn(className, styles.main)}
      data-testid={dataTestId}
    >
      <Typography variant="h3">{title}</Typography>
      <Typography variant="h0">{code}</Typography>
      <Button onClick={() => navigate('/')}>Home</Button>
    </div>
  );
};

PageError.displayName = 'PageError';
