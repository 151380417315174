import React, { FC, Fragment } from 'react';
import {
  Card,
  Chevron,
  ComponentProps,
  Typography,
  Icon,
  IconNames,
  Divider
} from '@components';
import { OmniInfoLicenseTranslations } from './dataTypes';
import styles from './OmniInfoLicenseCard.module.scss';
import commonStyles from '../../LicenseCard.module.scss';
import { colors, offsets } from '@componentsStyles';
import { LicenseApp } from '@components/LicenseCard/dataTypes';
import { LoadingLicenseCard } from '../LoadingLicenseCard';
import classNames from 'classnames';
import dayjs from 'dayjs';

export interface OmniInfoLicenseCardProps extends ComponentProps {
  /** For which application the license is used */
  app: LicenseApp;
  /** License description */
  description: string;
  /** Necessary translations */
  translations?: OmniInfoLicenseTranslations;
  /** List of license features names */
  licenseFeatures?: string[];
  /** license type (Investment Management / valuation / pro) */
  licenseType?: string;
  /** Is a card loading */
  isLoading?: boolean;
  /** Is it a trial version */
  isTrial?: boolean;
  /** Expiry date if trial */
  expiryDate?: Date;
  /** Action for clicking the card */
  onClick?(): void;
}

const defaultTranslations: OmniInfoLicenseTranslations = {
  licenseFeatures: 'License features',
  trialVersion: 'Trial version',
  expiryDate: 'Expiry date'
};

export const OmniInfoLicenseCard: FC<OmniInfoLicenseCardProps> = (props) => {
  const {
    id,
    style,
    className,
    dataTestId,
    translations = defaultTranslations,
    app,
    description,
    licenseType,
    isLoading,
    isTrial,
    expiryDate,
    licenseFeatures,
    onClick
  } = props;

  const logoName: IconNames = `logo-${app}`;
  const cardClasses = classNames(className, styles.card);

  const onClickAction = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Card
      id={id}
      style={style}
      className={cardClasses}
      data-testid={dataTestId}
    >
      {isLoading ? (
        <LoadingLicenseCard />
      ) : (
        <Fragment>
          <Icon
            onClick={onClickAction}
            color="disabled"
            name="chevrons-right"
            size={'s'}
            className={classNames(styles.icon, commonStyles.logo)}
          />
          <div className={commonStyles.header}>
            <Icon name={logoName} className={commonStyles.logo} />
            {isTrial && (
              <Chevron className={offsets['ml-12']}>
                {translations?.trialVersion}
              </Chevron>
            )}
          </div>

          {licenseType && (
            <Typography
              element="div"
              className={offsets['mb-12']}
              variant="body-5"
            >
              {licenseType}
            </Typography>
          )}

          {isTrial && expiryDate && (
            <Typography
              element="div"
              variant="body-3"
              className={offsets['mb-12']}
            >
              {translations.expiryDate}:{' '}
              <Typography variant="body-5">
                <time dateTime={dayjs(expiryDate).format('YYYY-MM-DD')}>
                  {dayjs(expiryDate).format('DD.MM.YYYY')}
                </time>
              </Typography>
            </Typography>
          )}
          <Typography
            element="div"
            variant="body-3"
            className={licenseFeatures?.length > 0 && offsets['pb-20']}
          >
            {description}
          </Typography>

          {licenseFeatures && licenseFeatures?.length > 0 && (
            <>
              <Divider type="thin" className={commonStyles.divider} />
              <Typography
                element="div"
                variant="system-heading"
                className={offsets['mt-40']}
                color={colors.gray40Color}
              >
                {translations?.licenseFeatures}
              </Typography>

              {licenseFeatures?.map((feature, key) => {
                return (
                  <Typography
                    key={key}
                    element="div"
                    variant="body-4"
                    className={offsets['mt-20']}
                  >
                    {feature}
                  </Typography>
                );
              })}
            </>
          )}
        </Fragment>
      )}
    </Card>
  );
};

OmniInfoLicenseCard.displayName = 'OmniInfoLicenseCard';
