import { logger } from '@componentsUtils';
import { useRef, useState } from 'react';
import { TableRow } from '@components';

interface UseActionsProps {
  onLoading: () => Promise<void>;
  onRowClick?: (row: TableRow) => void;
  noMoreData: boolean;
}

const TABLE_ID = 'ui-table';

function useActions({ onLoading, noMoreData, onRowClick }: UseActionsProps) {
  const [isSelectedRowHovered, setIsSelectedRowHovered] =
    useState<boolean>(false);
  const [tableWidth, setTableWidth] = useState<number>(null);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [tableScrollTop, setTableScrollTop] = useState<number>(0);

  const ref = useRef<HTMLDivElement>(null);

  const loadMoreItems = () => {
    setIsLoadingMore(true);

    onLoading()
      .catch((err) => {
        logger.error('Load more items error in table', err);
      })
      .finally(() => {
        setIsLoadingMore(false);
      });
  };

  const onScroll = () => {
    const table = document.getElementById(TABLE_ID);
    setTableScrollTop(table?.scrollTop);

    if (noMoreData || !onLoading) {
      return;
    }

    const spaceForScrollbar = 8;

    if (
      table.scrollTop + table.offsetHeight + spaceForScrollbar >=
      table.scrollHeight
    ) {
      if (!isLoadingMore) {
        loadMoreItems();
      }
    }
  };

  const updateWidth = () => {
    if (!ref?.current) {
      return;
    }
    const width = ref?.current.offsetWidth;
    setTableWidth(width);
  };

  const handleRowClick = (isCurrentRowSelected: boolean, row: TableRow) => {
    if (onRowClick) {
      if (!isCurrentRowSelected) {
        setIsSelectedRowHovered(true);
      }
      onRowClick(row);
    }
  };

  return {
    tableScrollTop,
    loadMoreItems,
    isLoadingMore,
    onScroll,
    updateWidth,
    ref,
    tableWidth,
    handleRowClick,
    setIsSelectedRowHovered,
    isSelectedRowHovered
  };
}

export default useActions;
