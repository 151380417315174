import React, { FC } from 'react';
import {
  ComponentProps,
  Typography,
  Card,
  Icon,
  SkeletonPlaceholder,
  Superscript,
  Tooltip,
  InvoicesTranslations
} from '@components';
import cn from 'classnames';
import styles from './Invoices.module.scss';
import { colors, offsets } from '@componentsStyles';
import {
  Currency,
  formatDate,
  formatNumber,
  getCurrencySign
} from '@componentsUtils';

export interface InvoicesProps extends ComponentProps {
  currency: Currency;
  lastInvoiceDate?: Date;
  nextInvoiceDate?: Date;
  lastPayment?: number;
  nextPayment?: number;
  translations?: InvoicesTranslations;
  isLoading?: boolean;
  isSeeDetails?: boolean;
  onSeeDetails?(): void;
}

const defaultTranslations = {
  invoices: 'Invoices',
  lastInvoiceDate: 'Last invoice date',
  nextInvoiceDate: 'Next invoice date',
  lastPayment: 'Last payment',
  nextPayment: 'Next payment',
  seeDetails: 'See details'
};

export const Invoices: FC<InvoicesProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    translations = defaultTranslations,
    lastInvoiceDate,
    nextInvoiceDate,
    lastPayment,
    nextPayment,
    currency,
    isLoading,
    isSeeDetails,
    onSeeDetails
  } = props;

  return (
    <Card
      className={cn(
        className,
        isLoading ? styles['card--loading'] : styles.card
      )}
      style={style}
      id={id}
      data-testid={dataTestId}
    >
      <div className={styles.header}>
        <Typography className={offsets['mr-8']} variant="h5">
          {translations.invoices}
        </Typography>
        {isSeeDetails && (
          <div className={styles.details}>
            <Tooltip content={translations.seeDetails}>
              <div
                onClick={onSeeDetails && onSeeDetails}
                data-testid="details-icon"
              >
                <Icon size="s" name="chevrons-right" />
              </div>
            </Tooltip>
          </div>
        )}
      </div>

      <div className={styles.grid}>
        <div>
          <Typography
            className={styles.title}
            variant="system-heading"
            color={colors.gray40Color}
            element="div"
          >
            {translations.lastInvoiceDate}
          </Typography>

          {isLoading ? (
            <SkeletonPlaceholder width={191} height={40} />
          ) : (
            <Typography variant="h1" element="div">
              {formatDate(lastInvoiceDate)}
            </Typography>
          )}
        </div>

        <div>
          <Typography
            className={styles.title}
            variant="system-heading"
            color={colors.gray40Color}
            element="div"
          >
            {translations.lastPayment}
          </Typography>

          {isLoading ? (
            <SkeletonPlaceholder width={115} height={40} />
          ) : (
            <>
              {lastPayment ? (
                <Superscript
                  content={formatNumber(lastPayment, 2)}
                  sign={getCurrencySign(currency)}
                />
              ) : (
                <Typography variant={'h1'}>–</Typography>
              )}
            </>
          )}
        </div>

        <Icon
          name={'arrow-right'}
          className={isLoading ? styles['arrow--disabled'] : styles.arrow}
          size={'s'}
        />

        <div>
          <Typography
            className={styles.title}
            variant="system-heading"
            color={colors.gray40Color}
            element="div"
          >
            {translations.nextInvoiceDate}
          </Typography>

          {isLoading ? (
            <SkeletonPlaceholder width={191} height={40} />
          ) : (
            <Typography variant="h1" element="div">
              {formatDate(nextInvoiceDate)}
            </Typography>
          )}
        </div>

        <div>
          <Typography
            className={styles.title}
            variant="system-heading"
            color={colors.gray40Color}
            element="div"
          >
            {translations.nextPayment}
          </Typography>

          {isLoading ? (
            <SkeletonPlaceholder width={115} height={40} />
          ) : (
            <>
              {nextPayment ? (
                <Superscript
                  content={formatNumber(nextPayment, 2)}
                  sign={getCurrencySign(currency)}
                />
              ) : (
                <Typography variant={'h1'}>–</Typography>
              )}
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

Invoices.displayName = 'Invoices';
