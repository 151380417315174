import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import {
  ComponentProps,
  DropdownItemProps,
  DropdownPosition,
  Typography,
  Icon,
  DropdownItemVariant
} from '@components';
import styles from './Dropdown.module.scss';
import { useComponentVisible } from '@componentsUtils/useComponentVisible';
import { DropdownItem } from '@components/Dropdown/components/DropdownItem/DropdownItem';
import { common } from '@componentsStyles';
import { logger } from '@componentsUtils';

export interface DropdownProps extends ComponentProps {
  /**
   * All items list
   */
  items: DropdownItemProps[];
  /**
   * Value of the item from the items array that will be displayed
   */
  selectedItemValue?: string;
  /**
   * How dropdown list will open
   */
  position?: DropdownPosition;
  /**
   * Variant of the dropdown
   */
  variant?: DropdownItemVariant;
  /**
   * Disabled Dropdown
   */
  disabled?: boolean;
  /**
   * Visible Dropdown menu
   */
  visible?: boolean;
  /**
   * Callback on click
   */
  onValueSelected?(value: string, label: string): void;
  noTextTransform?: boolean;
}

export const Dropdown: FC<DropdownProps> = (props) => {
  const {
    className,
    id,
    style,
    dataTestId,
    items,
    position = 'bottom',
    variant = 'underlined',
    visible,
    disabled,
    onValueSelected,
    selectedItemValue,
    noTextTransform
  } = props;

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(visible);

  const filterItems = (item?: DropdownItemProps): DropdownItemProps[] => {
    if (!item) {
      return items;
    }
    return items?.filter((el: DropdownItemProps) => el?.value !== item.value);
  };

  const [displayedItem, setDisplayedItem] = useState<DropdownItemProps>(null);
  const [displayedList, setDisplayedList] = useState<DropdownItemProps[]>([]);

  const handleValueSelected = (item: DropdownItemProps) => {
    setDisplayedItem(item);
    setDisplayedList(filterItems(item));

    if (onValueSelected) {
      onValueSelected(item.value, item.label);
    }
  };

  const listStyle = () => {
    switch (position) {
      case 'top':
        return styles.dropdown_list_top;

      case 'bottom':
        return styles.dropdown_list_bottom;

      default:
        return styles.dropdown_list_bottom;
    }
  };

  useEffect(() => {
    if (selectedItemValue && items?.length > 0) {
      const item = items.find((el) => el?.value === selectedItemValue);
      if (!item) {
        logger.error(
          `Wrong selectedItemValue in dropdown: ${selectedItemValue}`
        );
      }
      setDisplayedItem(item);
    }
  }, [selectedItemValue, items]);

  useEffect(() => {
    setDisplayedList(filterItems(displayedItem));
  }, [items, displayedItem]);

  return (
    <span
      ref={ref}
      id={id}
      className={classNames(
        className,
        styles.dropdown,
        disabled && styles.dropdown_disabled
      )}
      style={style}
      data-testid={dataTestId}
    >
      <Typography
        variant={variant === 'underlined' ? 'link' : 'body-4'}
        className={classNames(
          styles.title,
          noTextTransform && common['no-text-transform']
        )}
      >
        {displayedItem && displayedItem.label}
      </Typography>
      <Icon
        name="chevron-down"
        color={isComponentVisible ? 'default' : undefined}
        className={
          variant === 'underlined'
            ? styles['chevron-down--underlined']
            : styles['chevron-down']
        }
        size="s"
        onClick={() => !disabled && setIsComponentVisible(!isComponentVisible)}
      />

      {isComponentVisible && (
        <ul
          role="listbox"
          className={classNames(styles.dropdown_list, listStyle())}
        >
          {displayedList?.map((item, key) => {
            return (
              <DropdownItem
                key={key}
                label={item?.label}
                value={item?.value}
                selected={item?.selected}
                setIsComponentVisible={setIsComponentVisible}
                onValueSelected={() => handleValueSelected(item)}
                noTextTransform={noTextTransform}
              />
            );
          })}
        </ul>
      )}
    </span>
  );
};

Dropdown.displayName = 'Dropdown';
