import React, { FC } from 'react';
import cn from 'classnames';
import { ComponentProps, Typography, SkeletonPlaceholder } from '@components';
import styles from './SystemInfographics.module.scss';
import { colors, offsets } from '@componentsStyles';
import { DASH } from '@constants';

export interface SystemInfographicsProps extends ComponentProps {
  heading: string;
  content?: string;
  isLoading?: boolean;
  isError?: boolean;
}

export const SystemInfographics: FC<SystemInfographicsProps> = (props) => {
  const {
    heading,
    content,
    isLoading,
    isError,
    className,
    id,
    style,
    dataTestId,
    children
  } = props;

  return (
    <div
      id={id}
      style={style}
      className={cn(className, styles.infographics)}
      data-testid={dataTestId}
    >
      {isLoading ? (
        <SkeletonPlaceholder type="rectangle" width={50} height={12} />
      ) : (
        <Typography
          element={'div'}
          variant="system-heading"
          className={styles.heading}
          color={isError ? colors.red70Color : colors.gray60Color}
        >
          {heading}
        </Typography>
      )}

      {content && (
        <Typography
          element={'div'}
          variant="h3"
          className={offsets['mt-4']}
          color={isError ? colors.red70Color : colors.gray100Color}
        >
          {isLoading ? DASH : content || DASH}
        </Typography>
      )}

      {children && (
        <>
          {isLoading ? (
            <Typography variant="h3" className={offsets['mt-4']}>
              –
            </Typography>
          ) : (
            <div className={offsets['mt-4']}> {children} </div>
          )}
        </>
      )}
    </div>
  );
};

SystemInfographics.displayName = 'SystemInfographics';
