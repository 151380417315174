import { colors } from '@componentsStyles';

export const joyrideStyleConfig = {
  options: {
    backgroundColor: colors.gray80Color,
    arrowColor: colors.gray80Color,
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    color: colors.whiteColor,
    textColor: colors.whiteColor
  },
  overlay: {
    minHeight: '100dvh'
  },
  spotlight: {
    boxShadow: '2px 2px 4px 0px rgba(18, 18, 18, 0.20)'
  },
  tooltipTitle: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: '400',
    textAlign: 'left' as const,
    paddingLeft: '20px',
    paddingBottom: '15px'
  },
  tooltipContent: {
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'left' as const,
    padding: '2px 20px'
  },
  tooltipFooter: {
    marginTop: '10px'
  },
  tooltipFooterSpacer: {
    flex: '0'
  },
  buttonBack: {
    color: colors.whiteColor,
    backgroundColor: colors.gray80Color,
    marginLeft: '0',
    outline: 'none'
  },
  buttonNext: {
    color: colors.whiteColor,
    backgroundColor: colors.gray80Color,
    marginLeft: 'auto',
    outline: 'none'
  },
  buttonClose: {
    color: colors.whiteColor,
    position: 'absolute' as const,
    top: 3.3,
    right: 3.3,
    height: 9.33,
    width: 9.33,
    outline: 'none'
  },
  beacon: {
    display: 'none'
  }
};

export const floaterProps = {
  styles: {
    arrow: {
      length: 7,
      spread: 12
    }
  }
};
