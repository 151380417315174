import { PriceRuleType, PRICING_OPTIONS, PricingData } from '@components';
import { PricingPreviewData } from '@components/PricingList/components/PricingPreview';
import { logger } from '@componentsUtils';

export const calculatePricingPerUser = (
  pricing: PricingData[],
  pricePerUser: number,
  maxUserAmount: number,
  priceRuleType: PriceRuleType
): PricingPreviewData[] => {
  if (!pricing) {
    return;
  }

  const sortedPricing = [...pricing]?.sort(
    (a, b) => a?.numberOfUsers - b?.numberOfUsers
  );

  /* In cycle, we compare temporary max numberOfUsers value from pricing and save it  */
  /* If new numberOfUsers in cycle >  currentPricingMaxUsersAmount, save new values */
  let currentPricingMaxUsersAmount = sortedPricing[0]?.numberOfUsers;
  let currentPricingMaxUsersAmountIndex = 0;

  return Array.from({ length: maxUserAmount }, (_, i) => {
    return { numberOfUsers: i + 1 };
  }).map((pricingEl) => {
    const numberOfUsers = pricingEl.numberOfUsers;
    /** If current user amount is more than max user amount in pricing table */
    if (currentPricingMaxUsersAmountIndex > sortedPricing.length - 1) {
      return getMaxPricingEl(pricingEl, numberOfUsers, pricePerUser);
    }

    /** If current user amount is in current pricing interval */
    if (numberOfUsers <= currentPricingMaxUsersAmount) {
      return getPricingEl(
        pricingEl,
        numberOfUsers,
        pricePerUser,
        sortedPricing,
        currentPricingMaxUsersAmountIndex,
        priceRuleType
      );
    }

    /** Else increase current interval */
    currentPricingMaxUsersAmountIndex++;
    currentPricingMaxUsersAmount =
      sortedPricing[currentPricingMaxUsersAmountIndex]?.numberOfUsers;

    /** If next interval exist */
    if (currentPricingMaxUsersAmountIndex > sortedPricing.length - 1) {
      return getMaxPricingEl(pricingEl, numberOfUsers, pricePerUser);
    }
    return getPricingEl(
      pricingEl,
      numberOfUsers,
      pricePerUser,
      sortedPricing,
      currentPricingMaxUsersAmountIndex,
      priceRuleType
    );
  }) as PricingPreviewData[];
};

/* Based on for pricing table cycle, can be monthly and per user */
const getPricingEl = (
  pricingEl: PricingPreviewData,
  numberOfUsers: number,
  pricePerUser: number,
  sortedPricing: PricingData[],
  currentPricingMaxUsersAmountIndex: number,
  priceRuleType: PriceRuleType
) => {
  if (priceRuleType === PRICING_OPTIONS.TOTAL_PRICE) {
    return {
      ...pricingEl,
      priceMonthly: sortedPricing[currentPricingMaxUsersAmountIndex].price,
      pricePerUserMonthly:
        sortedPricing[currentPricingMaxUsersAmountIndex].price / numberOfUsers
    };
  }

  if (priceRuleType === PRICING_OPTIONS.PRICE_PER_USER) {
    return {
      ...pricingEl,
      priceMonthly:
        sortedPricing[currentPricingMaxUsersAmountIndex].price * numberOfUsers,
      pricePerUserMonthly:
        sortedPricing[currentPricingMaxUsersAmountIndex].price
    };
  }

  logger.warn(`priceRuleType ${priceRuleType} is not valid!`);
  return pricingEl;
};

/* Based on max price per user value  */
const getMaxPricingEl = (
  pricingEl: PricingPreviewData,
  numberOfUsers: number,
  pricePerUser: number
) => {
  return {
    ...pricingEl,
    priceMonthly: pricePerUser * numberOfUsers,
    pricePerUserMonthly: pricePerUser
  };
};

export const priceDifference = (prevPrice: number, nextPrice: number) => {
  if (prevPrice) {
    return (nextPrice / prevPrice) * 100 - 100;
  }
  return null;
};
