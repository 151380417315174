import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const RepeatIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893L21.7071 4.29289C22.0976 4.68342 22.0976 5.31658 21.7071 5.70711L17.7071 9.70711C17.3166 10.0976 16.6834 10.0976 16.2929 9.70711C15.9024 9.31658 15.9024 8.68342 16.2929 8.29289L18.5858 6H7C6.20435 6 5.44129 6.31607 4.87868 6.87868C4.31607 7.44129 4 8.20435 4 9V11C4 11.5523 3.55228 12 3 12C2.44772 12 2 11.5523 2 11V9C2 7.67392 2.52678 6.40215 3.46447 5.46447C4.40215 4.52678 5.67392 4 7 4H18.5858L16.2929 1.70711C15.9024 1.31658 15.9024 0.683417 16.2929 0.292893ZM20 15V13C20 12.4477 20.4477 12 21 12C21.5523 12 22 12.4477 22 13V15C22 16.3261 21.4732 17.5979 20.5355 18.5355C19.5979 19.4732 18.3261 20 17 20H5.41421L7.70711 22.2929C8.09763 22.6834 8.09763 23.3166 7.70711 23.7071C7.31658 24.0976 6.68342 24.0976 6.29289 23.7071L2.29289 19.7071C1.90237 19.3166 1.90237 18.6834 2.29289 18.2929L6.29289 14.2929C6.68342 13.9024 7.31658 13.9024 7.70711 14.2929C8.09763 14.6834 8.09763 15.3166 7.70711 15.7071L5.41421 18H17C17.7956 18 18.5587 17.6839 19.1213 17.1213C19.6839 16.5587 20 15.7956 20 15Z"
      fill={props.color}
    />
  </SvgIcon>
);

RepeatIcon.displayName = 'RepeatIcon';
