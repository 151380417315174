import React, { FC, Fragment } from 'react';
import { Divider } from '@components';

import styles from './LoadingLicenseCard.module.scss';
import { SkeletonPlaceholder } from '@components';
import { offsets } from '@componentsStyles';

export const LoadingLicenseCard: FC = () => {
  return (
    <Fragment>
      <SkeletonPlaceholder
        width={150}
        height={20}
        className={offsets['mb-12']}
      />
      <SkeletonPlaceholder
        width={80}
        height={16}
        className={offsets['mb-12']}
      />
      <Divider type="thin" className={styles.divider} />
      <SkeletonPlaceholder
        width={150}
        height={16}
        className={offsets['mt-16']}
      />
      <SkeletonPlaceholder width={80} height={16} className={offsets['mt-4']} />
    </Fragment>
  );
};

LoadingLicenseCard.displayName = 'LoadingLicenseCard';
