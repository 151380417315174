import {
  cellStyles,
  ComponentProps,
  Icon,
  Toggle,
  Typography
} from '@components';
import {
  SortDirection,
  TableColumn,
  TableDataType,
  TextAlignments
} from '@components/Table/dataTypes';
import { colors } from '@componentsStyles';
import classNames from 'classnames';
import cn from 'classnames';
import CSS from 'csstype';
import React, { FC } from 'react';
import styles from './TableHeader.module.scss';

export interface TableHeaderProps extends ComponentProps {
  columns: TableColumn[];
  actionColumn?: TableColumn;
  onTableDataType?: () => void;
  sortBy?: string;
  sortOrder?: SortDirection;
  tableDataType?: TableDataType;
  onSort?: (key: string) => void;
  isScrollable?: boolean;
  selectedColumnKey?: string;
}

export const TableHeader: FC<TableHeaderProps> = (props) => {
  const {
    columns,
    className,
    actionColumn,
    tableDataType,
    onTableDataType,
    sortBy,
    onSort,
    sortOrder = 'ASC',
    isScrollable,
    id,
    style,
    selectedColumnKey
  } = props;

  const headCellStyle = (column: TableColumn) => {
    const styles: CSS.Properties = {};

    if (column.alignment === 'right') {
      styles.justifyContent = 'end';
      if (column.sortable) {
        styles.marginLeft = '26px';
      }
      if (column.subtitle) {
        styles.alignItems = 'end';
      }
    }
    if (column.alignment === 'left') {
      styles.justifyContent = 'start';
      if (column.subtitle) {
        styles.alignItems = 'start';
      }
    }
    if (column.alignment === 'center') {
      styles.justifyContent = 'center';
      if (column.subtitle) {
        styles.alignItems = 'center';
      }
    }

    return styles;
  };

  const getSubtitleStyle = (align: TextAlignments) => {
    const styles: CSS.Properties = {};

    switch (align) {
      case 'start':
        styles.alignSelf = 'start';
        break;

      case 'center':
        styles.alignSelf = 'center';
        break;

      case 'end':
        styles.alignSelf = 'end';
        break;

      default:
        styles.alignSelf = 'start';
    }

    return styles;
  };

  return (
    <thead id={id} style={style} className={className}>
      <tr className={styles.row}>
        {columns?.map((column, key) => {
          return (
            <th
              className={styles.cell}
              style={cellStyles({ column, isScrollable, isHeader: true })}
              key={column.key}
              onClick={
                column.sortable && onSort ? () => onSort(column.key) : null
              }
            >
              <Typography
                id={`table-header-cell-name-${key}`}
                className={classNames(styles['cell-name'], {
                  [styles['cell-name--sortable']]: column.sortable,
                  [styles['cell-name--subtitle']]: column.subtitle
                })}
                element="div"
                color={colors.gray40Color}
                style={headCellStyle(column)}
                variant="system-heading"
              >
                {column.title}
                {column.sortable && (
                  <Icon
                    size="s"
                    className={
                      column.key === sortBy
                        ? styles.sort
                        : styles['sort--hidden']
                    }
                    name={sortOrder === 'DESC' ? 'sort-more' : 'sort-less'}
                  />
                )}
                {column.toggle && (
                  <div key={column.toggle.key} className={styles.toggleWrapper}>
                    <Toggle
                      id={`table-header-toggle-${key}`}
                      className={cn(
                        styles['toggleWrapper--toggle'],
                        column?.toggle?.className
                      )}
                      checked={selectedColumnKey === column.key}
                      onChange={() => column.toggle.onToggle(column.key)}
                      disabled={column.toggle.disabled}
                    />
                    <Typography
                      className={styles['toggleWrapper--info']}
                      element="div"
                      color={colors.gray40Color}
                      style={headCellStyle(column)}
                      variant="system-heading"
                    >
                      {column.toggle.title}
                      <div className={styles['toggleWrapper--unit']}>
                        {column.toggle.unit}
                      </div>
                    </Typography>
                  </div>
                )}
                {column.subtitle && !column.toggle && (
                  <div
                    className={styles['toggleWrapper--unit']}
                    style={getSubtitleStyle(column.subtitleAlign)}
                  >
                    {column.subtitle}
                  </div>
                )}
              </Typography>
            </th>
          );
        })}
        {onTableDataType && (
          <th
            onClick={onTableDataType}
            className={cn(styles.cell, styles.action)}
            style={cellStyles({ isHeader: true, column: actionColumn })}
          >
            <div
              id={'table-header-icon-number'}
              className={styles['dataTypeBtn-wrapper']}
            >
              <div className={styles.dataTypeBtn}>
                <Icon
                  size="s"
                  className={styles[`dataTypeBtn-icon`]}
                  name={tableDataType === 'absolute' ? 'number' : 'percent'}
                />
              </div>
            </div>
          </th>
        )}
        {actionColumn && <th className={cn(styles.cell, styles.action)} />}
      </tr>
    </thead>
  );
};

TableHeader.displayName = 'TableHeader';
