import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const LogoOmniIconDisabled: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 62 16" width={62} height={16}>
    <path d="M0 0L2.66667 4L5.33333 0H0Z" fill="#DADADA" />
    <path d="M14.666 12L17.3327 8L19.9993 12H14.666Z" fill="#EEEEEE" />
    <path d="M12 0L14.6667 4L17.3333 0H12Z" fill="#B3B3B3" />
    <path d="M9.33398 4L12.0007 0L14.6673 4H9.33398Z" fill="#B3B3B3" />
    <path d="M9.33398 4L12.0007 8L14.6673 4H9.33398Z" fill="#EEEEEE" />
    <path d="M5.33398 8L8.00065 4L10.6673 8H5.33398Z" fill="#DADADA" />
    <path d="M5.33398 8L8.00065 12L10.6673 8H5.33398Z" fill="#8F8F8F" />
    <path d="M2.66602 12L5.33268 8L7.99935 12H2.66602Z" fill="#8F8F8F" />
    <path d="M14.666 12L17.3327 16L19.9993 12H14.666Z" fill="#EEEEEE" />
    <path d="M17.334 16L20.0007 12L22.6673 16H17.334Z" fill="#EEEEEE" />
    <path d="M2.66602 4L5.33268 8L7.99935 4H2.66602Z" fill="#DADADA" />
    <path d="M2.66602 4L5.33268 0L7.99935 4H2.66602Z" fill="#DADADA" />
    <path d="M12 8L14.6667 12L17.3333 8H12Z" fill="#EEEEEE" />
    <path d="M12 8L14.6667 4L17.3333 8H12Z" fill="#EEEEEE" />
    <path
      d="M30.632 12.176C29.8533 12.176 29.192 11.9787 28.648 11.584C28.1147 11.1894 27.704 10.608 27.416 9.84004C27.1387 9.07204 27 8.14403 27 7.05604C27 5.98937 27.1387 5.07737 27.416 4.32003C27.704 3.55203 28.1147 2.96537 28.648 2.56003C29.192 2.14404 29.8533 1.93604 30.632 1.93604C31.4107 1.93604 32.0667 2.14404 32.6 2.56003C33.1333 2.96537 33.5387 3.55203 33.816 4.32003C34.104 5.07737 34.248 5.98937 34.248 7.05604C34.248 8.14403 34.104 9.07204 33.816 9.84004C33.5387 10.608 33.1333 11.1894 32.6 11.584C32.0667 11.9787 31.4107 12.176 30.632 12.176ZM30.632 11.04C31.1013 11.04 31.5013 10.8907 31.832 10.592C32.1733 10.2827 32.4293 9.8347 32.6 9.24804C32.7813 8.6507 32.872 7.92003 32.872 7.05604C32.872 6.2347 32.7813 5.5307 32.6 4.94404C32.4293 4.3467 32.1733 3.88804 31.832 3.56804C31.5013 3.24803 31.1013 3.08803 30.632 3.08803C30.1627 3.08803 29.7573 3.24803 29.416 3.56804C29.0747 3.88804 28.8133 4.3467 28.632 4.94404C28.4613 5.5307 28.376 6.2347 28.376 7.05604C28.376 7.92003 28.4613 8.6507 28.632 9.24804C28.8133 9.8347 29.0747 10.2827 29.416 10.592C29.7573 10.8907 30.1627 11.04 30.632 11.04Z"
      fill="black"
    />
    <path
      d="M35.224 12V2.03203H36.344L38.632 6.81604L40.952 2.01604H42.04V12H40.808V4.67203L38.856 8.56003H38.264L36.44 4.70403V12H35.224Z"
      fill="black"
    />
    <path
      d="M43.352 2.03203H44.696L48.616 9.15204L48.584 2.03203H49.832H49.992V2.16004C49.928 2.22403 49.8853 2.29337 49.864 2.36803C49.8533 2.4427 49.848 2.5707 49.848 2.75204V12H48.696L44.6 4.44803V12H43.352V2.03203Z"
      fill="black"
    />
    <path
      d="M51.848 12V10.88H53.816V3.15203H51.96V2.03203H57.096V3.15203H55.128V10.896H57.192V12H51.848Z"
      fill="black"
    />
  </SvgIcon>
);

LogoOmniIconDisabled.displayName = 'LogoOmniIconDisabled';
