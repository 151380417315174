import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const CornerDownLeftIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 3C20.5523 3 21 3.44772 21 4V11C21 12.3261 20.4732 13.5979 19.5355 14.5355C18.5979 15.4732 17.3261 16 16 16H6.41421L9.70711 19.2929C10.0976 19.6834 10.0976 20.3166 9.70711 20.7071C9.31658 21.0976 8.68342 21.0976 8.29289 20.7071L3.29289 15.7071C2.90237 15.3166 2.90237 14.6834 3.29289 14.2929L8.29289 9.29289C8.68342 8.90237 9.31658 8.90237 9.70711 9.29289C10.0976 9.68342 10.0976 10.3166 9.70711 10.7071L6.41421 14H16C16.7956 14 17.5587 13.6839 18.1213 13.1213C18.6839 12.5587 19 11.7956 19 11V4C19 3.44772 19.4477 3 20 3Z"
      fill={props.color}
    />
  </SvgIcon>
);

CornerDownLeftIcon.displayName = 'CornerDownLeftIcon';
