import React, { forwardRef, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import colors from '@componentsStyles/colors.scss';
import { ComponentProps, Typography } from '@components';
import styles from './Textarea.module.scss';
import ReactMarkdown from 'react-markdown';
import { display, offsets } from '@componentsStyles';
import { reactMarkdownConfig } from '@componentsUtils';

export interface TextareaProps extends ComponentProps {
  label: string;
  /**
   * Called when value of a Textarea changed
   */
  onChange?: (value: string) => void;
  /**
   * Specifies that a Textarea should be disabled
   */
  disabled?: boolean;
  /**
   * Specifies that a Textarea should be required
   */
  required?: boolean;
  /**
   * Shows error message
   */
  errorMessage?: string;
  /**
   * Specifies the value of a Textarea
   */
  value?: string;
  isMarkdown?: boolean;
}

export const Textarea = forwardRef<HTMLDivElement, TextareaProps>(
  (props, ref) => {
    const {
      id,
      className,
      style,
      dataTestId,
      disabled,
      required,
      label,
      errorMessage,
      value = '',
      onChange,
      isMarkdown
    } = props;

    const textareaRef = useRef(null);
    const renderedMarkdownRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);

    const textareaClassNames = cn(
      styles.input,
      errorMessage && styles.input_error
    );

    const autoResizeTextarea = () => {
      const textarea = textareaRef?.current;
      const renderedMarkdown = renderedMarkdownRef?.current;

      if (!isMarkdown) {
        textarea.style.height = 'auto';
      }

      const totalHeight =
        Math.max(textarea?.scrollHeight, renderedMarkdown?.scrollHeight, 91) +
        1;
      textarea.style.height = `${totalHeight}px`;

      if (isMarkdown) {
        renderedMarkdown.style.height = `${totalHeight}px`;
      }
    };

    const handleChange = (value: string) => {
      onChange(value);
      autoResizeTextarea();
    };

    useEffect(() => {
      if (textareaRef?.current && isFocused && isMarkdown) {
        textareaRef.current.focus();
      }
    }, [textareaRef?.current, isFocused, isMarkdown]);

    const onFocus = () => {
      if (isMarkdown) {
        autoResizeTextarea();
        setIsFocused(true);
      }
    };

    const onBlur = () => {
      if (isMarkdown) {
        setIsFocused(false);
      }
    };

    return (
      <div
        id={id}
        style={style}
        data-testid={dataTestId}
        ref={ref}
        className={cn(className, offsets['pb-20'])}
      >
        <div className={disabled ? styles['form--disabled'] : styles.form}>
          <div
            ref={renderedMarkdownRef}
            onClick={onFocus}
            className={cn(
              textareaClassNames,
              styles['markdown-area'],
              isMarkdown && !isFocused ? display['d-block'] : display['d-none']
            )}
          >
            <ReactMarkdown components={reactMarkdownConfig}>
              {value}
            </ReactMarkdown>
          </div>

          <textarea
            ref={textareaRef}
            rows={4}
            value={value}
            className={cn(
              textareaClassNames,
              isMarkdown && !isFocused ? display['d-none'] : display['d-block']
            )}
            disabled={disabled}
            required={required}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            placeholder=" "
            onFocus={onFocus}
            onBlur={onBlur}
          />

          <label className={styles.label} htmlFor={id}>
            {label}
            {required && <span className={styles['required-symbol']}>*</span>}
          </label>

          {errorMessage && (
            <Typography
              variant="system"
              color={colors.red70Color}
              className={styles['error-message']}
            >
              {errorMessage}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);

Textarea.displayName = 'Textarea';
