import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const SpinnerIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 84 84" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.986 10.6113C49.2173 7.59534 41.655 6.84818 34.4269 8.48127C27.1988 10.1144 20.6923 14.0402 15.8777 19.6732C11.0631 25.3063 8.19843 32.3448 7.71086 39.739C7.22329 47.1332 9.13898 54.4869 13.1722 60.7034C17.2055 66.9199 23.1401 71.6661 30.0912 74.2341C37.0422 76.8022 44.6372 77.0545 51.7433 74.9534C58.8495 72.8523 65.0861 68.5105 69.523 62.5754C73.9599 56.6403 76.3594 49.4299 76.3636 42.0197V38.5091C76.3636 36.4004 78.0731 34.6909 80.1818 34.6909C82.2905 34.6909 84 36.4004 84 38.5091V42.0218C83.9948 51.0788 81.0621 59.8937 75.6392 67.1477C70.2163 74.4017 62.5938 79.7084 53.9085 82.2764C45.2232 84.8444 35.9405 84.536 27.4448 81.3973C18.9491 78.2585 11.6955 72.4576 6.76603 64.8597C1.83652 57.2618 -0.504877 48.2739 0.0910425 39.2365C0.686962 30.1992 4.18827 21.5966 10.0728 14.7117C15.9573 7.82688 23.9097 3.02864 32.744 1.03265C41.5783 -0.963339 50.8211 -0.0501452 59.094 3.63604C61.0202 4.49429 61.8859 6.75151 61.0276 8.67767C60.1694 10.6038 57.9122 11.4696 55.986 10.6113Z"
      fill="url(#paint0_linear_2231_22305)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2231_22305"
        x1="1.90862"
        y1="44.8637"
        x2="93.5455"
        y2="44.8637"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7941D" />
        <stop offset="1" stopColor="#F7941D" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

SpinnerIcon.displayName = 'SpinnerIcon';
