import React, { FC } from 'react';
import classNames from 'classnames';
import { ComponentProps, Typography } from '@components';
import styles from './Tooltip.module.scss';
import { TooltipType } from './dataTypes';

export interface TooltipProps extends ComponentProps {
  content: string;
  type?: TooltipType;
  disabled?: boolean;
}

export const Tooltip: FC<TooltipProps> = (props) => {
  const {
    className,
    id,
    children,
    style,
    dataTestId,
    type = 'top',
    content,
    disabled = false
  } = props;

  return (
    <div
      id={id}
      style={style}
      className={classNames(className, styles.container)}
      data-testid={dataTestId}
    >
      {children}
      <span
        className={classNames(styles[`tooltip--${type}`], {
          [styles.disabled]: disabled
        })}
      >
        <Typography variant="label-2" className={styles.tooltip}>
          {content}
        </Typography>
      </span>
    </div>
  );
};

Tooltip.displayName = 'Tooltip';
