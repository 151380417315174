import React, { FC } from 'react';
import { ComponentProps, Icon, Typography } from '@components';
import styles from './Filter.module.scss';
import { useComponentVisible } from '@componentsUtils';
import { TableFilter, TableFilterGroup } from '@components/Filter/dataTypes';
import cn from 'classnames';
import { FilterDropdown } from '@components/Filter/components/FilterDropdown';
import { SelectedFilters } from '@components/Filter/components/SelectedFilters/SelectedFilters';

export interface FilterProps extends ComponentProps {
  title?: string;
  filters: TableFilter[];
  setFilters: (filters: TableFilter[]) => void;
  filterGroups: TableFilterGroup[];
}

export const Filter: FC<FilterProps> = (props) => {
  const {
    className,
    id,
    style,
    dataTestId,
    title = 'Filters',
    filters,
    setFilters,
    filterGroups
  } = props;

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <div
      id={id}
      style={style}
      className={cn(styles.wrap, className)}
      data-testid={dataTestId}
      ref={ref}
    >
      <div className={styles.selections}>
        <SelectedFilters filters={filters} setFilters={setFilters} />

        <div
          className={styles.title}
          data-testid={'title'}
          onClick={() => setIsComponentVisible(!isComponentVisible)}
        >
          <Typography variant={'link'}>{title}</Typography>
          <Icon size={'s'} name={'chevron-down'} />
        </div>
      </div>

      {isComponentVisible && (
        <FilterDropdown
          dataTestId={'filter-dropdown'}
          filters={filters}
          setFilters={setFilters}
          filterGroups={filterGroups}
        />
      )}
    </div>
  );
};

Filter.displayName = 'Filter';
