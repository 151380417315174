import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const MapIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.50386 1.13176C7.79416 0.965872 8.14816 0.956046 8.44721 1.10557L15.9685 4.86623L22.5039 1.13176C22.8134 0.954898 23.1936 0.956168 23.5019 1.13509C23.8102 1.31401 24 1.64353 24 2V18C24 18.3589 23.8077 18.6902 23.4961 18.8682L16.4961 22.8682C16.2058 23.0341 15.8518 23.044 15.5528 22.8944L8.03147 19.1338L1.49614 22.8682C1.18664 23.0451 0.806388 23.0438 0.498073 22.8649C0.189758 22.686 0 22.3565 0 22V6C0 5.64114 0.192286 5.3098 0.503861 5.13176L7.50386 1.13176ZM9 17.382L15 20.382V6.61803L9 3.61803V17.382ZM7 3.72318V17.4197L2 20.2768V6.58032L7 3.72318ZM17 6.58032V20.2768L22 17.4197V3.72318L17 6.58032Z"
      fill={props.color}
    />
  </SvgIcon>
);

MapIcon.displayName = 'MapIcon';
