import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const FullscreenIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 14 14" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00004 1.66683C8.63185 1.66683 8.33337 1.36835 8.33337 1.00016C8.33337 0.631973 8.63185 0.333496 9.00004 0.333496H13C13.3682 0.333496 13.6667 0.631973 13.6667 1.00016V5.00016C13.6667 5.36835 13.3682 5.66683 13 5.66683C12.6319 5.66683 12.3334 5.36835 12.3334 5.00016V2.60964L8.80478 6.13823C8.54443 6.39858 8.12232 6.39858 7.86197 6.13823C7.60162 5.87788 7.60162 5.45577 7.86197 5.19542L11.3906 1.66683H9.00004ZM5.1953 7.86209C5.45565 7.60174 5.87776 7.60174 6.13811 7.86209C6.39846 8.12244 6.39846 8.54455 6.13811 8.8049L2.60952 12.3335H5.00004C5.36823 12.3335 5.66671 12.632 5.66671 13.0002C5.66671 13.3684 5.36823 13.6668 5.00004 13.6668H1.00004C0.631851 13.6668 0.333374 13.3684 0.333374 13.0002V9.00016C0.333374 8.63197 0.631851 8.3335 1.00004 8.3335C1.36823 8.3335 1.66671 8.63197 1.66671 9.00016V11.3907L5.1953 7.86209Z"
      fill="#3D3D3D"
    />
  </SvgIcon>
);

FullscreenIcon.displayName = 'FullscreenIcon';
