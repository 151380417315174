import React, { FC, useState } from 'react';
import { ComponentProps, Divider, Icon, Input, Typography } from '@components';
import styles from './ListEditor.module.scss';
import { colors, common, offsets } from '@componentsStyles';

export interface ListEditorProps extends ComponentProps {
  title: string;
  label: string;
  items: string[];
  setItems: (items: string[]) => void;
  alreadyExistText?: string;
  regex?: RegExp;
  notValidText?: string;
}

export const ListEditor: FC<ListEditorProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    items,
    setItems,
    regex,
    notValidText = 'Value is not valid',
    title,
    label,
    alreadyExistText = 'Value is already exist'
  } = props;

  const [newItem, setNewItem] = useState<string>('');
  const [isValueUnique, setIsValueUnique] = useState<boolean>(true);
  const [isValueValid, setIsValueValid] = useState<boolean>(true);

  const onDelete = (item: string) => {
    const newItems = items?.filter((el) => el !== item);
    setItems(newItems);
  };

  const addNewItem = async () => {
    if (newItem.length === 0) {
      return;
    }

    if (items?.find((el) => el === newItem)) {
      setIsValueUnique(false);
      return;
    } else {
      setIsValueUnique(true);
    }

    if (regex) {
      const isValid = Boolean(String(newItem).match(regex));
      setIsValueValid(isValid);
      if (!isValid) {
        return;
      }
    }

    setItems([...items, newItem]);
    setNewItem('');
  };

  const onChangeValue = (value: string | number) => {
    setIsValueUnique(true);
    setIsValueValid(true);
    setNewItem(String(value));
  };

  return (
    <div className={className} style={style} id={id} data-testid={dataTestId}>
      <Input
        errorMessage={
          !isValueUnique ? alreadyExistText : !isValueValid ? notValidText : ''
        }
        className={offsets['mb-20']}
        label={label}
        value={newItem}
        onEnter={addNewItem}
        withIcon
        onChange={(value) => onChangeValue(value)}
        data-testid={'list-editor-input'}
      />

      {title && (
        <div>
          <Typography
            element={'div'}
            className={styles.header}
            variant="body-2"
          >
            {title}
          </Typography>
          <Divider style={{ borderColor: colors.gray20Color }} />
        </div>
      )}

      {items?.map((item, key) => {
        return (
          <div
            key={key}
            className={styles.item}
            data-testid={'list-editor-item'}
          >
            <Typography
              className={common['no-text-transform']}
              element={'div'}
              variant={'body-1'}
            >
              {item}
            </Typography>
            <Icon
              dataTestId={'list-editor-close'}
              className={styles.close}
              onClick={() => onDelete(item)}
              name={'close'}
              size={'s'}
            />
          </div>
        );
      })}
    </div>
  );
};

ListEditor.displayName = 'ListEditor';
