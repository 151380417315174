export const getUniqueYears = (timestamps: number[]): number[] => {
  const yearsSet = new Set<number>();

  // Convert each timestamp to a year and add it to the set
  for (const timestamp of timestamps) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    // Convert year to UNIX timestamp and add it to the set
    yearsSet.add(new Date(year, 0, 1).getTime());
  }

  // Convert the set back to an array and return it
  return Array.from(yearsSet);
};
