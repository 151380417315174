import React, { FC } from 'react';
import cn from 'classnames';
import { Avatar, Chevron, ComponentProps, Typography } from '@components';
import styles from './UserTableBlock.module.scss';
import { colors, common, offsets } from '@componentsStyles';
import { UserTableBlockTranslations } from '@components/User/UserTableBlock/dataTypes';

export interface UserTableBlockProps extends ComponentProps {
  user: {
    fullname?: string;
    email?: string;
    avatar?: string;
    isExternal?: boolean;
    isOrganizationAdmin?: boolean;
    fromAzure?: boolean;
    isDeleted?: boolean;
  };
  translations?: UserTableBlockTranslations;
  showAvatar?: boolean;
  isActive?: boolean;
}

const defaultTranslations: UserTableBlockTranslations = {
  external: 'external',
  azureAD: 'azure AD',
  admin: 'admin',
  deleted: 'deleted'
};

export const UserTableBlock: FC<UserTableBlockProps> = (props) => {
  const {
    className,
    id,
    dataTestId,
    style,
    user,
    translations = defaultTranslations,
    showAvatar,
    isActive = true
  } = props;

  return (
    <div id={id} className={className} style={style} data-testid={dataTestId}>
      <div className={styles.user}>
        {showAvatar && (
          <Avatar
            image={user?.avatar}
            name={user?.fullname}
            size={'small'}
            disabled
            className={offsets['mr-8']}
          />
        )}

        <div>
          <div className={styles.username}>
            <Typography
              element="div"
              variant="body-5"
              color={isActive ? colors.gray100Color : colors.gray40Color}
            >
              {user?.fullname}
            </Typography>
            {user?.isOrganizationAdmin && (
              <Chevron
                backgroundColor={isActive ? colors.gold : colors.palettesGold40}
                className={offsets['ml-8']}
              >
                {translations.admin}
              </Chevron>
            )}
            {user?.isExternal && (
              <Chevron
                backgroundColor={
                  isActive ? colors.gray60Color : colors.gray40Color
                }
                className={offsets['ml-8']}
              >
                {translations.external}
              </Chevron>
            )}
            {user?.fromAzure && (
              <Chevron
                backgroundColor={
                  isActive ? colors.blue50Color : colors.palettesBlue10
                }
                className={offsets['ml-8']}
              >
                {translations.azureAD}
              </Chevron>
            )}
            {user?.isDeleted && (
              <Chevron
                className={offsets['ml-8']}
                backgroundColor={colors.strawberry}
              >
                {translations.deleted}
              </Chevron>
            )}
          </div>

          {user?.email && (
            <Typography
              element="div"
              variant="body-6"
              color={isActive ? colors.gray100Color : colors.gray40Color}
              className={cn(offsets['mt-2'], common['no-text-transform'])}
            >
              {user?.email}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

UserTableBlock.displayName = 'UserTableBlock';
