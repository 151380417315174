import React, {
  AnchorHTMLAttributes,
  forwardRef,
  Fragment,
  useMemo
} from 'react';
import cn from 'classnames';
import { ComponentProps, Typography } from '@components';
import { LinkType } from './dataTypes';
import styles from './Link.module.scss';
import { NavLinkProps } from 'react-router-dom';

export interface LinkProps
  extends ComponentProps,
    AnchorHTMLAttributes<HTMLElement> {
  onClick?: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  type?: LinkType;
  href?: string;
  isLocalRoute?: boolean;
  NavLink?: React.ForwardRefExoticComponent<
    NavLinkProps & React.RefAttributes<HTMLAnchorElement>
  >;
  to?: string;
}

export type LinkRef = HTMLAnchorElement;

export const Link = forwardRef<LinkRef, LinkProps>((props, ref) => {
  const {
    type = 'primary',
    children,
    className,
    onClick,
    NavLink,
    to,
    id,
    disabled,
    style,
    href,
    isLocalRoute,
    dataTestId
  } = props;

  const linkClassNames = useMemo(() => {
    switch (type) {
      case 'primary':
        return styles.link_primary;

      case 'button':
        return styles.link_button;

      default:
        return styles.link_primary;
    }
  }, [type]);

  const handleClick = (
    event: React.MouseEvent<HTMLLinkElement | HTMLAnchorElement>
  ) => {
    if (disabled) {
      event.preventDefault();
      return;
    }

    if (onClick) {
      onClick(event);
    }
  };

  const classNames = cn(
    className,
    linkClassNames,
    disabled && styles.link_disabled
  );

  return (
    <Fragment>
      {isLocalRoute && NavLink && to ? (
        <NavLink
          {...props}
          to={to}
          ref={ref}
          id={id}
          style={style}
          className={classNames}
          onClick={handleClick}
          data-testid={dataTestId}
        >
          <Typography variant="link">{children}</Typography>
        </NavLink>
      ) : (
        <a
          {...props}
          href={href}
          ref={ref}
          id={id}
          style={style}
          className={classNames}
          onClick={handleClick}
          data-testid={dataTestId}
        >
          <Typography variant="link">{children}</Typography>
        </a>
      )}
    </Fragment>
  );
});

Link.displayName = 'Link';
