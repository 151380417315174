import cn from 'classnames';
import React, { FC } from 'react';
import styles from './DataInfo.module.scss';

export interface DataInfoProps {
  className?: string;
  children?: React.ReactNode;
}

export const DataInfo: FC<DataInfoProps> = (props) => {
  const { className, children } = props;

  return (
    <div className={cn(styles.wrapper, className)}>
      <>{children}</>
    </div>
  );
};
