export type LogLevel = 'none' | 'error' | 'warn' | 'info' | 'log';
export type LogProvider = {
  log(message: string, ...args: unknown[]): void;
  error(message: string, ...args: unknown[]): void;
  warn(message: string, ...args: unknown[]): void;
  info(message: string, ...args: unknown[]): void;
};

export type LoggerOptions = {
  level?: LogLevel;
  provider?: LogProvider;
};

export class Logger {
  private logLevel: LogLevel;
  private provider: LogProvider;

  private logLevels: Record<LogLevel, number> = {
    none: 0,
    error: 1,
    warn: 2,
    info: 3,
    log: 4
  };

  constructor(options: LoggerOptions = {}) {
    this.logLevel =
      options.level && Object.keys(this.logLevels).includes(options.level)
        ? options.level
        : 'log';
    this.provider = options.provider || console;
  }

  shouldLog(level: LogLevel): boolean {
    return this.logLevels[level] <= this.logLevels[this.logLevel];
  }

  setLogLevel(level: LogLevel): void {
    if (Object.keys(this.logLevels).includes(level)) {
      this.logLevel = level;
    }
  }

  setProvider(provider: LogProvider): void {
    this.provider = provider;
  }

  log(message: string, ...args: unknown[]): void {
    if (this.shouldLog('log')) {
      this.provider.log(message, ...args);
    }
  }

  error(message: string, ...args: unknown[]): void {
    if (this.shouldLog('error')) {
      this.provider.error(message, ...args);
    }
  }

  warn(message: string, ...args: unknown[]): void {
    if (this.shouldLog('warn')) {
      this.provider.warn(message, ...args);
    }
  }
  info(message: string, ...args: unknown[]): void {
    if (this.shouldLog('info')) {
      this.provider.info(message, ...args);
    }
  }
  hideConsoleLogs(): void {
    if (this.logLevels['log'] >= this.logLevels[this.logLevel]) {
      console.debug = function (): void {};
    }
    if (this.logLevels['info'] >= this.logLevels[this.logLevel]) {
      console.log = function (): void {};
    }
    if (this.logLevels['warn'] >= this.logLevels[this.logLevel]) {
      console.info = function (): void {};
    }
    if (this.logLevels['error'] >= this.logLevels[this.logLevel]) {
      console.warn = function (): void {};
    }
    if (this.logLevels['none'] >= this.logLevels[this.logLevel]) {
      console.error = function (): void {};
    }
  }
}

export const logger = new Logger();
