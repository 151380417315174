import React, { FC, useMemo } from 'react';
import { offsets } from '@componentsStyles';
import {
  ComponentProps,
  Divider,
  HeaderApp,
  HeaderAppItem,
  HeaderAppNames,
  Icon
} from '@components';
import styles from './AppSwitcher.module.scss';
import classNames from 'classnames';
import { useComponentVisible } from '@componentsUtils';

const indicator = require('@assets/images/indicator.svg');

export interface AppSwitcherProps extends ComponentProps {
  currentApp: HeaderApp;
  apps: HeaderAppItem[];
}

export const AppSwitcher: FC<AppSwitcherProps> = (props) => {
  const { id, style, className, currentApp, apps } = props;

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const dropdownItem = (appName: HeaderApp, apps: HeaderAppItem[]) => {
    if (!apps || apps.length === 0) {
      return;
    }
    const app = apps?.find((el) => el.name === appName);

    return (
      app && (
        <a
          href={app?.url}
          className={styles['dropdown-item']}
          onClick={() => setIsComponentVisible(false)}
        >
          {app?.name === currentApp && (
            <img
              src={indicator}
              className={styles.indicator}
              alt={'current app indicator'}
            />
          )}
          <Icon name={`logo-${app.name}`} />
        </a>
      )
    );
  };

  const showDivider = useMemo(() => {
    const administration = apps?.find(
      (el) => el?.name === HeaderAppNames.Administration
    );
    const omni = apps?.find((el) => el?.name === HeaderAppNames.Omni);
    const modelTree = apps?.find((el) => el?.name === HeaderAppNames.ModelTree);
    const fundy = apps?.find((el) => el?.name === HeaderAppNames.Fundy);
    const account = apps?.find((el) => el?.name === HeaderAppNames.Account);
    const insights = apps?.find((el) => el?.name === HeaderAppNames.Insights);

    return (
      (administration || omni) && (modelTree || insights || fundy || account)
    );
  }, [apps]);

  return (
    <div
      id={id}
      className={classNames(className, styles.wrapper)}
      style={style}
      ref={ref}
    >
      <div
        className={
          apps?.length > 1 ? styles.switcher : styles['switcher--disabled']
        }
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        {apps?.length > 1 && (
          <Icon
            id="app-switcher"
            name={'switcher'}
            size={'m'}
            className={
              isComponentVisible ? styles['icon--active'] : styles.icon
            }
          />
        )}
        <Icon
          name={`logo-${currentApp}${
            isComponentVisible && apps?.length > 1 ? '-disabled' : ''
          }`}
        />
      </div>

      {isComponentVisible && apps?.length > 1 && (
        <div className={styles.dropdown}>
          {dropdownItem(HeaderAppNames.Account, apps)}
          {dropdownItem(HeaderAppNames.ModelTree, apps)}
          {dropdownItem(HeaderAppNames.Fundy, apps)}
          {dropdownItem(HeaderAppNames.Insights, apps)}

          {showDivider && <Divider className={offsets['my-20']} />}

          {dropdownItem(HeaderAppNames.Administration, apps)}
          {dropdownItem(HeaderAppNames.Omni, apps)}
        </div>
      )}
    </div>
  );
};

AppSwitcher.displayName = 'AppSwitcher';
