import React, { FC } from 'react';
import { ComponentProps, Icon, SelectItem, Typography } from '@components';
import styles from './Chips.module.scss';

export interface ChipsProps extends ComponentProps {
  item: SelectItem;
  onClose: (item: SelectItem) => void;
  disabled?: boolean;
}

export const Chips: FC<ChipsProps> = (props) => {
  const { id, style, className, dataTestId, disabled, item, onClose } = props;

  const onCloseClick = (event: React.MouseEvent, item: SelectItem) => {
    event.stopPropagation();
    if (onClose) {
      onClose(item);
    }
  };

  return (
    <div id={id} style={style} className={className} data-testid={dataTestId}>
      <Typography
        variant={'label-1'}
        className={item?.label ? styles.value : styles['value--hidden']}
      >
        {item?.label}

        <div
          onClick={(event) => onCloseClick(event, item)}
          className={
            item?.label && !disabled ? styles.close : styles['close--disabled']
          }
          data-testid={'close-chips'}
        >
          <Icon name="close" size="xs" color="disabled" />
        </div>
      </Typography>
    </div>
  );
};

Chips.displayName = 'Chips';
