import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const BellAvailableIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.05033 3.05025C8.36309 1.7375 10.1436 1 12.0001 1C13.8566 1 15.6371 1.7375 16.9498 3.05025C18.2626 4.36301 19.0001 6.14349 19.0001 8C19.0001 11.3527 19.7171 13.4346 20.378 14.6461C20.7098 15.2544 21.0329 15.6535 21.2573 15.8904C21.3698 16.0091 21.4581 16.0878 21.5114 16.1322C21.538 16.1544 21.5558 16.168 21.5635 16.1737L21.5664 16.1758C21.9249 16.4221 22.0835 16.8725 21.9572 17.2898C21.8295 17.7115 21.4407 18 21.0001 18H3.00008C2.55941 18 2.17068 17.7115 2.04299 17.2898C1.91664 16.8725 2.07528 16.4221 2.43377 16.1758L2.43663 16.1737C2.44432 16.168 2.46218 16.1544 2.4888 16.1322C2.54202 16.0878 2.6304 16.0091 2.74288 15.8904C2.9673 15.6535 3.29039 15.2544 3.62218 14.6461C4.28301 13.4346 5.00008 11.3527 5.00008 8C5.00008 6.14348 5.73758 4.36301 7.05033 3.05025ZM5.1494 16H18.8508C18.7747 15.8753 18.6983 15.7434 18.6222 15.6039C17.783 14.0654 17.0001 11.6473 17.0001 8C17.0001 6.67392 16.4733 5.40215 15.5356 4.46447C14.5979 3.52678 13.3262 3 12.0001 3C10.674 3 9.40223 3.52678 8.46454 4.46447C7.52686 5.40215 7.00008 6.67392 7.00008 8C7.00008 11.6473 6.21715 14.0654 5.37797 15.6039C5.30188 15.7434 5.22549 15.8753 5.1494 16Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.76829 20.135C10.246 19.8579 10.8579 20.0205 11.1351 20.4983C11.223 20.6498 11.3491 20.7756 11.5009 20.863C11.6528 20.9505 11.8249 20.9965 12.0001 20.9965C12.1753 20.9965 12.3474 20.9505 12.4992 20.863C12.651 20.7756 12.7772 20.6498 12.8651 20.4983C13.1422 20.0205 13.7541 19.8579 14.2318 20.135C14.7096 20.4122 14.8722 21.0241 14.5951 21.5018C14.3313 21.9564 13.9528 22.3338 13.4974 22.5961C13.042 22.8584 12.5256 22.9965 12.0001 22.9965C11.4745 22.9965 10.9581 22.8584 10.5027 22.5961C10.0473 22.3338 9.66878 21.9564 9.40506 21.5018C9.12794 21.0241 9.29056 20.4122 9.76829 20.135Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 6C23 8.76142 20.7614 11 18 11C15.2386 11 13 8.76142 13 6C13 3.23858 15.2386 1 18 1C20.7614 1 23 3.23858 23 6Z"
      fill="#ffffff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 6C21 7.65685 19.6569 9 18 9C16.3431 9 15 7.65685 15 6C15 4.34315 16.3431 3 18 3C19.6569 3 21 4.34315 21 6Z"
      fill={props.color}
    />
  </SvgIcon>
);

BellAvailableIcon.displayName = 'BellAvailableIcon';
