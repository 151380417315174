import dayjs from 'dayjs';
import React from 'react';
import styles from '../TableChart.module.scss';

interface TickProps {
  x?: number;
  y?: number;
  visibleTicksCount?: number;
  index?: number;
  payload?: {
    value: string;
  };
}

const CustomTickX = React.memo(
  ({ x, y, payload, visibleTicksCount, index }: TickProps) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={visibleTicksCount - 1 === index ? -31 : 31}
          y={0}
          dy={16}
          textAnchor="middle"
          fill="#666"
          className={styles.tick}
        >
          {dayjs(payload?.value).format('DD.MM.YYYY')}
        </text>
      </g>
    );
  }
);

export default CustomTickX;
