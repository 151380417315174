import React, { FC, useState } from 'react';
import colors from '@componentsStyles/colors.scss';
import { DropdownItemProps, Typography } from '@components';
import styles from './DropdownItem.module.scss';
import { common } from '@componentsStyles';

export const DropdownItem: FC<DropdownItemProps> = (props) => {
  const {
    noTextTransform,
    value,
    label,
    selected,
    onValueSelected,
    setIsComponentVisible
  } = props;

  const [isSelected, setIsSelected] = useState<boolean>(selected);

  const selectValue = (value: string, label: string) => {
    setIsSelected(!isSelected);
    setIsComponentVisible(false);
    onValueSelected(value, label);
  };

  return (
    <li
      className={styles.item}
      data-value={value}
      onClick={() => selectValue(value, label)}
    >
      <Typography
        color={isSelected && colors.goldColor}
        className={noTextTransform && common['no-text-transform']}
        variant="label-1"
      >
        {label}
      </Typography>
    </li>
  );
};
