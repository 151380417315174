import { StatusNotificationTranslations } from '@xq/ui-kit';
import { TFunction } from 'i18next';

export const getStatusNotificationTranslations = (
  t: TFunction
): StatusNotificationTranslations => {
  return {
    savedSuccessfully: t('notifications.savedSuccessfully'),
    badRequest: t('notifications.badRequest'),
    internalServerError: t('notifications.internalServerError')
  };
};
