import React, { FC, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import colors from '@componentsStyles/colors.scss';
import {
  Checkbox,
  Chips,
  ComponentProps,
  Divider,
  Icon,
  Loader,
  SelectItem,
  Typography
} from '@components';
import styles from './Multiselect.module.scss';
import { offsets } from '@componentsStyles';
import { useComponentVisible } from '@componentsUtils';
// todo investigate why @interfaces do not work
import { Position } from '../../interfaces';

export interface MultiselectProps extends ComponentProps {
  /**
   * Label
   */
  label: string;
  /**
   * All items list
   */
  items: SelectItem[];
  /**
   * Specifies the value of an Input
   */
  selected: SelectItem[];
  /**
   * Called when value of an Input changed
   */
  onChange: (value: SelectItem[]) => void;
  /**
   * Specifies that an Input should be disabled
   */
  disabled?: boolean;
  /**
   * Specifies that options are loading
   */
  isLoading?: boolean;
  /**
   * Specifies that an Input should be required
   */
  required?: boolean;
  /**
   * Shows error message
   */
  errorMessage?: string;
  /**
   * Select all text
   */
  selectAllText?: string;
}

export const Multiselect: FC<MultiselectProps> = (props) => {
  const {
    id,
    style,
    className,
    dataTestId,
    disabled,
    isLoading,
    required,
    label,
    items,
    errorMessage,
    selected,
    onChange,
    selectAllText = 'Select all'
  } = props;

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [inputBlockHeight, setInputBlockHeight] = useState(null);

  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const selectItem = (item: SelectItem) => {
    if (!onChange) {
      return;
    }

    let updatedSelected: SelectItem[];

    if (selected?.find((el) => el.value === item.value)) {
      updatedSelected = selected?.filter((el) => el.value !== item.value);
    } else {
      updatedSelected = [...selected, item];
    }

    onChange(updatedSelected);
  };

  const onInputClick = () => {
    if (disabled) {
      return;
    }
    setIsComponentVisible(!isComponentVisible);
  };

  const onCloseClick = (item: SelectItem) => {
    if (onChange) {
      onChange(selected?.filter((el) => el.value !== item.value));
    }
  };

  const selectAll = () => {
    if (!onChange) {
      return;
    }

    if (isAllSelected) {
      setIsAllSelected(false);
      onChange([]);
    } else {
      setIsAllSelected(true);
      onChange(items);
    }
  };

  useEffect(() => {
    if (selected?.length === items?.length) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [selected, items]);

  const inputClasses = useMemo(() => {
    if (disabled) {
      return styles['input--disabled'];
    }
    return cn(styles.input, errorMessage && styles.input_error);
  }, [disabled, errorMessage]);

  const [position, setPosition] = useState<Position>(Position.Bottom);

  const updatePosition = () => {
    if (!ref?.current) {
      return;
    }
    const selectRect = ref?.current?.getBoundingClientRect();
    const distanceToBottom = window.innerHeight - selectRect?.bottom;
    const distanceToTop = selectRect?.top;

    if (distanceToBottom < distanceToTop) {
      setPosition(Position.Top);
    } else {
      setPosition(Position.Bottom);
    }
  };

  const updateInputHeight = () => {
    if (!ref?.current) {
      return;
    }
    const blockElement = ref?.current;
    const blockHeight = blockElement.getBoundingClientRect()?.height;

    setInputBlockHeight(blockHeight);
  };

  useEffect(() => {
    updatePosition();
  }, [
    ref,
    ref?.current?.getBoundingClientRect()?.top,
    ref?.current?.getBoundingClientRect()?.bottom
  ]);

  useEffect(() => {
    window.addEventListener('resize', updatePosition);
    window.addEventListener('resize', updateInputHeight);
    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('resize', updateInputHeight);
    };
  }, []);

  useEffect(() => {
    updateInputHeight();
  }, [ref, selected]);

  return (
    <div
      ref={ref}
      id={id}
      style={style}
      className={cn(
        className,
        disabled ? styles['wrapper--disabled'] : styles.wrapper
      )}
      data-testid={dataTestId}
    >
      <div className={styles.form} onClick={onInputClick}>
        <div tabIndex={0} className={inputClasses}>
          <div className={styles.values}>
            {selected?.map((item, key) => {
              return (
                <Chips
                  className={offsets['mb-8']}
                  key={key}
                  item={item}
                  onClose={() => onCloseClick(item)}
                />
              );
            })}
          </div>
        </div>

        <label
          className={
            selected?.length > 0 || isComponentVisible
              ? styles['label--up']
              : styles.label
          }
        >
          {label}
          {required && <span className={styles['required-symbol']}>*</span>}
        </label>

        <Icon name="chevron-down" size="s" className={styles.icon} />

        {errorMessage && !disabled && (
          <Typography
            variant="system"
            color={colors.red70Color}
            className={styles['error-message']}
          >
            {errorMessage}
          </Typography>
        )}
      </div>

      {isComponentVisible && (
        <div
          className={
            position === Position.Top
              ? styles['list--top']
              : styles['list--bottom']
          }
          style={position === Position.Top ? { bottom: inputBlockHeight } : {}}
        >
          <ul>
            {isLoading && (
              <div className={styles.loader}>
                <Loader size={'m'} color={'orange'} />
              </div>
            )}

            {!isLoading && (
              <div>
                <div className={styles['select-all']}>
                  <Checkbox
                    className={styles['select-all-checkbox']}
                    checked={isAllSelected}
                    onChange={selectAll}
                    label={selectAllText}
                  />
                </div>
                <Divider type="thin" />
                <div>
                  {items?.map((item: SelectItem) => {
                    return (
                      <li key={item.value} className={styles['list-item']}>
                        <Checkbox
                          onChange={() => selectItem(item)}
                          className={offsets['mr-8']}
                          // todo optimize
                          checked={Boolean(
                            selected?.find((el) => el.value === item.value)
                          )}
                          label={item.label}
                        />
                      </li>
                    );
                  })}
                </div>
              </div>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

Multiselect.displayName = 'Multiselect';
