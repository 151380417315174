import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const ClipboardIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2.99844C7 1.89387 7.89543 0.998444 9 0.998444H15C16.1046 0.998444 17 1.89387 17 2.99844H18C18.7956 2.99844 19.5587 3.31451 20.1213 3.87712C20.6839 4.43973 21 5.20279 21 5.99844V19.9984C21 20.7941 20.6839 21.5572 20.1213 22.1198C19.5587 22.6824 18.7957 22.9984 18 22.9984H6C5.20435 22.9984 4.44129 22.6824 3.87868 22.1198C3.31607 21.5572 3 20.7941 3 19.9984V5.99844C3 5.20279 3.31607 4.43973 3.87868 3.87712C4.44129 3.31451 5.20435 2.99844 6 2.99844H7ZM7 4.99844H6C5.73478 4.99844 5.48043 5.1038 5.29289 5.29134C5.10536 5.47887 5 5.73323 5 5.99844V19.9984C5 20.2637 5.10536 20.518 5.29289 20.7055C5.48043 20.8931 5.73478 20.9984 6 20.9984H18C18.2652 20.9984 18.5196 20.8931 18.7071 20.7055C18.8946 20.518 19 20.2637 19 19.9984V5.99844C19 5.73323 18.8946 5.47887 18.7071 5.29134C18.5196 5.1038 18.2652 4.99844 18 4.99844H17C17 6.10301 16.1046 6.99844 15 6.99844H9C7.89543 6.99844 7 6.10301 7 4.99844ZM15 4.99844H9V2.99844H15V4.99844Z"
      fill={props.color}
    />
  </SvgIcon>
);

ClipboardIcon.displayName = 'ClipboardIcon';
