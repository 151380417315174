import React, { FC } from 'react';
import {
  Card,
  ComponentProps,
  ContactPersonTranslations,
  Icon,
  SkeletonPlaceholder,
  Tooltip,
  Typography
} from '@components';
import cn from 'classnames';
import styles from './ContactPerson.module.scss';
import { common, display, offsets } from '@componentsStyles';
import { DASH } from '@constants';

export interface ContactPersonProps extends ComponentProps {
  name?: string;
  role?: string;
  phoneNumber?: string;
  email?: string;
  comments?: string;
  isEdit?: boolean;
  isLoading?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  onSeeDetails?: () => void;
  translations?: ContactPersonTranslations;
}

const defaultTranslations: ContactPersonTranslations = {
  seeDetails: 'See details',
  role: 'Role',
  name: 'Name',
  phoneNumber: 'Phone number',
  email: 'Email',
  comments: 'Comments',
  delete: 'Delete',
  edit: 'Edit'
};

export const ContactPerson: FC<ContactPersonProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    translations = defaultTranslations,
    name,
    role,
    phoneNumber,
    email,
    comments,
    isEdit,
    isLoading,
    onDelete,
    onEdit,
    onSeeDetails
  } = props;

  return (
    <Card
      className={cn(
        className,
        isLoading ? styles['card--loading'] : styles.card
      )}
      style={style}
      id={id}
      data-testid={dataTestId}
    >
      <div className={styles.header}>
        <Typography className={offsets['mr-8']} variant="h5">
          {isLoading ? translations.name : name || DASH}
        </Typography>
        <div className={styles.details}>
          {isEdit ? (
            <div className={cn(display['d-flex'])}>
              <Tooltip content={translations.edit}>
                <Icon
                  dataTestId="edit-icon"
                  size="s"
                  name="edit"
                  onClick={onEdit && onEdit}
                  className={cn(offsets['mr-8'], styles['edit-icon'])}
                />
              </Tooltip>

              <Tooltip content={translations.delete}>
                <Icon
                  dataTestId="delete-icon"
                  onClick={onDelete && onDelete}
                  size="s"
                  name="trash"
                  className={styles['edit-icon']}
                />
              </Tooltip>
            </div>
          ) : (
            <div className={styles.redirect}>
              <Tooltip content={translations.seeDetails}>
                <Icon
                  dataTestId="redirect-icon"
                  size="s"
                  name="chevrons-right"
                  onClick={onSeeDetails && onSeeDetails}
                />
              </Tooltip>
            </div>
          )}
        </div>
      </div>

      <div className={styles.row}>
        {isLoading ? (
          <SkeletonPlaceholder width={255} height={18} />
        ) : (
          <>
            <Typography className={offsets['mr-4']} variant="body-4">
              {translations.role}:
            </Typography>
            <Typography variant="body-5">{role || DASH}</Typography>
          </>
        )}
      </div>

      <div className={styles.row}>
        {isLoading ? (
          <SkeletonPlaceholder width={190} height={18} />
        ) : (
          <>
            <Typography className={offsets['mr-4']} variant="body-4">
              {translations.phoneNumber}:
            </Typography>
            <Typography variant="body-5">{phoneNumber || DASH}</Typography>
          </>
        )}
      </div>

      <div className={styles.row}>
        {isLoading ? (
          <SkeletonPlaceholder width={128} height={18} />
        ) : (
          <>
            <Typography className={offsets['mr-4']} variant="body-4">
              {translations.email}:
            </Typography>
            <Typography
              className={common['no-text-transform']}
              variant="body-5"
            >
              {email || DASH}
            </Typography>
          </>
        )}
      </div>

      <div className={styles.row}>
        {isLoading ? (
          <SkeletonPlaceholder width={74} height={18} />
        ) : (
          <>
            <Typography className={offsets['mr-4']} variant="body-4">
              {translations.comments}:
            </Typography>
            <Typography variant="body-5">{comments || DASH}</Typography>
          </>
        )}
      </div>
    </Card>
  );
};

ContactPerson.displayName = 'ContactPerson';
