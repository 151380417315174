import React, { FC, Fragment, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  ComponentProps,
  Typography,
  OrganizationItem,
  Icon,
  Divider
} from '@components';
import styles from './OrganizationDropdown.module.scss';
import { offsets } from '@componentsStyles';

export interface OrganizationDropdownProps extends ComponentProps {
  currentOrganizationId: string;
  organizations: OrganizationItem[];
  onOrganizationChange?(): void;
}

export const OrganizationDropdown: FC<OrganizationDropdownProps> = (props) => {
  const {
    id,
    style,
    className,
    organizations,
    currentOrganizationId,
    onOrganizationChange
  } = props;

  const [isShowList, setShowList] = useState<boolean>(false);
  const [organizationId, setOrganizationId] = useState<string>(
    currentOrganizationId
  );

  const showList = () => {
    if (organizations?.length > 1) {
      setShowList(!isShowList);
    }
  };

  const currentOrg = useMemo(() => {
    if (!organizations || organizations?.length === 0) {
      return null;
    }
    const organization = organizations?.find(
      (org) => org?.uuid === organizationId
    );
    if (!organization) {
      return organizations[0];
    }
    return organization;
  }, [organizations, organizationId]);

  const filteredOrganizations = useMemo(() => {
    return organizations?.filter((org) => org?.uuid !== organizationId);
  }, [organizations, organizationId]);

  const setOrganization = (uuid: string) => {
    setShowList(false);
    setOrganizationId(uuid);
    onOrganizationChange && onOrganizationChange();
  };

  return (
    <div className={className} id={id} style={style}>
      {organizations?.length > 1 && (
        <Fragment>
          <div className={styles.link} onClick={showList}>
            {currentOrg && (
              <Typography variant={'body-4'}>{currentOrg?.name}</Typography>
            )}

            {organizations?.length > 1 && (
              <Icon
                className={offsets['ml-2']}
                name={'chevron-down'}
                size={'s'}
              />
            )}
          </div>

          {isShowList && (
            <Fragment>
              {filteredOrganizations?.map((org) => {
                return (
                  <div
                    className={classNames(styles['link--small'])}
                    key={org?.uuid}
                    onClick={() => setOrganization(org?.uuid)}
                  >
                    <Typography variant={'label-2'}>{org?.name}</Typography>
                  </div>
                );
              })}

              <Divider type="bold" className={classNames(styles.divider)} />
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

OrganizationDropdown.displayName = 'OrganizationDropdown';
