import React, { ErrorInfo } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { withNavigation } from './hocs';
import { logger } from './services';

interface StateInterface {
  errorMessage?: string;
  redirect?: boolean;
}

interface PropsInterface {
  children: React.ReactNode;
  navigate?: NavigateFunction;
}

class ErrorBoundary extends React.Component<PropsInterface, StateInterface> {
  constructor(props: PropsInterface) {
    super(props);
    this.state = {
      errorMessage: '',
      redirect: false
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { errorMessage: error.toString() };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.logErrorToServices(error.toString(), info.componentStack);
  }

  logErrorToServices = logger.error;

  componentDidUpdate(
    previousProps: PropsInterface,
    previousState: StateInterface
  ) {
    if (this.state.errorMessage !== previousState.errorMessage) {
      this.props.navigate('/internal-error');
      this.setState({ errorMessage: '' });
    }
  }

  render() {
    return this.props.children;
  }
}

export default withNavigation(ErrorBoundary);
