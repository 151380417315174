import React, { FC, Fragment } from 'react';
import {
  Card,
  Chevron,
  ComponentProps,
  Divider,
  Icon,
  IconNames,
  SelectItem,
  Typography
} from '@components';
import { UserLicenseTranslations } from './dataTypes';
import styles from './UserLicenseCard.module.scss';
import commonStyles from '../../LicenseCard.module.scss';
import { offsets } from '@componentsStyles';
import { LicenseApp } from '@components/LicenseCard/dataTypes';
import { LoadingLicenseCard } from '../LoadingLicenseCard';
import classNames from 'classnames';
import dayjs from 'dayjs';

export interface UserLicenseCardProps extends ComponentProps {
  /** Necessary translations */
  translations: UserLicenseTranslations;
  /** For which application the license is used */
  app: LicenseApp;
  /** License description */
  description: string;
  /** Selected role */
  selectedRole?: SelectItem;
  /** license type (Investment Management / valuation / pro) */
  licenseType?: string;
  /** Is a card disabled */
  disabled?: boolean;
  /** Is a card loading */
  isLoading?: boolean;
  /** Is it a trial version */
  isTrial?: boolean;
  /** Expiry date if trial */
  expiryDate?: Date;
}

export const UserLicenseCard: FC<UserLicenseCardProps> = (props) => {
  const {
    id,
    style,
    className,
    dataTestId,
    translations,
    app,
    description,
    disabled,
    licenseType,
    selectedRole,
    isLoading,
    isTrial,
    expiryDate
  } = props;

  const logoName: IconNames = `logo-${app}${disabled ? '-disabled' : ''}`;
  const cardClasses = classNames(
    className,
    disabled ? styles['card--disabled'] : styles.card
  );

  return (
    <Card
      id={id}
      style={style}
      className={cardClasses}
      data-testid={dataTestId}
    >
      {isLoading ? (
        <LoadingLicenseCard />
      ) : (
        <Fragment>
          <div className={commonStyles.header}>
            <Icon name={logoName} className={commonStyles.logo} />
            {isTrial && (
              <Chevron className={offsets['ml-12']} disabled={disabled}>
                {translations.trialVersion}
              </Chevron>
            )}
          </div>

          {licenseType && (
            <Typography
              element="div"
              className={offsets['mb-12']}
              variant="body-5"
            >
              {licenseType}
            </Typography>
          )}

          {isTrial && expiryDate && (
            <Typography
              element="div"
              variant="body-3"
              className={offsets['mb-12']}
            >
              {translations?.expiryDate}:{' '}
              <Typography variant="body-5">
                <time dateTime={dayjs(expiryDate).format('YYYY-MM-DD')}>
                  {dayjs(expiryDate).format('DD.MM.YYYY')}
                </time>
              </Typography>
            </Typography>
          )}
          <Typography element="div" variant="body-3">
            {description}
          </Typography>

          {selectedRole && (
            <div className={offsets['pt-20']}>
              <Divider
                type="thin"
                className={
                  disabled
                    ? commonStyles['divider--disabled']
                    : commonStyles.divider
                }
              />
              <Typography
                element="div"
                variant="body-5"
                className={offsets['pt-16']}
              >
                {translations.role}
              </Typography>
              <Typography
                element="div"
                variant="body-3"
                className={offsets['pt-4']}
              >
                {selectedRole.label}
              </Typography>
            </div>
          )}
        </Fragment>
      )}
    </Card>
  );
};

UserLicenseCard.displayName = 'UserLicenseCard';
