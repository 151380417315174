import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const KeyIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.639 1.29289C22.0295 1.68342 22.0295 2.31658 21.639 2.70711L20.3461 4L22.639 6.29289C23.0295 6.68342 23.0295 7.31658 22.639 7.70711L19.139 11.2071C18.7485 11.5976 18.1153 11.5976 17.7248 11.2071L15.4319 8.91421L12.6855 11.6607C12.9929 12.0765 13.2504 12.5285 13.4516 13.0075C13.7836 13.7978 13.9561 14.646 13.9589 15.5033C13.9618 16.3605 13.7951 17.2099 13.4683 18.0024C13.1416 18.795 12.6613 19.5151 12.0552 20.1213C11.449 20.7274 10.7289 21.2077 9.93635 21.5344C9.1438 21.8612 8.29445 22.0279 7.4372 22.025C6.57995 22.0221 5.73173 21.8497 4.94138 21.5177C4.15104 21.1857 3.43418 20.7006 2.83209 20.0904L2.82459 20.0828C1.64056 18.8568 0.985436 17.2149 1.00025 15.5106C1.01506 13.8063 1.69865 12.176 2.90381 10.9709C4.10896 9.76574 5.73924 9.08214 7.44352 9.06733C8.81634 9.0554 10.1487 9.47819 11.2558 10.2619L20.2248 1.29289C20.6153 0.902369 21.2485 0.902369 21.639 1.29289ZM10.6538 12.3559C10.6445 12.3476 10.6353 12.339 10.6262 12.3303C9.77749 11.5106 8.64078 11.057 7.4609 11.0673C6.28101 11.0775 5.15236 11.5508 4.31802 12.3851C3.48368 13.2194 3.01042 14.3481 3.00017 15.528C2.98993 16.7061 3.44213 17.8412 4.25951 18.6895C4.67557 19.1101 5.17051 19.4446 5.71603 19.6738C6.26319 19.9037 6.85041 20.023 7.4439 20.025C8.03738 20.027 8.62539 19.9116 9.17408 19.6854C9.72277 19.4592 10.2213 19.1267 10.641 18.707C11.0606 18.2874 11.3931 17.7889 11.6193 17.2402C11.8455 16.6915 11.9609 16.1035 11.9589 15.51C11.957 14.9165 11.8376 14.3293 11.6077 13.7821C11.3841 13.2498 11.0602 12.7656 10.6538 12.3559ZM16.8461 7.5L18.4319 9.08579L20.5177 7L18.9319 5.41421L16.8461 7.5Z"
      fill={props.color}
    />
  </SvgIcon>
);

KeyIcon.displayName = 'KeyIcon';
