import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const LogoFundyIconDisabled: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 66 20" width={66} height={20}>
    <path d="M4 17.2727L9.45455 14.5454V20L4 17.2727Z" fill="#DADADA" />
    <path
      d="M4 17.2727L13.0909 12.7273V7.27271L4 11.8182V17.2727Z"
      fill="#B3B3B3"
    />
    <path d="M4 11.8182L16.7273 5.45455V0L4 6.36364V11.8182Z" fill="#8F8F8F" />
    <path
      d="M27 14.984V5H32.904V6.136H28.28V9.032H32.008V10.168H28.28V14.984H27Z"
      fill="#121212"
    />
    <path
      d="M37.832 15.176C36.7547 15.176 35.9333 14.856 35.368 14.216C34.8133 13.5653 34.536 12.6693 34.536 11.528V5.016H35.864H36.024V5.144C35.96 5.208 35.9173 5.27733 35.896 5.352C35.8853 5.42667 35.88 5.55467 35.88 5.736V11.544C35.88 12.312 36.024 12.9253 36.312 13.384C36.6 13.832 37.112 14.056 37.848 14.056C38.584 14.056 39.096 13.832 39.384 13.384C39.6827 12.9253 39.832 12.296 39.832 11.496V5.016H41.144V11.48C41.144 12.3227 41.0053 13.016 40.728 13.56C40.4507 14.104 40.0613 14.5093 39.56 14.776C39.0693 15.0427 38.4933 15.176 37.832 15.176Z"
      fill="#121212"
    />
    <path
      d="M42.552 5.016H43.896L47.816 12.136L47.784 5.016H49.032H49.192V5.144C49.128 5.208 49.0853 5.27733 49.064 5.352C49.0533 5.42667 49.048 5.55467 49.048 5.736V14.984H47.896L43.8 7.432V14.984H42.552V5.016Z"
      fill="#121212"
    />
    <path
      d="M50.616 15V5.016H53.016C53.6027 5.016 54.0933 5.06933 54.488 5.176C54.8827 5.28267 55.2187 5.43733 55.496 5.64C55.7733 5.832 56.024 6.07733 56.248 6.376C56.472 6.67467 56.6533 7.016 56.792 7.4C56.9413 7.77333 57.0533 8.17867 57.128 8.616C57.2027 9.05333 57.24 9.53333 57.24 10.056C57.2187 10.824 57.1173 11.512 56.936 12.12C56.7653 12.728 56.504 13.2453 56.152 13.672C55.8107 14.0987 55.368 14.4293 54.824 14.664C54.28 14.888 53.6133 15 52.824 15H50.616ZM51.912 13.944H52.776C53.5227 13.944 54.1253 13.7947 54.584 13.496C55.0427 13.1867 55.3787 12.744 55.592 12.168C55.816 11.592 55.9227 10.904 55.912 10.104C55.912 9.28267 55.8107 8.57333 55.608 7.976C55.416 7.37867 55.1067 6.92 54.68 6.6C54.2533 6.28 53.6773 6.12 52.952 6.12H51.912V13.944Z"
      fill="#121212"
    />
    <path
      d="M61.24 14.984V11.048L58.2 5.016H59.672L61.992 9.624L64.072 5.016H65.464L62.664 11.048V14.984H61.24Z"
      fill="#121212"
    />
  </SvgIcon>
);

LogoFundyIconDisabled.displayName = 'LogoFundyIconDisabled';
