import { handleBackendError, modelTreeApiConfiguration, wait } from '@services';
import { ModelTreeRelease, ReleasesList } from '@pages';
import { ModelTreeReleasesMainPageApi } from '@xq/model-tree-releases-gateway-frontend-client';

export interface ReleasesService {
  getLatestReleaseVersion(): Promise<string>;
  getReleasesList(page?: number, limit?: number): Promise<ReleasesList>;
  getReleaseInfo(releaseVersion?: string): Promise<ModelTreeRelease>;
  downloadFile(releaseVersion: string): Promise<Blob>;
}

const mainPageGateway = new ModelTreeReleasesMainPageApi(
  modelTreeApiConfiguration
);

export class ReleasesService implements ReleasesService {
  async getLatestReleaseVersion(): Promise<string> {
    try {
      const response =
        await mainPageGateway.modelTreeReleasesMainPageControllerGetPageData();
      return response?.lastVersion;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.getLatestReleaseVersion.name
      );
    }
  }

  async getReleasesList(page?: number, limit?: number): Promise<ReleasesList> {
    try {
      return await mainPageGateway.modelTreeReleasesMainPageControllerGetReleaseList(
        { page, limit }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.getReleasesList.name
      );
    }
  }

  async getReleaseInfo(releaseVersion: string): Promise<ModelTreeRelease> {
    try {
      return await mainPageGateway.modelTreeReleasesMainPageControllerGetReleaseInfo(
        { modelTreeReleaseVersion: releaseVersion }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.getReleaseInfo.name
      );
    }
  }

  async downloadFile(releaseVersion: string): Promise<Blob> {
    try {
      return await mainPageGateway.modelTreeReleasesMainPageControllerGetReleaseFile(
        { modelTreeReleaseVersion: releaseVersion }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.downloadFile.name
      );
    }
  }
}

const releaseNoteMock =
  '**We’re constantly working to improve your ModelTree app expirience. \n' +
  'Here’s a summary of what has changed.**\n' +
  '\n' +
  '**KPI Dynamics Grid** provides details how [key asset indicators](https://www.exquance.com) have been changing from analysis to analysis. Now you can assess how each asset has performed on a timeline.\n' +
  '\n' +
  '**KPI Dynamics Chart** allows you to draw series of multiple asset indicators simultaneously. This makes it possible to visually compare the development of different indicators together for both one asset and several at once. Just send assets you want to check to KPI Dynamics tool.\n' +
  '\n' +
  '![image](https://cdn.exquance.com/landing/images/blog/proestate-toby.png)\n' +
  '\n' +
  '#### Improvements\n' +
  ' - General UX improvements\n' +
  ' - Added ability in Direct Cap models to include custom set of adjustment items\n' +
  ' - Added ability to use independent Cash Flow templates for Direct Cap and DCF when both valuation methods applied\n' +
  ' - Added ability to set forecast period up to 20 years in DCF valuation\n' +
  ' - Added ability to input and model ERRV-303 rents\n' +
  ' - Added ability to calculate PV of over/under rents\n' +
  ' - Moved asset forecast period editor to DCF settings panel\n' +
  " - Moved asset's Cash Flow template settings to appropriate valuation method settings (DCF, Direct Cap)\n" +
  ' - Calculate Market Value per sqm for each valuation method\n' +
  ' - Added "Depreciation" view to allow including depreciation for buildings and utilities in asset cash flow forecast models\n' +
  '\n' +
  '#### Investment Management improvements\n' +
  ' - Renamed Market Value to Net Asset Value (with levered cash flow models)\n' +
  ' - Renamed Property Market Value to Gross Asset Value\n' +
  '\n' +
  '#### Assets view improvements \n' +
  '- Added ability to sort assets by KPI value change\n' +
  '- Improved formatting of insignificant KPI value change\n' +
  '- Added "Send To" sub-menu to context menu\n' +
  "- Fixed: KPI Comparison doesn't properly refresh after switching previous analysis\n" +
  "- Fixed: Clear sorting of assets-grid doesn't work\n" +
  '\n' +
  '#### Fixes\n' +
  '- Column sorting in some Dictionary editors worked incorrectly\n' +
  "- Warning message doesn't appear when user updating Rates\n" +
  '- Impossible to enter value in empty fields on Asset Analysis Tab\n' +
  '- Incorrect grid cells behavior when entering Market Assumptions or Analysis Rates\n' +
  '- Entering Market Assumptions not working after importing new premises use types\n';

const releaseInfo: ModelTreeRelease = {
  version: '8.3.1',
  description:
    'KPI Dynamics Grid provides details how key asset indicators have been changing',
  publishDate: new Date(2022, 8, 12),
  notes: releaseNoteMock,
  fileVariantTags: []
};

const releaseNoteList: ReleasesList = {
  meta: {
    totalItems: 6,
    totalPages: 1,
    itemsPerPage: 10,
    currentPage: 1,
    itemCount: 6
  },
  items: [
    {
      publishDate: new Date(2023, 7, 13),
      description:
        'KPI Dynamics Grid provides details how key asset indicators have been changing',
      version: '8.3.1'
    },
    {
      publishDate: new Date(2023, 6, 8),
      description: "Clear sorting of assets-grid doesn't work",
      version: '8.3.0'
    },
    {
      publishDate: new Date(2023, 4, 8),
      description: 'Calculate Market Value per sqm for each valuation method',
      version: '8.2.14'
    },
    {
      publishDate: new Date(2023, 3, 21),
      description:
        'KPI Dynamics Chart allows you to draw series of multiple asset indicators simultaneously',
      version: '8.2.13'
    },
    {
      publishDate: new Date(2023, 1, 16),
      description: 'Added ability to sort assets by KPI value change',
      version: '8.2.12'
    },
    {
      publishDate: new Date(2023, 1, 8),
      description: 'Improved formatting of insignificant KPI value change',
      version: '8.2.11'
    }
  ]
};

export class ReleasesServiceMock implements ReleasesService {
  async getLatestReleaseVersion(): Promise<string> {
    try {
      /* api call */
      await wait(1000);
      return '8.3.2';
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.getLatestReleaseVersion.name
      );
    }
  }

  async getReleasesList(page?: number, limit?: number): Promise<ReleasesList> {
    try {
      await wait(1000);
      return releaseNoteList;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.getReleasesList.name
      );
    }
  }

  async getReleaseInfo(releaseVersion: string): Promise<ModelTreeRelease> {
    try {
      /* api call */
      await wait(1000);
      return releaseInfo;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.getReleaseInfo.name
      );
    }
  }

  async downloadFile(releaseVersion: string): Promise<Blob> {
    try {
      const blobMock = new Blob(['testing'], { type: 'application/pdf' });
      await wait(1000);
      return blobMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.downloadFile.name
      );
    }
  }
}
