import React, { FC } from 'react';
import classNames from 'classnames';
import { ComponentProps, Typography, SkeletonPlaceholder } from '@components';
import styles from './Superscript.module.scss';
import { colors } from '@componentsStyles';
import { SuperscriptVariant } from './dataTypes';

export interface SuperscriptProps extends ComponentProps {
  content: string;
  sign: string;
  isLoading?: boolean;
  isError?: boolean;
  variant?: SuperscriptVariant;
}

export const Superscript: FC<SuperscriptProps> = (props) => {
  const {
    content,
    sign,
    isLoading,
    isError,
    variant = 'h1',
    className,
    id,
    style,
    dataTestId
  } = props;

  const signVariant = () => {
    switch (variant) {
      case 'h1': {
        return 'superscript';
      }
      case 'h3': {
        return 'superscript-2';
      }
      default: {
        return 'superscript';
      }
    }
  };

  return (
    <div
      id={id}
      style={style}
      className={classNames(className, styles.superscript)}
      data-testid={dataTestId}
    >
      {isLoading ? (
        <SkeletonPlaceholder
          className={styles['content--loading']}
          type="rectangle"
          width={42}
          height={12}
        />
      ) : (
        <Typography
          variant={variant}
          className={styles.content}
          color={isError ? colors.red70Color : colors.gray100Color}
        >
          {content}
        </Typography>
      )}

      <Typography
        variant={signVariant()}
        className={styles.sign}
        color={isError ? colors.red70Color : colors.gray100Color}
      >
        {sign}
      </Typography>
    </div>
  );
};

Superscript.displayName = 'Superscript';
