import cn from 'classnames';
import dayjs from 'dayjs';
import React, { InlineCSSVariables } from 'react';
import { chartColors, ChartData, ChartKpiInfo } from '../dataTypes';
import styles from '../TableChart.module.scss';
import { Tooltip } from '@components';

interface ChartLegendProps {
  keys: string[];
  data: ChartData | null;
  onClick?: (key: string) => void;
  activeKey?: string | null;
  unit?: string;
  isMapOpen?: boolean;
  chartKpiInfo?: ChartKpiInfo[];
}

const ChartLegend = ({
  keys,
  data,
  onClick,
  activeKey,
  unit,
  isMapOpen,
  chartKpiInfo
}: ChartLegendProps) => {
  return (
    <div className={styles.chartLegend}>
      <div className={styles.chartLegend__items}>
        {keys
          .filter((key) => !key.includes('_fake'))
          .map((key, index) => {
            const kpiInfo = chartKpiInfo?.find((kpi) => kpi.title === key);

            return (
              <div
                className={styles.chartLegend__item}
                onClick={() => data?.[key] && onClick?.(key)}
              >
                <Tooltip
                  key={key}
                  content={kpiInfo?.description ?? '-'}
                  type="bottom"
                >
                  <div className={styles.chartLegend__title}>
                    <div
                      className={styles.legendSquare}
                      style={{ background: chartColors[index] }}
                    />
                    <p>{key}</p>
                  </div>
                </Tooltip>
                <div
                  className={cn(styles.chartLegend__value, {
                    [styles.chartLegend__value_disabled]: data?.[key] === null,
                    [styles.chartLegend__value_active]:
                      activeKey === key && data,
                    [styles.chartLegend__value_default]:
                      activeKey === key && data && isMapOpen
                  })}
                  style={
                    { '--lineColor': chartColors[index] } as InlineCSSVariables
                  }
                >
                  <div
                    className={cn({
                      [styles.chartLegend__settedValue]:
                        activeKey === key && data
                    })}
                  >
                    {data?.[key] === null
                      ? '—'
                      : data?.[key]?.toLocaleString('fr', {
                          maximumFractionDigits: 2
                        })}
                  </div>
                  {unit && (
                    <span
                      className={cn(styles.chartLegend__unit, {
                        [styles.chartLegend__unit_active]:
                          activeKey === key && data
                      })}
                    >
                      {unit}
                    </span>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <p className={styles.chartLegend__date}>
        {data?.date && dayjs(data.date).format('DD.MM.YYYY')}
      </p>
    </div>
  );
};

export default ChartLegend;
