import React, { FC } from 'react';
import { NotificationType, Typography, Icon } from '@components';
import { Slide, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toastify.css';
import { ToastContainerProps } from 'react-toastify/dist/types';
import { offsets, flex, colors } from '@componentsStyles';
import classNames from 'classnames';

export const openNotification = (
  message: React.ReactNode,
  type?: NotificationType,
  autoClose?: number
) => {
  toast(
    <Typography color={colors.gray100Color} variant="body-1">
      {typeof message === 'string'
        ? message[0].toUpperCase() + message.slice(1)
        : message}
    </Typography>,
    {
      type,
      autoClose: autoClose || false,
      icon: () => getNotificationIcon(type)
    }
  );
};

const getNotificationIcon = (type: NotificationType) => {
  switch (type) {
    case 'success':
      return <Icon name="success" size="s" color="success" />;

    case 'error':
      return <Icon name="error" size="s" color="error" />;

    case 'warning':
      return <Icon name="alert" size="s" color="warning" />;

    case 'info':
      return <Icon name="info" size="s" color="info" />;

    default:
      return <Icon name="success" size="s" color="success" />;
  }
};

export const NotificationContainer: FC<ToastContainerProps> = (props) => {
  const closeButton:
    | React.ReactElement
    | ((props: any) => React.ReactElement)
    | boolean = ({ closeToast }) => (
    <div className={classNames(flex['align-self-center'], offsets['ml-16'])}>
      <Icon name="close" onClick={closeToast} size="s" />
    </div>
  );

  const { className } = props;

  return (
    <ToastContainer
      className={className}
      hideProgressBar={true}
      position="top-right"
      closeButton={closeButton}
      autoClose={10000}
      transition={Slide}
      draggablePercent={60}
      role="alert"
      closeOnClick={false}
    />
  );
};

NotificationContainer.displayName = 'NotificationContainer';
