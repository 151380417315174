import React from 'react';
import { Image, Link, Typography } from '@components';
import { offsets } from '@componentsStyles';
import { Components } from 'react-markdown/src/ast-to-react';

export const reactMarkdownConfig: Components = {
  h1({ children }) {
    return (
      <Typography element={'div'} variant={'h1'}>
        {children}
      </Typography>
    );
  },
  h2({ children }) {
    return (
      <Typography element={'div'} variant={'h2'}>
        {children}
      </Typography>
    );
  },
  h3({ children }) {
    return (
      <Typography element={'div'} variant={'h3'}>
        {children}
      </Typography>
    );
  },
  h4({ children }) {
    return (
      <Typography element={'div'} variant={'h4'}>
        {children}
      </Typography>
    );
  },
  h5({ children }) {
    return (
      <Typography element={'div'} variant={'h5'}>
        {children}
      </Typography>
    );
  },
  h6({ children }) {
    return (
      <Typography element={'div'} variant={'h5'}>
        {children}
      </Typography>
    );
  },
  p({ children }) {
    return (
      <Typography
        element={'div'}
        className={offsets['mb-20']}
        variant={'body-1'}
      >
        {children}
      </Typography>
    );
  },
  strong({ children }) {
    return (
      <Typography className={offsets['mb-20']} variant={'body-2'}>
        {children}
      </Typography>
    );
  },
  b({ children }) {
    return (
      <Typography className={offsets['mb-20']} variant={'body-2'}>
        {children}
      </Typography>
    );
  },
  li({ children }) {
    return (
      <li>
        <Typography
          element={'div'}
          className={offsets['mb-20']}
          variant={'body-1'}
        >
          {children}
        </Typography>
      </li>
    );
  },
  a({ children, href }) {
    return (
      <Link href={href} target={'_blank'}>
        {children}
      </Link>
    );
  },
  img({ src, alt }) {
    return <Image src={src} alt={alt} />;
  }
};
