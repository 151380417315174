import React, { useState, useEffect, useRef } from 'react';

/**
 * Helper for detecting outside's click of ref component and visibility
 */
export const useComponentVisible = (
  initialState: boolean
): {
  ref: React.MutableRefObject<HTMLDivElement | null>;
  isComponentVisible: boolean;
  setIsComponentVisible: (e: boolean) => void;
} => {
  const [isComponentVisible, setIsComponentVisible] =
    useState<boolean>(initialState);
  const ref = useRef<HTMLDivElement | null>(null);

  /**
   * Mouse click support
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsComponentVisible(false);
    }
  };

  /**
   * Escape support
   */
  const handleClickKeyboard = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('keydown', handleClickKeyboard, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('keydown', handleClickKeyboard, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
};
