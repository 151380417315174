import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const InvoiceDetailsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.87868 1.87868C4.44129 1.31607 5.20435 1 6 1H13C13.2652 1 13.5196 1.10536 13.7071 1.29289L20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V20C21 20.7957 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7957 23 18 23H6C5.20435 23 4.44129 22.6839 3.87868 22.1213C3.31607 21.5587 3 20.7957 3 20V4C3 3.20435 3.31607 2.44129 3.87868 1.87868ZM6 3C5.73478 3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 4V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V10H13C12.4477 10 12 9.55228 12 9V3H6ZM14 4.41421L17.5858 8H14V4.41421ZM8 8C8.55228 8 9 8.44772 9 9L9 18C9 18.5523 8.55229 19 8 19C7.44772 19 7 18.5523 7 18L7 9C7 8.44772 7.44772 8 8 8ZM12 12C12.5523 12 13 12.4477 13 13V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V13C11 12.4477 11.4477 12 12 12ZM16 14C16.5523 14 17 14.4477 17 15V18C17 18.5523 16.5523 19 16 19C15.4477 19 15 18.5523 15 18V15C15 14.4477 15.4477 14 16 14Z"
      fill={props.color}
    />
  </SvgIcon>
);

InvoiceDetailsIcon.displayName = 'InvoiceDetailsIcon';
