import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const BookUploadIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 5C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6V15.1716C5.3182 15.0591 5.65606 15 6 15H11C11.5523 15 12 15.4477 12 16C12 16.5523 11.5523 17 11 17H6C5.73478 17 5.48043 17.1054 5.29289 17.2929C5.10536 17.4804 5 17.7348 5 18C5 18.2652 5.10536 18.5196 5.29289 18.7071C5.48043 18.8946 5.73478 19 6 19H14C14.5523 19 15 19.4477 15 20C15 20.5523 14.5523 21 14 21H6C5.20435 21 4.44129 20.6839 3.87868 20.1213C3.31607 19.5587 3 18.7956 3 18V6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 18.5523 10 18 10C17.4477 10 17 9.55228 17 9V5H6ZM17 15.4142L15.7071 16.7071C15.3166 17.0976 14.6834 17.0976 14.2929 16.7071C13.9024 16.3166 13.9024 15.6834 14.2929 15.2929L17.2929 12.2929C17.6834 11.9024 18.3166 11.9024 18.7071 12.2929L21.7071 15.2929C22.0976 15.6834 22.0976 16.3166 21.7071 16.7071C21.3166 17.0976 20.6834 17.0976 20.2929 16.7071L19 15.4142V22C19 22.5523 18.5523 23 18 23C17.4477 23 17 22.5523 17 22V15.4142Z"
      fill={props.color}
    />
  </SvgIcon>
);

BookUploadIcon.displayName = 'BookUploadIcon';
