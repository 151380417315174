import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const PaperclipIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7798 2.38776C14.9836 2.38776 14.2199 2.70406 13.6569 3.26709L4.46691 12.4571C3.5286 13.3954 3.00146 14.668 3.00146 15.995C3.00146 17.3219 3.5286 18.5946 4.46691 19.5329C5.40522 20.4712 6.67783 20.9983 8.0048 20.9983C9.33177 20.9983 10.6044 20.4712 11.5427 19.5329L20.7327 10.3429C21.1232 9.95235 21.7564 9.95235 22.1469 10.3429C22.5374 10.7334 22.5374 11.3666 22.1469 11.7571L12.9569 20.9471C11.6435 22.2605 9.8622 22.9983 8.0048 22.9983C6.1474 22.9983 4.36607 22.2605 3.05269 20.9471C1.73931 19.6337 1.00146 17.8524 1.00146 15.995C1.00146 14.1376 1.73931 12.3563 3.05269 11.0429L12.2427 1.85287C13.1808 0.914775 14.4531 0.387756 15.7798 0.387756C17.1065 0.387756 18.3788 0.914775 19.3169 1.85287C20.255 2.79097 20.782 4.06331 20.782 5.38998C20.782 6.71665 20.255 7.98899 19.3169 8.92709L10.1169 18.1171C9.55409 18.6799 8.79075 18.9961 7.9948 18.9961C7.19886 18.9961 6.43551 18.6799 5.87269 18.1171C5.30988 17.5543 4.99369 16.7909 4.99369 15.995C4.99369 15.199 5.30988 14.4357 5.87269 13.8729L14.3631 5.39246C14.7539 5.00216 15.387 5.00254 15.7773 5.39329C16.1676 5.78404 16.1672 6.41721 15.7765 6.8075L7.28691 15.2871C7.09942 15.4748 6.99369 15.7297 6.99369 15.995C6.99369 16.2605 7.09916 16.5151 7.28691 16.7029C7.47465 16.8906 7.72929 16.9961 7.9948 16.9961C8.26031 16.9961 8.51495 16.8906 8.70269 16.7029L17.9027 7.51287C18.4655 6.94989 18.782 6.18604 18.782 5.38998C18.782 4.59374 18.4657 3.83011 17.9027 3.26709C17.3397 2.70406 16.576 2.38776 15.7798 2.38776Z"
      fill={props.color}
    />
  </SvgIcon>
);

PaperclipIcon.displayName = 'PaperclipIcon';
