import React, { FC } from 'react';
import { Button, ComponentProps, Icon, Typography } from '@components';
import styles from './PricingListRow.module.scss';
import { colors } from '@componentsStyles';
import { Currency, formatNumber } from '@componentsUtils/format';
import { PricingInput } from '../PricingInput';
import { useComponentVisible } from '@componentsUtils';

export interface PricingListRowProps extends ComponentProps {
  numberOfUsers: number;
  price: number;
  currency: Currency;
  usersAmountOptions: number[];
  changePricingItemAmount: (
    oldNumberOfUsers: number,
    numberOfUsers: number
  ) => void;
  changeItemPrice: (numberOfUsers: number, price: number) => void;
  deleteRow: (numberOfUsers: number) => void;
  pricingAmount: number;
  deleteButtonOffset: number;
  percent?: number;
  disabled?: boolean;
  deleteText?: string;
}

export const PricingListRow: FC<PricingListRowProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    deleteText = 'Delete',
    disabled,
    numberOfUsers,
    price,
    percent,
    deleteRow,
    pricingAmount,
    usersAmountOptions,
    changePricingItemAmount,
    changeItemPrice,
    deleteButtonOffset,
    currency
  } = props;

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const toggleOpen = () => {
    if (disabled) {
      return;
    }

    setIsComponentVisible(!isComponentVisible);
  };

  const changeOption = (amount: number) => {
    setIsComponentVisible(false);
    changePricingItemAmount(numberOfUsers, amount);
  };

  const changePrice = (price: number) => {
    changeItemPrice(numberOfUsers, price);
  };

  return (
    <div style={style} id={id} data-testid={dataTestId} className={className}>
      <div className={disabled ? styles['row--disabled'] : styles.row}>
        <div
          ref={ref}
          className={
            disabled
              ? styles['dropdown-header--disabled']
              : styles['dropdown-header']
          }
          onClick={toggleOpen}
        >
          <Typography
            variant="number-1"
            color={disabled ? colors.gray40Color : colors.gray100Color}
          >
            {numberOfUsers}
          </Typography>
          <Icon
            className={
              usersAmountOptions?.length > 0
                ? styles.icon
                : styles['icon--disabled']
            }
            name="chevron-down"
            size="s"
          />

          {isComponentVisible && (
            <div className={styles['dropdown-menu']}>
              {usersAmountOptions?.map((amount) => {
                return (
                  <div
                    key={amount}
                    className={styles['dropdown-menu-item']}
                    onClick={() => changeOption(amount)}
                  >
                    <Typography variant="number-1">{amount}</Typography>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div>
          <PricingInput
            disabled={disabled}
            onChange={changePrice}
            value={price}
            currency={currency}
          />
          {typeof percent === 'number' && !isNaN(percent) && (
            <Typography
              className={styles.percent}
              element="div"
              variant={'label-2'}
            >
              {percent >= 0 && '+ '}
              {percent < 0 && '- '}
              {`${formatNumber(Math.abs(percent), 2)} %`}
            </Typography>
          )}
        </div>

        <Button
          className={
            pricingAmount > 1 ? styles.delete : styles['delete--hidden']
          }
          id="delete-button"
          style={{ right: deleteButtonOffset }}
          type="fourth"
          onClick={() => deleteRow(numberOfUsers)}
          icon="close"
        >
          {deleteText}
        </Button>
      </div>
    </div>
  );
};

PricingListRow.displayName = 'PricingListRow';
