import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const DictionariesIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 3C6.10218 3 5.72064 3.15804 5.43934 3.43934C5.15804 3.72064 5 4.10218 5 4.5V16.3377C5.46443 16.1174 5.97616 16 6.5 16H19V3H6.5ZM21 2C21 1.44772 20.5523 1 20 1H6.5C5.57174 1 4.6815 1.36875 4.02513 2.02513C3.36875 2.6815 3 3.57174 3 4.5V19.5C3 20.4283 3.36875 21.3185 4.02513 21.9749C4.6815 22.6313 5.57174 23 6.5 23H20C20.5523 23 21 22.5523 21 22V2ZM19 18H6.5C6.10218 18 5.72064 18.158 5.43934 18.4393C5.15804 18.7206 5 19.1022 5 19.5C5 19.8978 5.15804 20.2794 5.43934 20.5607C5.72064 20.842 6.10218 21 6.5 21H19V18Z"
      fill={props.color}
    />
  </SvgIcon>
);

DictionariesIcon.displayName = 'DictionariesIcon';
