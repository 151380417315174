import React, { FC } from 'react';
import { ComponentProps, SkeletonPlaceholder, Typography } from '@components';
import { colors, offsets } from '@componentsStyles';
import { DASH } from '@constants';

export interface UserItemProps extends ComponentProps {
  title: string;
  count?: number;
  isLoading?: boolean;
}

export const UserItem: FC<UserItemProps> = (props) => {
  const { count, title, isLoading } = props;

  return (
    <div>
      <Typography
        className={offsets['mb-4']}
        variant="system-heading"
        color={colors.gray40Color}
        element="div"
      >
        {title}
      </Typography>

      {isLoading ? (
        <SkeletonPlaceholder width={60} height={40} />
      ) : (
        <Typography variant={'h1'} element="div">
          {count || DASH}
        </Typography>
      )}
    </div>
  );
};

UserItem.displayName = 'UserItem';
