import { breakpoints } from '@componentsStyles';
import { useState, useEffect } from 'react';

export const XS = 'xs';
export const SM = 'sm';
export const MD = 'md';
export const LG = 'lg';

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const getBreakpoint = (width: number) => {
  if (width < Number(breakpoints.smNumber)) {
    return XS;
  }
  if (width < Number(breakpoints.mdNumber)) {
    return SM;
  }
  if (width < Number(breakpoints.lgNumber)) {
    return MD;
  }
  return LG;
};

export function useCurrentWidth() {
  const [width, setWidth] = useState(getWidth());
  const [breakpoint, setBreakpoint] = useState(getBreakpoint(width));

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        const newWidth = getWidth();
        setWidth(newWidth);
        setBreakpoint(getBreakpoint(newWidth));
      }, 150);
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return { width, breakpoint };
}
