import React, { FC } from 'react';
import { Button, ComponentProps, IconNames, Typography } from '@components';
import styles from './EmptyState.module.scss';
import { colors, offsets } from '@componentsStyles';
import cn from 'classnames';

export interface EmptyStateProps extends ComponentProps {
  heading?: string;
  description?: string;
  onClick?(): void;
  buttonText?: string;
  buttonIcon?: IconNames;
  isCentered?: boolean;
}

export const EmptyState: FC<EmptyStateProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    heading = 'Ooops! It’s empty',
    description,
    onClick,
    buttonText,
    buttonIcon,
    isCentered
  } = props;

  return (
    <div
      className={cn(className, isCentered && styles.centered)}
      style={style}
      id={id}
      data-testid={dataTestId}
    >
      <div className={styles.card}>
        <img
          className={styles.image}
          src={require('@assets/images/empty-state.svg')}
          alt={'empty folder image'}
        />
        {heading && (
          <Typography
            className={
              description ? cn(styles.text, offsets['mb-10']) : styles.text
            }
            variant="h4"
            color={colors.gray60Color}
          >
            {heading}
          </Typography>
        )}
        {description && (
          <Typography
            element={'div'}
            className={styles.text}
            variant="body-1"
            color={colors.gray60Color}
          >
            {description}
          </Typography>
        )}

        {buttonText && (
          <Button
            className={styles.button}
            type={'third'}
            icon={buttonIcon}
            onClick={onClick}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

EmptyState.displayName = 'EmptyState';
