import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const VisualTourChevronRightIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 10 8" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.529247 0.195262C0.789596 -0.0650874 1.21171 -0.0650874 1.47206 0.195262L4.80539 3.5286C5.06574 3.78894 5.06574 4.21106 4.80539 4.4714L1.47206 7.80474C1.21171 8.06509 0.789596 8.06509 0.529247 7.80474C0.268897 7.54439 0.268897 7.12228 0.529247 6.86193L3.39118 4L0.529247 1.13807C0.268897 0.877722 0.268897 0.455612 0.529247 0.195262ZM5.19591 0.195262C5.45626 -0.0650874 5.87837 -0.0650874 6.13872 0.195262L9.47206 3.5286C9.73241 3.78894 9.73241 4.21106 9.47206 4.4714L6.13872 7.80474C5.87837 8.06509 5.45626 8.06509 5.19591 7.80474C4.93556 7.54439 4.93556 7.12228 5.19591 6.86193L8.05784 4L5.19591 1.13807C4.93556 0.877722 4.93556 0.455612 5.19591 0.195262Z"
      fill={props.color}
    />
  </SvgIcon>
);

VisualTourChevronRightIcon.displayName = 'VisualTourChevronRight';
