import React, { FC } from 'react';

import { SvgIcon, SvgIconProps } from '@components';

export const UserMinusIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 4C7.34315 4 6 5.34315 6 7C6 8.65685 7.34315 10 9 10C10.6569 10 12 8.65685 12 7C12 5.34315 10.6569 4 9 4ZM4 7C4 4.23858 6.23858 2 9 2C11.7614 2 14 4.23858 14 7C14 9.76142 11.7614 12 9 12C6.23858 12 4 9.76142 4 7ZM16 11C16 10.4477 16.4477 10 17 10H23C23.5523 10 24 10.4477 24 11C24 11.5523 23.5523 12 23 12H17C16.4477 12 16 11.5523 16 11ZM1.56574 15.442C2.55765 14.5121 3.88994 14 5.26667 14H12.7333C14.1101 14 15.4424 14.5121 16.4343 15.442C17.4284 16.374 18 17.6523 18 19V21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21V19C16 18.2259 15.6726 17.4694 15.0664 16.9011C14.458 16.3307 13.6198 16 12.7333 16H5.26667C4.38022 16 3.54202 16.3307 2.93362 16.9011C2.3274 17.4694 2 18.2259 2 19V21C2 21.5523 1.55228 22 1 22C0.447715 22 0 21.5523 0 21V19C0 17.6523 0.571643 16.374 1.56574 15.442Z"
      fill={props.color}
    />
  </SvgIcon>
);

UserMinusIcon.displayName = 'UserMinusIcon';
