import React, { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Button, ComponentProps, Typography } from '@components';
import styles from './Alert.module.scss';
import { offsets } from '@componentsStyles';

export interface AlertProps extends ComponentProps {
  isOpen: boolean;
  cancelText: string;
  submitText: string;

  onClose(): void;

  onCancel?(): void;

  onSubmit?(): void;
}

export const Alert: FC<AlertProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    children,
    cancelText,
    submitText,
    onCancel,
    onSubmit,
    onClose,
    isOpen = false
  } = props;

  const modalRef = useRef<HTMLDialogElement>(null);

  const submit = () => {
    if (onSubmit) {
      onSubmit();
    }

    close();
  };

  const cancel = () => {
    if (onCancel) {
      onCancel();
    }

    close();
  };

  const close = () => {
    const { current } = modalRef;

    setDialogClasses(cn(className, styles.alert, styles.hide));

    current.addEventListener(
      'animationend',
      () => {
        setDialogClasses(cn(className, styles.alert));
        // @ts-ignore
        current.close();
      },
      { once: true }
    );

    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    const { current } = modalRef;
    if (current) {
      // @ts-ignore
      isOpen && current.showModal();
    }
  }, [isOpen]);

  const [dialogClasses, setDialogClasses] = useState(
    cn(className, styles.alert)
  );

  return (
    <dialog
      id={id}
      style={style}
      className={dialogClasses}
      data-testid={dataTestId}
      ref={modalRef}
    >
      <Typography element={'div'} className={styles.text} variant="body-1">
        {children}
      </Typography>

      <div className={styles.buttons}>
        {onSubmit && (
          <Button
            type={'primary'}
            className={offsets['mr-20']}
            onClick={submit}
          >
            {submitText}
          </Button>
        )}

        <Button type={'secondary'} onClick={cancel}>
          {cancelText}
        </Button>
      </div>
    </dialog>
  );
};

Alert.displayName = 'Alert';
