import React, { forwardRef } from 'react';
import cn from 'classnames';
import { ComponentProps, Icon, Loader } from '@components';
import styles from './Search.module.scss';
import { colors } from '@componentsStyles';

export interface SearchProps extends ComponentProps {
  placeholder: string;
  /**
   * Called when value of an Input changed
   */
  onChange?: (value: string) => void;
  /**
   * Specifies that an Input should be disabled
   */
  disabled?: boolean;
  /**
   * Specifies the value of an Input
   */
  value?: string;
  /**
   * Is loading state
   */
  isLoading?: boolean;
}

export type SearchRef = HTMLInputElement;

export const Search = forwardRef<SearchRef, SearchProps>((props, ref) => {
  const {
    id,
    placeholder,
    className,
    disabled,
    value = '',
    isLoading,
    onChange,
    style,
    dataTestId
  } = props;

  return (
    <div
      className={cn(styles.form, className)}
      style={style}
      id={id}
      data-testid={dataTestId}
    >
      <input
        ref={ref}
        type={'search'}
        value={value}
        className={styles.input}
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        placeholder={placeholder}
        style={{ borderColor: isLoading ? colors.gold : null }}
      />

      <Icon
        name="search"
        size="s"
        className={styles['icon-search']}
        style={{ fill: isLoading ? colors.gold : null }}
      />

      {!isLoading && (
        <Icon
          name="close"
          size="s"
          className={styles['icon-close']}
          onClick={onChange ? () => onChange('') : null}
        />
      )}

      {isLoading && (
        <Loader size="s" color={'orange'} className={styles['icon-loader']} />
      )}
    </div>
  );
});

Search.displayName = 'Search';
