import React, { FC, useEffect, useRef } from 'react';
import {
  AdditionalPaymentsListTranslations,
  Button,
  ComponentProps,
  DateIntervalType,
  DatePicker,
  Locales
} from '@components';
import { Currency } from '@componentsUtils/format';
import { PricingInput } from '@components/PricingList/components/PricingInput';
import styles from './AdditionalPaymentsListRow.module.scss';
import { offsets } from '@componentsStyles';

export interface AdditionalPaymentsListRowProps extends ComponentProps {
  currency: Currency;
  price?: number;
  comment?: string;
  service?: string;
  date?: Date;
  amountOfUsers?: number;
  isEditable?: boolean;
  uuid?: string;
  changeItemPrice: (idx: number, price: number) => void;
  changeItemComment: (idx: number, comment: string) => void;
  changeItemService: (idx: number, service: string) => void;
  changeItemAmountOfUsers: (
    idx: number,
    amountOfUsers: number | string
  ) => void;
  changeItemDate: (idx: number, date: Date) => void;
  deleteRow: (numberOfUsers: number) => void;
  length: number;
  percent?: number;
  deleteText?: string;
  index: number;
  translations?: AdditionalPaymentsListTranslations;
  locale?: Locales;
}

export const AdditionalPaymentsListRow: FC<AdditionalPaymentsListRowProps> = (
  props
) => {
  const {
    id,
    className,
    style,
    dataTestId,
    deleteText = 'Delete',
    comment,
    service,
    price,
    amountOfUsers,
    date,
    isEditable,
    deleteRow,
    length,
    changeItemPrice,
    changeItemComment,
    changeItemService,
    changeItemDate,
    changeItemAmountOfUsers,
    translations,
    currency,
    index,
    locale = 'en'
  } = props;

  const serviceRef = useRef<HTMLTextAreaElement>(null);
  const commentRef = useRef<HTMLTextAreaElement>(null);

  const changePrice = (price: number) => {
    changeItemPrice(index, price);
  };

  useEffect(() => {
    const { current: textarea } = commentRef;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [comment]);

  useEffect(() => {
    const { current: textarea } = serviceRef;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [service]);

  return (
    <div style={style} id={id} data-testid={dataTestId} className={className}>
      <div className={isEditable ? styles.row : styles['row--disabled']}>
        <div className={styles.grid}>
          <DatePicker
            style={{ background: 'inherit' }}
            datePickerPlaceholder={'mm-year'}
            type={'day'}
            dateIntervalType={DateIntervalType.Month}
            locale={locale}
            datePickerValue={date}
            onDatePickerChange={(date) => changeItemDate(index, date)}
            disabled={!isEditable}
            isPopupFixed={true}
          />

          <textarea
            ref={serviceRef}
            style={{ height: 'auto' }}
            data-testid={'input-service'}
            className={isEditable ? styles.input : styles['input--disabled']}
            disabled={!isEditable}
            value={service}
            placeholder={translations.service}
            onChange={(e) => {
              changeItemService(index, e.target.value);
            }}
          />

          <input
            data-testid={'input-amount-of-users'}
            className={isEditable ? styles.input : styles['input--disabled']}
            disabled={!isEditable}
            value={amountOfUsers}
            type={'number'}
            placeholder={translations.amountOfUsers}
            onChange={(e) => {
              changeItemAmountOfUsers(index, e.target.value);
            }}
          />

          <textarea
            ref={commentRef}
            data-testid={'input-comment'}
            className={isEditable ? styles.input : styles['input--disabled']}
            disabled={!isEditable}
            value={comment}
            placeholder={translations.comment}
            onChange={(e) => {
              changeItemComment(index, e.target.value);
            }}
          />

          <PricingInput
            className={offsets['mt-8']}
            inputMaxWidth={'120px'}
            onChange={changePrice}
            value={price}
            currency={currency}
            disabled={!isEditable}
          />
        </div>

        <div className={length > 1 ? styles.delete : styles['delete--hidden']}>
          <Button
            data-testId={'delete-button'}
            type="fourth"
            onClick={() => deleteRow(index)}
            icon="close"
            disabled={!isEditable}
            style={!isEditable ? { display: 'none' } : {}}
          >
            {deleteText}
          </Button>
        </div>
      </div>
    </div>
  );
};

AdditionalPaymentsListRow.displayName = 'AdditionalPaymentsListRow';
