import React, { FC } from 'react';
import {
  ComponentProps,
  Typography,
  Card,
  Divider,
  Chevron,
  Icon,
  SkeletonPlaceholder,
  Tooltip
} from '@components';
import cn from 'classnames';
import styles from './Licenses.module.scss';
import { colors, offsets } from '@componentsStyles';
import { LicensesCards, LicensesTranslations } from './dataTypes';
import { formatDate, formatNumber } from '@componentsUtils';

export interface LicensesProps extends ComponentProps {
  licenses: LicensesCards[];
  translations?: LicensesTranslations;
  isLoading?: boolean;
  isOmniView?: boolean;
  onSeeDetails?(): void;
}

const defaultTranslations: LicensesTranslations = {
  seeDetails: 'See details',
  license: 'License',
  activeUsers: 'Active users',
  invoicing: 'invoicing',
  paymentMethod: 'payment method',
  activationDate: 'Activation date',
  billingStartDate: 'Billing start date',
  expiryDate: 'Expiry date',
  licenses: 'Licenses',
  notDefined: 'Not defined',
  trialVersion: 'trial version',
  unlimited: 'Unlimited'
};

export const Licenses: FC<LicensesProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    translations = defaultTranslations,
    licenses,
    isLoading,
    onSeeDetails,
    isOmniView
  } = props;

  const emptyLicenses: LicensesCards[] = [
    {
      uuid: '0',
      license: translations.notDefined
    }
  ];

  const gridClasses = cn(
    offsets['mt-16'],
    styles.grid,
    isOmniView ? styles['grid-omni'] : styles['grid-admin']
  );

  const loadingItems = [1, 2];

  return (
    <Card
      className={cn(
        className,
        isLoading ? styles['card--loading'] : styles.card
      )}
      style={style}
      id={id}
      data-testid={dataTestId}
    >
      <Typography variant="h5" className={styles.header}>
        {translations?.licenses}
      </Typography>

      <div className={styles['scroll-wrapper']}>
        <div
          className={styles.details}
          data-testid="details-icon"
          onClick={onSeeDetails && onSeeDetails}
        >
          <Tooltip content={translations.seeDetails}>
            <Icon size="s" name="chevrons-right" />
          </Tooltip>
        </div>

        <div className={styles['scroll-container']}>
          <div
            className={
              isOmniView ? styles['min-width-omni'] : styles['min-width-admin']
            }
          >
            <div className={gridClasses}>
              <Typography variant="system-heading" color={colors.gray40Color}>
                {translations?.license}
              </Typography>
              <Typography
                variant="system-heading"
                color={colors.gray40Color}
                className={cn(styles.right, offsets['mr-40'])}
              >
                {translations?.activeUsers}
              </Typography>
              {isOmniView && (
                <Typography variant="system-heading" color={colors.gray40Color}>
                  {`${translations?.invoicing} / ${translations.paymentMethod}`}
                </Typography>
              )}
              <Typography variant="system-heading" color={colors.gray40Color}>
                {translations?.activationDate}
              </Typography>
              {isOmniView && (
                <Typography variant="system-heading" color={colors.gray40Color}>
                  {translations?.billingStartDate}
                </Typography>
              )}
              <Typography
                variant="system-heading"
                color={colors.gray40Color}
                className={styles.right}
              >
                {translations?.expiryDate}
              </Typography>
            </div>

            <Divider type="thin" className={offsets['pt-8']} />

            {!isLoading &&
              (licenses && licenses?.length > 0
                ? licenses
                : emptyLicenses
              )?.map((license, key) => {
                return (
                  <div key={key} className={gridClasses}>
                    <div>
                      <Typography variant="body-4" element="div">
                        {license?.license}
                      </Typography>
                      {license?.isTrial && (
                        <Chevron className={offsets['mt-4']}>
                          {translations?.trialVersion}
                        </Chevron>
                      )}
                    </div>

                    <Typography
                      variant="number-1"
                      element="div"
                      className={cn(styles.right, offsets['mr-40'])}
                    >
                      {formatNumber(license?.activeUsers)}
                    </Typography>

                    {isOmniView && (
                      <Typography variant="body-4" element="div">
                        {license?.invoicing && license?.paymentMethod
                          ? `${license?.invoicing} / ${license.paymentMethod}`
                          : translations.notDefined}
                      </Typography>
                    )}

                    <Typography variant="body-4" element="div">
                      {license?.activationDate
                        ? formatDate(license?.activationDate)
                        : translations.notDefined}
                    </Typography>
                    {isOmniView && (
                      <Typography variant="body-4" element="div">
                        {license?.billingStartDate
                          ? formatDate(license?.billingStartDate)
                          : translations.notDefined}
                      </Typography>
                    )}

                    <Typography
                      variant="body-4"
                      element="div"
                      className={styles.right}
                    >
                      {license?.isTrial
                        ? license?.expiryDate
                          ? formatDate(license?.expiryDate)
                          : translations.notDefined
                        : translations.unlimited}
                    </Typography>
                  </div>
                );
              })}

            {isLoading &&
              loadingItems.map((el) => (
                <div key={el} className={gridClasses}>
                  <SkeletonPlaceholder width={100} height={18} />
                  <SkeletonPlaceholder
                    width={30}
                    height={18}
                    className={cn(styles.right, offsets['mr-40'])}
                  />
                  {isOmniView && <SkeletonPlaceholder width={90} height={18} />}
                  <SkeletonPlaceholder width={90} height={18} />
                  {isOmniView && <SkeletonPlaceholder width={90} height={18} />}
                  <SkeletonPlaceholder
                    width={68}
                    height={18}
                    className={styles.right}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </Card>
  );
};

Licenses.displayName = 'Licenses';
