import React, { FC } from 'react';
import Joyride, { CallBackProps, Placement, Step } from 'react-joyride';
import { ComponentProps, Icon, Typography } from '@components';
import { VisualTourStepItemProps } from '@components/VisualTour/dataTypes';
import styles from './VisualTour.module.scss';
import {
  floaterProps,
  joyrideStyleConfig
} from '@components/VisualTour/config';

export interface VisualTourProps extends ComponentProps {
  runTour?: boolean;
  stepIndex?: number;
  steps: VisualTourStepItemProps[];
  previousText?: string;
  nextText?: string;
  lastText?: string;
  disableScrolling?: boolean;
  disableScrollParentFix?: boolean;

  visualTourCallback(data: CallBackProps): void;
}

export const VisualTour: FC<VisualTourProps> = (props) => {
  const {
    runTour,
    stepIndex,
    steps,
    visualTourCallback,
    previousText,
    nextText,
    lastText,
    disableScrolling,
    disableScrollParentFix
  } = props;

  const stepsData: Step[] = steps.map((step) => {
    return {
      title: step.title,
      content: step.content,
      target: step.target,
      data: step.data ?? undefined,
      disableBeacon: true,
      spotlightPadding: step.spotlightPadding || 0,
      placement: step.placement as Placement,
      isFixed: true
    };
  });

  const handleVisualTourCallback = (data: CallBackProps) => {
    visualTourCallback && visualTourCallback(data);
  };

  return (
    <Joyride
      run={runTour}
      disableOverlayClose={true}
      continuous
      scrollDuration={500}
      steps={stepsData}
      stepIndex={stepIndex}
      callback={handleVisualTourCallback}
      disableScrolling={disableScrolling}
      disableScrollParentFix={disableScrollParentFix}
      floaterProps={floaterProps}
      styles={joyrideStyleConfig}
      locale={{
        back: (
          <div className={styles['btn-icon']}>
            <Icon
              name="visual-tour-chevron-left"
              size="xs"
              color="white"
              className={styles['icon-back']}
            />
            <Typography variant="button-third">
              {previousText ? previousText : 'Back'}
            </Typography>
          </div>
        ),
        next: (
          <div className={styles['btn-icon']}>
            <Typography variant="button-third">
              {nextText ? nextText : 'Next'}
            </Typography>
            <Icon
              name="visual-tour-chevron-right"
              size="xs"
              color="white"
              className={styles['icon-next']}
            />
          </div>
        ),
        last: (
          <div className={styles['btn-icon']}>
            <Typography variant="button-third">
              {lastText ? lastText : 'Done'}
            </Typography>
            <Icon
              name="visual-tour-chevron-right"
              size="xs"
              color="white"
              className={styles['icon-next']}
            />
          </div>
        )
      }}
    />
  );
};
