import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
const clickable = require('@assets/images/clickable.svg');
import { ComponentProps, Icon } from '@components';
import styles from './Avatar.module.scss';
import { AvatarSize } from './dataTypes';

export interface AvatarProps extends ComponentProps {
  name: string;
  disabled?: boolean;
  onChange?: (file: File) => void;
  size?: AvatarSize;
  image?: string;
  file?: File;
}

export const Avatar: FC<AvatarProps> = (props) => {
  const {
    id,
    className,
    style,
    dataTestId,
    disabled,
    onChange,
    size = 'large',
    name,
    image
  } = props;

  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState<string>(image);

  useEffect(() => {
    if (imageFile) {
      setImageUrl(URL.createObjectURL(imageFile));
    }
  }, [imageFile]);

  useEffect(() => {
    setImageUrl(image);
  }, [image]);

  const avatarClasses = () => {
    return classNames(styles.avatar, styles[`avatar--${size}`]);
  };

  const changeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    setImageFile(file);
    if (onChange) {
      onChange(file);
    }
  };

  const avatarName = () => {
    if (!name) {
      return '';
    }

    return name
      .split(' ')
      .reduce((res, el) => {
        if (el) {
          return (res += el[0]);
        }
        return res;
      }, '')
      .slice(0, 2);
  };

  return (
    <span
      id={id}
      className={classNames(className, styles.wrapper)}
      style={style}
      data-testid={dataTestId}
    >
      {imageUrl ? (
        <img className={avatarClasses()} src={imageUrl} alt="Avatar" />
      ) : (
        <span className={classNames(avatarClasses(), styles['avatar--name'])}>
          <span className={styles[`letters--${size}`]}>{avatarName()}</span>
        </span>
      )}

      {!disabled && (
        <span className={styles['click-zone']}>
          <input
            type="file"
            accept="image/*"
            id="img"
            data-testid="input-file"
            name="img"
            style={{ display: 'none' }}
            onChange={(e) => changeImage(e)}
          />
          <label className={styles[`input--${size}`]} htmlFor="img" />

          <img
            alt={'clickable'}
            src={clickable}
            className={styles[`hover--${size}`]}
          />
          <Icon
            size="s"
            className={styles[`icon--${size}`]}
            color={'white'}
            name={'chevron-down'}
          />
        </span>
      )}
    </span>
  );
};

Avatar.displayName = 'Avatar';
