import React, { FC, useEffect, useState } from 'react';
import { colors, offsets } from '@componentsStyles';
import { Typography, Icon, IconNames } from '@components';
import { FileInfo } from '../../dataTypes';
import styles from './../File.module.scss';
import { displayFileName } from '@components/Uploader/components/utils';

interface UploadedFileProps {
  fileInfo: FileInfo;
  deleteFile: (file: FileInfo, event: any) => void;
  disabled?: boolean;
  loadingFailedText?: string;
  onClick?: (file: FileInfo) => void;
}

export const UploadedFile: FC<UploadedFileProps> = (props) => {
  const { disabled, fileInfo, deleteFile, loadingFailedText, onClick } = props;

  let timerId: NodeJS.Timeout = null;
  const now = new Date();

  const defaultIcon = (): IconNames => {
    if (fileInfo.status === 'error' || !fileInfo.uploadingTime) {
      return 'trash';
    }

    const timeDiff = now.getSeconds() - fileInfo.uploadingTime.getSeconds();
    const delay = 1000;

    if (timeDiff <= delay) {
      if (!timerId) {
        timerId = setTimeout(() => {
          setIcon('trash');
          fileInfo.uploadingTime = null;
        }, delay - timeDiff);
      }
      return 'success';
    }
  };

  useEffect(() => {
    setIcon(defaultIcon());
    return () => clearTimeout(timerId);
  }, [fileInfo]);

  const fillColor = (): string => {
    if (disabled) {
      return colors.gray20Color;
    }
    if (icon === 'success') {
      return colors.green70Color;
    }
    if (fileInfo.status === 'error') {
      return colors.red70Color;
    }

    return colors.gray60Color;
  };

  const isError = fileInfo.status === 'error';
  const textColor = isError ? colors.gray40Color : colors.gray100Color;

  const [icon, setIcon] = useState<IconNames>(null);

  return (
    <div className={styles.file} onClick={() => onClick(fileInfo)}>
      <div className={styles['file-wrap']}>
        <div
          className={
            isError ? styles['file-block-error'] : styles['file-block']
          }
        >
          <Icon
            style={{ fill: textColor }}
            size="s"
            className={offsets['mr-8']}
            name="paperclip"
          />
          <Typography
            color={textColor}
            className={styles['file-name']}
            variant="label-1"
          >
            {displayFileName(fileInfo.file.name, fileInfo.file.size).nameStart}
          </Typography>
          <Typography
            color={textColor}
            variant="label-1"
            className={styles['file-name-end']}
          >
            {displayFileName(fileInfo.file.name, fileInfo.file.size).nameEnd}
          </Typography>
          <Typography
            color={textColor}
            variant="label-1"
            className={offsets['ml-8']}
          >
            {displayFileName(fileInfo.file.name, fileInfo.file.size).size}
          </Typography>
        </div>

        {isError && (
          <Typography element="div" color={colors.red70Color} variant="label-1">
            {loadingFailedText}
          </Typography>
        )}
      </div>

      <div
        onClick={(event: any) =>
          disabled || icon === 'success' ? null : deleteFile(fileInfo, event)
        }
      >
        <Icon
          className={
            disabled ? styles['file-icon--disabled'] : styles['file-icon']
          }
          size="m"
          data-testid="file-icon"
          name={icon}
          style={{
            fill: fillColor()
          }}
        />
      </div>
    </div>
  );
};
