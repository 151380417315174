import { GoogleMap, Polygon, useLoadScript } from '@react-google-maps/api';
import cn from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './Map.module.scss';
import {
  BottomData,
  CloseControl,
  DataInfo,
  FullscreenControl,
  Marker,
  ZoomControls
} from './components';
import { MapProps, ShapeWithCenter } from './dataTypes';
import { noop } from '@componentsUtils';

export const Map: FC<MapProps> = (props) => {
  const {
    id,
    options = { disableDefaultUI: true },
    mapContainerClassName,
    googleMapsApiKey,
    zoom = 10,
    wrapperClassName,
    headerData,
    bottomData,
    hideControls,
    hideCloseControl,
    hideFullscreenControl,
    hideZoomControls,
    onCloseClick,
    onFullscreenClick,
    shapes,
    activeShape,
    center,
    onZoomChange,
    onBoundChange,
    onMapLoad
  } = props;
  const [map, setMap] = useState<google.maps.Map | null>(null);
  let formattedShapes: ShapeWithCenter[] | [] = [];

  const jsonShape = JSON.stringify(activeShape ?? {});

  useEffect(() => {
    if (map && activeShape) {
      map?.setZoom(13);
      map?.setCenter({
        lat: getMarkerPosition(activeShape?.id)?.lat(),
        lng: getMarkerPosition(activeShape?.id)?.lng()
      });
    }
  }, [map, jsonShape]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey
  });

  if (shapes) {
    formattedShapes = shapes.map((shape) => {
      const bounds =
        typeof google !== 'undefined'
          ? new google.maps.LatLngBounds()
          : undefined;
      shape.coordinates.forEach((coord) => bounds?.extend(coord));
      const center = bounds?.getCenter();

      return {
        ...shape,
        center
      };
    });
  }

  const centerObj = useMemo(
    () => center || { lat: 55.7558, lng: 37.6173 },
    [center]
  );

  const getMarkerPosition = (id: number) => {
    const shape = formattedShapes.find(
      (shape: ShapeWithCenter) => shape.id === id
    );
    return shape?.center;
  };

  const getMarkerTitle = (id: number) => {
    const shape = formattedShapes.find(
      (shape: ShapeWithCenter) => shape.id === id
    );
    return shape?.submarket;
  };

  const onLoad = (map: google.maps.Map) => {
    if (onMapLoad) {
      onMapLoad(map);
    }
    setMap(map);
  };

  return (
    <div className={cn(styles.wrapper, wrapperClassName)} id={id}>
      {!isLoaded ? (
        <div>Loading...</div>
      ) : (
        <div className={styles.mapWrapper}>
          <GoogleMap
            options={options}
            onZoomChanged={onZoomChange ? onZoomChange : noop}
            onBoundsChanged={onBoundChange ? onBoundChange : noop}
            mapContainerClassName={cn(
              styles.defaultMapContainer,
              mapContainerClassName
            )}
            center={centerObj}
            zoom={zoom}
            onLoad={onLoad}
          >
            {formattedShapes?.map((shape) => (
              <Polygon
                onClick={shape.onClick}
                paths={shape.coordinates}
                options={{
                  ...shape,
                  zIndex: activeShape?.id === shape.id ? 2 : 1,
                  strokeColor:
                    activeShape?.id === shape.id ? '#FFFFFF' : shape.strokeColor
                }}
                key={shape.id}
              />
            ))}
            {activeShape && (
              <Marker
                activeShape={{
                  ...activeShape,
                  position: getMarkerPosition(activeShape.id),
                  title: getMarkerTitle(activeShape.id)
                }}
              />
            )}
          </GoogleMap>
          {headerData && <DataInfo>{headerData}</DataInfo>}
          {!hideControls && (
            <div className={styles.controls}>
              {!hideCloseControl && <CloseControl onClick={onCloseClick} />}
              {!hideFullscreenControl && (
                <FullscreenControl onClick={onFullscreenClick} />
              )}
              {!hideZoomControls && <ZoomControls map={map} />}
            </div>
          )}
          {bottomData && <BottomData>{bottomData}</BottomData>}
        </div>
      )}
    </div>
  );
};

Map.displayName = 'Map';
