import { Icon } from '@components/Icon';
import cn from 'classnames';
import React, { FC, useState } from 'react';
import styles from './FullscreenControl.module.scss';

export interface FullscreenControlProps {
  className?: string;
  onClick?: () => void;
}

export const FullscreenControl: FC<FullscreenControlProps> = (props) => {
  const { className, onClick } = props;
  const [isExpand, setIsExpand] = useState<boolean>(false);

  const handleClick = () => {
    setIsExpand(!isExpand);
    onClick && onClick();
  };

  return (
    <div className={cn(styles.wrapper, className)} onClick={handleClick}>
      <Icon name={isExpand ? 'minimize-map' : 'fullscreen'} size="s" />
    </div>
  );
};
